import * as React from 'react';

import { cn } from '../../../utils/shadcn';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  minHeight?: string;
  maxHeight?: string;
  autoResize?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, minHeight = '60px', maxHeight = '500px', autoResize = false, ...props }, ref) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const handleResize = React.useCallback(
      (textarea: HTMLTextAreaElement) => {
        if (!autoResize) return;

        // Reset height to allow shrinkage
        textarea.style.height = minHeight;

        // Calculate new height
        const newHeight = Math.min(Math.max(textarea.scrollHeight, parseInt(minHeight)), parseInt(maxHeight));

        textarea.style.height = `${newHeight}px`;
      },
      [autoResize, minHeight, maxHeight]
    );

    React.useEffect(() => {
      const textarea = textareaRef.current;
      if (textarea && autoResize) {
        handleResize(textarea);
        textarea.addEventListener('input', () => handleResize(textarea));
      }

      return () => {
        if (textarea && autoResize) {
          textarea.removeEventListener('input', () => handleResize(textarea));
        }
      };
    }, [autoResize, handleResize]);

    return (
      <textarea
        className={cn(
          'flex w-full rounded-2xl border border-input text-muted-foreground bg-input px-4 py-4 ring-offset-0 hover:placeholder:text-foreground placeholder:text-muted focus-visible:outline-none focus-visible:ring-0 focus:border-transparent focus-visible:ring-transparent focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={(node) => {
          textareaRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        style={{ minHeight, maxHeight }}
        {...props}
      />
    );
  }
);

Textarea.displayName = 'Textarea';

export { Textarea };
