import { LuUsers } from 'react-icons/lu';

import { CREATOR_COIN_TYPES } from 'utils/constants';
import LowNumFormatter from './LowNumFormatter';
import { abbreviateNumber } from './USDValueWithDESO';
import { cn } from '../../utils/shadcn';
import { Avatar } from '../core/Avatar';
import getContentBlurredStyle from '../../utils/blurred';
import { InfoTooltip } from 'components/core/InfoTooltip';
import { Badge } from '../shadcn/ui/badge';
import { Progress } from '../shadcn/ui/progress';
import { formatDecimalValue, usdToDeso, usdToFocus } from 'utils/currency';
import { CoinCategoryFormatter } from './CoinCategoryFormatter';
import { getUserName } from '../../utils/user';
import { Account, UserDaoCoinStat } from '../../graphql/codegen/graphql';
import { Routes } from '../../RoutePaths';
import { RouteLink } from '../core/RouteLink';
import { DESO_TICKER, FOCUS_TICKER, USD_TICKER } from '../../constants/TradeConstants';
import { DESO_USDC_PUBLIC_KEY, DESO_ZERO_PUBLIC_KEY, FOCUS_TOKEN_PUBLIC_KEY } from '../../constants/AppConstants';
import { getDominantMarketQuoteCurrencyPublicKey } from '../../utils/orderbook';

export const renderDenominatingCurrencyTableCell = ({
  coinStat,
  focusExchangeRate,
  desoExchangeRate,
  usdValue,
}: {
  coinStat: UserDaoCoinStat;
  focusExchangeRate: number;
  desoExchangeRate: number;
  usdValue: number;
}) => {
  const dominantMarketPublicKey = getDominantMarketQuoteCurrencyPublicKey(coinStat);

  let price: number;
  let denominatingCurrencyDisplayName: string;

  switch (dominantMarketPublicKey) {
    case FOCUS_TOKEN_PUBLIC_KEY:
      price = usdToFocus(Number(usdValue ?? 0), focusExchangeRate);
      denominatingCurrencyDisplayName = FOCUS_TICKER;
      break;
    case DESO_USDC_PUBLIC_KEY:
      price = Number(usdValue ?? 0);
      denominatingCurrencyDisplayName = USD_TICKER;
      break;
    case DESO_ZERO_PUBLIC_KEY:
    default:
      price = usdToDeso(Number(usdValue ?? 0), desoExchangeRate);
      denominatingCurrencyDisplayName = DESO_TICKER;
      break;
  }

  return (
    <div className="font-mono text-xs text-muted hover:text-foreground">
      <LowNumFormatter price={price} isUsd={false} />{' '}
      <span className="text-[10px]">{denominatingCurrencyDisplayName}</span>
    </div>
  );
};

export const renderPriceUsdTableCell = (
  coinStat: UserDaoCoinStat,
  focusExchangeRate: number,
  desoExchangeRate: number,
) => {
  const priceUsd = Number(coinStat.priceUsd ?? 0);

  return (
    <div className="flex flex-col gap-0 text-left">
      <div className="flex items-center font-mono text-muted-foreground">
        <LowNumFormatter className="text-sm" price={priceUsd} />
      </div>
      {renderDenominatingCurrencyTableCell({
        coinStat,
        focusExchangeRate,
        desoExchangeRate,
        usdValue: priceUsd,
      })}
    </div>
  );
};

export const renderCoinUserTableCell = ({
  coinUsername,
  coinPublicKey,
  extraData,
  clickable,
  coinCategory,
  usernamePrefix = '$',
  isTokenCreator = false,
  footerText,
  avatarSize = 40,
  userNameClassName,
}: {
  coinUsername: string;
  coinPublicKey: string;
  extraData?: Record<string, string>;
  clickable: boolean;
  coinCategory?: CREATOR_COIN_TYPES | string | null;
  usernamePrefix?: string;
  isTokenCreator?: boolean;
  footerText?: string;
  avatarSize?: number;
  userNameClassName?: string;
}) => {
  return (
    <div>
      <div className="flex w-full items-center gap-2 text-sm">
        <RouteLink to={Routes.profile(coinUsername)}>
          <Avatar size={'sm'} src={coinPublicKey} />
        </RouteLink>
        <div
          className="flex grow flex-col items-start gap-0 w-[calc(100%-40px)]"
          style={getContentBlurredStyle(coinUsername?.length || coinPublicKey?.length)}
        >
          <RouteLink to={Routes.profile(coinUsername)}>
            <div className="inline-flex text-ellipsis underline-offset-4 hover:underline text-foreground font-semibold">
              {getUserName({
                account: {
                  username: coinUsername,
                  publicKey: coinPublicKey,
                  extraData,
                } as Account,
                customPrefix: usernamePrefix,
              })}
            </div>
          </RouteLink>

          {isTokenCreator && (
            <InfoTooltip text={<span className="text-xs text-muted">You are the owner of this token</span>}>
              <Badge className="my-1 text-[10px] px-2 py-1 leading-none">Created By You</Badge>
            </InfoTooltip>
          )}

          {coinCategory && <CoinCategoryFormatter coinCategory={coinCategory} className="text-muted" />}

          {footerText && <span className="text-muted">{footerText}</span>}
        </div>
      </div>
    </div>
  );
};

export const renderMarketCapTableCell = ({
  coinStat,
  focusExchangeRate,
  desoExchangeRate,
}: {
  coinStat: UserDaoCoinStat;
  focusExchangeRate: number;
  desoExchangeRate: number;
}) => {
  const marketCapUsd = Number(coinStat.marketCapUsd ?? 0);

  return (
    <div className="flex flex-col gap-0 text-left font-mono text-foreground">
      <div className="text-sm">${abbreviateNumber(marketCapUsd)}</div>

      {renderDenominatingCurrencyTableCell({
        coinStat,
        focusExchangeRate,
        desoExchangeRate,
        usdValue: marketCapUsd,
      })}
    </div>
  );
};

export const renderMarketCapTooltip = () => {
  return (
    <InfoTooltip
      iconSize={16}
      text="Market cap is simply computed as price times the total number of coins in
circulation. Note that locked coins are included in the supply in the top
row, but not in the second row."
    />
  );
};

export const renderHoldersCell = (numHolders: number) => {
  return (
    <div className="ml-auto flex items-center justify-end gap-2 pr-3 text-right hover:opacity-80">
      <div className="font-mono text-sm">{abbreviateNumber(numHolders)}</div>
      <LuUsers className="size-4 text-muted" />
    </div>
  );
};

export const renderVolumeTableCell = ({
  coinStat,
  focusExchangeRate,
  desoExchangeRate,
}: {
  coinStat: UserDaoCoinStat;
  focusExchangeRate: number;
  desoExchangeRate: number;
}) => {
  const volumeUsd = Number(coinStat.volumeUsdPast24H ?? 0);

  return (
    <div className="flex flex-col gap-0 text-left font-mono text-xs">
      <div className={cn('font-mono text-sm', volumeUsd === 0 ? 'text-muted' : 'text-foreground')}>
        ${abbreviateNumber(volumeUsd)}
      </div>

      {renderDenominatingCurrencyTableCell({
        coinStat,
        focusExchangeRate,
        desoExchangeRate,
        usdValue: volumeUsd,
      })}
    </div>
  );
};

export const renderCirculatingSupplyTableCell = ({
  circulatingSupply,
  totalSupply,
}: {
  circulatingSupply: number;
  totalSupply: number;
}) => {
  const circulatingSupplyPercentage = Number(
    formatDecimalValue((Number(circulatingSupply) * 100) / Number(totalSupply)),
  );
  const percentage = Number.isNaN(circulatingSupplyPercentage) ? 0 : circulatingSupplyPercentage;

  return (
    <div className="flex w-full flex-col gap-0 font-mono text-xs">
      <div className="text-left font-mono text-sm text-foreground">{abbreviateNumber(circulatingSupply)}</div>
      <div className="flex flex-row items-center gap-2 text-left">
        <Progress indicatorClassName="bg-muted-foreground" value={percentage} className="h-1 max-w-[60%] bg-card" />
        <span className="text-[10px] text-muted">{percentage}%</span>
      </div>
    </div>
  );
};

export const renderCirculatingSupplyTooltip = () => {
  return <InfoTooltip iconSize={16} text="This is the total number of coins in circulation, including locked coins." />;
};

export const renderApyTooltip = () => {
  return (
    <InfoTooltip
      iconSize={16}
      text='This column shows the APY (annual percentage yield) you would get for
  locking your coins for exactly one year. You can lock your coins by
  hitting the three dots and then hitting "Lock."'
    />
  );
};
