import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '../../../utils/shadcn';
import { Spinner } from '../../core/Spinner';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full !font-sans text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-foreground focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 disabled:bg-card disabled:text-muted disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default:
          'border border-transparent bg-primary text-primary-foreground hover:border-primary hover:bg-primary-foreground hover:text-primary',
        destructive: 'hover:bg-destructive/90 bg-destructive text-destructive-foreground',
        success: 'bg-green-600 text-black hover:bg-green-600/90',
        outline: 'border border-border bg-transparent text-foreground hover:bg-card hover:text-muted-foreground',
        outlinePrimary: 'border border-primary bg-transparent text-primary hover:bg-card hover:text-primary',
        secondary: 'border border-border-light bg-secondary text-muted-foreground hover:opacity-80',
        ghost: 'text-muted hover:bg-transparent hover:text-primary',
        link: 'button-link text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-6 px-3 text-xs font-normal',
        sm: 'h-9 px-3',
        lg: 'h-11 px-8',
        xl: 'h-14 px-8',
        icon: 'size-10',
        tooltip: 'size-5',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, loading = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={props.disabled || loading}
        type="button"
        {...props}
      >
        {loading ? <Spinner size={30} /> : props.children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
