import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { ReactNode } from 'react';
import { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

interface SwiperCarouselProps {
  slides: ReactNode[];
}
export function SwiperCarousel({ slides, ...rest }: SwiperCarouselProps & SwiperProps) {
  return (
    <Swiper pagination={true} navigation={true} modules={[Navigation]} {...rest}>
      {slides.map((s, i) => (
        <SwiperSlide key={i}>{s}</SwiperSlide>
      ))}
    </Swiper>
  );
}
