import axios from 'axios';
import { FOCUS_URL } from '../constants/AppConstants';

const axiosInstance = axios.create({
  baseURL: `${FOCUS_URL}/api/v0`,
});

interface CandlestickHistoryRequest {
  symbol: string;
  to: number;
  resolution: '1M' | '5M' | '15M' | '1H' | '3H' | '1D' | '1W' | '4W';
  countback: number;
  quoteSymbol: string; // Quote Currency Public Key
}

interface Candlestick {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
  timestamp: string; // Human-readable equivalent of the time field
}

interface CandlestickHistoryResponse {
  candlesticks: Candlestick[];
}

export const getCandlestickHistory = async (params: CandlestickHistoryRequest): Promise<CandlestickHistoryResponse> => {
  const { symbol, to, resolution, countback, quoteSymbol } = params;

  const { data } = await axiosInstance.get(`tokens/candlesticks/history`, {
    params: {
      symbol,
      to,
      resolution,
      countback,
      quoteSymbol,
    },
  });

  return { candlesticks: data };
};
