import { useEffect, useState } from 'react';
import { linkifyText } from 'utils/text';
import { Button as ButtonNew } from 'components/shadcn/ui/button';

const MAX_POST_LENGTH = 500;

interface ActivityFeedContentBodyProps {
  body: string;
  truncateContent?: boolean;
}

export function ActivityFeedContentBody({ body, truncateContent = true }: ActivityFeedContentBodyProps) {
  const [postBody, setPostBody] = useState<string>('');
  const chars = Array.from(body);
  const hasReadMore = truncateContent && chars.length > MAX_POST_LENGTH;
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setPostBody(linkifyText(hasReadMore && !isExpanded ? `${chars.slice(0, MAX_POST_LENGTH).join('')}...` : body));
  }, [isExpanded]);

  return (
    <>
      <div
        className="max-w-[90%] text-foreground leading-5 text-sm break-words"
        dangerouslySetInnerHTML={{ __html: postBody }}
      ></div>
      {hasReadMore && (
        <ButtonNew
          variant="ghost"
          className="p-0"
          onClick={(ev) => {
            ev.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? 'See less' : 'See more'}
        </ButtonNew>
      )}
    </>
  );
}
