import type { Column } from '@tanstack/react-table';
import type { ReactNode } from 'react';
import { LuArrowDown, LuArrowUp, LuArrowUpDown } from 'react-icons/lu';
import { cn } from '../../utils/shadcn';
import { Button } from '../shadcn/ui/button';

interface RenderSortingHeaderProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<T, unknown>;
  title: string;
  infoTooltip?: ReactNode;
}

export const renderSortingHeader = <T,>({
  column,
  title = '',
  className,
  infoTooltip,
  sortable = true,
}: RenderSortingHeaderProps<T> & {
  sortable?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const isSortedCn = column.getIsSorted();

  if (!sortable) {
    return (
      <div className={cn('flex items-center text-left', className)}>
        <span>{title}</span>
        {infoTooltip && <div className="ml-1 size-4">{infoTooltip}</div>}
      </div>
    );
  }

  return (
    <Button
      variant="ghost"
      onClick={() => column.toggleSorting(!isSortedCn || isSortedCn === 'asc')}
      className={cn('justify-start px-0 text-muted-foreground text-left hover:text-muted', className)}
    >
      <span>{title}</span>
      {infoTooltip && <div className="ml-1 size-4">{infoTooltip}</div>}
      {isSortedCn && (
        <>{isSortedCn === 'asc' ? <LuArrowUp className="ml-2 size-4" /> : <LuArrowDown className="ml-2 size-4" />}</>
      )}

      {!isSortedCn && <LuArrowUpDown className="ml-2 size-4" />}
    </Button>
  );
};
