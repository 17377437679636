import { Autolinker } from 'autolinker';
import escape from 'lodash/escape';
import twttr from 'twitter-text';

export function centerEllipsis(text: string, pad: number) {
  if (text.length <= pad * 2) {
    return text;
  }
  const prefix = text.slice(0, pad);
  const suffix = text.slice(text.length - pad, text.length);
  return `${prefix}...${suffix}`;
}

export function toTitleCase(text: string) {
  return text
    .split(' ')
    .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}`)
    .join(' ');
}

export function linkifyText(text: string, linkClassName: string = ''): string {
  // limit of two new lines in a row and display new lines
  let result = escape(text.replace(/\n\n+/g, '\n\n')).replace(/\n/g, '<br>');

  // This expands @-mentions and $-cashtags into links to profiles.
  // This is going to expand anything that's prefixed with @/$ into links, not just usernames.
  // Maybe we should have more code to validate whether an @-mention is a legit username (e.g.
  // the server could return something) and only link if so
  //
  // Note: we aren't using the same vendored version of this library as we do in diamondapp / bitclout,
  // because it causes typescript compilation errors.
  // const twitter = require("../../vendor/twitter-text-3.1.0.js");

  let entities = twttr.extractEntitiesWithIndices(result);

  // Only link usernames and cashtags for now (not hashtags etc)
  entities = entities.filter((entity: any) => entity.screenName || entity.cashtag);

  const textWithMentionLinks = twttr.autoLinkEntities(result, entities, {
    usernameUrlBase: `/profile/`,
    usernameClass: 'text-blue',
    usernameIncludeSymbol: true,
    cashtagUrlBase: `/profile/`,
    cashtagClass: '',
    targetBlank: true,
  });

  return Autolinker.link(textWithMentionLinks, {
    className: linkClassName,
  });
}

export interface PluralizeOptions {
  // Can be used to override irregular plural suffix rules
  irregularSuffix?: string;
  // If passed it will completely override the word. Useful for words that don't follow the standard pluralization rules
  // such as "person" -> "people" and the like.
  irregularWord?: string;
  // If true, the localized, formatted count will be included in the output. For example, "1,000 followers" or "1 follower", etc. This is true by default.
  includeCount?: boolean;
}

const irregularPlurals: { [key: string]: string } = {
  person: 'people',
  child: 'children',
  man: 'men',
  woman: 'women',
  tooth: 'teeth',
  foot: 'feet',
  mouse: 'mice',
  goose: 'geese',
};

export const pluralize = (count: number, word: string, options: Partial<PluralizeOptions> = {}) => {
  let pluralizedResult: string;
  const defaultOptions = { includeCount: true };
  const { irregularSuffix, irregularWord, includeCount } = {
    ...defaultOptions,
    ...options,
  };

  if (count === 1) {
    pluralizedResult = word;
  } else if (irregularWord) {
    pluralizedResult = irregularWord;
  } else if (irregularPlurals[word.toLowerCase()]) {
    pluralizedResult = irregularPlurals[word.toLowerCase()];
  } else if (irregularSuffix) {
    pluralizedResult = `${word.slice(0, -1)}${irregularSuffix}`;
  } else if (/y$/.test(word) && !/[aeiou]y$/.test(word)) {
    pluralizedResult = `${word.slice(0, -1)}ies`;
  } else if (/(ch|s|sh|x|z)$/.test(word)) {
    pluralizedResult = `${word}es`;
  } else {
    pluralizedResult = `${word}s`;
  }

  return includeCount ? `${count.toLocaleString()} ${pluralizedResult}` : pluralizedResult;
};
