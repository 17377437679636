import { Avatar } from 'components/core/Avatar';
import { useToast } from 'hooks/useToast';
import { useRef, useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';
import { Button } from 'components/shadcn/ui/button';

const GHOST_IMAGE_URL = `${window.location.origin}/images/ghost-profile-image.png`;

interface ProfilePicEditorProps {
  profilePicUrl: string | undefined;
  handleImageUrl: (url: string) => void;
  autoFocus?: boolean;
}
export function ProfilePicEditor({ profilePicUrl, handleImageUrl, autoFocus = false }: ProfilePicEditorProps) {
  const [newProfilePicUrl, setNewProfilePicUrl] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const onError = (e: any) =>
    toast.show({
      message: getErrorMsg(e),
      type: 'error',
    });

  return (
    <div className="rounded-2xl p-4 lg:p-12 text-center border border-border relative items-center">
      <div>
        <Avatar size="xl" src={newProfilePicUrl ?? profilePicUrl ?? GHOST_IMAGE_URL} className="border mb-2" />
      </div>
      <Button variant={'outline'} className="mt-2 whitespace-nowrap" onClick={() => fileInputRef.current?.click()}>
        Upload Profile Photo
      </Button>
      <input
        ref={fileInputRef}
        className="hidden"
        type="file"
        accept="image/png, image/jpeg"
        onChange={(ev) => {
          const file = ev.currentTarget.files?.[0];
          const reader = new FileReader();

          if (!file) {
            onError('There was a problem uploading your file. Please try again.');
            return;
          }

          reader.addEventListener(
            'load',
            () => {
              const dataUrl = reader.result?.toString();

              if (!dataUrl) {
                onError('There was a problem uploading your file. Please try again.');
                return;
              }

              setNewProfilePicUrl(dataUrl);
              handleImageUrl(dataUrl);
            },
            false,
          );
          reader.addEventListener('error', (err) => {
            onError(err);
          });

          reader.readAsDataURL(file);
        }}
      />
    </div>
  );
}
