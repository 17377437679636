import { SiteHeader } from 'components/layout/SiteHeader';
import { Outlet } from 'react-router-dom';

export function SiteContainer() {
  return (
    <div className="overflow-x-hidden lg:overflow-inherit h-full">
      <SiteHeader />
      <div role="main" tabIndex={-1}>
        <Outlet />
      </div>
    </div>
  );
}
