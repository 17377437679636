import { CopyToClipboard } from 'components/app-ui/CopyToClipboard';
import { DepositEventsList } from 'components/app-ui/DepositEventsList';
import { FullPageError } from 'components/app-ui/FullPageError';
import { SwapDesoForUSD } from 'components/app-ui/SwapDesoForUSD';
import { SwapUSDForDeso } from 'components/app-ui/SwapUSDForDeso';
import { ExternalLink } from 'components/core/ExternalLink';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import { Button } from 'components/shadcn/ui/button';
import { DESO_ZERO_PUBLIC_KEY, INVESTMENT_NETWORK_FEE_NANOS_BUFFER } from 'constants/AppConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useToast } from 'hooks/useToast';
import { useContext, useEffect, useState } from 'react';
import { deso, heroswap, openfund } from 'services';
import { DepositEvent, SupportedDestinationTickers } from 'services/HeroSwapper';
import { desoNanosToDeso, desoToUSD, formatDecimalValue } from 'utils/currency';
import { getErrorMsg } from 'utils/getErrorMsg';
import { quantityBigIntToFloat } from 'utils/orderbook';
import {
  formatTicker,
  formatTickerWithTooltip,
  getExplorerLink,
  getTickerCurrencyName,
  getTickerNetworkName,
  Ticker,
  wrappedAssetTickerToPublicKey,
} from 'utils/tickers';
import { DESO_PROJECT_NAME } from '../../constants/TradeConstants';
import { CurrencyNetworkAlert } from '../core/CurrencyNetworkAlert';
import { NumberInput } from './NumberInput';
import UsdToUsdc from './UsdToUsdc';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { LuExternalLink } from 'react-icons/lu';
import { RouteLink } from '../core/RouteLink';
import { Skeleton } from '../shadcn/ui/skeleton';

interface SwapCurrencyProps {
  depositAddress: string;
  depositTicker: Ticker;
  destinationTicker: SupportedDestinationTickers;
}

export const NetworkFeesLabel = (ticker: string) => {
  switch (ticker) {
    case 'USDC':
    case 'ETH':
    case 'USDT':
      return 'Ethereum';
    case 'BTC':
      return 'Bitcoin';
    case 'USDC-SOL':
    case 'SOL':
      return 'Solana';
    case 'DESO':
    case 'DUSD':
      return 'DeSo';
    default:
      return '';
  }
};

export function SwapCurrency({ depositAddress, depositTicker, destinationTicker }: SwapCurrencyProps) {
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const { exchangeRates } = useContext(QuoteCurrencyContext);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<any>();
  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
  const [destinationAmountPerOneDepositUnit, setDestinationAmountPerOneDepositUnit] = useState(0);
  const [previewDepositAmount, setPreviewDepositAmount] = useState<string>('10');
  const [networkFeeAmount, setNetworkFeeAmount] = useState<string>('0');
  const [previewDestinationAmount, setPreviewDestinationAmount] = useState<string>('0');
  const [depositEvents, setDepositEvents] = useState<DepositEvent[]>([]);
  const spendableDesoNanos =
    currentUser?.BalanceNanos && currentUser?.BalanceNanos > INVESTMENT_NETWORK_FEE_NANOS_BUFFER
      ? currentUser.BalanceNanos - INVESTMENT_NETWORK_FEE_NANOS_BUFFER
      : 0;
  const [maxSpendAmount, setMaxSpendAmount] = useState<number>(0);
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (destinationTicker === 'DUSD') {
      setMaxSpendAmount(
        quantityBigIntToFloat(BigInt(currentUser?.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0')),
      );
      return;
    }
    if (destinationTicker === DESO_PROJECT_NAME) {
      setMaxSpendAmount(desoNanosToDeso(spendableDesoNanos));
      return;
    }
    deso
      .getProjectHolding(currentUser.PublicKeyBase58Check, wrappedAssetTickerToPublicKey(destinationTicker))
      .then((balanceEntry) => {
        setMaxSpendAmount(
          balanceEntry ? quantityBigIntToFloat(BigInt(balanceEntry.BalanceNanosUint256.toString())) : 0,
        );
      });
  }, [destinationTicker, currentUser, spendableDesoNanos]);

  const checkNewDeposits = async () => {
    if (!depositAddress) return Promise.resolve();
    return heroswap.pollNewDepositOnce(depositAddress, depositTicker).then(() =>
      heroswap.getRecentDepositEventsForDepositAddress(depositTicker, depositAddress).then((events) => {
        setDepositEvents(events);

        return openfund.reloadCurrentUserData().then((res) => {
          setCurrentUser(res);
        });
      }),
    );
  };

  useEffect(() => {
    heroswap.calcCurrencySwapAmount(depositTicker, destinationTicker, previewDepositAmount.toString()).then((res) => {
      setDestinationAmountPerOneDepositUnit(parseFloat(res.SwapRateDestinationTickerPerDepositTicker));
      setNetworkFeeAmount(parseFloat(res.DepositFeeDeducted).toString());
      setPreviewDestinationAmount(parseFloat(res.DestinationAmount).toString());
    });
  }, [destinationTicker, depositTicker, previewDepositAmount]);

  const refreshBalance = async () => {
    if (isRefreshingBalance) {
      return;
    }
    setIsRefreshingBalance(true);
    try {
      await checkNewDeposits();
    } catch (e) {
      const errorMsg = getErrorMsg(e);

      if (errorMsg.toLowerCase().includes('error discovering deposits')) {
        return;
      }

      toast.show({ message: errorMsg, type: 'error' });
    }
    setIsRefreshingBalance(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setLoadingError(undefined);
    checkNewDeposits()
      .catch((e) => {
        setLoadingError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentUser?.PublicKeyBase58Check, destinationTicker, depositTicker]);

  if (isLoading || !currentUser) {
    return (
      <div className="flex flex-col gap-4">
        <Skeleton className="h-[50px] w-full" />
        <Skeleton className="h-[120px] w-full" />
        <Skeleton className="h-[50px] w-full" />
        <Skeleton className="h-[120px] w-full" />
      </div>
    );
  }

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  return (
    <>
      {depositAddress && (
        <div className="p-4 sm:p-6 border border-border-light rounded-2xl flex flex-col gap-6">
          {!(
            (depositTicker === 'DESO' && destinationTicker === 'DUSD') ||
            (depositTicker === 'DUSD' && destinationTicker === 'DESO')
          ) && (
            <div className="flex flex-col gap-2">
              <div className="text-sm text-muted-foreground font-semibold mb-2">
                Send <span className="underline">{formatTicker(depositTicker)}</span> to this{' '}
                {getTickerNetworkName(depositTicker)} address to swap for {formatTickerWithTooltip(destinationTicker)}
              </div>
              <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-2">
                <CopyToClipboard width={13} text={depositAddress} />
                <ExternalLink
                  kind="text-only-underline-light"
                  target="_blank"
                  href={getExplorerLink(depositTicker, depositAddress, 'address')}
                  className="sm:ml-3 hover:text-muted-foreground text-xs"
                >
                  View Status
                  <LuExternalLink className="inline-block ml-2 h-4 w-4" />
                </ExternalLink>
              </div>
            </div>
          )}
          {!(
            (depositTicker === 'DESO' && destinationTicker === 'DUSD') ||
            (depositTicker === 'DUSD' && destinationTicker === 'DESO')
          ) && (
            <NumberInput
              labelText={
                <div className="flex items-center">
                  Preview swap of {getTickerCurrencyName(depositTicker)} for {getTickerCurrencyName(destinationTicker)}
                </div>
              }
              initialValue={previewDepositAmount}
              allowedDecimalPlaces={6}
              onValueChange={(value) => setPreviewDepositAmount(value)}
            />
          )}
          {depositTicker === 'DESO' && destinationTicker === 'DUSD' && currentUser && (
            <SwapDesoForUSD
              currentUser={currentUser}
              desoDepositAddress={depositAddress}
              onSendDesoSuccess={() => {
                refreshBalance();
              }}
              onSendDesoError={(err) => {
                // TODO: error handling
                console.error(err);
              }}
              onDESOAmountChange={(value) => setPreviewDepositAmount(value.toString())}
            />
          )}
          {depositTicker === 'DUSD' && destinationTicker === 'DESO' && currentUser && (
            <SwapUSDForDeso
              currentUser={currentUser}
              usdDepositAddress={depositAddress}
              onSendUSDSuccess={() => {
                refreshBalance();
              }}
              onSendUSDError={(err) => {
                // TODO: error handling
                console.error(err);
              }}
              onUSDAmountChange={(value) => setPreviewDepositAmount(value.toString())}
            />
          )}
          <div className="flex flex-col border border-border-light bg-accent rounded-2xl text-sm">
            <div className="border-b border-border-light px-4 py-2 flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <span className="text-muted">${formatTicker(destinationTicker)} Amount Received</span>
              <span className="font-mono text-green-500 font-shadow-green">
                {formatDecimalValue(previewDestinationAmount, 6)}
              </span>
            </div>
            <div className="border-b border-border-light px-4 py-2 flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <span className="text-muted">Swap Rate</span>
              <span className="font-mono text-muted-foreground">
                1 {formatTicker(depositTicker)} ≈ {formatDecimalValue(destinationAmountPerOneDepositUnit, 6)}{' '}
                {formatTicker(destinationTicker)}
              </span>
            </div>
            <div className="px-4 py-2 flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <span className="text-muted">Network Fees ({NetworkFeesLabel(depositTicker)})</span>
              <span className="font-mono text-muted-foreground">
                {formatDecimalValue(networkFeeAmount, 6)} {formatTicker(depositTicker)}
              </span>
            </div>
          </div>
        </div>
      )}

      <CurrencyNetworkAlert ticker={depositTicker} alertType="send" />

      <section className="border border-border p-4 rounded-2xl block w-full mt-2">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:justify-between">
          <div className="flex flex-col gap-1 w-full sm:w-[40%]">
            <div className="text-md font-semibold font-sans text-muted-foreground">
              Current ${formatTicker(destinationTicker)} Balance
            </div>
            <div className="text-sm text-muted">Click refresh to check your latest balance.</div>
          </div>
          <div className="flex items-center gap-4 text-right">
            {isRefreshingBalance ? (
              <div className="mr-12">
                <LoadingSpinner className="h-12 w-12" />
              </div>
            ) : (
              <div className="text-right">
                <div className="font-mono text-green-600">
                  ≈ {destinationTicker === 'DUSD' || destinationTicker === 'DESO' ? '$' : ''}
                  {destinationTicker === 'DESO' && !!exchangeRates
                    ? formatDecimalValue(desoToUSD(maxSpendAmount, exchangeRates[DESO_ZERO_PUBLIC_KEY]), 5, 5)
                    : formatDecimalValue(maxSpendAmount, 5, 5)}{' '}
                  {destinationTicker === 'DUSD' || destinationTicker === 'DESO'
                    ? 'USD'
                    : formatTicker(destinationTicker)}
                </div>
                {destinationTicker === 'DESO' ? (
                  <div className="font-mono text-green-800 text-sm">
                    {formatDecimalValue(maxSpendAmount, 5, 5)} DESO
                  </div>
                ) : (
                  destinationTicker === 'DUSD' && (
                    <div className="font-mono text-green-800 text-sm">
                      <UsdToUsdc usdBalance={maxSpendAmount / 100} numDecimals={5} /> USDC
                    </div>
                  )
                )}
              </div>
            )}
            <div>
              <Button variant="outline" onClick={refreshBalance}>
                Refresh
              </Button>
            </div>
          </div>
        </div>
        {depositEvents.length > 0 && (
          <DepositEventsList
            className="mt-4"
            events={depositEvents}
            ticker={depositTicker}
            depositAddress={depositAddress}
          />
        )}

        <div className="my-4 text-center text-xs text-muted mt-6">
          Need help?{' '}
          <RouteLink
            className="cursor-pointer underline underline-offset-4 hover:text-foreground"
            to="https://t.me/heroswap"
            target="_blank"
          >
            Message HeroSwap Support
          </RouteLink>
        </div>
      </section>
    </>
  );
}
