import { FullPageError } from 'components/app-ui/FullPageError';
import { NotFound } from 'components/app-ui/NotFound';
import { USDValueWithDESO } from 'components/app-ui/USDValueWithDESO';
import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/shadcn/ui/button';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { Tabs } from 'components/core/Tabs';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { DAOCoinLimitOrderEntryResponse, GetUsersResponse } from 'deso-protocol';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'components/hooks/use-toast';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Routes } from 'RoutePaths';
import { deso, openfund } from 'services';
import { focusToUSD, formatDecimalValue } from 'utils/currency';
import { getErrorMsg } from 'utils/getErrorMsg';
import { DESO_USDC_PUBLIC_KEY, DESO_ZERO_PUBLIC_KEY, FOCUS_TOKEN_PUBLIC_KEY } from '../../constants/AppConstants';
import { ASK, BID, DESO_TOKEN_PUBLIC_KEY, FOCUS_TICKER, USD_TICKER } from '../../constants/TradeConstants';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { getWrappedAsset, getWrappedAssetIcon } from '../../utils/deso';
import LowNumFormatter from '../app-ui/LowNumFormatter';
import UsdToUsdc from '../app-ui/UsdToUsdc';
import { classNames } from 'utils/classNames';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableScrollable,
} from 'components/shadcn/ui/table';
import { getQuoteCurrencyFromPair } from '../../utils/orderbook';
import { TradeContext } from '../../contexts/TradeContext';
import { Skeleton } from '../shadcn/ui/skeleton';

interface Order {
  PriceInQuoteCurrency: number;
  Quantity: number;
  ProjectUsername: string;
  ProjectPublicKeyBaseCheck58: string;
  DenominatingUsername: string;
  DenominatingProjectPublicKeyBaseCheck58: string;
  Side: string;
  OrderID: string;
}

export function MyOrders({
  publicKey,
  className = '',
  overflow = true,
  heading = null,
  baseCurrencyPublicKey = '',
  showAll = false,
  onCancel,
}: {
  publicKey?: string;
  className?: string;
  overflow?: boolean;
  heading?: ReactNode | null;
  baseCurrencyPublicKey?: string;
  showAll?: boolean;
  onCancel?: (txnHashHex: string) => void;
}) {
  useDocumentTitle(publicKey ? 'Open Orders' : 'My Orders');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>();
  const [orders, setOrders] = useState<Order[]>([]);
  const toast = useToast();

  const { currentUser, setCurrentUser, loadingUser } = useContext(OpenfundContext);
  const { quoteCurrencyPublicKey, exchangeRates } = useContext(QuoteCurrencyContext);
  const { transactorOrders } = useContext(TradeContext);

  const effectivePublicKey = publicKey || currentUser?.PublicKeyBase58Check || '';

  const loadOrders = () => {
    setIsLoading(true);

    const promise =
      publicKey || showAll
        ? deso.getAllTransactorTokenLimitOrders(effectivePublicKey)
        : Promise.resolve(transactorOrders);

    promise
      .then((orderEntryResponses) => {
        orderEntryResponses = (orderEntryResponses || []).filter((order) =>
          !showAll
            ? order.BuyingDAOCoinCreatorPublicKeyBase58Check === quoteCurrencyPublicKey ||
              order.SellingDAOCoinCreatorPublicKeyBase58Check === quoteCurrencyPublicKey ||
              (quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY &&
                order.BuyingDAOCoinCreatorPublicKeyBase58Check === 'DESO') ||
              (quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY &&
                order.SellingDAOCoinCreatorPublicKeyBase58Check === 'DESO')
            : true,
        );
        const buyingTokenPublicKeys = orderEntryResponses
          ? orderEntryResponses.map((order) => order.BuyingDAOCoinCreatorPublicKeyBase58Check)
          : [];
        const sellingTokenPublicKeys = orderEntryResponses
          ? orderEntryResponses.map((order) => order.SellingDAOCoinCreatorPublicKeyBase58Check)
          : [];
        const allPublicKeys = buyingTokenPublicKeys
          .concat(sellingTokenPublicKeys)
          .filter((sellingTokenPublicKey) => sellingTokenPublicKey !== '');
        const uniquePublicKeys = Array.from(new Set(allPublicKeys));
        return Promise.all([deso.getUsers(uniquePublicKeys, { SkipForLeaderboard: true }), orderEntryResponses]);
      })
      .then(([usersResponse, orderEntryResponses]) => {
        if (!orderEntryResponses) {
          return [];
        }
        const userList = usersResponse?.UserList || [];
        const publicKeyToUsername: { [publicKey: string]: string } = {};
        for (const user of userList) {
          publicKeyToUsername[user.PublicKeyBase58Check] = user.ProfileEntryResponse?.Username ?? '';
          if (user.PublicKeyBase58Check === 'DESO') {
            publicKeyToUsername[user.PublicKeyBase58Check] = 'DESO';
          }
        }

        let orderAccumulator: Order[] = [];

        const effectiveQuoteCurrencyPublicKey = showAll
          ? null
          : quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY
            ? DESO_TOKEN_PUBLIC_KEY
            : quoteCurrencyPublicKey;

        for (const orderEntryResponse of orderEntryResponses) {
          let side = orderEntryResponse.OperationType;
          let quantity = orderEntryResponse.QuantityToFill;
          const effectiveQuoteCurrencyForTrade =
            effectiveQuoteCurrencyPublicKey ??
            getQuoteCurrencyFromPair(
              orderEntryResponse.BuyingDAOCoinCreatorPublicKeyBase58Check,
              orderEntryResponse.SellingDAOCoinCreatorPublicKeyBase58Check,
            );
          if (effectiveQuoteCurrencyForTrade === null) {
            continue;
          }

          if (
            orderEntryResponse.OperationType === BID &&
            orderEntryResponse.BuyingDAOCoinCreatorPublicKeyBase58Check === effectiveQuoteCurrencyForTrade
          ) {
            side = ASK;
          } else if (
            orderEntryResponse.OperationType === ASK &&
            orderEntryResponse.SellingDAOCoinCreatorPublicKeyBase58Check === effectiveQuoteCurrencyForTrade
          ) {
            quantity = orderEntryResponse.QuantityToFill / orderEntryResponse.ExchangeRateCoinsToSellPerCoinToBuy;
            side = BID;
          }
          const price =
            side === BID
              ? orderEntryResponse.ExchangeRateCoinsToSellPerCoinToBuy
              : 1 / orderEntryResponse.ExchangeRateCoinsToSellPerCoinToBuy;

          const projectPublicKeyBaseCheck58 =
            side === BID
              ? orderEntryResponse.BuyingDAOCoinCreatorPublicKeyBase58Check
              : orderEntryResponse.SellingDAOCoinCreatorPublicKeyBase58Check;

          const denominatingProjectPublicKeyBaseCheck58 =
            side === BID
              ? orderEntryResponse.SellingDAOCoinCreatorPublicKeyBase58Check
              : orderEntryResponse.BuyingDAOCoinCreatorPublicKeyBase58Check;

          // If for whatever reason we can't get a username for the project, we fall back to public key
          let projectUsername = projectPublicKeyBaseCheck58;
          if (projectPublicKeyBaseCheck58 in publicKeyToUsername) {
            projectUsername = publicKeyToUsername[projectPublicKeyBaseCheck58];
          }
          let denominatingUsername = denominatingProjectPublicKeyBaseCheck58;
          if (denominatingProjectPublicKeyBaseCheck58 in publicKeyToUsername) {
            denominatingUsername = publicKeyToUsername[denominatingProjectPublicKeyBaseCheck58];
          }

          orderAccumulator.push({
            PriceInQuoteCurrency: price,
            Quantity: quantity,
            OrderID: orderEntryResponse.OrderID,
            ProjectUsername: projectUsername,
            ProjectPublicKeyBaseCheck58: projectPublicKeyBaseCheck58,
            DenominatingUsername: denominatingUsername,
            DenominatingProjectPublicKeyBaseCheck58: denominatingProjectPublicKeyBaseCheck58,
            Side: side,
          });
        }
        // sort orders by project username, then price, then orderid so the sort is deterministic always
        orderAccumulator = orderAccumulator
          .sort((a, b) => {
            if (a.ProjectUsername !== b.ProjectUsername) {
              return a.ProjectUsername.localeCompare(b.ProjectUsername);
            }
            if (a.PriceInQuoteCurrency !== b.PriceInQuoteCurrency) {
              return b.PriceInQuoteCurrency - a.PriceInQuoteCurrency;
            }
            return a.OrderID.localeCompare(b.OrderID);
          })
          .filter(({ ProjectPublicKeyBaseCheck58 }) => {
            const effectiveProjectPublicKey =
              ProjectPublicKeyBaseCheck58 === DESO_TOKEN_PUBLIC_KEY
                ? DESO_ZERO_PUBLIC_KEY
                : ProjectPublicKeyBaseCheck58;
            return baseCurrencyPublicKey ? effectiveProjectPublicKey === baseCurrencyPublicKey : true;
          });
        setOrders(orderAccumulator);
      })
      .catch((e) => {
        setLoadingError(e);
        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!effectivePublicKey) {
      return;
    }

    loadOrders();
  }, [effectivePublicKey, quoteCurrencyPublicKey, transactorOrders]);

  if (loadingUser) {
    return (
      <div className={classNames('max-w-7xl m-auto', !heading && 'py-12')}>
        <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between items-start md:items-center mb-4 lg:mb-4">
          <Skeleton className="h-[200px] w-full" />
        </div>
      </div>
    );
  }

  if (!effectivePublicKey) {
    return <NotFound />;
  }

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  return (
    <div className={classNames('max-w-7xl m-auto', !heading && 'py-12')}>
      <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between items-start md:items-center mb-4 lg:mb-4">
        {heading || (
          <div className="font-sans text-xl text-muted-foreground font-semibold">
            <div className="flex justify-between items-center lg:items-end">
              {publicKey && publicKey !== currentUser?.PublicKeyBase58Check ? 'Their Orders' : 'My Orders'}
            </div>
          </div>
        )}
      </div>

      {isLoading ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : (
        <div className={classNames('border rounded-2xl overflow-x-auto', className)}>
          <Tabs
            overflow={false}
            showSelect={false}
            tabs={[
              {
                tab: 'Open Orders',
                panel: (
                  <div className="min-w-[900px]">
                    <TableScrollable containerClassname="overflow-x-auto">
                      <TableHeader className="border-b">
                        <TableRow>
                          <TableHead className="w-[120px]">Token</TableHead>
                          <TableHead className="w-[80px]">Quote Currency</TableHead>
                          <TableHead className="w-[50px]">Side</TableHead>
                          <TableHead className="w-[100px]">Price</TableHead>
                          <TableHead className="w-[150px]">Quantity</TableHead>
                          {!publicKey && currentUser && <TableHead className="w-[100px]">Actions</TableHead>}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {orders.length === 0 ? (
                          <TableRow>
                            <TableCell
                              colSpan={!publicKey && currentUser ? 5 : 6}
                              className="text-center text-muted py-6"
                            >
                              No orders available
                            </TableCell>
                          </TableRow>
                        ) : (
                          orders.map((order) => {
                            const wrappedAsset = getWrappedAsset(order.ProjectUsername);
                            const isWrapped = !!wrappedAsset;
                            const denominatingWrappedAsset = getWrappedAsset(order.DenominatingUsername);
                            const isDenominatingWrapped = !!denominatingWrappedAsset;
                            const displayName = wrappedAsset?.displayName || order.ProjectUsername;
                            const avatarURL = wrappedAsset
                              ? getWrappedAssetIcon(wrappedAsset)
                              : deso.profilePicUrl(order.ProjectPublicKeyBaseCheck58);

                            const denominatingAvatarURL = isDenominatingWrapped
                              ? getWrappedAssetIcon(denominatingWrappedAsset)
                              : deso.profilePicUrl(order.DenominatingProjectPublicKeyBaseCheck58);
                            const denominatingDisplayName =
                              denominatingWrappedAsset?.displayName || order.DenominatingUsername;

                            return (
                              <TableRow key={order.OrderID}>
                                <TableCell>
                                  <RouteLink
                                    kind="text-only"
                                    to={Routes.tradeToken(isWrapped ? wrappedAsset.displayName : order.ProjectUsername)}
                                    className="flex items-center"
                                  >
                                    <Avatar className="mr-2 border" src={avatarURL} />{' '}
                                    <span className="text-muted-foreground font-semibold hover:underline">
                                      ${displayName}
                                    </span>
                                  </RouteLink>
                                </TableCell>
                                <TableCell>
                                  <RouteLink
                                    kind="text-only"
                                    to={Routes.tradeToken(
                                      isDenominatingWrapped
                                        ? denominatingWrappedAsset.displayName
                                        : order.DenominatingUsername,
                                    )}
                                    className="flex items-center"
                                  >
                                    <Avatar className="mr-2" src={denominatingAvatarURL} />{' '}
                                    <span className="text-muted-foreground font-semibold hover:underline">
                                      ${denominatingDisplayName}
                                    </span>
                                  </RouteLink>
                                </TableCell>
                                <TableCell>
                                  <span
                                    className={classNames(
                                      'font-semibold uppercase text-sm',
                                      order.Side === 'BID' ? 'text-green-500' : 'text-red-500',
                                    )}
                                  >
                                    {order.Side}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  {order.DenominatingProjectPublicKeyBaseCheck58 === DESO_TOKEN_PUBLIC_KEY &&
                                    exchangeRates && (
                                      <>
                                        {exchangeRates && (
                                          <USDValueWithDESO
                                            desoValue={order.PriceInQuoteCurrency}
                                            usdCentsPerDeSoExchangeRate={exchangeRates[DESO_ZERO_PUBLIC_KEY]}
                                          />
                                        )}
                                      </>
                                    )}
                                  {order.DenominatingProjectPublicKeyBaseCheck58 === DESO_USDC_PUBLIC_KEY && (
                                    <div className="ml-auto">
                                      <span className="font-mono text-muted-foreground">
                                        <UsdToUsdc usdBalance={order.PriceInQuoteCurrency / 100} />
                                        <span className="text-xs ml-1">USD</span>
                                      </span>
                                      <div className="flex text-xs font-mono text-muted">
                                        <span>
                                          {formatDecimalValue(order.PriceInQuoteCurrency, 5, 5)} {USD_TICKER}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {order.DenominatingProjectPublicKeyBaseCheck58 === FOCUS_TOKEN_PUBLIC_KEY &&
                                    exchangeRates && (
                                      <div className="ml-auto">
                                        <LowNumFormatter
                                          className="text-sm"
                                          price={focusToUSD(
                                            order.PriceInQuoteCurrency,
                                            exchangeRates[FOCUS_TOKEN_PUBLIC_KEY],
                                          )}
                                        />{' '}
                                        USD
                                        <div className="flex">
                                          <span>
                                            {formatDecimalValue(order.PriceInQuoteCurrency, 5, 5)} {FOCUS_TICKER}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                  <span className="font-mono text-muted-foreground">
                                    {formatDecimalValue(order.Quantity, 5, 5)}
                                  </span>
                                </TableCell>
                                {!publicKey && currentUser && (
                                  <TableCell>
                                    <Button
                                      variant="outline"
                                      onClick={() => {
                                        deso
                                          .cancelTokenLimitOrder({ CancelOrderID: order.OrderID })
                                          .then(async ({ submittedTransactionResponse }) => {
                                            loadOrders();
                                            onCancel && onCancel(submittedTransactionResponse?.TxnHashHex as string);
                                            setCurrentUser(await openfund.reloadCurrentUserData());
                                            toast.toast({
                                              title: 'Order Cancelled',
                                              description: 'Your order has been successfully cancelled.',
                                              variant: 'success',
                                            });
                                          })
                                          .catch((e) => {
                                            toast.toast({
                                              title: 'Error',
                                              description: getErrorMsg(e),
                                              variant: 'destructive',
                                            });
                                          });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </TableScrollable>
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default MyOrders;
