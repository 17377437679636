import { Switch } from '@headlessui/react';
import { CopyToClipboard } from 'components/app-ui/CopyToClipboard';
import { CurrencyInputWithTickerToggle } from 'components/app-ui/CurrencyInputWithTickerToggle';
import { FullPageError } from 'components/app-ui/FullPageError';
import { NumberInput } from 'components/app-ui/NumberInput';
import { Input } from 'components/core/Input';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import { Modal } from 'components/core/Modal';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { useSettingsContext } from 'components/pages/Settings';
import { Button } from 'components/shadcn/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/shadcn/ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/ui/select';
import { DESO_DOLLAR_PROFILE_NAME } from 'constants/AppConstants.dev';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { getExchangeRates } from 'deso-protocol';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { confetti, openfund } from 'services';
import { FUNDING_ROUND_STATUSES, FundingRound, FundingRoundAccess, FundingRoundPostData } from 'services/Openfund';
import { classNames } from 'utils/classNames';
import {
  baseUnitsToTokens,
  basisPointsToPercent,
  calcUSDPricePerToken,
  desoNanosToDeso,
  desoNanosToUSD,
  desoToDesoNanos,
  desoToUSD,
  formatDecimalValue,
  formatUSD,
  percentToBasisPoints,
  toHex,
  tokenToBaseUnits,
  usdCentsToDeso,
  usdCentsToUSD,
  usdToDeso,
  usdToUSDCents,
} from 'utils/currency';
import { ONE_DAY_MILLIS, toDateTimePickerFormat } from 'utils/date';
import { BONDING_CURVE_UNITS, getDefaultFundingRoundSettings, getFormDataFromRound } from 'utils/fundingRound';
import { getErrorMsg } from 'utils/getErrorMsg';
import { toTitleCase } from 'utils/text';
import { getTickerCurrencyName, PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER } from 'utils/tickers';
import { v4 as uuid } from 'uuid';
import { GetExchangeRateUpdatedResponse } from '../../../services/Deso';

const DEFAULT_NUM_TOKENS_TO_ISSUE = 1;
const NEW_ROUND_SELECT_ID = uuid();

interface CloseRoundDetails {
  AmountRaisedDesoNanos: number;
  AmountRaisedUSDCents: number;
  AmountEarnedViaReserveRateDesoNanos: number;
  AmountOwedViaReferralRateDesoNanos: number;
  AmountOwedViaReferralRateUSDCents: number;
  TotalReferralsCount: number;
}

export function FundRaising() {
  useDocumentTitle(`Edit fundraising details`);
  const [now] = useState(Date.now());
  const [endDateMin, setEndDateMin] = useState(toDateTimePickerFormat(new Date(now + ONE_DAY_MILLIS)));
  const { setHeadingSuffix, currentUser } = useSettingsContext();
  const { setCurrentUser } = useContext(OpenfundContext);
  const defaultFormData = getDefaultFundingRoundSettings(currentUser.PublicKeyBase58Check, now);
  const [formModel, setFormModel] = useState<FundingRoundPostData>(defaultFormData);
  const [newRoundFormData, setNewRoundFormData] = useState<FundingRoundPostData>(defaultFormData);
  const [initialValues, setInitialValues] = useState({ ...formModel });
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>();
  const [selectedFundingRound, setSelectedFundingRound] = useState<FundingRound>();
  const [roundSelectValue, setRoundSelectValue] = useState(NEW_ROUND_SELECT_ID);
  const [allFundingRounds, setAllFundingRounds] = useState<FundingRound[]>([]);
  const [exchangeRates, setExchangeRates] = useState<GetExchangeRateUpdatedResponse>();
  const isMounted = useIsMounted();
  const [isFinalizeRoundModalOpen, setIsFinalizeRoundModalOpen] = useState(false);
  const [isLoadingProjectReferrals, setIsLoadingProjectReferrals] = useState(false);
  const [canOpenNewRound, setCanOpenNewRound] = useState(true);
  const [isPendingFinalize, setIsPendingFinalize] = useState(false);
  const [fundingRoundAccess, setFundingRoundAccess] = useState<FundingRoundAccess>({
    Password: uuid().substring(0, 8),
    Enabled: false,
  });
  const [latestFundingRoundAccess, setLatestFundingRoundAccess] = useState<FundingRoundAccess>({
    Password: '',
    Enabled: false,
  });
  const [isSelectingRound, setIsSelectingRound] = useState(false);
  const [closeRoundDetails, setCloseRoundDetails] = useState<CloseRoundDetails>();
  const [isRefundConfirmationOpen, setIsRefundConfirmationOpen] = useState(false);
  const [hasInvalidEndDate, setHasInvalidEndDate] = useState(false);
  const [hasInvalidStartDate, setHasInvalidStartDate] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const hasRoundSelector =
    allFundingRounds.length > 1 ||
    (allFundingRounds.length === 1 &&
      !allFundingRounds.find(
        (r) => r.RoundStatus === FUNDING_ROUND_STATUSES.OPEN || r.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED,
      ));
  const didRender = useRef(false);
  const createRoundDerivedKey = useRef('');

  useEffectOnce(() => {
    setHeadingSuffix('Fundraising Details');
  });

  useEffect(() => {
    if (!currentUser.ProfileEntryResponse) {
      navigate(Routes.settingsProfile(), { replace: true });
      return;
    }

    Promise.all([
      getExchangeRates(),
      openfund.getFundingRoundsByUsername(currentUser.ProfileEntryResponse.Username, didRender.current),
    ])
      .then(([xRates, fundingRounds]) => {
        if (!isMounted.current) return;
        const latestRound = fundingRounds[0];
        setAllFundingRounds(() => fundingRounds);
        setExchangeRates(() => xRates);

        // NOTE: if there is an open round, it *should* always be the latest
        // round since they cannot have multiple open rounds. Rounds are sorted
        // DESC on the CreatedAt timestamp by the backend.
        if (
          latestRound?.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ||
          latestRound?.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED
        ) {
          setSelectedFundingRound(() => latestRound);
          setRoundSelectValue(() => latestRound.RoundID);
          setCanOpenNewRound(() => false);
          const formData = getFormDataFromRound(latestRound, xRates);
          setFormModel(() => formData);
          setInitialValues(() => formData);

          return Promise.all([
            openfund.getFundingRoundPassword(latestRound.RoundID).then(({ Enabled, Password }) => {
              if (isMounted.current) {
                setLatestFundingRoundAccess(() => ({ Enabled, Password }));
                setFundingRoundAccess(() => ({
                  Enabled,
                  Password: !Enabled && !Password ? uuid().substring(0, 8) : Password,
                }));
              }
            }),
          ]);
        } else {
          openfund
            .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.FUNDING_ROUND)
            .then(({ DerivedPublicKeyBase58Check }) => {
              createRoundDerivedKey.current = DerivedPublicKeyBase58Check;
            });
          setSelectedFundingRound(() => undefined);
          setRoundSelectValue(() => NEW_ROUND_SELECT_ID);
          setCanOpenNewRound(() => true);
          const formData = {
            ...defaultFormData,
            RoundName: `Round ${fundingRounds.length + 1}`,
          };
          setFormModel(() => formData);
          setInitialValues(() => formData);
        }
      })
      .catch((e) => {
        setLoadingError(e);
        throw e;
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
          didRender.current = true;
        }
      });
  }, [currentUser]);

  if (isLoading) {
    return (
      <div className="text-center mt-1 w-full">
        <Spinner />
      </div>
    );
  }

  if (!exchangeRates || loadingError) {
    return <FullPageError error={loadingError} />;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2 text-xl font-sans font-semibold text-muted-foreground mb-6">
        Edit project details
      </div>
      {hasRoundSelector && (
        <div className="mb-6 p-6">
          <div className="flex items-center w-full mb-4 pt-4">
            <div>
              <Text>Contribution Round</Text>
              <Text size="sm" color="secondary">
                Choose a contribution round
              </Text>
            </div>
            <div className="ml-auto">
              {selectedFundingRound && (
                <label
                  htmlFor="contribution-round-select"
                  className={classNames(
                    'mr-4 font-bold',
                    selectedFundingRound.RoundStatus === FUNDING_ROUND_STATUSES.OPEN && 'text-green',
                  )}
                >
                  {toTitleCase(selectedFundingRound.RoundStatus)}
                </label>
              )}
              <Select
                value={roundSelectValue}
                onValueChange={(value) => {
                  if (value === NEW_ROUND_SELECT_ID) {
                    setRoundSelectValue(NEW_ROUND_SELECT_ID);
                    setSelectedFundingRound(() => undefined);
                    setFormModel(() => newRoundFormData);
                    setInitialValues(() => newRoundFormData);
                    return;
                  }

                  if (canOpenNewRound && value !== NEW_ROUND_SELECT_ID && roundSelectValue === NEW_ROUND_SELECT_ID) {
                    // cache the new round form data in case they toggle back to it
                    setNewRoundFormData(() => ({ ...formModel }));
                  }

                  const selectedRound = allFundingRounds.find((r) => r.RoundID === value);

                  if (!selectedRound) {
                    return;
                  }

                  setIsSelectingRound(true);

                  setSelectedFundingRound(() => selectedRound);
                  setRoundSelectValue(() => selectedRound.RoundID);
                  const formData = getFormDataFromRound(selectedRound, exchangeRates);
                  setFormModel(() => formData);
                  setInitialValues(() => formData);

                  openfund
                    .getFundingRoundPassword(selectedRound.RoundID)
                    .then((passwordData) => {
                      setFundingRoundAccess(passwordData);
                    })
                    .catch((e) => {
                      setLoadingError(e);
                    })
                    .finally(() => {
                      setIsSelectingRound(false);
                    });
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a round" />
                </SelectTrigger>
                <SelectContent>
                  {canOpenNewRound && <SelectItem value={NEW_ROUND_SELECT_ID}>Open a new round</SelectItem>}
                  {allFundingRounds.map((r) => (
                    <SelectItem key={r.RoundID} value={r.RoundID}>
                      {r.RoundName}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      )}
      <div className="w-full">
        {isSelectingRound ? (
          <div className="text-center w-full my-32 h-96">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full">
              <div className="w-full border border-border-light rounded-2xl p-4 sm:p-6 mb-8">
                <div className="mb-2 text-muted text-sm">Share your project link</div>
                {currentUser?.ProfileEntryResponse?.Username && (
                  <CopyToClipboard
                    text={`${window.location.origin}${Routes.fund(currentUser.ProfileEntryResponse.Username)}${
                      latestFundingRoundAccess.Enabled ? `?key=${latestFundingRoundAccess.Password}` : ''
                    }`}
                    width={1400}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col font-sans gap-2 text-lg font-semibold text-muted-foreground">
              {!!selectedFundingRound
                ? selectedFundingRound.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ||
                  selectedFundingRound.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED
                  ? 'Edit fundraising details'
                  : 'Fundraising details'
                : 'Open a new round'}
              {!!selectedFundingRound &&
                selectedFundingRound.RoundStatus !== FUNDING_ROUND_STATUSES.OPEN &&
                selectedFundingRound.RoundStatus !== FUNDING_ROUND_STATUSES.PAUSED && (
                  <div className="text-sm text-muted-foreground">Editing is disabled for finalized funding rounds</div>
                )}
            </div>
            <div className="mb-4 text-muted text-sm mt-1">* Indicates required fields</div>
            {(selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ||
              selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED) && (
              <>
                <div className="border border-border-light rounded-2xl p-4 sm:p-6 mt-4 mb-6">
                  <div className="mb-4">
                    <div className="text-foreground text-sm mb-2">Close Funding Round</div>
                    <div className="text-muted text-sm">
                      This will close your projects fundraising round and no longer accept contributions for this round.
                      You can always open a new fundraising round again when you're ready.
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Button
                      onClick={async () => {
                        if (!currentUser.ProfileEntryResponse) {
                          toast.show({
                            message: 'Unable to retrieve project referral information without a profile username',
                            type: 'error',
                          });
                          return;
                        }

                        try {
                          setIsLoadingProjectReferrals(true);
                          const referralData = await openfund.getProjectReferrals(
                            currentUser.ProfileEntryResponse.Username,
                            selectedFundingRound.RoundID,
                          );
                          setCloseRoundDetails({
                            AmountRaisedDesoNanos: selectedFundingRound.AmountRaisedDesoNanos,
                            AmountRaisedUSDCents: selectedFundingRound.AmountRaisedUSDCents,
                            AmountEarnedViaReserveRateDesoNanos:
                              (selectedFundingRound.AmountRaisedDesoNanos *
                                selectedFundingRound.ReserveRateBasisPoints) /
                              10000,
                            AmountOwedViaReferralRateDesoNanos: referralData.TotalReferralAmountEarnedDesoNanos,
                            AmountOwedViaReferralRateUSDCents:
                              baseUnitsToTokens(referralData.TotalReferralAmountEarnedDaoCoinsHex) * 100,
                            TotalReferralsCount: referralData.TotalReferralsCount,
                          });
                          setIsLoadingProjectReferrals(false);
                          setIsFinalizeRoundModalOpen(true);
                        } catch (e) {
                          setIsLoadingProjectReferrals(false);
                          toast.show({ message: getErrorMsg(e), type: 'error' });
                        }
                      }}
                    >
                      Finalize
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setIsRefundConfirmationOpen(true);
                      }}
                    >
                      Refund
                    </Button>
                  </div>
                </div>
              </>
            )}
            <form
              id="edit-fundraising-details-form"
              onSubmit={async (ev) => {
                ev.preventDefault();
                if (
                  selectedFundingRound &&
                  selectedFundingRound?.RoundStatus !== FUNDING_ROUND_STATUSES.OPEN &&
                  selectedFundingRound?.RoundStatus !== FUNDING_ROUND_STATUSES.PAUSED
                ) {
                  toast.show({
                    message:
                      'This funding round is not open or paused. A funding round must be open or paused to be updated.',
                    type: 'error',
                  });
                  return;
                }

                let fundingRoundId;
                let postBodyData = { ...formModel };

                // NOTE: we do some finagling to make it nicer to enter the
                // price in USD/DESO when the user has a DESO treasury. We need
                // to do some conversion to store the value as deso nanos in the
                // backend. Otherwise we just pass the raw values to the api
                // untouched.
                switch (postBodyData.PriceIncreaseUnit) {
                  case 'USD':
                    postBodyData.PriceIncreaseUnit = BONDING_CURVE_UNITS.DESO_NANOS;
                    postBodyData.PriceIncreaseAmount = Math.round(
                      desoToDesoNanos(
                        usdToDeso(postBodyData.PriceIncreaseAmount, exchangeRates.USDCentsPerDeSoCoinbase),
                      ),
                    );
                    break;
                  case 'DESO':
                    postBodyData.PriceIncreaseUnit = BONDING_CURVE_UNITS.DESO_NANOS;
                    postBodyData.PriceIncreaseAmount = desoToDesoNanos(postBodyData.PriceIncreaseAmount);
                    break;
                }

                if (postBodyData.GlobalReferralRateBasisPoints + postBodyData.ReserveRateBasisPoints > 10000) {
                  toast.show({
                    message: 'Combined reserve rate and referral bonus must not exceed 100%',
                    type: 'error',
                  });
                  return;
                }

                if (!selectedFundingRound) {
                  // If there is not a selected round, it means we are creating a new one.
                  try {
                    const newFundingRound = await openfund.createFundingRound(
                      postBodyData,
                      createRoundDerivedKey.current,
                    );
                    setSelectedFundingRound(newFundingRound);
                    setCurrentUser({ ...currentUser, isProjectOwner: true });
                    fundingRoundId = newFundingRound.RoundID;
                  } catch (e) {
                    toast.show({
                      message: getErrorMsg(e),
                      type: 'error',
                    });
                    throw e;
                  }
                } else {
                  try {
                    fundingRoundId = selectedFundingRound.RoundID;
                    await openfund.updateFundingRound(
                      selectedFundingRound.RoundID,
                      postBodyData,
                      selectedFundingRound.DerivedPublicKeyBase58Check,
                    );
                    // trigger useEffect to reset page state
                    setCurrentUser({ ...currentUser });
                  } catch (e) {
                    toast.show({
                      message: getErrorMsg(e),
                      type: 'error',
                    });
                    throw e;
                  }
                }

                if (fundingRoundId) {
                  try {
                    await openfund.upsertFundingRoundPassword(fundingRoundId, fundingRoundAccess);
                    confetti.celebrate();
                    toast.show({
                      message: (
                        <>
                          Round created successfully.{' '}
                          {!!currentUser.ProfileEntryResponse?.Username && (
                            <RouteLink
                              kind="text-only-light"
                              to={Routes.fund(currentUser.ProfileEntryResponse?.Username)}
                            >
                              View your project profile!
                            </RouteLink>
                          )}
                        </>
                      ),
                      type: 'success',
                    });
                  } catch (e) {
                    toast.show({
                      message: getErrorMsg(e),
                      type: 'error',
                    });
                    throw e;
                  }
                }
              }}
            >
              <fieldset
                disabled={
                  !!selectedFundingRound &&
                  selectedFundingRound.RoundStatus !== FUNDING_ROUND_STATUSES.OPEN &&
                  selectedFundingRound.RoundStatus !== FUNDING_ROUND_STATUSES.PAUSED
                }
              >
                {(selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ||
                  selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED) && (
                  <div className="border border-border-light rounded-2xl p-4 sm:p-6 mb-6 flex items-start sm:justify-between flex-col md:flex-row gap-4">
                    <div className="w-3/4">
                      <div className="text-foreground text-sm mb-2">Pause Funding Round</div>
                      <div className="text-muted text-sm">
                        Temporarily disable investments for this funding round. You can change this setting at any time.
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Text tag="span" className="mr-2">
                        {formModel.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED ? 'On' : 'Off'}
                      </Text>

                      <Switch
                        checked={formModel.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED}
                        onChange={(isChecked) =>
                          setFormModel((prev) => ({
                            ...prev,
                            RoundStatus: isChecked ? FUNDING_ROUND_STATUSES.PAUSED : FUNDING_ROUND_STATUSES.OPEN,
                          }))
                        }
                        className={`${
                          formModel.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED ? 'bg-blue' : 'bg-gray'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                      >
                        <span
                          className={`${
                            formModel.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white`}
                        />
                      </Switch>
                    </div>
                  </div>
                )}
                <div className="mb-8 border border-border-light p-4 sm:p-6 rounded-2xl w-full flex-col sm:flex-row gap-4 sm:justify-between flex items-start">
                  <div>
                    <label
                      className={classNames('font-medium text-sm required mr-2', !!selectedFundingRound && 'text-gray')}
                    >
                      Treasury Currency
                    </label>
                    <div className="mt-1 text-sm text-muted leading-5 sm:max-w-[80%]">
                      The treasury currency can only be edited when initially creating a funding round. USD is a
                      sensible default for most projects.
                    </div>
                  </div>
                  <Select
                    disabled={!!selectedFundingRound}
                    value={formModel.TreasuryDaoCoin || 'DESO'}
                    onValueChange={(value) => {
                      const treasuryUnit = value === 'DESO' ? 'DESO' : 'DAO_COIN';
                      setFormModel({
                        ...formModel,
                        TreasuryCurrencyUnit: treasuryUnit,
                        TreasuryDaoCoin: treasuryUnit === 'DAO_COIN' ? value : '',
                      });
                    }}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select treasury currency" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={DESO_DOLLAR_PROFILE_NAME}>USD</SelectItem>
                      <SelectItem value="DESO">DESO</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="mb-8 p-4 sm:p-6 border border-border-light rounded-2xl">
                  <NumberInput
                    prefix="$"
                    initialValue={usdCentsToUSD(initialValues.AmountToRaiseUsdCents)}
                    labelText="Fundraising Goal ($USD)"
                    allowedDecimalPlaces={2}
                    hint={
                      formModel.TreasuryCurrencyUnit === 'DESO'
                        ? `≈ ${formatDecimalValue(
                            usdCentsToDeso(formModel.AmountToRaiseUsdCents, exchangeRates?.USDCentsPerDeSoCoinbase),
                          )} DESO`
                        : undefined
                    }
                    onValueChange={(value) => {
                      setFormModel({ ...formModel, AmountToRaiseUsdCents: usdToUSDCents(Number(value)) });
                    }}
                  />
                  <p className="text-muted text-sm mt-4">How much are you looking to raise for this round?</p>
                  <div className="flex items-center mt-4 border-t border-border-light pt-4">
                    <Input
                      labelText="Allow overflow (funding goal is uncapped)"
                      className="w-auto"
                      labelSrOnly={true}
                      type="checkbox"
                      textSize="sm"
                      checked={formModel.AllowOverflow}
                      onChange={() => setFormModel({ ...formModel, AllowOverflow: !formModel.AllowOverflow })}
                    />
                    <span className="ml-2 relative text-muted text-sm">
                      <span className="font-semibold">Allow Overflow</span>. The funding goal will be uncapped.
                    </span>
                  </div>
                </div>
                <section className="pt-4 border-t border-border-light mb-6">
                  <div className="flex flex-col gap-2 text-lg font-sans font-semibold text-muted-foreground">
                    Advanced Settings
                    <div className="text-muted text-sm font-normal">
                      Defaults to the below, unless otherwise specified.
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 border border-border-light rounded-2xl mt-6 mb-6">
                    <Input
                      labelText="Round Name"
                      className="w-full"
                      required={true}
                      value={formModel.RoundName}
                      onChange={(ev) => {
                        setFormModel({ ...formModel, RoundName: ev.currentTarget.value });
                      }}
                    />
                  </div>

                  <div className="my-4 flex flex-col gap-6">
                    <div className="p-4 sm:p-6 border border-border-light rounded-2xl">
                      {formModel.TreasuryCurrencyUnit === 'DESO' ? (
                        <CurrencyInputWithTickerToggle
                          labelText="Set Token Price"
                          initialValue={
                            calcUSDPricePerToken(
                              formModel.StartDaoCoinBaseUnitsPerTreasuryUnitHex,
                              exchangeRates.USDCentsPerDeSoCoinbase,
                            ) * DEFAULT_NUM_TOKENS_TO_ISSUE
                          }
                          defaultTicker="USD"
                          alternateTicker="DESO"
                          onStateChange={(value, ticker) => {
                            // TODO: support multiple treasury currencies. Assuming only USD and DESO for now.
                            const desoValue =
                              ticker === 'USD'
                                ? usdToDeso(value, exchangeRates.USDCentsPerDeSoCoinbase)
                                : Number(value);

                            const desoCostPerSingleToken = desoValue / DEFAULT_NUM_TOKENS_TO_ISSUE;
                            const startTokensPerDeso = 1 / desoCostPerSingleToken;
                            const startTokenBaseUnitsPerDeso = tokenToBaseUnits(startTokensPerDeso);

                            setFormModel(() => ({
                              ...formModel,
                              StartDaoCoinBaseUnitsPerTreasuryUnitHex: toHex(startTokenBaseUnitsPerDeso),
                            }));
                          }}
                        />
                      ) : (
                        <NumberInput
                          prefix="$"
                          initialValue={1 / baseUnitsToTokens(formModel.StartDaoCoinBaseUnitsPerTreasuryUnitHex)}
                          labelText="Set Token Price ($USD)"
                          allowedDecimalPlaces={2}
                          onValueChange={(value) => {
                            const valueAsNumber = Number(value);
                            if (valueAsNumber <= 0) return;
                            const amountPerTreasuryUnit = 1 / valueAsNumber;
                            setFormModel({
                              ...formModel,
                              StartDaoCoinBaseUnitsPerTreasuryUnitHex: toHex(tokenToBaseUnits(amountPerTreasuryUnit)),
                            });
                          }}
                        />
                      )}
                    </div>
                    <div className="border border-border-light rounded-2xl">
                      <Input
                        containerClasses="p-4 sm:p-6 border-b border-border-light"
                        className="w-full"
                        labelText="Project Start Date"
                        type="datetime-local"
                        required={true}
                        value={toDateTimePickerFormat(new Date(formModel.StartTime))}
                        state={
                          (!selectedFundingRound && now > new Date(formModel.StartTime).getTime()) ||
                          hasInvalidStartDate
                            ? 'error'
                            : 'default'
                        }
                        hint={
                          !selectedFundingRound && new Date(now).getTime() > new Date(formModel.StartTime).getTime()
                            ? `You must pick a date on or after ${new Date(now).toDateString()} ${new Date(
                                now,
                              ).toTimeString()}`
                            : undefined
                        }
                        onChange={(ev) => {
                          const selectedDate = new Date(ev.currentTarget.value);
                          setEndDateMin(toDateTimePickerFormat(selectedDate));
                          try {
                            const StartTime = selectedDate.toISOString();
                            setFormModel((prev) => ({ ...prev, StartTime }));
                            setHasInvalidStartDate(false);
                          } catch (e) {
                            setHasInvalidStartDate(true);
                          }
                        }}
                      />
                      <div className="p-4 sm:p-6 ">
                        <Input
                          labelText="Project End Date"
                          type="datetime-local"
                          value={!!formModel.EndTime ? toDateTimePickerFormat(new Date(formModel.EndTime)) : ''}
                          state={
                            (formModel.EndTime &&
                              new Date(endDateMin).getTime() > new Date(formModel.EndTime).getTime()) ||
                            hasInvalidEndDate
                              ? 'error'
                              : 'default'
                          }
                          hint={
                            formModel.EndTime && new Date(endDateMin).getTime() > new Date(formModel.EndTime).getTime()
                              ? `You must pick a date on or after ${new Date(endDateMin).toDateString()} ${new Date(
                                  endDateMin,
                                ).toTimeString()}`
                              : undefined
                          }
                          onChange={(ev) => {
                            try {
                              const EndTime = !!ev.currentTarget.value
                                ? new Date(ev.currentTarget.value).toISOString()
                                : null;
                              setFormModel({
                                ...formModel,
                                EndTime,
                              });
                              setHasInvalidEndDate(false);
                            } catch (e) {
                              setHasInvalidEndDate(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col rounded-2xl border border-border-light">
                      <div className="border-b border-border-light p-4 sm:p-6">
                        <NumberInput
                          suffix="%"
                          labelText="Reserve Rate"
                          initialValue={basisPointsToPercent(initialValues.ReserveRateBasisPoints).toString()}
                          allowedDecimalPlaces={5}
                          max={100}
                          state={
                            formModel.ReserveRateBasisPoints + formModel.GlobalReferralRateBasisPoints > 10000
                              ? 'error'
                              : 'default'
                          }
                          hint={
                            formModel.ReserveRateBasisPoints + formModel.GlobalReferralRateBasisPoints > 10000
                              ? 'Combined reserve rate and referral bonus must not exceed 100%'
                              : ''
                          }
                          onValueChange={(value) => {
                            setFormModel({ ...formModel, ReserveRateBasisPoints: percentToBasisPoints(Number(value)) });
                          }}
                        />
                        <div className="text-muted text-sm mt-4">
                          The % that goes back to your profile (wallet) as a creator.
                        </div>
                      </div>
                      <div className="p-4 sm:p-6">
                        <NumberInput
                          suffix="%"
                          labelText="Referral Bonus"
                          initialValue={basisPointsToPercent(initialValues.GlobalReferralRateBasisPoints).toString()}
                          allowedDecimalPlaces={5}
                          max={100}
                          hint={
                            formModel.ReserveRateBasisPoints + formModel.GlobalReferralRateBasisPoints > 10000
                              ? 'Combined reserve rate and referral bonus must not exceed 100%'
                              : ''
                          }
                          state={
                            formModel.ReserveRateBasisPoints + formModel.GlobalReferralRateBasisPoints > 10000
                              ? 'error'
                              : 'default'
                          }
                          onValueChange={(value) => {
                            setFormModel({ ...formModel, GlobalReferralRateBasisPoints: Number(value) * 100 });
                          }}
                        />
                        <div className="text-muted text-sm mt-4">
                          The % that your referrers will receive from contributions.
                        </div>
                      </div>
                    </div>

                    <div className="border border-border-light rounded-2xl flex flex-col">
                      <div className="sm:p-6 p-4 border-b border-border-light">
                        <div className="flex flex-col gap-2 mb-6">
                          <div className="text-sm font-semibold text-muted-foreground">Set Bonding Curve</div>
                          <div className="text-muted text-sm">
                            Set an upwards bonding curve for contributions to your project
                          </div>
                        </div>
                        <NumberInput
                          containerClasses="flex-1 md:pr-1"
                          suffix="%"
                          labelText="Increase Price Per Token..."
                          initialValue={basisPointsToPercent(
                            initialValues.PriceIncreaseIncrementBasisPoints,
                          ).toString()}
                          allowedDecimalPlaces={5}
                          onValueChange={(value) => {
                            setFormModel({
                              ...formModel,
                              PriceIncreaseIncrementBasisPoints: percentToBasisPoints(Number(value)),
                            });
                          }}
                        />
                      </div>
                      <div className="sm:p-6 p-4">
                        <NumberInput
                          prefix={formModel.PriceIncreaseUnit === 'USD' ? '$' : ''}
                          initialValue={initialValues.PriceIncreaseAmount}
                          allowedDecimalPlaces={5}
                          labelText="Increase Price Every..."
                          hint={
                            formModel.TreasuryCurrencyUnit === 'DESO'
                              ? formModel.PriceIncreaseUnit === 'USD'
                                ? `≈ ${formatDecimalValue(
                                    usdToDeso(formModel.PriceIncreaseAmount, exchangeRates.USDCentsPerDeSoCoinbase),
                                  )} $DESO`
                                : `≈ ${formatUSD(
                                    desoToUSD(formModel.PriceIncreaseAmount, exchangeRates.USDCentsPerDeSoCoinbase),
                                  )} USD`
                              : undefined
                          }
                          onValueChange={(value) => {
                            setFormModel({
                              ...formModel,
                              PriceIncreaseAmount: Number(value),
                            });
                          }}
                        />
                        <Select
                          value={formModel.PriceIncreaseUnit}
                          onValueChange={(value) => {
                            setFormModel({ ...formModel, PriceIncreaseUnit: value });
                          }}
                        >
                          <SelectTrigger className="w-full mt-4">
                            <SelectValue placeholder="Select Unit" />
                          </SelectTrigger>
                          <SelectContent>
                            {formModel.TreasuryCurrencyUnit === 'DESO' && (
                              <>
                                <SelectItem value="USD">USD Invested</SelectItem>
                                <SelectItem value={BONDING_CURVE_UNITS.DESO}>$DESO Invested</SelectItem>
                              </>
                            )}
                            {formModel.TreasuryCurrencyUnit === 'DAO_COIN' && (
                              <SelectItem value={BONDING_CURVE_UNITS.DAO_COINS}>
                                {getTickerCurrencyName(
                                  PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER[formModel.TreasuryDaoCoin] ||
                                    'DUSDC',
                                )}{' '}
                                Invested
                              </SelectItem>
                            )}
                            <SelectItem value={BONDING_CURVE_UNITS.DAYS}>Days</SelectItem>
                            <SelectItem value={BONDING_CURVE_UNITS.HOURS}>Hours</SelectItem>
                            <SelectItem value={BONDING_CURVE_UNITS.MINUTES}>Minutes</SelectItem>
                            <SelectItem value={BONDING_CURVE_UNITS.SECONDS}>Seconds</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <div className="border border-border-light p-4 sm:p-6 rounded-2xl">
                      <Input
                        labelText="Terms and Conditions"
                        className="w-full"
                        value={formModel.TermsAndConditions}
                        hint="An optional link to terms and conditions. We've provide you with a default. Feel free to change or remove it."
                        onChange={(ev) => {
                          setFormModel({ ...formModel, TermsAndConditions: ev.currentTarget.value });
                        }}
                      />
                    </div>
                    <div className="border border-border-light rounded-2xl">
                      <div className="flex items-start flex-col sm:flex-row gap-4 justify-start sm:justify-between p-4 sm:p-6">
                        <div className="text-sm flex flex-col gap-2">
                          <div>Enable Privacy</div>
                          <div className="text-muted text-sm">
                            Set an access password for contributing to your project.
                          </div>
                        </div>
                        <div className="flex items-center">
                          <Text tag="span" className="mr-2">
                            {fundingRoundAccess.Enabled ? 'On' : 'Off'}
                          </Text>

                          <Switch
                            checked={fundingRoundAccess.Enabled}
                            onChange={(isChecked) =>
                              setFundingRoundAccess({ ...fundingRoundAccess, Enabled: isChecked })
                            }
                            className={`${
                              fundingRoundAccess.Enabled ? 'bg-primary' : 'bg-card'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                          >
                            <span className="sr-only">Set privacy</span>
                            <span
                              className={`${
                                fundingRoundAccess.Enabled ? 'translate-x-6' : 'translate-x-1'
                              } inline-block h-4 w-4 transform rounded-full bg-white`}
                            />
                          </Switch>
                        </div>
                      </div>
                      {fundingRoundAccess.Enabled && (
                        <div className="p-4 sm:p-6  border-t border-border-light">
                          <Input
                            labelText="Private Access Password"
                            disabled={!fundingRoundAccess.Enabled}
                            className="w-full"
                            required={true}
                            value={fundingRoundAccess.Password}
                            state={!fundingRoundAccess.Password ? 'error' : 'default'}
                            onChange={(ev) =>
                              setFundingRoundAccess({ ...fundingRoundAccess, Password: ev.currentTarget.value })
                            }
                          />
                          <div className="text-muted text-sm mt-4">
                            This is the password that will unlock your project.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                {(selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ||
                  selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED ||
                  !selectedFundingRound) && (
                  <Button type="submit" variant="default">
                    {!!selectedFundingRound ? 'Save Changes' : 'Create Round'}
                  </Button>
                )}
              </fieldset>
            </form>
            {(selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ||
              selectedFundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.PAUSED) && (
              <>
                <Modal
                  theme="minimal"
                  size="md"
                  isOpen={isRefundConfirmationOpen}
                  onClose={setIsRefundConfirmationOpen}
                  title="Refund all contributors"
                  description={
                    <Text className="text-center font-bold" size="xl">
                      Would you like to issue a refund for{' '}
                      <span className="whitespace-nowrap">{selectedFundingRound.RoundName}</span>?
                    </Text>
                  }
                  body={
                    <div className="text-center pt-2 px-6 pb-6">
                      <Text className="mb-6 px-3">
                        By issuing a refund to your contributors, everyone who purchased tokens as part of this round
                        will be sent back the{' '}
                        {selectedFundingRound.TreasuryCurrencyUnit === 'DESO'
                          ? 'DESO'
                          : getTickerCurrencyName(
                              PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER[selectedFundingRound.TreasuryDaoCoin],
                            )}{' '}
                        that they contributed.
                      </Text>
                      <div className="mb-6">
                        <Text className="font-bold">{selectedFundingRound.RoundName} Summary</Text>
                        <Text>
                          You will refund a total of{' '}
                          {selectedFundingRound.TreasuryCurrencyUnit === 'DESO' ? (
                            <>
                              <span className="text-green font-bold">
                                {formatUSD(
                                  desoNanosToUSD(
                                    selectedFundingRound.AmountRaisedDesoNanos,
                                    exchangeRates.USDCentsPerDeSoCoinbase,
                                  ),
                                )}{' '}
                                USD
                              </span>{' '}
                              (≈ {formatDecimalValue(desoNanosToDeso(selectedFundingRound.AmountRaisedDesoNanos))}{' '}
                              $DESO)
                            </>
                          ) : (
                            <>{formatUSD(usdCentsToUSD(selectedFundingRound.AmountRaisedUSDCents))} USD</>
                          )}
                        </Text>
                      </div>
                      <Button
                        onClick={async () => {
                          setIsPendingFinalize(true);
                          try {
                            await openfund.refundFundingRound(selectedFundingRound.RoundID);
                            // updating current user triggers useEffect to reset state
                            setCurrentUser({ ...currentUser });
                          } catch (e) {
                            toast.show({ message: getErrorMsg(e), type: 'error' });
                          }
                          setIsRefundConfirmationOpen(false);
                          setIsPendingFinalize(false);
                        }}
                      >
                        Refund
                      </Button>
                    </div>
                  }
                />
                <Dialog open={isFinalizeRoundModalOpen} onOpenChange={setIsFinalizeRoundModalOpen}>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Close funding round</DialogTitle>
                      <DialogDescription>Would you like to close {selectedFundingRound.RoundName}?</DialogDescription>
                    </DialogHeader>
                    <div className="p-4 pt-0">
                      {isLoadingProjectReferrals || !closeRoundDetails ? (
                        <Spinner />
                      ) : (
                        <>
                          <Text className="mb-6 px-3">
                            If you close your round, you will no longer be able to actively receive investments for{' '}
                            {selectedFundingRound.RoundName}. However, you can always open up a new round at anytime.
                          </Text>

                          <div className="text-left mb-6 p-4 border border-border-light rounded-2xl bg-accent">
                            <div className="text-muted-foreground">{selectedFundingRound.RoundName} Summary</div>
                            <div className="flex gap-2 items-center text-muted">
                              You've raised a total of{' '}
                              {selectedFundingRound.TreasuryCurrencyUnit === 'DESO' ? (
                                <>
                                  <span className="text-green-500 font-mono">
                                    {formatUSD(
                                      desoNanosToUSD(
                                        closeRoundDetails.AmountRaisedDesoNanos,
                                        exchangeRates.USDCentsPerDeSoCoinbase,
                                      ),
                                    )}{' '}
                                    USD
                                  </span>{' '}
                                  (≈ {formatDecimalValue(desoNanosToDeso(selectedFundingRound.AmountRaisedDesoNanos))}{' '}
                                  $DESO)
                                </>
                              ) : (
                                <span className="text-green-500 font-mono">
                                  {formatUSD(usdCentsToUSD(selectedFundingRound.AmountRaisedUSDCents))} USD
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="bg-gray-eee p-4 rounded-lg mb-6">
                            <div className="flex items-start pt-2">
                              <div className="text-left">
                                <Text className="font-bold">Referral payouts to hodlers</Text>
                                <Text color="secondary" size="sm">
                                  via invite links
                                </Text>
                              </div>
                              <div className="ml-auto text-right">
                                {selectedFundingRound.TreasuryCurrencyUnit === 'DESO' ? (
                                  <>
                                    <Text>
                                      {formatUSD(
                                        desoNanosToUSD(
                                          closeRoundDetails.AmountOwedViaReferralRateDesoNanos,
                                          exchangeRates.USDCentsPerDeSoCoinbase,
                                        ),
                                      )}{' '}
                                      USD
                                    </Text>
                                    <Text size="sm" color="secondary">
                                      ≈{' '}
                                      {formatDecimalValue(
                                        desoNanosToDeso(closeRoundDetails.AmountOwedViaReferralRateDesoNanos),
                                        9,
                                      )}{' '}
                                      $DESO
                                    </Text>
                                  </>
                                ) : (
                                  <Text>
                                    {formatUSD(usdCentsToUSD(closeRoundDetails.AmountOwedViaReferralRateUSDCents))} USD
                                  </Text>
                                )}
                              </div>
                            </div>
                          </div>

                          <Button
                            onClick={async () => {
                              setIsPendingFinalize(true);

                              if (isPendingFinalize) {
                                return;
                              }

                              try {
                                await openfund.finalizeFundingRound(
                                  selectedFundingRound.RoundID,
                                  currentUser.PublicKeyBase58Check,
                                );
                                // updating current user triggers useEffect to reset state
                                setCurrentUser({ ...currentUser });
                                setIsFinalizeRoundModalOpen(false);
                                confetti.celebrate();
                                toast.show({
                                  message: 'Your round was closed successfully!',
                                  type: 'success',
                                  sticky: false,
                                });
                              } catch (e) {
                                setIsFinalizeRoundModalOpen(false);
                                toast.show({
                                  message: getErrorMsg(e),
                                  type: 'error',
                                });
                                throw e;
                              }
                              setIsPendingFinalize(false);
                            }}
                          >
                            {isPendingFinalize ? <LoadingSpinner className="h-8 w-8" /> : 'Finalize'}
                          </Button>
                        </>
                      )}
                    </div>
                  </DialogContent>
                </Dialog>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default FundRaising;
