import { ActivityFeedItem } from 'components/app-ui/ActivityFeedItem';
import { FullPageError } from 'components/app-ui/FullPageError';
import { ProjectAdvocatesLeaderBoard } from 'components/app-ui/ProjectAdvocatesLeaderBoard';
import { ProjectHodlersLeaderBoard } from 'components/app-ui/ProjectHodlersLeaderBoard';
import { Spinner } from 'components/core/Spinner';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/ui/select';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { BalanceEntryResponse, PostEntryResponse, ProfileEntryResponse } from 'deso-protocol';
import { useEffectOnce } from 'hooks/useEffectOnce';
import filter from 'lodash/filter';
import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { deso, openfund } from 'services';
import { classNames } from 'utils/classNames';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { FundingRound, ProjectReferral, Proposal } from '../../services/Openfund';
import { TabGroup, Tabs } from '../core/Tabs';
import { Button as ButtonNew } from '../shadcn/ui/button';
import { ActivityFeedCreateProposal } from './ActivityFeedCreateProposal';
import { ActivityFeedProposal } from './ActivityFeedProposal';
import { CreatePost } from './CreatePost';

interface ProjectActivityFeedProps {
  projectProfile: ProfileEntryResponse;
  hodlers: BalanceEntryResponse[];
  holdings: BalanceEntryResponse[];
  exchangeRates: GetExchangeRateUpdatedResponse;
  selectedTab?: number;
  advocates: ProjectReferral[];
  referralLink?: string;
  fundingRound: FundingRound;
  setHoldings: (e: BalanceEntryResponse[]) => void;
}

enum ProjectActivityFeedTab {
  FEED = 'Feed',
  HODLERS = 'Holders',
  ADVOCATES = 'Referrals',
}

const ProjectActivityFeedTabs = [
  ProjectActivityFeedTab.FEED,
  ProjectActivityFeedTab.HODLERS,
  ProjectActivityFeedTab.ADVOCATES,
];

enum FeedSort {
  HOT = 'hot',
  NEW = 'new',
}

enum FeedFilter {
  MENTIONS = 'mentions',
  DAO_OWNER = 'dao_owner',
}

const projectActivityFeedTabLocalStorageKey = 'dao-activity-feed-tab';
const projectActivityFeedFilterLocalStorageKey = 'dao-activity-feed-filter';
function getDefaultProjectActivityFeedFilter(): FeedFilter {
  const localStorageVal = localStorage.getItem(projectActivityFeedFilterLocalStorageKey);
  return localStorageVal !== null ? (localStorageVal as FeedFilter) : FeedFilter.DAO_OWNER;
}
const projectActivityFeedSortLocalStorageKey = 'dao-activity-feed-sort';
function getDefaultProjectActivityFeedSort(): FeedSort {
  const localStorageVal = localStorage.getItem(projectActivityFeedSortLocalStorageKey);
  return localStorageVal !== null ? (localStorageVal as FeedSort) : FeedSort.HOT;
}

export function ProjectActivityFeed({
  projectProfile,
  hodlers = [],
  holdings = [],
  exchangeRates,
  advocates,
  selectedTab = 0,
  referralLink,
  fundingRound,
  setHoldings = () => {},
}: ProjectActivityFeedProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [proposalsLoading, setProposalsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [showCreateProposals, setShowCreateProposals] = useState(false);
  const [feedSort, setFeedSort] = useState<FeedSort>(getDefaultProjectActivityFeedSort);
  const [feedFilter, setFeedFilter] = useState<FeedFilter>(getDefaultProjectActivityFeedFilter());
  const [loadingError, setLoadingError] = useState<any>();
  const [posts, setPosts] = useState<PostEntryResponse[]>([]);
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const { diamondLevelMap } = useContext(OpenfundContext);

  const localStorageTab = localStorage.getItem(projectActivityFeedTabLocalStorageKey);
  if (localStorageTab !== null) {
    selectedTab = ProjectActivityFeedTabs.indexOf(localStorageTab as ProjectActivityFeedTab);
  }

  const proposalIdParam = searchParams.get('proposalId');
  if (proposalIdParam !== undefined && proposalIdParam !== null && proposalIdParam !== '') {
    selectedTab = 1;
  }

  const getFeed: (currentFeedFilter: FeedFilter, currentFeedSort: FeedSort) => Promise<void> = async (
    currentFeedFilter,
    currentFeedSort,
  ) => {
    if (currentFeedFilter === FeedFilter.MENTIONS) {
      const res = await deso.getHotFeed(
        [],
        100,
        `@${projectProfile.Username.toLowerCase()}`,
        currentFeedSort === FeedSort.NEW,
      );
      setPosts(res?.HotFeedPage || []);
    } else if (currentFeedFilter === FeedFilter.DAO_OWNER) {
      const res = await deso.getPostsForPublicKey(projectProfile.PublicKeyBase58Check, '', 20);
      if (res?.Posts) {
        res.Posts.map((post) => {
          post.ProfileEntryResponse = {
            ...post.ProfileEntryResponse,
            ...{ Username: projectProfile.Username },
          } as ProfileEntryResponse;
        });
      }
      setPosts(res?.Posts || []);
    }
  };

  const hideCreateProposal = () => {
    setShowCreateProposals(false);
  };

  function addPostToFeed(addedPost: PostEntryResponse) {
    if (addedPost !== undefined && addedPost !== null) {
      const newPosts = posts;
      newPosts.unshift(addedPost);
      setPosts(newPosts);
    }
  }

  async function getAllProposals(): Promise<void> {
    return openfund.getAllProposalsForProject(projectProfile.PublicKeyBase58Check).then((res) => {
      setProposals(res.sort((a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()));
      setProposalsLoading(false);
    });
  }

  useEffectOnce(() => {
    setIsLoading(true);
    setProposalsLoading(true);
    const getProposalsPromise = getAllProposals();
    const getFeedPromise = getFeed(feedFilter, feedSort).catch((e) => setLoadingError(e));
    Promise.all([getFeedPromise, getProposalsPromise]).finally(() => setIsLoading(false));
  });

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  const tabs = [
    {
      tab: 'Feed',
      panel: (
        <div>
          <div className="text-muted md:px-12 px-4 pt-4">
            <div className="flex pb-4" style={{ justifyContent: 'space-between' }}>
              <div className="text-sm">Post on ${projectProfile.Username}</div>
            </div>
            <CreatePost
              addPostToFeed={addPostToFeed}
              appendToPost={`\n\n@${projectProfile.Username}\n\nPosted on @Openfund`}
              projectPublicKey={projectProfile.PublicKeyBase58Check}
            />
          </div>
          <div className="py-6 px-4 sm:px-12 border-b border-border-light">
            <div className="flex lg:justify-end justify-start lg:flex-row flex-col">
              <div
                className={classNames(
                  'flex items-center mr-3 lg:pb-0 pb-1',
                  feedFilter === FeedFilter.DAO_OWNER && 'hidden',
                )}
              >
                <label className="inline-block mr-3 text-gray lg:w-fit w-9">Sort</label>
                <Select
                  value={feedSort}
                  disabled={feedFilter === FeedFilter.DAO_OWNER}
                  onValueChange={(value) => {
                    setFeedSort(value as FeedSort);
                    localStorage.setItem(projectActivityFeedSortLocalStorageKey, value);
                    setIsLoading(true);
                    getFeed(feedFilter, value as FeedSort).then(() => setIsLoading(false));
                  }}
                >
                  <SelectTrigger className="top-10 right-2">
                    <SelectValue placeholder="Select sort" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={FeedSort.HOT}>Hot</SelectItem>
                    <SelectItem value={FeedSort.NEW}>New</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex lg:pl-2 items-center">
                <label className="inline-block mr-3 text-gray lg:w-fit w-9">Filter</label>
                <Select
                  value={feedFilter}
                  onValueChange={(value) => {
                    setFeedFilter(value as FeedFilter);
                    localStorage.setItem(projectActivityFeedFilterLocalStorageKey, value);
                    setIsLoading(true);
                    getFeed(value as FeedFilter, feedSort).then(() => setIsLoading(false));
                  }}
                >
                  <SelectTrigger className="top-10 right-2">
                    <SelectValue placeholder="Select filter" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={FeedFilter.DAO_OWNER}>Posts by ${projectProfile.Username}</SelectItem>
                    <SelectItem value={FeedFilter.MENTIONS}>Posts mentioning ${projectProfile.Username}</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="text-center py-8">
              <Spinner />
            </div>
          ) : posts.filter(({ IsHidden }) => !IsHidden).length ? (
            posts
              .filter(({ IsHidden }) => !IsHidden)
              .map((post, i) => (
                <div className="px-4 sm:px-12 sm:py-6 sm:pb-4 border-b border-border-light" key={post.PostHashHex}>
                  <ActivityFeedItem post={post} className={i < posts.length - 1 ? '' : ''} />
                </div>
              ))
          ) : (
            <div className="flex justify-center text-gray center p-12">
              No posts mentioning ${projectProfile.Username}
            </div>
          )}
        </div>
      ),
    },
    {
      tab: 'Proposals',
      panel: (
        <div className="p-0">
          {showCreateProposals && (
            <div className="border-b border-gray">
              <ActivityFeedCreateProposal
                projectOwnerPkid={projectProfile.PublicKeyBase58Check}
                projectOwnerUsername={projectProfile.Username}
                className=""
                hideCreateProposals={hideCreateProposal}
                getAllProposals={getAllProposals}
              />
            </div>
          )}
          {!showCreateProposals && (
            <div className="flex items-center flex-col gap-2 border-b border-border-light p-6 pb-12">
              <div className="text-muted-foreground text-lg font-semibold">
                Create a proposal for ${projectProfile.Username}
              </div>
              <div className="text-muted mb-2">
                There are currently {filter(proposals, { Status: 'CREATED' }).length} active proposals happening.
              </div>
              <ButtonNew type="submit" onClick={() => setShowCreateProposals(true)}>
                Create Proposal
              </ButtonNew>
            </div>
          )}

          {proposals.map((proposal, i) => (
            <ActivityFeedProposal
              key={i}
              className={i !== proposals.length - 1 ? 'border-b border-border-light' : ''}
              proposal={proposal}
              exchangeRates={exchangeRates}
              diamondLevelMap={diamondLevelMap ?? {}}
            />
          ))}
        </div>
      ),
    },
    {
      tab: 'Holders',
      panel: (
        <div className="flex flex-col gap-4 px-4 sm:px-12 py-0">
          <ProjectHodlersLeaderBoard hodlers={hodlers} exchangeRates={exchangeRates} project={projectProfile} />
        </div>
      ),
    },
    holdings.length > 0
      ? {
          tab: 'Holdings',
          panel: (
            <div className="flex flex-col gap-4 px-4 sm:px-12 py-0">
              <ProjectHodlersLeaderBoard
                hodlers={holdings}
                exchangeRates={exchangeRates}
                project={projectProfile}
                holdingsView={true}
              />
            </div>
          ),
        }
      : null,
    {
      tab: 'Referrals',
      panel: (
        <div className="flex flex-col gap-4 border mx-12 my-0 border-border-light rounded-2xl pt-0">
          <ProjectAdvocatesLeaderBoard
            advocates={advocates}
            exchangeRates={exchangeRates}
            referralLink={referralLink}
            fundingRound={fundingRound}
          />
        </div>
      ),
    },
    // {
    //   tab: 'Messages',
    //   panel: (
    //     <>
    //       <Messaging
    //         wideChat={true}
    //         showSelector={false}
    //         defaultProjectPublicKey={projectProfile.PublicKeyBase58Check}
    //       ></Messaging>
    //     </>
    //   ),
    // },
  ];

  const visibleTabs = tabs.filter((e) => e !== null) as TabGroup[];

  return (
    <div>
      <Tabs selectedIndex={selectedTab} tabs={visibleTabs} tabListClassName="mx-4 md:mx-12" />
    </div>
  );
}
