import { NumberInput, NumberInputProps } from 'components/app-ui/NumberInput';
import { useState } from 'react';

export type NumberInputWithMaxToggleProps = {
  onMax?: (() => string) | undefined;
} & NumberInputProps;

export function NumberInputWithMaxToggle({
  initialValue,
  onMax = undefined,
  onValueChange,
  ...rest
}: NumberInputWithMaxToggleProps) {
  const [currentValue, setCurrentValue] = useState(initialValue);
  return (
    <div className="relative flex-1">
      <NumberInput
        className="is-invalid invalid-feedback relative"
        initialValue={currentValue}
        onValueChange={(value) => {
          setCurrentValue(value);
          onValueChange?.(value);
        }}
        {...rest}
      />
      {onMax && (
        <button
          className="absolute top-12 text-sm right-4 text-foreground hover:text-primary cursor-pointer"
          type="button"
          onClick={() => {
            const maxVal = onMax();
            setCurrentValue(maxVal);
          }}
        >
          Max
        </button>
      )}
    </div>
  );
}
