import { LuExternalLink } from 'react-icons/lu';

import { CopyToClipboard } from './CopyToClipboard';
import { DESO_EXPLORER_URL } from '../../constants/AppConstants';
import { cn } from '../../utils/shadcn';
import { shortenLongWord } from '../../utils/deso';

const TransactionCopyToClipboard = ({
  transactionHash,
  timestamp = '',
  txnClassName = '',
}: {
  transactionHash: string;
  timestamp?: string;
  txnClassName?: string;
}) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <CopyToClipboard text={transactionHash} label="">
        <a
          href={`${DESO_EXPLORER_URL}/txn/${transactionHash}`}
          target="blank"
          className=" flex items-center gap-1"
          title="View on explorer"
        >
          <div className={cn('text-sm font-medium', txnClassName)}>{shortenLongWord(transactionHash, 5, 5)}</div>
          <LuExternalLink size={16} className="text-muted" />
        </a>
      </CopyToClipboard>
      <div className="text-xs hidden text-muted">
        {timestamp
          ? new Date(timestamp)
              .toLocaleString('en-US', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })
              .replace(',', ' @')
          : 'Pending...'}
      </div>
    </div>
  );
};
export default TransactionCopyToClipboard;
