import { useContext, useEffect, useMemo, useRef } from 'react';
import { ChartingLibraryWidgetOptions, ResolutionString, widget } from '../../charting_library';
import { HistoryMetadata } from '../../../public/charting_library/datafeed-api';
import { ProfileEntryResponse } from 'deso-protocol';
import { OpenfundContext } from '../../contexts/OpenfundContext';
import { getCandlestickHistory } from '../../services/Focus';
import { useTheme } from '../../contexts/ThemeContext';
import { DESO_TOKEN_PUBLIC_KEY } from '../../constants/TradeConstants';
import { DESO_USDC_PUBLIC_KEY, DESO_ZERO_PUBLIC_KEY } from '../../constants/AppConstants';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { THEME_OPTIONS } from 'utils/constants';

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
  theme: 'dark' | 'light';
  overrides: ChartingLibraryWidgetOptions['overrides'];
  disabled_features: ChartingLibraryWidgetOptions['disabled_features'];
  time_frames: ChartingLibraryWidgetOptions['time_frames'];
}

const getQueryParamBasedOnResolution = (resolution: string) => {
  switch (resolution) {
    case '1':
      return '1M';
    case '5':
      return '5M';
    case '15':
      return '15M';
    case '60':
      return '1H';
    case '180':
      return '3H';
    case 'D':
      return '1D';
    case 'W':
      return '1W';
    case '4W':
      return '4W';
    default:
      return '1D';
  }
};

const getThemeOverrides = (theme: string) => {
  switch (theme) {
    case THEME_OPTIONS.lightMode:
    case THEME_OPTIONS.modernLightMode:
      return {
        'paneProperties.background': '#ffffff',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.separatorColor': '#e0e0e0',
        'paneProperties.vertGridProperties.color': '#e0e0e0',
        'paneProperties.horzGridProperties.color': '#e0e0e0',
      };
    case THEME_OPTIONS.darkMode:
    case THEME_OPTIONS.modernDarkMode:
      return {
        'paneProperties.background': '#040609',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.separatorColor': '#2a323ba8',
        'paneProperties.vertGridProperties.color': '#2a323ba8',
        'paneProperties.horzGridProperties.color': '#2a323ba8',
      };
    case THEME_OPTIONS.paperLightMode:
      return {
        'paneProperties.background': '#f5f5f1',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.separatorColor': '#d2d2d2',
        'paneProperties.vertGridProperties.color': '#d2d2d2',
        'paneProperties.horzGridProperties.color': '#d2d2d2',
      };
    case THEME_OPTIONS.paperDarkMode:
      return {
        'paneProperties.background': '#071017',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.separatorColor': '#253441',
        'paneProperties.vertGridProperties.color': '#253441',
        'paneProperties.horzGridProperties.color': '#253441',
      };
    case THEME_OPTIONS.radarDarkMode:
      return {
        'paneProperties.background': '#080f0a',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.separatorColor': '#7282750d',
        'paneProperties.vertGridProperties.color': '#7282750d',
        'paneProperties.horzGridProperties.color': '#7282750d',
      };
    case THEME_OPTIONS.radarLightMode:
      return {
        'paneProperties.background': '#0f0808',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.separatorColor': '#423131',
        'paneProperties.vertGridProperties.color': '#423131',
        'paneProperties.horzGridProperties.color': '#423131',
      };
    default:
      return {
        'paneProperties.background': '#ffffff',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.separatorColor': '#e0e0e0',
        'paneProperties.vertGridProperties.color': '#e0e0e0',
        'paneProperties.horzGridProperties.color': '#e0e0e0',
      };
  }
};
const getThemeBackground = (theme: string): string => {
  switch (theme) {
    case THEME_OPTIONS.lightMode:
    case THEME_OPTIONS.modernLightMode:
      return '#ffffff';
    case THEME_OPTIONS.darkMode:
    case THEME_OPTIONS.modernDarkMode:
      return '#040609';
    case THEME_OPTIONS.paperLightMode:
      return '#f5f5f1';
    case THEME_OPTIONS.paperDarkMode:
      return '#071017';
    case THEME_OPTIONS.radarDarkMode:
      return '#080f0a';
    case THEME_OPTIONS.radarLightMode:
      return '#0f0808';
    default:
      return '#ffffff';
  }
};

const getThemeForeground = (theme: string): string => {
  switch (theme) {
    case THEME_OPTIONS.lightMode:
      return '#000000';
    case THEME_OPTIONS.modernLightMode:
      return '#2f2f2f';
    case THEME_OPTIONS.darkMode:
      return '#64758a';
    case THEME_OPTIONS.modernDarkMode:
      return '#a3adb9';
    case THEME_OPTIONS.paperLightMode:
      return '#737373';
    case THEME_OPTIONS.paperDarkMode:
      return '#8ea0ae';
    case THEME_OPTIONS.radarDarkMode:
      return '#728275';
    case THEME_OPTIONS.radarLightMode:
      return '#827272';
    default:
      return '#000000';
  }
};

export const AdvancedTradingViewChart = ({ selectedProject }: { selectedProject: ProfileEntryResponse }) => {
  const { darkTheme, theme } = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const tvWidgetRef = useRef<any>(null);

  const { quoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const { currentUser } = useContext(OpenfundContext);

  const defaultProps: Omit<ChartContainerProps, 'container'> = useMemo(
    () => ({
      symbol: selectedProject.Username,
      interval: '180' as ResolutionString,
      libraryPath: '/charting_library/',
      chartsStorageUrl: 'https://saveload.tradingview.com',
      chartsStorageApiVersion: '1.1',
      clientId: 'tradingview.com',
      userId: currentUser?.PublicKeyBase58Check,
      fullscreen: false,
      autosize: true,
      disabled_features: [],
      studiesOverrides: {},
      theme: darkTheme ? 'dark' : 'light',
      overrides: getThemeOverrides(theme),
      time_frames: [
        { text: '1M', resolution: '3H' as ResolutionString, description: '1 month in 3 hour interval' },
        { text: '1W', resolution: '1H' as ResolutionString, description: '1 week with 1 hour interval' },
      ],
    }),
    [selectedProject, currentUser],
  );

  const createDatafeed = () => {
    return {
      onReady: (callback: (config: any) => void) => {
        setTimeout(
          () =>
            callback({
              supports_search: true,
              supports_group_request: false,
              supported_resolutions: ['1', '5', '15', '60', '180', 'D', 'W', 'M'],
              supports_marks: false,
              supports_time: true,
            }),
          0,
        );
      },

      resolveSymbol: (symbolName: string, onResolve: (symbolInfo: any) => void, onError: (error: string) => void) => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        setTimeout(
          () =>
            onResolve({
              ticker: symbolName,
              name: symbolName,
              type: 'crypto',
              session: '24x7',
              timezone: userTimezone,
              minmov: 1,
              pricescale: 100000,
              has_intraday: true,
              supported_resolutions: ['1', '5', '15', '60', '180', 'D', 'W', 'M'],
              volume_precision: 2,
            }),
          0,
        );
      },

      getBars: async (
        symbolInfo: any,
        resolution: string,
        periodParams: { from: number; to: number; countBack: number; firstDataRequest: boolean },
        onResult: (bars: any[], metadata: HistoryMetadata) => void,
        onError: (error: string) => void,
      ) => {
        try {
          const { candlesticks } = await getCandlestickHistory({
            symbol:
              selectedProject.PublicKeyBase58Check === DESO_TOKEN_PUBLIC_KEY
                ? DESO_ZERO_PUBLIC_KEY
                : selectedProject.PublicKeyBase58Check,
            to: periodParams.to * 1000,
            resolution: getQueryParamBasedOnResolution(resolution),
            countback: periodParams.countBack,
            quoteSymbol:
              selectedProject.PublicKeyBase58Check === DESO_TOKEN_PUBLIC_KEY
                ? DESO_USDC_PUBLIC_KEY
                : quoteCurrencyPublicKey,
          });

          const bars = candlesticks.map((candle: any) => ({
            time: Number(candle.time),
            open: candle.open,
            high: candle.high,
            low: candle.low,
            close: candle.close,
            volume: candle.volume,
          }));

          onResult(bars, { noData: candlesticks.length === 0 });
        } catch (error) {
          console.error('Error fetching candlestick data:', error);
          onError('Failed to load data');
        }
      },

      searchSymbols: (userInput: string, exchange: string, symbolType: string, onResult: (symbols: any[]) => void) => {
        onResult([]);
      },

      subscribeBars: (symbolInfo: any, resolution: string, onTick: (bar: any) => void, listenerGuid: string) => {},

      unsubscribeBars: (listenerGuid: string) => {},
    };
  };

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      datafeed: createDatafeed(),
      interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
      container: chartContainerRef.current ?? '',
      library_path: defaultProps.libraryPath as string,
      locale: 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'header_compare',
        'header_fullscreen_button',
        'header_undo_redo',
        'header_saveload',
        'control_bar',
      ],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: defaultProps.theme,
      overrides: defaultProps.overrides,
      time_frames: defaultProps.time_frames,
    };

    const tvWidget = new widget(widgetOptions);
    tvWidgetRef.current = tvWidget;

    tvWidget.onChartReady(() => {
      const backgroundColor = getThemeBackground(theme);
      const foregroundColor = getThemeForeground(theme);
      tvWidget.setCSSCustomProperty('--tv-color-platform-background', backgroundColor);
      tvWidget.setCSSCustomProperty('--tv-color-pane-background', backgroundColor);
      tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-text', foregroundColor);

      const priceScale = tvWidget.activeChart().getPanes()[0].getMainSourcePriceScale();
      priceScale?.setAutoScale(true);
    });

    return () => {
      if (tvWidgetRef.current) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };
  }, [selectedProject]);

  useEffect(() => {
    if (tvWidgetRef.current) {
      const backgroundColor = getThemeBackground(theme);
      const foregroundColor = getThemeForeground(theme);
      tvWidgetRef.current.setCSSCustomProperty('--tv-color-platform-background', backgroundColor);
      tvWidgetRef.current.setCSSCustomProperty('--tv-color-pane-background', backgroundColor);
      tvWidgetRef.current.setCSSCustomProperty('--tv-color-toolbar-button-text', foregroundColor);
      tvWidgetRef.current.applyOverrides(getThemeOverrides(theme));
    }
  }, [theme, darkTheme]);

  return <div ref={chartContainerRef} className="h-[350px] md:h-[670px]" />;
};
