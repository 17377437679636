import { App } from 'components/App';
import { NotFound } from 'components/app-ui/NotFound';
import { SiteContainer } from 'components/layout/SiteContainer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RoutePaths } from 'RoutePaths';

import Home from 'components/pages/Home';
import ProjectDetail from 'components/pages/ProjectDetail';
import Discover from 'components/pages/Discover';
import MyOrders from 'components/pages/MyOrders';
import MyWallet from 'components/pages/MyWallet';
import Onboarding from 'components/pages/Onboarding';
import ProjectDetails from 'components/pages/Onboarding/ProjectDetails';
import FollowTopProjects from 'components/pages/Onboarding/FollowTopProjects';
import ProfileInfo from 'components/pages/Onboarding/ProfileInfo';
import ProfileDetail from 'components/pages/ProfileDetail';
import Settings from 'components/pages/Settings';
import Advanced from 'components/pages/Settings/Advanced';
import ProjectDistribution from 'components/pages/Settings/ProjectDistribution';
import FundRaising from 'components/pages/Settings/FundRaising';
import NotificationSettings from 'components/pages/Settings/Notifications';
import Profile from 'components/pages/Settings/Profile';
import Project from 'components/pages/Settings/Project';
import Proposals from 'components/pages/Settings/Proposals';
import Trade from 'components/pages/Trade';
import OnePager from './pages/OnePager';
import VerifyEmail from './pages/VerifyEmail';

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutePaths.home.path} element={<App />}>
          <Route path={RoutePaths.home.path} element={<SiteContainer />}>
            <Route path={RoutePaths.home.path} element={<Home />} />
            <Route path={RoutePaths.discover.path} element={<Discover />} />
            {/*<Route path={RoutePaths.activity.path} element={<Activity />} />*/}
            {/*<Route path={RoutePaths.activityDetail.path} element={<Activity />} />*/}
            <Route path={RoutePaths.fund.path} element={<ProjectDetail />} />
            <Route path={RoutePaths.profile.path} element={<ProfileDetail />} />
            <Route path={RoutePaths.wallet.path} element={<MyWallet />} />
            <Route path={RoutePaths.orders.path} element={<MyOrders showAll={true} />} />
            <Route path={RoutePaths.trade.path} element={<Trade />} />
            <Route path={RoutePaths.tradeToken.path} element={<Trade />} />
            {/*<Route path={RoutePaths.notifications.path} element={<Notifications />} />*/}
            <Route path={RoutePaths.onePager.path} element={<OnePager />} />
            <Route path={RoutePaths.verifyEmail.path} element={<VerifyEmail />} />
            <Route path={RoutePaths.settings.path} element={<Settings />}>
              <Route index element={<Profile />} />
              <Route path={RoutePaths.settings.profile.path} element={<Profile />} />
              <Route path={RoutePaths.settings.project.path} element={<Project />} />
              <Route path={RoutePaths.settings.fundraising.path} element={<FundRaising />} />
              <Route path={RoutePaths.settings.fundraising.path} element={<FundRaising />} />
              <Route path={RoutePaths.settings.distribution.path} element={<ProjectDistribution />} />
              <Route path={RoutePaths.settings.proposals.path} element={<Proposals />} />
              <Route path={RoutePaths.settings.notifications.path} element={<NotificationSettings />} />
              <Route path={RoutePaths.settings.advanced.path} element={<Advanced />} />
            </Route>
          </Route>
          <Route path={RoutePaths.projectOnboarding.path} element={<Onboarding onboardingType="PROJECT" />}>
            <Route path={RoutePaths.projectOnboarding.profile.path} element={<ProfileInfo />} />
            <Route path={RoutePaths.projectOnboarding.details.path} element={<ProjectDetails />} />
          </Route>
          <Route path={RoutePaths.userOnboarding.path} element={<Onboarding onboardingType="USER" />}>
            <Route path={RoutePaths.userOnboarding.profile.path} element={<ProfileInfo />} />
            <Route path={RoutePaths.userOnboarding.follow.path} element={<FollowTopProjects />} />
          </Route>
          <Route path="*" element={<SiteContainer />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
