import { FullPageError } from 'components/app-ui/FullPageError';
import { InvestWithTickerDeposit } from 'components/app-ui/InvestWithTickerDeposit';
import { Tabs } from 'components/core/Tabs';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { ProfileEntryResponse } from 'deso-protocol';
import { useToast } from 'hooks/useToast';
import { useContext } from 'react';
import { deso, openfund } from 'services';
import { FundingRound } from 'services/Openfund';
import { toHex } from 'utils/currency';
import { getErrorMsg } from 'utils/getErrorMsg';
import { PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER } from 'utils/tickers';
import { trackingLogEvent } from '../../utils/tracking';
import { useDepositAddresses } from '../../hooks/useDepositAddresses';
import { Skeleton } from '../shadcn/ui/skeleton';

interface InvestWidgetProps {
  fundingRound: FundingRound;
  referralID: string | null;
  projectProfile: ProfileEntryResponse;
  fundingRoundPassword?: string;
  onInvestSuccess?: () => void;
}
export function InvestWidget({
  fundingRound,
  referralID,
  projectProfile,
  fundingRoundPassword = '',
  onInvestSuccess,
}: InvestWidgetProps) {
  const { currentUser } = useContext(OpenfundContext);
  const { addresses, loading, loadingError } = useDepositAddresses();

  const treasuryCurrencyTicker =
    fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
      ? PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER[fundingRound.TreasuryDaoCoin]
      : 'DESO';
  const toast = useToast();

  if (loading) {
    return <Skeleton className="h-6 w-full" />;
  } else if (loadingError || !addresses) {
    return <FullPageError error={loadingError} />;
  }

  const depositAddresses = addresses[treasuryCurrencyTicker];

  const invest = async (amountBaseUnitsToInvest: bigint, derivedKey: string, onDerivedKeyAuthorized?: () => void) => {
    if (!currentUser) {
      throw new Error('Attempted to contribute but there is not a logged in user');
    }
    let payload = {
      RoundID: fundingRound.RoundID,
      ReferrerReferralID: referralID ?? '',
      InvestorPkidBase58check: currentUser.PublicKeyBase58Check,
      ReserveRateBasisPoints: fundingRound.ReserveRateBasisPoints,
      AmountInvestedDesoNanos: fundingRound.TreasuryCurrencyUnit === 'DESO' ? Number(amountBaseUnitsToInvest) : 0,
      AmountInvestedDaoCoinsHex:
        fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? toHex(amountBaseUnitsToInvest) : '0x0',
    };
    try {
      const expirationDays = fundingRound.EndTime
        ? Math.ceil((new Date(fundingRound.EndTime).getTime() - Date.now()) / 86400000) + 1
        : 365;
      await openfund.createInvestment(payload, fundingRound.DaoOwnerPkidBase58check, derivedKey, expirationDays, {
        onDerivedKeyAuthorized,
        password: fundingRoundPassword,
        tokenTreasuryPublicKey: currentUser.usdBalanceEntry?.CreatorPublicKeyBase58Check,
      });
      await deso.addProjectPurchasedToProfile(fundingRound.DaoOwnerPkidBase58check);
      onInvestSuccess?.();
    } catch (e) {
      trackingLogEvent('invest : create : error', { ...payload, ...fundingRound, error: getErrorMsg(e) });
      toast.show({ message: getErrorMsg(e), type: 'error' });
    }
  };

  return (
    <Tabs
      defaultIndex={0}
      tabs={[
        {
          tab: '$ETH',
          panel: (
            <div className="p-0">
              <InvestWithTickerDeposit
                projectProfile={projectProfile}
                depositAddress={depositAddresses?.DepositAddresses.ETH}
                fundingRound={fundingRound}
                ticker="ETH"
                onInvest={invest}
              />
            </div>
          ),
        },
        {
          tab: '$BTC',
          panel: (
            <div className="p-0">
              <InvestWithTickerDeposit
                projectProfile={projectProfile}
                depositAddress={depositAddresses?.DepositAddresses.BTC}
                fundingRound={fundingRound}
                ticker="BTC"
                onInvest={invest}
              />
            </div>
          ),
        },
        {
          tab: '$SOL',
          panel: (
            <div className="p-0">
              <InvestWithTickerDeposit
                projectProfile={projectProfile}
                depositAddress={depositAddresses?.DepositAddresses.SOL}
                fundingRound={fundingRound}
                ticker="SOL"
                onInvest={invest}
              />
            </div>
          ),
        },
        {
          tab: '$DESO',
          panel: (
            <div className="p-0">
              <InvestWithTickerDeposit
                projectProfile={projectProfile}
                depositAddress={
                  fundingRound.TreasuryCurrencyUnit === 'DESO'
                    ? currentUser?.PublicKeyBase58Check
                    : depositAddresses?.DepositAddresses.DESO
                }
                fundingRound={fundingRound}
                ticker="DESO"
                onInvest={invest}
              />
            </div>
          ),
        },
        {
          tab: '$USDC',
          panel: (
            <div className="p-0">
              <InvestWithTickerDeposit
                projectProfile={projectProfile}
                depositAddress={depositAddresses?.DepositAddresses.USDC}
                fundingRound={fundingRound}
                ticker="USDC"
                onInvest={invest}
              />
            </div>
          ),
        },
        {
          tab: '$DUSDC',
          panel: (
            <div className="p-0">
              <InvestWithTickerDeposit
                projectProfile={projectProfile}
                depositAddress={
                  fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
                    ? currentUser?.PublicKeyBase58Check
                    : depositAddresses?.DepositAddresses.DUSD
                }
                fundingRound={fundingRound}
                ticker="DUSD"
                onInvest={invest}
              />
            </div>
          ),
        },
      ]}
    />
  );
}
