import { NotFound } from 'components/app-ui/NotFound';
import { NumberInput } from 'components/app-ui/NumberInput';
import { Button } from 'components/shadcn/ui/button';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import { Spinner } from 'components/core/Spinner';
import { useSettingsContext } from 'components/pages/Settings';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useToast } from 'hooks/useToast';
import { useContext, useState } from 'react';
import { deso, openfund } from 'services';
import { FUNDING_ROUND_STATUSES } from 'services/Openfund';
import { classNames } from 'utils/classNames';
import { formatTokenBaseUnits, toHex, tokenToBaseUnits } from 'utils/currency';
import { getErrorMsg } from 'utils/getErrorMsg';
import { LuTriangleAlert } from 'react-icons/lu';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/ui/select';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/ui/dialog';

const TRANSFER_RESTRICTIONS: Record<string, string> = {
  unrestricted: 'Anyone Can Transfer & Receive Tokens',
  profile_owner_only: 'Only The Owner Can Transfer & Receive Tokens',
  dao_members_only: 'Only Current Token Owners Can Transfer & Receive Tokens',
  permanently_unrestricted: '[Permanent Setting] Anyone Can Transfer & Receive Tokens',
};
const TRANSFER_RESTRICTION_DETAILS: Record<string, string> = {
  unrestricted:
    'Anyone can buy, sell, transfer, and receive your token. This is the recommended setting unless you know what you are doing.',
  profile_owner_only:
    'Anyone can contribute to your project through a funding round and get tokens. However, users are restricted from buying, selling, transferring, or receiving your token to each other or trading them on the exchange. You are able to freely transfer tokens to any user. Only choose this setting if you are sure what you are doing.',
  dao_members_only:
    'Anyone can contribute to your project through a funding round and get tokens. Only users who already own your token will be able to buy, sell, transfer, and receive your token. Only choose this setting if you are sure what you are doing.',
  permanently_unrestricted:
    '[WARNING] This is an irreversible action! Anyone can buy, sell, transfer, and receive your token. Only choose this setting if you are sure you want this setting to be permanent.',
};

export function Advanced() {
  useDocumentTitle('Advanced');
  const { setHeadingSuffix } = useSettingsContext();
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);

  useEffectOnce(() => setHeadingSuffix('Advanced'));

  const [tokensToMint, setTokensToMint] = useState(0);
  const [tokensToBurn, setTokensToBurn] = useState(0);
  const [isPendingMint, setIsPendingMint] = useState(false);
  const [isPendingBurn, setIsPendingBurn] = useState(false);
  const [showAdvancedProjectSettings, setShowAdvancedProjectSettings] = useState(false);
  const [isPendingDisableMinting, setIsPendingDisableMinting] = useState(false);
  const [isDisableMintingConfirmationOpen, setIsDisableMintingConfirmationOpen] = useState(false);
  const [isPendingTransferRestrictionUpdate, setIsPendingTransferRestrictionUpdate] = useState(false);

  const [selectedTransferRestrictionStatus, setSelectedTransferRestrictionStatus] = useState<
    'unrestricted' | 'profile_owner_only' | 'dao_members_only' | 'permanently_unrestricted'
  >(
    (currentUser?.ProfileEntryResponse?.DAOCoinEntry?.TransferRestrictionStatus ??
      TRANSFER_RESTRICTIONS.unrestricted) as
      | 'unrestricted'
      | 'profile_owner_only'
      | 'dao_members_only'
      | 'permanently_unrestricted',
  );

  const toast = useToast();

  if (!currentUser || !currentUser.ProfileEntryResponse) {
    return <NotFound />;
  }

  return (
    <div>
      <div className="text-muted-foreground font-semibold text-xl mb-6">Advanced Settings</div>
      <div>
        {isPendingDisableMinting ? (
          <div className="text-center py-8">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex gap-2 mb-4 text-muted">
              Tokens in Circulation:{' '}
              <span className="font-mono text-muted-foreground">
                {currentUser.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos
                  ? formatTokenBaseUnits(
                      currentUser.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos.toString(),
                    )
                  : 0}
              </span>
            </div>
            {!currentUser.ProfileEntryResponse?.DAOCoinEntry?.MintingDisabled && (
              <div className="rounded-2xl border border-border-light mb-6">
                <form
                  className="flex flex-col md:flex-row sm:items-center gap-4 p-4 sm:p-6 border-b border-border-light"
                  onSubmit={async (ev) => {
                    ev.preventDefault();
                    if (tokensToMint === 0 || isPendingMint) {
                      return;
                    }
                    setIsPendingMint(true);
                    try {
                      await deso.mintMyTokens(toHex(tokenToBaseUnits(tokensToMint)));
                      toast.show({
                        message: `Successfully minted ${tokensToMint} tokens`,
                        type: 'success',
                        sticky: false,
                      });
                      setCurrentUser(await openfund.reloadCurrentUserData());
                    } catch (e) {
                      toast.show({ message: getErrorMsg(e), type: 'error' });
                    }
                    setIsPendingMint(false);
                    setTokensToMint(0);
                  }}
                >
                  <div className="w-full">
                    <NumberInput
                      labelText={`Mint $${currentUser.ProfileEntryResponse.Username} Tokens`}
                      value={tokensToMint}
                      allowedDecimalPlaces={18}
                      onValueChange={(value) => {
                        setTokensToMint(Number(value));
                      }}
                      hint={`This will manually mint new tokens into your wallet.`}
                    />
                  </div>
                  <Button type="submit" disabled={!tokensToMint}>
                    {isPendingMint ? <LoadingSpinner colorHex="#fff" className="h-6 w-6" /> : 'Mint'}
                  </Button>
                </form>
                <form
                  className="flex flex-col md:flex-row sm:items-center gap-4 p-4 sm:p-6 "
                  onSubmit={async (ev) => {
                    ev.preventDefault();
                    if (tokensToBurn === 0) {
                      return;
                    }
                    setIsPendingBurn(true);
                    try {
                      await deso.burnTokens(toHex(tokenToBaseUnits(tokensToBurn)), currentUser.PublicKeyBase58Check);
                      toast.show({
                        message: `Successfully burned ${tokensToBurn} tokens`,
                        type: 'success',
                        sticky: false,
                      });
                      setCurrentUser(await openfund.reloadCurrentUserData());
                    } catch (e) {
                      toast.show({ message: getErrorMsg(e), type: 'error' });
                    }
                    setIsPendingBurn(false);
                    setTokensToBurn(0);
                  }}
                >
                  <div className="w-full">
                    <NumberInput
                      labelText={`Burn $${currentUser.ProfileEntryResponse.Username} Tokens`}
                      initialValue={tokensToBurn}
                      allowedDecimalPlaces={5}
                      onValueChange={(value) => {
                        setTokensToBurn(Number(value));
                      }}
                      hint={`This will manually burn existing tokens from your wallet.`}
                    />
                  </div>
                  <Button type="submit" disabled={!tokensToBurn}>
                    {isPendingBurn ? <LoadingSpinner colorHex="#fff" className="h-6 w-6" /> : 'Burn'}
                  </Button>
                </form>
              </div>
            )}
            <div className="p-6 rounded-2xl border border-red-500 bg-red-900/5">
              <div className="text-red-500 text-sm font-semibold mb-4 flex items-center gap-2">
                <LuTriangleAlert className="text-xl" />
                Danger Zone
              </div>
              <div className="relative">
                <div className="text-sm mb-2">Token Transfer Restriction</div>
                <div className="md:flex items-end align-top gap-4">
                  <div className="w-full">
                    <Select
                      disabled={
                        currentUser?.ProfileEntryResponse?.DAOCoinEntry?.TransferRestrictionStatus ===
                        'permanently_unrestricted'
                      }
                      defaultValue={selectedTransferRestrictionStatus}
                      onValueChange={(
                        value: 'unrestricted' | 'profile_owner_only' | 'dao_members_only' | 'permanently_unrestricted',
                      ) => {
                        setSelectedTransferRestrictionStatus(value);
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select transfer restriction" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.keys(TRANSFER_RESTRICTIONS).map((k) => (
                          <SelectItem key={k} value={k}>
                            {TRANSFER_RESTRICTIONS[k]}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <div className="text-muted text-sm my-4">
                      {TRANSFER_RESTRICTION_DETAILS[selectedTransferRestrictionStatus]}
                    </div>
                  </div>
                  <div className="mb-auto">
                    <Button
                      disabled={
                        currentUser?.ProfileEntryResponse?.DAOCoinEntry?.TransferRestrictionStatus ===
                        'permanently_unrestricted'
                      }
                      onClick={async () => {
                        if (isPendingTransferRestrictionUpdate) {
                          return;
                        }
                        setIsPendingTransferRestrictionUpdate(true);
                        try {
                          await deso.changeMyTokenTransferRestrictionStatus(selectedTransferRestrictionStatus);
                          setCurrentUser(await openfund.reloadCurrentUserData());
                          toast.show({
                            message: 'Transfer restriction updated successfully',
                            type: 'success',
                            sticky: false,
                          });
                        } catch (e) {
                          toast.show({
                            message: getErrorMsg(e),
                            type: 'error',
                          });
                        }
                        setIsPendingTransferRestrictionUpdate(false);
                      }}
                    >
                      {isPendingTransferRestrictionUpdate ? (
                        <LoadingSpinner colorHex="#fff" className="h-6 w-6" />
                      ) : (
                        'Update'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              {!currentUser?.ProfileEntryResponse?.DAOCoinEntry?.MintingDisabled && (
                <div className="flex flex-col gap-2 border-t border-border-light pt-6 mt-2">
                  <div className="text-sm mb-2">Permanently Disable Minting</div>
                  <Button
                    size="sm"
                    variant={'outline'}
                    onClick={() => {
                      setShowAdvancedProjectSettings(!showAdvancedProjectSettings);
                    }}
                  >
                    {showAdvancedProjectSettings ? 'Hide' : 'Show'} Option to Permanently Disable Minting
                  </Button>
                </div>
              )}
              <div
                className={classNames(
                  !showAdvancedProjectSettings &&
                    !currentUser?.ProfileEntryResponse?.DAOCoinEntry?.MintingDisabled &&
                    'hidden',
                )}
              >
                <div className="text-muted text-sm my-4">
                  <span className="font-semibold text-red-500">WARNING!</span>{' '}
                  <span className="text-sm text-red-500">This is an irreversible action.</span> This automatically
                  closes any of your open funding rounds and you will not be able to open a new one. It will permanently
                  disable your ability to mint new project tokens.
                </div>
                <Button
                  variant="destructive"
                  disabled={currentUser?.ProfileEntryResponse?.DAOCoinEntry?.MintingDisabled}
                  onClick={async () => {
                    setIsDisableMintingConfirmationOpen(true);
                  }}
                  aria-describedby="disable-minting-message"
                >
                  {currentUser?.ProfileEntryResponse?.DAOCoinEntry?.MintingDisabled
                    ? 'Minting has been permanently disabled'
                    : 'Disable Minting'}
                </Button>
              </div>
            </div>
            <Dialog open={isDisableMintingConfirmationOpen} onOpenChange={setIsDisableMintingConfirmationOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Confirm</DialogTitle>
                  <DialogDescription className="text-muted text-sm leading-5 m-0">
                    Are you sure you want to permanently disable the ability to mint $
                    {currentUser.ProfileEntryResponse.Username} tokens? This will automatically close any of your open
                    funding rounds and you will not be able to open a new one.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter className="flex gap-4">
                  <Button
                    variant="destructive"
                    onClick={async () => {
                      if (!currentUser.ProfileEntryResponse) {
                        toast.show({
                          message: 'Attempted to disable minting but there is not a logged in profile',
                          type: 'error',
                        });
                        return;
                      }
                      setIsPendingDisableMinting(true);
                      // first we try to close any open funding round they might
                      // have regardless of whether anything fails here, we still
                      // try to disable minting in the subsequent try/catch
                      try {
                        const fundingRounds = await openfund.getFundingRoundsByUsername(
                          currentUser.ProfileEntryResponse?.Username,
                        );
                        const openFundingRound = fundingRounds.find(
                          ({ RoundStatus }) => RoundStatus === FUNDING_ROUND_STATUSES.OPEN,
                        );
                        if (openFundingRound) {
                          await openfund.finalizeFundingRound(
                            openFundingRound.RoundID,
                            currentUser.PublicKeyBase58Check,
                          );
                        }
                      } catch (e) {
                        toast.show({ message: getErrorMsg(e), type: 'error' });
                      }

                      try {
                        await deso.disableMintingMyToken();
                        setCurrentUser(await openfund.reloadCurrentUserData());
                        setIsDisableMintingConfirmationOpen(false);
                        toast.show({ message: 'Successfully disabled minting', type: 'success', sticky: false });
                      } catch (e) {
                        toast.show({ message: getErrorMsg(e), type: 'error' });
                      }
                      setIsPendingDisableMinting(false);
                    }}
                  >
                    Confirm Disable Minting
                  </Button>
                  <Button variant="outline" onClick={() => setIsDisableMintingConfirmationOpen(false)}>
                    Cancel
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
    </div>
  );
}

export default Advanced;
