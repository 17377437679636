import { NumberInput, NumberInputProps } from 'components/app-ui/NumberInput';

import {
  Select as SelectNew,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/shadcn/ui/tooltip';
import { useState } from 'react';

function getInputPrefix(ticker: string) {
  switch (ticker) {
    case 'USD':
      return '$';
    default:
      return '';
  }
}

type CurrencyInputWithTickersProps = {
  tickers: string[];
  onStateChange: (value: string, ticker: string) => void;
  onMax?: (selectedTicker: string) => string | number;
  autoSelectTickerOnMax: string;
  allowedDecimalPlaces?: number | undefined;
  hideTicker?: boolean;
} & NumberInputProps;

export function CurrencyInputWithTickers({
  tickers,
  onStateChange,
  initialValue,
  autoSelectTickerOnMax,
  onMax,
  allowedDecimalPlaces,
  hint,
  hideTicker = false,
  ...rest
}: CurrencyInputWithTickersProps) {
  if (tickers.length === 0) {
    throw new Error('Tickers list cannot be empty');
  }

  const [currentValue, setCurrentValue] = useState(initialValue?.toString().replace(/[^\d.]/g, '') ?? '0');
  const [currentTicker, setCurrentTicker] = useState(tickers[0]);

  return (
    <div className="relative">
      <NumberInput
        prefix={getInputPrefix(currentTicker)}
        initialValue={currentValue}
        allowedDecimalPlaces={currentTicker === 'USD' || currentTicker === 'USDC' ? 9 : allowedDecimalPlaces}
        rightContent={
          <div className="flex items-center justify-end gap-2 w-[120px] text-right absolute -top-[8px] right-[8px]">
            {typeof onMax !== 'undefined' && (
              <button
                type="button"
                className="text-foreground text-sm hover:text-muted-foreground"
                onClick={() => {
                  setCurrentTicker(autoSelectTickerOnMax);

                  const newValue = onMax(autoSelectTickerOnMax).toString();
                  setCurrentValue(newValue);
                }}
              >
                Max
              </button>
            )}
            {!hideTicker && (
              <SelectNew
                value={currentTicker}
                onValueChange={(value) => {
                  setCurrentTicker(value);
                  onStateChange?.(currentValue, value);
                }}
              >
                <SelectTrigger>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <SelectValue>
                          {currentTicker.length > 8 ? `${currentTicker.slice(0, 8)}...` : currentTicker}
                        </SelectValue>
                      </TooltipTrigger>
                      {currentTicker.length > 8 && <TooltipContent>${currentTicker}</TooltipContent>}
                    </Tooltip>
                  </TooltipProvider>
                </SelectTrigger>
                <SelectContent>
                  {tickers.map((t) => (
                    <SelectItem key={t} value={t}>
                      {t}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectNew>
            )}
          </div>
        }
        {...rest}
        onValueChange={(value) => {
          onStateChange?.(value, currentTicker);
          setCurrentValue(value);
        }}
      />
    </div>
  );
}
