import { Alert, AlertDescription } from 'components/shadcn/ui/alert';

interface CurrencyNetworkAlertProps {
  ticker: string;
  alertType: 'send' | 'receive';
}

export function CurrencyNetworkAlert({ ticker, alertType }: CurrencyNetworkAlertProps) {
  const getSendText = () => {
    switch (ticker) {
      case 'USDC':
        return 'Note: This address only accepts mainnet USDC ERC-20 tokens sent from an Ethereum wallet. Do not send USDC from other networks, any layer-2, side chains, ZK-rollups, wrapped USDC, etc. or it will result in a loss of funds.';
      case 'ETH':
        return 'Note: This address only accepts mainnet ETH sent from an Ethereum wallet. Do not send ETH from other networks, any layer-2, side chains, ZK-rollups, wrapped ETH, etc. or it will result in a loss of funds.';
      case 'USDC-SOL':
        return 'Note: This address only accepts mainnet USDC tokens sent from a Solana wallet. Do not send USDC from other networks, any layer-2, side chains, ZK-rollups, wrapped USDC, etc. or it will result in a loss of funds.';
      case 'SOL':
        return 'Note: This address only accepts mainnet SOL sent from a Solana wallet. Do not send SOL from other networks, SPL tokens, wrapped SOL, etc. or it will result in a loss of funds.';
      case 'USDT':
        return 'Note: This address only accepts mainnet USDT ERC-20 tokens sent from an Ethereum wallet. Do not send USDT from other networks, any layer-2, side chains, ZK-rollups, wrapped USDT, etc. or it will result in a loss of funds.';
      case 'BTC':
        return 'Note: This address only accepts mainnet BTC sent from a Bitcoin wallet. Do not send BTC from other networks, wrapped BTC, etc. or it will result in a loss of funds.';
    }
  };

  const getReceiveText = () => {
    switch (ticker) {
      case 'BTC':
        return 'Note: This address must be a valid Bitcoin mainnet wallet address to receive BTC. Do not use a wallet address from any other network, side chain, L2, ZK-rollups, etc. or it will result in a loss of funds.';
      case 'ETH':
        return 'Note: This address must be a valid Ethereum mainnet wallet address to receive ETH. Do not use a wallet address from any other network, side chain, L2, ZK-rollups, etc. or it will result in a loss of funds.';
      case 'SOL':
        return 'Note: This address must be a valid Solana mainnet wallet address to receive SOL. Do not use a wallet address from any other network, side chain, L2, ZK-rollups, etc. or it will result in a loss of funds.';
      case 'USDC':
        return 'Note: This address must be a valid Ethereum mainnet wallet address to receive USDC ERC-20 tokens. Do not use a wallet address from any other network, side chain, L2, ZK-rollups, etc. or it will result in a loss of funds.';
      case 'USDT':
        return 'Note: This address must be a valid Ethereum mainnet wallet address to receive USDT ERC-20 tokens. Do not use a wallet address from any other network, side chain, L2, ZK-rollups, etc. or it will result in a loss of funds.';
    }
  };

  const getText = () => {
    if (alertType === 'receive') {
      return getReceiveText();
    } else if (alertType === 'send') {
      return getSendText();
    }

    throw new Error(`Alert type ${alertType} is not supported`);
  };

  const text = getText();

  if (!text) {
    return <></>;
  }

  return (
    <Alert variant="warning" className="my-4">
      <AlertDescription>{text}</AlertDescription>
    </Alert>
  );
}
