import { FiLoader } from 'react-icons/fi';
import { IoCopyOutline, IoInformationCircleOutline } from 'react-icons/io5';
import { CopyToClipboard } from 'components/app-ui/CopyToClipboard';
import { CountdownTimer } from 'components/app-ui/CountdownTimer';
import { FeaturedImage } from 'components/app-ui/FeaturedImage';
import { FollowButton } from 'components/app-ui/FollowButton';
import { FullPageError } from 'components/app-ui/FullPageError';
import { InvestWidget } from 'components/app-ui/InvestWidget';
import { NFTCarousel } from 'components/app-ui/NFTCarousel';
import { NotFound } from 'components/app-ui/NotFound';
import { ProjectActivityFeed } from 'components/app-ui/ProjectActivityFeed';
import { ProjectHodlersLeaderBoard } from 'components/app-ui/ProjectHodlersLeaderBoard';
import { ToggleProjectTradingStatus } from 'components/app-ui/ToggleProjectTradingStatus';
import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/core/Button';
import { Heading } from 'components/core/Heading';
import { Input } from 'components/core/Input';
import { Modal } from 'components/core/Modal';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/shadcn/ui/dialog';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/shadcn/ui/table';
import { OpenfundContext } from 'contexts/OpenfundContext';
import {
  BalanceEntryResponse,
  DAOCoinEntryResponse,
  getExchangeRates,
  GetHodlersForPublicKeyResponse,
  GetNFTsCreatedByPublicKeyResponse,
  HodlersSortType,
  ProfileEntryResponse,
} from 'deso-protocol';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { LuShare } from 'react-icons/lu';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { Routes } from 'RoutePaths';
import { confetti, deso, openfund } from 'services';
import { FUNDING_ROUND_STATUSES, FundingRound, GetProjectReferralsResponse, Investment } from 'services/Openfund';
import { classNames } from 'utils/classNames';
import {
  baseUnitsToTokens,
  computeTokensLessReserveAndFees,
  desoNanosToUSD,
  desoToUSD,
  formatDecimalValue,
  formatUSD,
  getTokenPrice,
  usdCentsToDeso,
  usdCentsToUSD,
} from 'utils/currency';
import { hasEndTime } from 'utils/fundingRound';
import { getErrorMsg } from 'utils/getErrorMsg';
import { sortHoldersByTokenValue } from 'utils/holders';
import { centerEllipsis, linkifyText, toTitleCase } from 'utils/text';
import { v4 as uuid } from 'uuid';
import { GetExchangeRateUpdatedResponse, ProjectPublicKeysPurchasedKey } from '../../services/Deso';
import { formatDateSimpleStr } from '../../utils/date';
import { DEFAULT_FOLLOW_MODAL_STATE, FollowModal, FollowModalState } from '../app-ui/FollowModal';
import LoginModal from '../core/LoginModal';

const claimTokenModalShownMap: { [k: string]: boolean } = {};

export function ProjectDetail() {
  const { username } = useParams();
  useDocumentTitle(`Fund ${username}`);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>();
  const [profile, setProfile] = useState<ProfileEntryResponse | null>(null);
  const [fundingRound, setFundingRound] = useState<FundingRound | null>(null);
  const [allFundingRounds, setAllFundingRounds] = useState<FundingRound[]>([]);
  const [referralLink, setReferralLink] = useState('');
  const [shareLink, setShareLink] = useState(window.location.href);
  const [hasReferralCodeApiError, setHasReferralCodeApiError] = useState(false);
  const [currentUserBalanceHex, setCurrentUserBalanceHex] = useState('0x0');
  const toast = useToast();
  const navigate = useNavigate();
  const [exchangeRates, setExchangeRates] = useState<GetExchangeRateUpdatedResponse>();
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [topHolders, setTopHolders] = useState<GetHodlersForPublicKeyResponse>();
  const [projectHoldings, setProjectHoldings] = useState<BalanceEntryResponse[]>([]);
  const [investments, setInvestments] = useState<(Investment & { projectProfile: ProfileEntryResponse })[]>([]);
  const [percentRaised, setPercentRaised] = useState(0);
  const [selectedCommunityTab, setSelectedCommunityTab] = useState(0);
  const [isContributeModalOpen, setIsContributeModalOpen] = useState(false);
  const [isRoundHistoryModalOpen, setIsRoundHistoryModalOpen] = useState(false);
  const [showRefundInvestmentModal, setShowRefundInvestmentModal] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [tokenPrice, setTokenPrice] = useState(0);
  const isMounted = useIsMounted();
  const [password, setPassword] = useState(searchParams.get('key') ?? '');
  const [followModalState, setFollowModalState] = useState<FollowModalState>(DEFAULT_FOLLOW_MODAL_STATE);
  const [projectReferrals, setProjectReferrals] = useState<GetProjectReferralsResponse>();
  const [canInvest, setCanInvest] = useState(true);
  const [hasFutureStartTime, setHasFutureStartTime] = useState(false);
  const [descriptionReadMore, setDescriptionReadMore] = useState(false);
  const [claimTokensModalOpen, setClaimTokensModalOpen] = useState<boolean>(false);
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const communitySectionId = uuid();
  const [nftsPromise, setNFTsPromise] = useState<Promise<GetNFTsCreatedByPublicKeyResponse | null>>();
  const [hasNFTs, setHasNFTs] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    if (username) {
      Promise.all([
        deso.getProfileByUsername(username, true, true),
        openfund.getFundingRoundsByUsername(username, true),
        getExchangeRates(),
        openfund.getTopProjectHolders(username, 'wealth'), // {'wealth', 'coin_balance'}
        deso.getProjectHoldings(username, HodlersSortType.wealth, 0, true), // {'wealth', 'coin_balance'}
        deso.getFollowerCountByUsername(username),
        deso.getFollowingCountByUsername(username),
        openfund.getProjectReferrals(username),
      ])
        .then(
          ([
            profileResponse,
            fundingRounds,
            xRates,
            topHodlersResponse,
            holdingsResponse,
            followerCountResponse,
            followingCountResponse,
            _projectReferrals,
          ]) => {
            if (profileResponse.IsBlacklisted) {
              navigate('/not-found', { replace: true });
              return;
            }
            if (fundingRounds.length === 0) {
              navigate(`/profile/${username}`, { replace: true });
              return;
            }
            if (isMounted.current) {
              const passwordParam = searchParams.get('key') ?? '';
              setPassword(passwordParam);
              setProfile(profileResponse.Profile);
              const latestRound: FundingRound = fundingRounds[0];
              const percent =
                latestRound.TreasuryCurrencyUnit === 'DAO_COIN'
                  ? (latestRound.AmountRaisedUSDCents / latestRound.AmountToRaiseUsdCents) * 100
                  : ((desoNanosToUSD(latestRound.AmountRaisedDesoNanos, xRates.USDCentsPerDeSoCoinbase) * 100) /
                      latestRound.AmountToRaiseUsdCents) *
                    100;
              setPercentRaised(percent);
              const now = Date.now();
              const startsInTheFuture = new Date(latestRound.StartTime).getTime() > now;
              const hasEnded = hasEndTime(latestRound) && new Date(latestRound.EndTime).getTime() < now;
              setHasFutureStartTime(startsInTheFuture);
              setCanInvest(
                latestRound &&
                  !startsInTheFuture &&
                  !hasEnded &&
                  (latestRound.AmountToRaiseUsdCents === 0 || latestRound.AllowOverflow || percent < 100) &&
                  latestRound.RoundStatus === FUNDING_ROUND_STATUSES.OPEN,
              );
              setExchangeRates(xRates);
              setFundingRound(latestRound);
              setTopHolders(topHodlersResponse);

              let holdings = sortHoldersByTokenValue(holdingsResponse.Hodlers);
              setProjectHoldings(holdings);

              setFollowerCount(followerCountResponse);
              setFollowingCount(followingCountResponse);
              setAllFundingRounds(fundingRounds);
              setProjectReferrals(_projectReferrals);

              if (
                latestRound.IsPasswordProtected &&
                currentUser &&
                profileResponse.Profile?.PublicKeyBase58Check === currentUser.PublicKeyBase58Check
              ) {
                return openfund.getFundingRoundPassword(latestRound.RoundID).then(({ Password }) => {
                  setHasAccess(true);
                  setPassword(Password);
                });
              }

              if (!latestRound.IsPasswordProtected) {
                setHasAccess(true);
                return;
              }

              if (latestRound.IsPasswordProtected && !passwordParam) {
                setHasAccess(false);
                return;
              }

              if (latestRound.IsPasswordProtected && passwordParam) {
                return openfund
                  .verifyFundingRoundPassword(latestRound.RoundID, passwordParam)
                  .then(() => {
                    setHasAccess(true);
                  })
                  .catch((e) => {
                    if (e.response.status === 403) {
                      setHasAccess(false);
                      setIsPasswordValid(false);
                    } else {
                      throw e;
                    }
                  });
              }
            }
          },
        )
        .catch((e) => {
          if (isMounted.current) {
            setLoadingError(e);
          }
          throw e;
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false);
          }
        });
    } else {
      navigate('/not-found', { replace: true });
    }
  }, [username, currentUser]);

  useEffect(() => {
    if (!profile) return;

    setNFTsPromise(
      Promise.all([
        deso.getNFTsCreatedByUser({
          PublicKeyBase58Check: profile.PublicKeyBase58Check,
          Username: profile.Username,
          ReaderPublicKeyBase58Check: currentUser?.PublicKeyBase58Check ?? '',
          LastPostHashHex: '',
          NumToFetch: 100,
        }),
        openfund.getAppUserWithPubKey(profile.PublicKeyBase58Check),
      ]).then(([nftsResult, appUserResult]) => {
        if (!isMounted.current || !appUserResult.EnableDaoNFTGallery) {
          return null;
        }

        setHasNFTs((nftsResult?.NFTs?.length ?? 0) > 0);
        return nftsResult;
      }),
    );
  }, [profile?.Username, currentUser?.PublicKeyBase58Check]);

  async function getEscrowedInvestments() {
    if (currentUser !== null) {
      const investmentsResp = await openfund.getInvestments(currentUser.PublicKeyBase58Check, 'ESCROWED');
      const projectProfiles = await deso.getUsers(investmentsResp.map((i) => i.DaoOwnerPkidBase58check));
      const projectProfilesByKey =
        projectProfiles.UserList?.reduce(
          (keyProfileMap, user) => {
            if (user.ProfileEntryResponse) {
              keyProfileMap[user.PublicKeyBase58Check] = user.ProfileEntryResponse;
            }
            return keyProfileMap;
          },
          {} as Record<string, ProfileEntryResponse>,
        ) ?? {};
      setInvestments(
        investmentsResp.map((i) => ({ ...i, projectProfile: projectProfilesByKey[i.DaoOwnerPkidBase58check] })),
      );
    }
  }

  useEffect(() => {
    if (profile && fundingRound?.RoundStatus === FUNDING_ROUND_STATUSES.OPEN && currentUser?.PublicKeyBase58Check) {
      if (fundingRound.GlobalReferralRateBasisPoints > 0) {
        openfund
          .getReferralCode({
            RoundID: fundingRound.RoundID,
            ReferrerPkidBase58check: currentUser.PublicKeyBase58Check,
          })
          .then((code) => {
            const linkWithCode = `${window.origin}${Routes.fund(profile.Username)}?invite=${code}${
              password ? `&key=${password}` : ''
            }`;
            setReferralLink(linkWithCode);
            setShareLink(linkWithCode);
            setHasReferralCodeApiError(false);
          })
          .catch((e) => {
            setReferralLink('');
            setShareLink(window.location.href);
            setHasReferralCodeApiError(true);
            throw e;
          });
      }

      getEscrowedInvestments();
    } else {
      setReferralLink('');
      setShareLink(window.location.href);
    }
  }, [fundingRound, currentUser, profile, password]);

  useEffect(() => {
    if (!fundingRound || !profile) {
      return;
    }

    if (currentUser) {
      deso.getProjectHolding(currentUser.PublicKeyBase58Check, fundingRound.DaoOwnerPkidBase58check).then((res) => {
        if (res) {
          setCurrentUserBalanceHex(() => res.BalanceNanosUint256.toString());
          openClaimTokensModal(res.BalanceNanosUint256.toString());
        }
      });
    }

    // Pretend to invest 0.1 DESO or 1 token to get the current price
    const amountToInvest = BigInt(fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? 1e18 : 1e9 / 10);
    computeTokensLessReserveAndFees(fundingRound.RoundID, fundingRound.TreasuryCurrencyUnit, amountToInvest).then(
      (res) => {
        setTokenPrice(() => getTokenPrice(profile, res.PricePerToken));
      },
    );
  }, [fundingRound, currentUser, profile]);

  function openClaimTokensModal(balanceHex: string) {
    // We need the current user and the profile loaded.
    if (
      !currentUser ||
      !currentUser?.PublicKeyBase58Check ||
      !currentUser.ProfileEntryResponse ||
      !profile ||
      !profile?.PublicKeyBase58Check
    ) {
      return;
    }
    // Profile Owner always is considered a purchaser.
    if (currentUser.PublicKeyBase58Check === profile.PublicKeyBase58Check) {
      return;
    }
    // If the current user has already been propmted in this session, we bail.
    if (claimTokenModalShownMap[`${currentUser.PublicKeyBase58Check}${profile.PublicKeyBase58Check}`]) {
      return;
    }
    if (balanceHex && balanceHex !== '0x0') {
      // get projects purchased
      const projectsPurchased = new Set(
        (currentUser.ProfileEntryResponse.ExtraData[ProjectPublicKeysPurchasedKey] || '').split(','),
      );
      const hasPurchased = projectsPurchased.has(profile.PublicKeyBase58Check);
      if (hasPurchased) {
        // If they've purchased this project, we don't need to do anything.
        return;
      }
      claimTokenModalShownMap[`${currentUser.PublicKeyBase58Check}${profile.PublicKeyBase58Check}`] = true;
      setClaimTokensModalOpen(true);
    }
  }

  if (isLoading) {
    return (
      <div className="text-center py-8">
        <Spinner />
      </div>
    );
  }

  if (loadingError || !fundingRound || !profile?.DAOCoinEntry || !exchangeRates) {
    return <FullPageError error={loadingError} />;
  }

  if (!profile?.Username) {
    return <NotFound />;
  }

  if (!hasAccess) {
    return (
      <div className="text-center py-32">
        <Heading level={1} className="mb-4">
          Do you HODL the key?
        </Heading>
        <form
          className="w-1/4 mx-auto"
          onSubmit={(ev) => {
            ev.preventDefault();
            const trimmedPassword = password.trim();
            if (!trimmedPassword) {
              setHasAccess(false);
              setIsPasswordValid(false);
            }

            openfund
              .verifyFundingRoundPassword(fundingRound.RoundID, trimmedPassword)
              .then(() => {
                setHasAccess(true);
                searchParams.set('key', trimmedPassword);
                setSearchParams(searchParams);
                setPassword(trimmedPassword);
              })
              .catch((e) => {
                if (e.response.status === 403) {
                  setHasAccess(false);
                  setIsPasswordValid(false);
                } else {
                  throw e;
                }
              });
          }}
        >
          <Input
            theme="dark"
            labelText="Enter the access key"
            labelSrOnly={true}
            placeholder="Secret key"
            className="text-center"
            containerClasses="mb-4"
            state={!isPasswordValid ? 'error' : 'default'}
            hint={!isPasswordValid ? 'Invalid key' : ''}
            value={password}
            onInput={(ev) => {
              setIsPasswordValid(true);
              setPassword(ev.currentTarget.value);
            }}
          />
          <Button type="submit">Submit</Button>
        </form>
      </div>
    );
  }

  const markdownDescriptionChars = Array.from(profile.ExtraData?.MarkdownDescription ?? []);
  const maxMarkdownDescriptionLength = 1200;

  return (
    <div className="w-full">
      {hasFutureStartTime && (
        <div className="flex w-full items-center justify-center gap-2 text-sm border-b border-border-light px-4 py-2">
          <span className="text-sm text-muted">Funding round starts in</span>
          <CountdownTimer endTime={fundingRound.StartTime} />
        </div>
      )}
      {canInvest && !hasFutureStartTime && hasEndTime(fundingRound) && (
        <div className="flex w-full items-center justify-center gap-2 text-sm border-b border-border-light px-4 py-2">
          <span className="text-sm text-muted">This funding round will end in</span>
          <CountdownTimer endTime={fundingRound.EndTime} />
        </div>
      )}

      {fundingRound.AmountToRaiseUsdCents > 0 && fundingRound.RoundStatus !== FUNDING_ROUND_STATUSES.FINALIZED && (
        <div className="border-b border-border w-full p-4">
          <div className="h-1 w-full rounded-full bg-card overflow-hidden mb-3">
            <div style={{ width: `${percentRaised}%` }} className={`h-full bg-primary rounded-full`}></div>
          </div>
          <div className="flex items-center text-sm justify-between">
            <div>
              <div className="text-primary font-semibold font-mono">{Math.round(percentRaised)}%</div>
              <div className="text-muted">
                <span className="font-mono">{formatUSD(usdCentsToUSD(fundingRound.AmountRaisedUSDCents), false)}</span>{' '}
                raised so far
              </div>
            </div>
            <div className="text-muted text-lg font-semibold font-mono">
              🎉 {formatUSD(usdCentsToUSD(fundingRound.AmountToRaiseUsdCents), false)}
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col xl:flex-row m-auto justify-center">
        <aside className="mb-12 xl:mb-0 xl:w-3/12 border-r border-border">
          <div className="relative border-b border-border-light p-4">
            <div className="w-full text-left flex justify-between">
              <div className="text-left flex flex-row items-center ">
                <Avatar src={profile.PublicKeyBase58Check} className="bg-gray-faint w-[70px] h-[70px] m-auto" />
                <div className="ml-4">
                  <div className="flex items-start flex-col">
                    <h2 className="text-xl font-semibold text-muted-foreground">
                      {profile.ExtraData?.DisplayName || profile.Username}
                    </h2>
                    <span className="font-medium text-muted">${profile.Username}</span>
                  </div>
                  <div className="flex text-left items-center gap-4">
                    <div>
                      <Button
                        kind="text-only"
                        size="sm"
                        aria-label={`${profile.PublicKeyBase58Check}'s followers`}
                        title={`${profile.PublicKeyBase58Check}'s followers`}
                        className="flex"
                        onClick={() => {
                          setFollowModalState({
                            isOpen: true,
                            followState: 'FOLLOWERS',
                          });
                        }}
                      >
                        <span className="text-muted-foreground font-bold inline-block mr-2">
                          {followerCount.toLocaleString('en-US')}
                        </span>{' '}
                        <span className="text-muted">Follower{followerCount !== 1 && 's'}</span>
                      </Button>
                    </div>
                    <div>
                      <Button
                        kind="text-only"
                        size="sm"
                        aria-label={`${profile.PublicKeyBase58Check}'s following`}
                        title={`${profile.PublicKeyBase58Check}'s following`}
                        className="flex"
                        onClick={() => {
                          setFollowModalState({
                            isOpen: true,
                            followState: 'FOLLOWING',
                          });
                        }}
                      >
                        <span className="text-muted-foreground font-bold inline-block mr-2">
                          {followingCount.toLocaleString('en-US')}
                        </span>{' '}
                        <span className="text-muted">Following</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-between">
                <div className="sm:text-right flex flex-col items-end">
                  <FollowButton followeePublicKey={profile.PublicKeyBase58Check} />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b px-4 border-border-light">
            <ButtonNew
              title={profile.PublicKeyBase58Check}
              variant="ghost"
              className="hover:underline !font-mono p-0"
              onClick={() => {
                window.navigator.clipboard
                  .writeText(profile.PublicKeyBase58Check)
                  .then(() => {
                    toast.show({ message: `Copied public key to clipboard.`, type: 'success', sticky: false });
                  })
                  .catch((e) => {
                    toast.show({ message: getErrorMsg(e), type: 'error', sticky: false });
                  });
              }}
            >
              {centerEllipsis(profile.PublicKeyBase58Check, 12)}
              <IoCopyOutline className="inline ml-1" />
            </ButtonNew>
          </div>

          <div className="m-0 p-4 border-b border-border-light">
            {/* <div className="flex items-center gap-4 mb-4">
              {!!profile.ExtraData?.WebsiteURL && (
                <ExternalLink kind="text-only" target="_blank" href={profile.ExtraData?.WebsiteURL}>
                  <IoGlobeOutline className="inline text-xl text-muted hover:text-primary" />
                </ExternalLink>
              )}
              <ExternalLink kind="text-only" href={`https://diamondapp.com/u/${profile.Username}`}>
                <DiamondLogo className="inline text-xl text-muted hover:text-primary" />
              </ExternalLink>
              {!!profile.ExtraData?.DiscordURL && (
                <ExternalLink kind="text-only" target="_blank" href={profile.ExtraData?.DiscordURL}>
                  <IoLogoDiscord className="inline text-xl text-muted hover:text-primary" />
                </ExternalLink>
              )}
              {!!profile.ExtraData?.TwitterURL && (
                <ExternalLink kind="text-only" target="_blank" href={profile.ExtraData?.TwitterURL}>
                  <IoLogoTwitter className="inline text-xl text-muted hover:text-primary" />
                </ExternalLink>
              )}
            </div> */}

            {profile.Description && (
              <div>
                <div
                  className={`leading-5 text-sm text-muted ${!showFullDescription ? 'line-clamp-5' : ''}`}
                  dangerouslySetInnerHTML={{ __html: linkifyText(profile.Description) }}
                ></div>
                {profile.Description.length > 240 && (
                  <ButtonNew
                    variant="ghost"
                    onClick={() => setShowFullDescription(!showFullDescription)}
                    className="p-0 underline underline-offset-4 text-sm"
                  >
                    {showFullDescription ? 'Show less' : 'Show more'}
                  </ButtonNew>
                )}
              </div>
            )}
          </div>

          {topHolders?.Hodlers && (topHolders.Hodlers?.length ?? 0) > 0 && (
            <section className="p-4">
              <h2 className="mb-4 text-base text-muted-foreground font-semibold">Top Holders</h2>
              <div className="overflow-hidden">
                <ProjectHodlersLeaderBoard
                  project={profile}
                  exchangeRates={exchangeRates}
                  hodlers={topHolders.Hodlers}
                  limit={100}
                  viewMoreAction={
                    (topHolders.Hodlers?.length ?? 0) > 10
                      ? () => {
                          const hodlersTabIndex = Array.from(
                            document.getElementById(communitySectionId)?.querySelectorAll('[data-tab]') ?? [],
                          ).findIndex((el) => el.textContent === 'Holders');
                          setSelectedCommunityTab(hodlersTabIndex);
                          document.getElementById(communitySectionId)?.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      : undefined
                  }
                />
              </div>
            </section>
          )}
        </aside>

        <div className="w-full lg:w-6/12 order-first lg:order-none">
          {profile.PublicKeyBase58Check === currentUser?.PublicKeyBase58Check &&
            profile.DAOCoinEntry.TransferRestrictionStatus !== 'unrestricted' &&
            profile.DAOCoinEntry.TransferRestrictionStatus !== 'permanently_unrestricted' && (
              <div className="text-center border border-border-light p-4 sm:p-6 bg-card">
                <h3 className="mb-4 text-muted-foreground text-sm font-semibold">
                  Enable Trading for ${profile.Username}
                </h3>
                <ToggleProjectTradingStatus
                  projectProfile={profile}
                  className="text-center mb-4"
                  onStatusToggleSuccess={(status) => {
                    if (status === 'unrestricted') {
                      toast.show({
                        message: (
                          <>
                            Successfully enabled trading for your project!{' '}
                            {profile.Username && (
                              <RouteLink kind="text-only-light" to={Routes.tradeToken(profile.Username)}>
                                View your trade page.
                              </RouteLink>
                            )}
                          </>
                        ),
                        type: 'success',
                      });
                    }
                  }}
                />
                {profile.PublicKeyBase58Check === currentUser?.PublicKeyBase58Check &&
                  profile.DAOCoinEntry.TransferRestrictionStatus !== 'unrestricted' &&
                  profile.DAOCoinEntry.TransferRestrictionStatus !== 'permanently_unrestricted' && (
                    <div className="text-center text-muted text-sm flex items-center gap-2 mt-4 justify-center">
                      <IoInformationCircleOutline className="h-5 w-5 inline" /> You need to enable trading in order to
                      activate the order-book exchange for your token.
                    </div>
                  )}
              </div>
            )}
          <div className="flex items-center bg-accent pb-2">
            {canInvest ? (
              <div className="mr-2 w-1/2 p-4 text-center">
                <div className="flex items-center flex-col">
                  <h3 className="mb-2 text-sm text-muted">Contribute to Project</h3>
                  <ButtonNew
                    onClick={() => {
                      setIsContributeModalOpen(true);
                    }}
                  >
                    Contribute
                  </ButtonNew>
                </div>
              </div>
            ) : (
              <div className="mr-2 w-1/2 text-center">
                <div className="flex items-center flex-col">
                  <h3 className="mb-2 text-sm text-muted">Round is Closed</h3>
                  <ButtonNew
                    disabled={true}
                    onClick={() => {
                      setIsContributeModalOpen(true);
                    }}
                  >
                    Contribute
                  </ButtonNew>
                </div>
              </div>
            )}
            <div className="flex flex-col items-center p-4 w-1/2 text-muted text-center">
              <div className="m-auto">
                <h3 className="mb-2 text-sm text-muted">Trade Tokens</h3>
              </div>

              <RouteLink to={Routes.tradeToken(profile.Username)}>
                <ButtonNew
                  variant={
                    profile.DAOCoinEntry?.TransferRestrictionStatus === 'unrestricted' ||
                    profile.DAOCoinEntry?.TransferRestrictionStatus === 'permanently_unrestricted'
                      ? 'default'
                      : 'outline'
                  }
                >
                  Trade ${profile.Username}
                </ButtonNew>
              </RouteLink>
            </div>
          </div>

          <div className="mb-6">
            <FeaturedImage src={profile.ExtraData?.FeaturedImageURL} />
          </div>

          <section className="mb-0">
            <div className="project-description border-y border-border-light p-12">
              {profile.ExtraData?.MarkdownDescription ? (
                <div>
                  <span className="mb-3 inline-block text-sm text-muted font-bold">Project Description</span>

                  {!descriptionReadMore && markdownDescriptionChars.length > maxMarkdownDescriptionLength ? (
                    <>
                      <div className="prose-invert max-w-none">
                        <ReactMarkdown
                          children={`${markdownDescriptionChars.slice(0, maxMarkdownDescriptionLength).join('')}`}
                          remarkPlugins={[remarkGfm]}
                        />
                      </div>

                      <div className="mt-6 w-full">
                        <ButtonNew
                          variant="outline"
                          className="w-full"
                          onClick={() => {
                            setDescriptionReadMore(true);
                          }}
                        >
                          ... continue reading
                        </ButtonNew>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="prose-invert max-w-none">
                        <ReactMarkdown children={profile.ExtraData?.MarkdownDescription} remarkPlugins={[remarkGfm]} />
                      </div>

                      {markdownDescriptionChars.length > maxMarkdownDescriptionLength && (
                        <div className="mt-6 w-full">
                          <ButtonNew
                            variant="outline"
                            className="w-full"
                            onClick={() => {
                              setDescriptionReadMore(false);
                            }}
                          >
                            ... show less
                          </ButtonNew>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="text-center text-muted">There is currently no description for this project.</div>
                </>
              )}
            </div>
          </section>
          <section className="lg:hidden block">
            <RoundContainer
              fundingRound={fundingRound}
              profile={profile}
              hasFutureStartTime={hasFutureStartTime}
              allFundingRounds={allFundingRounds}
              openRoundHistoryModal={() => setIsRoundHistoryModalOpen(true)}
              openContributeModal={() => setIsContributeModalOpen(true)}
              exchangeRates={exchangeRates}
              canInvest={canInvest}
              tokenPrice={tokenPrice}
            />
          </section>
          <section className={`mb-12 lg:hidden ${fundingRound ? 'block' : 'hidden'}`}>
            <OwnershipWidget
              fundingRound={fundingRound}
              currentUserBalanceHex={currentUserBalanceHex}
              profile={profile}
            />
          </section>
          {canInvest && (
            <section className="mb-0 pt-4">
              <div className="flex justify-between items-center w-full p-6 py-0">
                <div className="text-xl font-semibold text-muted-foreground ">Contribute to ${profile.Username}</div>
                <div className="flex items-center gap-2">
                  {investments?.length !== undefined && investments?.length > 0 && (
                    <>
                      <ButtonNew variant="outline" onClick={() => setShowRefundInvestmentModal(true)}>
                        Refund
                      </ButtonNew>
                    </>
                  )}
                  <RouteLink to={Routes.tradeToken(profile.Username)}>
                    <ButtonNew variant="outline">Trade</ButtonNew>
                  </RouteLink>
                </div>
              </div>
              <div className="flex justify-between items-start p-6 pb-0">
                <div className="flex flex-col">
                  <div className="font-bold mb-1 text-lg">Your Tokens</div>
                  <div className="text-muted text-sm">
                    Your current ownership of <span className="text-foreground">${username}</span> tokens
                  </div>
                </div>
                <div className="text-right">
                  <div>
                    <span className="font-mono mr-1">
                      {formatDecimalValue((Number(currentUserBalanceHex) || 0) / 1e18, 5)}
                    </span>{' '}
                    <span className="text-muted">${username}</span>
                  </div>
                  <div className="text-sm mt-1">
                    <RouteLink className="text-muted text-xs underline-offset-4 underline" to={Routes.wallet()}>
                      View Wallet
                    </RouteLink>
                  </div>
                </div>
              </div>
              <div className="p-4 md:p-6">
                <InvestWidget
                  fundingRound={fundingRound}
                  referralID={searchParams.get('invite')}
                  projectProfile={profile}
                  fundingRoundPassword={password}
                  onInvestSuccess={async () => {
                    try {
                      claimTokenModalShownMap[`${currentUser?.PublicKeyBase58Check}${profile.PublicKeyBase58Check}`] =
                        true;
                      await deso
                        .getProjectHolding(
                          currentUser?.PublicKeyBase58Check ?? '',
                          fundingRound.DaoOwnerPkidBase58check,
                        )
                        .then((res) => {
                          if (res) {
                            setCurrentUserBalanceHex(() => res.BalanceNanosUint256.toString());
                          }
                        });

                      confetti.celebrate();
                      // Should we do something more than a toast here?
                      toast.show({
                        message: (
                          <>
                            Your tokens have been minted!{' '}
                            <RouteLink kind="text-only-light" to={Routes.wallet()}>
                              View your wallet.
                            </RouteLink>
                          </>
                        ),
                        type: 'success',
                      });
                      setCurrentUser(await openfund.reloadCurrentUserData());
                    } catch (e) {
                      toast.show({ message: getErrorMsg(e), type: 'error' });
                    }
                  }}
                />
              </div>
            </section>
          )}
          {hasNFTs && nftsPromise && (
            <section className="p-6 sm:p-12">
              <div className="text-lg font-semibold mb-6">NFT Gallery</div>
              <NFTCarousel nftsPromise={nftsPromise} />
            </section>
          )}
          <section id={communitySectionId} className="py-4 sm:py-12 border-t border-border-light">
            <ProjectActivityFeed
              projectProfile={profile}
              hodlers={topHolders?.Hodlers ?? []}
              holdings={projectHoldings || []}
              advocates={projectReferrals?.Referrals ?? []}
              exchangeRates={exchangeRates}
              selectedTab={selectedCommunityTab}
              referralLink={referralLink}
              fundingRound={fundingRound}
              setHoldings={setProjectHoldings}
            />
          </section>
        </div>
        <div className="w-full lg:w-3/12 border-l border-border">
          <div className="mb-0">
            <RoundContainer
              fundingRound={fundingRound}
              profile={profile}
              hasFutureStartTime={hasFutureStartTime}
              allFundingRounds={allFundingRounds}
              openRoundHistoryModal={() => setIsRoundHistoryModalOpen(true)}
              openContributeModal={() => setIsContributeModalOpen(true)}
              exchangeRates={exchangeRates}
              canInvest={canInvest}
              tokenPrice={tokenPrice}
              className="hidden lg:block"
            />

            <OwnershipWidget
              fundingRound={fundingRound}
              currentUserBalanceHex={currentUserBalanceHex}
              profile={profile}
            />
          </div>

          <div className="p-4">
            {canInvest && !hasReferralCodeApiError && (
              <div className="p-4 border border-border-light rounded-2xl">
                <div className="mb-4 text-lg text-muted font-semibold flex items-center gap-2">
                  <LuShare />
                  Share this project
                </div>
                {!!referralLink && (
                  <div className="my-4">
                    <CopyToClipboard width={10} text={referralLink} />
                  </div>
                )}
                {!!currentUser ? (
                  <div className="text-muted text-sm leading-5">
                    Share this link to earn{' '}
                    <div className="font-bold text-muted-foreground inline-block">
                      {fundingRound.GlobalReferralRateBasisPoints / 100}%
                    </div>{' '}
                    of the amount contributed to this project.
                  </div>
                ) : (
                  <Text size="sm" color="secondary" className="py-1">
                    <LoginModal
                      onClick={async (closeModal) => {
                        try {
                          setCurrentUser(await openfund.login());
                        } catch (e) {
                          toast.show({
                            message: getErrorMsg(e),
                            type: 'error',
                          });
                        } finally {
                          closeModal();
                        }
                      }}
                    >
                      <Button kind="text-only">
                        <span className="dark:text-white underline">Log in or create an account</span>
                      </Button>
                    </LoginModal>{' '}
                    to earn {fundingRound.GlobalReferralRateBasisPoints / 100}% of the amount contributed for sharing
                    this project.
                  </Text>
                )}
              </div>
            )}

            {((!!referralLink && canInvest) || currentUser?.PublicKeyBase58Check === profile.PublicKeyBase58Check) && (
              <div className="flex items-end mt-4">
                {!!referralLink && canInvest && (
                  <div className="border rounded-2xl border-border mb-12 w-full">
                    <dl>
                      {projectReferrals && (
                        <div className="border-b border-border-light p-2 flex justify-between items-start">
                          <div className="text-muted text-sm">Total Referral Payouts</div>
                          <dd className="inline-block font-mono text-muted-foreground text-sm">
                            {formatUSD(
                              desoNanosToUSD(
                                projectReferrals.TotalReferralAmountEarnedDesoNanos,
                                exchangeRates.USDCentsPerDeSoCoinbase,
                              ) + baseUnitsToTokens(projectReferrals.TotalReferralAmountEarnedDaoCoinsHex),
                            )}
                          </dd>
                        </div>
                      )}
                      {currentUser && projectReferrals && (
                        <div className="p-2 flex justify-between">
                          <div className="text-muted text-sm">Your Referral Earnings</div>
                          <dd className="inline-block font-mono text-muted-foreground text-sm">
                            {formatUSD(
                              fundingRound.TreasuryCurrencyUnit === 'DESO'
                                ? desoNanosToUSD(
                                    projectReferrals.Referrals.find((r) => {
                                      return r.ReferrerPkidBase58check === currentUser?.PublicKeyBase58Check;
                                    })?.ReferralAmountEarnedDesoNanos ?? 0,
                                    exchangeRates.USDCentsPerDeSoCoinbase,
                                  )
                                : baseUnitsToTokens(
                                    projectReferrals.Referrals.find((r) => {
                                      return r.ReferrerPkidBase58check === currentUser?.PublicKeyBase58Check;
                                    })?.ReferralAmountEarnedDaoCoinsHex ?? '0x0',
                                  ),
                            )}
                          </dd>
                        </div>
                      )}
                    </dl>
                    <div className="border-t border-border-light text-center">
                      <ButtonNew
                        variant="ghost"
                        className="w-full p-0"
                        onClick={() => {
                          const advocatesTabIndex = Array.from(
                            document.getElementById(communitySectionId)?.querySelectorAll('[data-tab]') ?? [],
                          ).findIndex((el) => el.textContent === 'Advocates');
                          setSelectedCommunityTab(advocatesTabIndex);
                          document.getElementById(communitySectionId)?.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }}
                      >
                        See all referral advocates
                      </ButtonNew>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog open={isContributeModalOpen} onOpenChange={setIsContributeModalOpen}>
        <DialogContent className="min-w-[700px]">
          <DialogHeader>
            <DialogTitle>Contribute to ${profile.Username}</DialogTitle>
          </DialogHeader>
          <div className="p-4 md:p-6">
            <div className="text-muted text-sm mb-4">
              Available Balance:{' '}
              <span className="font-mono text-muted-foreground">
                {formatDecimalValue(baseUnitsToTokens(currentUserBalanceHex))} ${profile.Username}
              </span>
            </div>
            <InvestWidget
              fundingRound={fundingRound}
              referralID={searchParams.get('invite')}
              projectProfile={profile}
              onInvestSuccess={async () => {
                await deso
                  .getProjectHolding(currentUser?.PublicKeyBase58Check ?? '', fundingRound.DaoOwnerPkidBase58check)
                  .then((res) => {
                    if (res) {
                      setCurrentUserBalanceHex(() => res.BalanceNanosUint256.toString());
                    }
                  });

                // Should we do something more than a toast here?
                confetti.celebrate();
                toast.show({
                  message: (
                    <>
                      Your tokens have been minted!{' '}
                      <RouteLink kind="text-only-light" to={Routes.wallet()}>
                        View your wallet.
                      </RouteLink>
                    </>
                  ),
                  type: 'success',
                });
                setCurrentUser(await openfund.reloadCurrentUserData());
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showRefundInvestmentModal} onOpenChange={setShowRefundInvestmentModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Choose a contribution to refund</DialogTitle>
          </DialogHeader>
          <div className="w-full">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>Project</TableHead>
                  <TableHead>Amount</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {investments
                  .sort((a, _) => {
                    // sort the current project to the top.
                    return a.projectProfile.Username === profile.Username ? -1 : 1;
                  })
                  .reduce((reactNodes, investment) => {
                    const hasNonZeroAmount =
                      investment.AmountInvestedDesoNanos - investment.AmountRefundedDesoNanos > 0 ||
                      BigInt(investment.AmountInvestedDaoCoinsHex) - BigInt(investment.AmountRefundedDaoCoinsHex) > 0;

                    if (hasNonZeroAmount) {
                      reactNodes.push(
                        <RefundInvestmentRowItem
                          investment={investment}
                          exchangeRates={exchangeRates}
                          onSuccess={async () => {
                            try {
                              await getEscrowedInvestments();
                              toast.show({
                                message: `Successfully refunded investment`,
                                type: 'success',
                                sticky: false,
                              });
                            } catch (e) {
                              toast.show({ message: getErrorMsg(e), type: 'error' });
                            }
                          }}
                          onFailure={(e) => {
                            toast.show({ message: getErrorMsg(e), type: 'error' });
                          }}
                        />,
                      );
                    }

                    return reactNodes;
                  }, [] as ReactNode[])}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={isRoundHistoryModalOpen} onOpenChange={setIsRoundHistoryModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Round History</DialogTitle>
          </DialogHeader>
          <div className="border m-6 border-border-light rounded-2xl">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-left">Round</TableHead>
                  <TableHead className="text-right">Raised</TableHead>
                  <TableHead className="text-right">Contributors</TableHead>
                  <TableHead className="text-right">Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {allFundingRounds.reverse().map((r) => (
                  <TableRow key={r.RoundID}>
                    <TableCell>{r.RoundName}</TableCell>
                    <TableCell className="text-right font-mono text-muted-foreground">
                      {r.TreasuryCurrencyUnit === 'DESO'
                        ? formatUSD(desoNanosToUSD(r.AmountRaisedDesoNanos, exchangeRates.USDCentsPerDeSoCoinbase))
                        : formatUSD(usdCentsToUSD(r.AmountRaisedUSDCents))}
                    </TableCell>
                    <TableCell className="text-right">{profile.DAOCoinEntry?.NumberOfHolders ?? 0}</TableCell>
                    <TableCell
                      className={classNames(
                        r.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ? 'text-yellow-500' : 'text-green-600',
                        'text-right',
                      )}
                    >
                      {toTitleCase(r.RoundStatus)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
      <Modal
        title={`You were airdropped $${profile.Username} tokens!`}
        theme="minimal"
        description={
          <>
            <Heading level={1} className="p-2 text-center">
              You were airdropped ${profile.Username} Tokens!
            </Heading>
            <Text size="lg" className="p-4">
              If you'd like to appear in the <b>Hodlers</b> list, please claim your tokens below or via your wallet
              page.
            </Text>
          </>
        }
        body={
          <div className="flex justify-center p-2">
            <Button
              className="mx-6"
              kind="btn-tertiary"
              aria-label="Cancel"
              onClick={async () => {
                setClaimTokensModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="mx-6"
              kind="btn-primary"
              aria-label="Claim"
              onClick={async () => {
                await deso.addProjectPurchasedToProfile(profile?.PublicKeyBase58Check);
                setCurrentUser(await openfund.reloadCurrentUserData());
                confetti.celebrate();
                setClaimTokensModalOpen(false);
              }}
            >
              Claim
            </Button>
          </div>
        }
        isOpen={claimTokensModalOpen}
        onClose={() => setClaimTokensModalOpen(false)}
      />
      <FollowModal
        profile={profile}
        exchangeRates={exchangeRates as GetExchangeRateUpdatedResponse}
        followModalState={followModalState}
        onClose={() => setFollowModalState(DEFAULT_FOLLOW_MODAL_STATE)}
      />
    </div>
  );
}

type RoundContainerProps = {
  fundingRound: FundingRound;
  profile: ProfileEntryResponse;
  hasFutureStartTime: boolean;
  allFundingRounds: FundingRound[];
  openRoundHistoryModal: () => void;
  openContributeModal: () => void;
  exchangeRates: GetExchangeRateUpdatedResponse;
  canInvest: boolean;
  tokenPrice: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function RoundContainer({
  fundingRound,
  profile,
  hasFutureStartTime,
  allFundingRounds,
  openRoundHistoryModal,
  exchangeRates,
  canInvest,
  tokenPrice,
  openContributeModal,
  className,
}: RoundContainerProps) {
  return (
    <div className={classNames(className)}>
      <dl>
        <div className="border-b border-border-light pb-4 p-5">
          <dt>
            <span className="text-muted text-sm mb-1 block">Round Title</span>
          </dt>
          <dd>
            <div className="text-lg leading-6 font-semibold text-muted-foreground">{fundingRound.RoundName}</div>
            <div className="flex items-center">
              <div
                className={classNames(
                  'text-sm mt-1',
                  fundingRound.RoundStatus === FUNDING_ROUND_STATUSES.OPEN && !hasFutureStartTime
                    ? 'text-success'
                    : 'text-muted',
                )}
              >
                Currently {hasFutureStartTime ? 'Pending' : toTitleCase(fundingRound.RoundStatus)}
              </div>{' '}
              {allFundingRounds.length > 1 && (
                <>
                  <span className="inline-block mx-2 text-muted">&bull;</span>{' '}
                  <ButtonNew variant={'link'} className="text-muted p-0" onClick={openRoundHistoryModal}>
                    View Previous Rounds
                  </ButtonNew>
                </>
              )}
            </div>
          </dd>
        </div>

        {fundingRound.AmountToRaiseUsdCents > 0 && fundingRound.RoundStatus !== FUNDING_ROUND_STATUSES.FINALIZED && (
          <div className="flex justify-between border-b border-border-light px-4 py-2">
            <div className="flex flex-col gap-1">
              <div className="text-muted text-sm">Fundraising Goal</div>
              <div className="text-muted text-xs">{fundingRound.AllowOverflow ? <> Target is uncapped</> : ''}</div>
            </div>
            <div className="text-right">
              <span className="flex flex-col">
                <span
                  className="text-primary font-mono"
                  title={usdCentsToUSD(fundingRound.AmountToRaiseUsdCents).toLocaleString('en-US')}
                >
                  ${usdCentsToUSD(fundingRound.AmountToRaiseUsdCents).toLocaleString('en-US')}
                </span>
                {fundingRound.TreasuryCurrencyUnit === 'DESO' && (
                  <span className="text-muted font-mono text-xs block">
                    {usdCentsToDeso(
                      fundingRound.AmountToRaiseUsdCents,
                      exchangeRates?.USDCentsPerDeSoCoinbase,
                    ).toLocaleString('en-US')}{' '}
                    DESO
                  </span>
                )}
              </span>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center border-b border-border-light px-4 py-2 text-sm">
          <dt>
            <span className="text-muted">Raised</span>
          </dt>
          <dd>
            <span className="text-muted-foreground font-mono ">
              {formatUSD(usdCentsToUSD(fundingRound.AmountRaisedUSDCents), false)}
            </span>
          </dd>
        </div>
        <div className="flex justify-between items-start px-4 py-2 border-b border-border-light">
          <div className="text-muted text-sm flex flex-col gap-1">
            <div>Price</div>
            {fundingRound.TreasuryCurrencyUnit === 'DESO' && (
              <div className="text-muted font-mono text-xs">TREASURY: DESO</div>
            )}
          </div>
          <div className="flex flex-col items-end gap-1">
            <div className="font-semibold text-sm font-mono">
              <span className="text-xs text-muted inline-block mr-2">USD</span>
              <span className="text-muted-foreground">
                $
                {fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
                  ? formatDecimalValue(tokenPrice, 5, 5)
                  : formatDecimalValue(desoToUSD(tokenPrice, exchangeRates.USDCentsPerDeSoCoinbase), 5, 5)}
              </span>
            </div>
            <span className="text-muted text-xs font-mono">
              {fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
                ? `$${formatDecimalValue(tokenPrice, 5, 5)}`
                : `${formatDecimalValue(tokenPrice, 5, 5)} DESO`}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center border-b border-border-light px-4 py-2 text-sm">
          <dt className="flex flex-col gap-1">
            <span className="text-muted">Market Cap</span>
          </dt>
          <dd className="text-right">
            {tokenPrice ? (
              <>
                <span className="text-muted-foreground block font-mono">
                  {/* TODO: we need to return the amount raised denominated in
                  token when treasury is demoninated in tokens */}
                  {formatUSD(
                    fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
                      ? tokenPrice *
                          baseUnitsToTokens(
                            (profile.DAOCoinEntry as DAOCoinEntryResponse).CoinsInCirculationNanos.toString(),
                          )
                      : desoToUSD(
                          tokenPrice *
                            baseUnitsToTokens(
                              (profile.DAOCoinEntry as DAOCoinEntryResponse).CoinsInCirculationNanos.toString(),
                            ),
                          exchangeRates.USDCentsPerDeSoCoinbase,
                        ),
                    false,
                  )}
                </span>
              </>
            ) : (
              <Text color="secondary" size="sm">
                Market price unavailable
              </Text>
            )}
          </dd>
        </div>
        <div className="flex justify-between items-center border-b border-border-light px-4 py-2 text-sm">
          <dt>
            <span className="text-muted">Total Supply</span>
          </dt>
          <dd className="text-muted-foreground font-mono lowercase">
            {formatDecimalValue(
              baseUnitsToTokens((profile.DAOCoinEntry as DAOCoinEntryResponse).CoinsInCirculationNanos.toString()),
            )}
          </dd>
        </div>
        <div className="flex justify-between items-center border-b border-border-light px-4 py-2 text-sm">
          <dt>
            <span className="text-muted">Token Holders</span>
          </dt>
          <dd className="text-muted-foreground font-mono lowercase">
            {(profile.DAOCoinEntry as DAOCoinEntryResponse).NumberOfHolders.toLocaleString('en-US')}
          </dd>
        </div>
      </dl>
    </div>
  );
}

type OwnershipWidgetProps = {
  fundingRound: FundingRound;
  currentUserBalanceHex: string;
  profile: ProfileEntryResponse;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function OwnershipWidget({ fundingRound, currentUserBalanceHex, profile, className }: OwnershipWidgetProps) {
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const toast = useToast();
  return fundingRound ? (
    <div className={className}>
      <dl>
        <div className="border-b border-border-light flex justify-between items-start text-sm px-4 py-2">
          <dt className="flex flex-col gap-1">
            <div className="text-muted">Reserve Rate</div>
            <div className="text-muted text-xs">Reward received by the creator</div>
          </dt>
          <dd className="text-muted-foreground font-mono">{fundingRound.ReserveRateBasisPoints / 100}%</dd>
        </div>
        <div className="border-b border-border-light flex justify-between px-4 py-2 items-center">
          <dt>
            <div className="text-muted text-sm">Your Ownership %</div>
            {!currentUser && (
              <Text color="secondary" className="text-xs">
                <LoginModal
                  onClick={async (closeModal) => {
                    try {
                      setCurrentUser(await openfund.login());
                    } catch (e) {
                      toast.show({
                        message: getErrorMsg(e),
                        type: 'error',
                      });
                    } finally {
                      closeModal();
                    }
                  }}
                >
                  <Button kind="text-only">
                    <span className="dark:text-white underline">Sign up</span>
                  </Button>
                </LoginModal>{' '}
                to fund this project
              </Text>
            )}
          </dt>
          <dd className="text-sm text-muted-foreground font-mono">
            {formatDecimalValue(
              (Number(currentUserBalanceHex) /
                Number((profile.DAOCoinEntry as DAOCoinEntryResponse).CoinsInCirculationNanos)) *
                100 || 0,
              2,
            )}
            %
          </dd>
        </div>
      </dl>
    </div>
  ) : (
    <></>
  );
}

const RefundInvestmentRowItem = ({
  investment,
  exchangeRates,
  onSuccess,
  onFailure,
}: {
  investment: Investment & { projectProfile: ProfileEntryResponse };
  exchangeRates: GetExchangeRateUpdatedResponse;
  onSuccess: () => Promise<any>;
  onFailure: (error: any) => void;
}) => {
  const [isRefundingInvestment, setIsRefundingInvestment] = useState(false);
  let amountToRefundUSD = 0;
  if (investment.AmountInvestedDesoNanos - investment.AmountRefundedDesoNanos > 0) {
    amountToRefundUSD = desoNanosToUSD(
      investment.AmountInvestedDesoNanos - investment.AmountRefundedDesoNanos,
      exchangeRates.USDCentsPerDeSoCoinbase,
    );
  } else if (BigInt(investment.AmountInvestedDaoCoinsHex) - BigInt(investment.AmountRefundedDaoCoinsHex) > 0) {
    amountToRefundUSD = baseUnitsToTokens(
      BigInt(investment.AmountInvestedDaoCoinsHex) - BigInt(investment.AmountRefundedDaoCoinsHex),
    );
  }

  return (
    <TableRow className="text-left">
      <TableCell className="whitespace-nowrap text-muted text-sm">
        {formatDateSimpleStr(investment.CreatedAt)}
      </TableCell>
      <TableCell className="whitespace-nowrap">
        <div>
          <Avatar src={investment.projectProfile.PublicKeyBase58Check} className="mr-2" />
          <span className="text-muted-foreground font-semibold">${investment.projectProfile.Username}</span>
        </div>
      </TableCell>
      <TableCell className="whitespace-nowrap text-sm font-mono text-muted-foreground">
        {formatUSD(amountToRefundUSD)}
      </TableCell>
      <TableCell className="text-right">
        <ButtonNew
          variant="outline"
          size="sm"
          onClick={() => {
            if (isRefundingInvestment) return;
            setIsRefundingInvestment(true);
            return openfund
              .withdrawInvestment(investment.InvestmentID, investment.InvestorPkidBase58check)
              .then(onSuccess)
              .catch(onFailure)
              .finally(() => {
                setIsRefundingInvestment(false);
              });
          }}
        >
          {isRefundingInvestment ? <FiLoader className="h-6 w-6 inline rotate" /> : 'Refund'}
        </ButtonNew>
      </TableCell>
    </TableRow>
  );
};

export default ProjectDetail;
