export function checkFormValidity(form: HTMLFormElement | null) {
  if (!form) {
    return false;
  }

  let isValid = true;

  for (let i = 0; i < form.elements.length; i++) {
    const el = form.elements.item(i) as HTMLFormElement;
    isValid = el.checkValidity();
    if (!isValid) break;
  }

  return isValid;
}
