import { useContext, useEffect, useState } from 'react';
import { formatUSD } from '../../utils/currency';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { DESO_USDC_PUBLIC_KEY } from '../../constants/AppConstants';

interface UsdToUsdtProps {
  usdBalance: number;
  numDecimals?: number;
}

const UsdToUsdc = ({ usdBalance, numDecimals = 2 }: UsdToUsdtProps) => {
  const [convertedValue, setConvertedValue] = useState<number>(0);
  const { exchangeRates } = useContext(QuoteCurrencyContext);

  useEffect(() => {
    setConvertedValue(Number(usdBalance) * exchangeRates[DESO_USDC_PUBLIC_KEY]);
  }, [usdBalance, exchangeRates]);

  return <>{formatUSD(convertedValue, true, numDecimals)}</>;
};

export default UsdToUsdc;
