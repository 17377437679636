import { useEffect, useState } from 'react';
import { LARGE_WIDTH_BREAKPOINT } from 'utils/constants';

const useIsMobile = (breakpoint: number = 768) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(screenWidth <= breakpoint);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setScreenWidth(currentWidth);
      setIsMobile(currentWidth <= breakpoint);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return { isMobile, screenWidth, isDevice: screenWidth <= LARGE_WIDTH_BREAKPOINT };
};

export default useIsMobile;
