import { ProfilePicEditor } from 'components/app-ui/ProfilePicEditor';
import { UsernameInput, UsernameInputState } from 'components/app-ui/UsernameInput';
import { Button } from 'components/shadcn/ui/button';
import { Textarea } from 'components/shadcn/ui/textarea';
import { Spinner } from 'components/core/Spinner';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useToast } from 'hooks/useToast';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { openfund } from 'services';
import { checkFormValidity } from 'utils/checkFormValidity';
import { getErrorMsg } from 'utils/getErrorMsg';
import { v4 as uuid } from 'uuid';
import { OnboardingLayout, useOnboardingContext } from '../Onboarding';
import { Label } from 'components/shadcn/ui/label';

export function ProfileInfo() {
  const { onboardingData, setOnboardingData, onboardingType } = useOnboardingContext();
  useDocumentTitle(onboardingType === 'PROJECT' ? 'Create your project profile' : 'Create your profile');
  const [usernameInputState, setUsernameInputState] = useState<UsernameInputState['state']>('default');
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPendingProfileCreate, setIsPendingProfileCreate] = useState(false);
  const toast = useToast();
  const usernameInputId = uuid();
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const derivedKey = useRef('');

  useEffectOnce(() => {
    if (formRef.current) {
      setIsFormValid(checkFormValidity(formRef.current) && onboardingData.username.length > 0);
    }

    if (currentUser && onboardingType === 'USER') {
      openfund
        .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.PROFILE)
        .then(({ DerivedPublicKeyBase58Check }) => {
          derivedKey.current = DerivedPublicKeyBase58Check;
        });
    }
  });

  if (isPendingProfileCreate) {
    return (
      <div className="text-center py-8">
        <Spinner />
      </div>
    );
  }

  return (
    <OnboardingLayout
      title={onboardingType === 'PROJECT' ? 'Your Project Profile' : 'Your Profile'}
      content={
        <form
          ref={formRef}
          className="flex flex-col items-center"
          id="project-profile-form"
          noValidate={true}
          onSubmit={async (e) => {
            e.preventDefault();

            if (usernameInputState === 'error') {
              setTimeout(() => {
                document.getElementById(usernameInputId)?.focus();
              }, 1);
              return;
            }

            if (isFormValid) {
              switch (onboardingType) {
                case 'PROJECT':
                  navigate(Routes.projectOnboardingDetails());
                  break;

                case 'USER':
                  if (checkFormValidity(e.currentTarget) && usernameInputState === 'success') {
                    try {
                      await openfund.updateUserProfile(
                        {
                          Username: onboardingData.username,
                          Email: onboardingData.email,
                          Description: onboardingData.shortDesc,
                          ProfilePic: onboardingData.profilePicUrl.startsWith('data:')
                            ? onboardingData.profilePicUrl
                            : '',
                          DisplayName: currentUser?.ProfileEntryResponse?.ExtraData?.DisplayName ?? '',
                          FeaturedImageURL: currentUser?.ProfileEntryResponse?.ExtraData?.FeaturedImageURL ?? '',
                          WebsiteURL: currentUser?.ProfileEntryResponse?.ExtraData?.WebsiteURL ?? '',
                          TwitterURL: currentUser?.ProfileEntryResponse?.ExtraData?.TwitterURL ?? '',
                          DiscordURL: currentUser?.ProfileEntryResponse?.ExtraData?.DiscordURL ?? '',
                          MarkdownDescription: currentUser?.ProfileEntryResponse?.ExtraData?.MarkdownDescription ?? '',
                          EnableDaoNFTGallery: true,
                        },
                        () => {
                          setIsPendingProfileCreate(true);
                        },
                        derivedKey.current,
                      );
                      setCurrentUser(await openfund.reloadCurrentUserData());
                      navigate(Routes.userOnboardingFollow());
                    } catch (err) {
                      toast.show({
                        message: getErrorMsg(err),
                        type: 'error',
                      });
                    }
                    setIsPendingProfileCreate(false);
                  }
                  break;
              }
            }
          }}
        >
          <div className="border-border-light border p-6 w-full xl:w-3/5 rounded-2xl">
            <div className="flex flex-col pb-6 w-full text-left">
              <div className="mb-4">Add a profile photo</div>
              <div className="text-center">
                <ProfilePicEditor
                  profilePicUrl={onboardingData.profilePicUrl}
                  handleImageUrl={(url) => setOnboardingData({ ...onboardingData, profilePicUrl: url })}
                />
              </div>
            </div>
            <div className="flex flex-col text-left pb-6 w-full">
              <UsernameInput
                id={usernameInputId}
                placeholder={onboardingType === 'PROJECT' ? 'myAwesomeProject' : 'satoshi'}
                initialValue={onboardingData.username}
                onInputStateChange={({ state, value }) => {
                  if (!formRef.current) return;
                  setIsFormValid(checkFormValidity(formRef.current) && state === 'success');
                  setUsernameInputState(state);
                  // This function can be called multiple times but we only want to update the
                  // username state on the first call.
                  if (state === 'pending') {
                    setOnboardingData({ ...onboardingData, username: value.trim() });
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-3 text-left w-full">
              <Label>Tagline</Label>
              <Textarea
                name="pitch"
                placeholder={
                  onboardingType === 'PROJECT' ? `What is your project's elevator pitch?` : 'Describe yourself...'
                }
                maxLength={180}
                rows={3}
                value={onboardingData.shortDesc}
                onInput={(ev) => setOnboardingData({ ...onboardingData, shortDesc: ev.currentTarget.value })}
              />
            </div>
          </div>
        </form>
      }
      controls={[
        <div className="flex gap-4 mx-auto justify-center">
          <Button variant="outline" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button type="submit" form="project-profile-form">
            {onboardingType === 'USER' ? <>Create profile</> : <>Continue</>}
          </Button>
        </div>,
      ]}
    />
  );
}

export default ProfileInfo;
