import { InfoTooltip } from 'components/core/InfoTooltip';
import { ReactNode } from 'react';
import { cn } from 'utils/shadcn';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const DEFAULT_DATE_FORMAT = 'MMM D[th] YYYY [at] h:mmA';

type TimestampTooltipProps = {
  timestamp: string;
  className?: string;
  children?: ReactNode;
  leadingText?: string;
  format?: string;
};

export const TimestampTooltip = ({
  timestamp,
  className = '',
  children,
  leadingText = '',
  format,
}: TimestampTooltipProps) => {
  if (!timestamp) return null;

  return (
    <InfoTooltip
      text={
        <span className={cn('text-xs text-muted', className)}>{dayjs.utc(timestamp).format(DEFAULT_DATE_FORMAT)}</span>
      }
    >
      {children || (
        <span className={cn('inline-block text-xs text-muted leading-[12px]', className)}>
          {leadingText ? `${leadingText} ` : ''}
          {format ? dayjs(timestamp).format(format) : dayjs.utc(timestamp).fromNow()}
        </span>
      )}
    </InfoTooltip>
  );
};
