import { ReactNode, useMemo, useRef, useState } from 'react';
import { Account } from 'graphql/codegen/graphql';
import { CopyToClipboard } from 'components/app-ui/CopyToClipboard';
import { Drawer, DrawerContent, DrawerTitle } from '../shadcn/ui/drawer';
import { LuBadgeDollarSign, LuExternalLink, LuUser, LuUsers } from 'react-icons/lu';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/ui/popover';
import { ProfileStat } from './ProfileStat';
import { pluralize } from 'utils/text';
import { useMobile } from '../hooks/use-mobile';
import { StopPropagationWrapper } from '../core/StopPropagationWrapper';
import { Avatar } from 'components/core/Avatar';
import { getUserDisplayName, getUserName } from 'utils/user';
import { getMarkdownBody } from 'utils/markdown';
import { isDesoPublicKey, shortenLongWord } from 'utils/deso';
import { Button } from 'components/shadcn/ui/button';
import { FOCUS_TOKEN_USERNAME } from 'utils/constants';
import { DESO_EXPLORER_URL, EXPLORER_URLS, FOCUS_URL } from 'constants/AppConstants';
import { useAccountDetails } from 'hooks/useAccountDetails';
import { PartialAccount } from 'utils/types';
import { AccountStats } from './AccountStats';
import { type PopoverContentProps } from '@radix-ui/react-popover';
import { Skeleton } from '../shadcn/ui/skeleton';

type BaseAccountSocialCardProps = {
  children: ReactNode;
  showAvatar?: boolean;
  truncateName?: boolean;
  clickable?: boolean;
  wrapperClassName?: string;
  popoverSide?: PopoverContentProps['side'];
};

type AccountSocialCardByPublicKey = BaseAccountSocialCardProps & {
  publicKey: string;
};

type AccountSocialCardByUsername = BaseAccountSocialCardProps & {
  publicKey?: never;
};

type AccountSocialCardProps = AccountSocialCardByPublicKey | AccountSocialCardByUsername;

const getTotalSubscribersCount = (account: PartialAccount) => {
  if (!account) {
    return 0;
  }

  return (
    (account.subscriptionTiers?.nodes || [])?.reduce((totalSubscribersCount, tier) => {
      return totalSubscribersCount + (tier?.subscriptions?.totalCount ?? 0);
    }, 0) ?? 0
  );
};

export function AccountSocialCard({
  children,
  publicKey,
  clickable = true,
  popoverSide = 'bottom',
}: AccountSocialCardProps) {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const showTimeoutRef = useRef<NodeJS.Timeout>(null!);
  const hideTimeoutRef = useRef<NodeJS.Timeout>(null!);
  const { hasMouse } = useMobile();

  const { account, accountFirstTransaction, loading } = useAccountDetails(
    open && publicKey && isDesoPublicKey(publicKey) ? publicKey : undefined,
    true,
  );

  const effectivePublicKey = account?.publicKey ?? publicKey;

  const cardContent = useMemo(() => {
    if (loading) {
      return (
        <StopPropagationWrapper className="relative rounded-lg border border-solid border-transparent bg-card md:min-w-[400px]">
          <div className="flex w-full gap-x-2">
            <div className="w-full">
              <div className="p-4">
                <header className="mb-2 flex justify-between">
                  <div className="flex w-full gap-x-2">
                    <Skeleton className="size-12 rounded-full" />

                    <div className="flex w-full items-center justify-between">
                      <div className="flex flex-col gap-1 text-sm">
                        <Skeleton className="h-5 w-32" />
                        <Skeleton className="h-4 w-24" />
                      </div>
                    </div>
                  </div>
                </header>

                <div className="mb-2 mt-4">
                  <Skeleton className="h-4 w-36" />
                </div>

                <div className="my-4 flex items-center justify-start gap-2">
                  <Skeleton className="h-6 w-24" />
                  <Skeleton className="h-6 w-24" />
                  <Skeleton className="h-6 w-24" />
                </div>

                <div className="my-4">
                  <Skeleton className="h-4 w-full mb-1" />
                  <Skeleton className="h-4 w-3/4" />
                </div>

                <div className="flex items-center justify-between gap-2">
                  <Skeleton className="h-9 w-full" />
                  <Skeleton className="h-9 w-full" />
                  <Skeleton className="h-9 w-full" />
                  <Skeleton className="h-9 w-full" />
                </div>
              </div>

              <div className="px-4 mt-0 gap-2">
                <Skeleton className="h-16 w-full" />
              </div>
            </div>
          </div>
        </StopPropagationWrapper>
      );
    }

    if (!account) {
      return (
        <StopPropagationWrapper className="relative rounded-lg border border-solid border-transparent bg-card md:min-w-[400px]">
          <div className="flex w-full flex-col items-start gap-4 p-4 lg:flex-row">
            <div className="flex w-full">
              <div className="flex w-full gap-x-2">
                <div className="w-full">
                  <header className="mb-2 flex justify-between">
                    <div className="flex w-full gap-x-2">
                      <Avatar src={publicKey} />

                      <div className="flex w-full items-center justify-between">
                        <h4 className="flex flex-col gap-1 text-sm text-muted-foreground">
                          {clickable ? (
                            <a href={`/${publicKey}`} className="font-sans font-semibold hover:underline">
                              {getUserDisplayName({ publicKey })}
                            </a>
                          ) : (
                            <span className="font-sans font-semibold">{getUserDisplayName({ publicKey })}</span>
                          )}

                          <span className="text-muted">
                            {getUserName({ account: { id: '', username: '', publicKey } as Account })}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </header>
                  {effectivePublicKey && (
                    <div className="mb-2 mt-4 flex items-center gap-x-2">
                      <CopyToClipboard text={effectivePublicKey}>
                        <span className="flex items-center font-mono text-xs text-muted">
                          {shortenLongWord(effectivePublicKey, 6, 6)}
                        </span>
                      </CopyToClipboard>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </StopPropagationWrapper>
      );
    }

    const displayName = getUserDisplayName(account);
    const subscribersCount = getTotalSubscribersCount(account);

    return (
      <StopPropagationWrapper className="relative rounded-lg border border-solid border-transparent bg-card md:min-w-[400px]">
        <div className="flex w-full gap-x-2">
          <div className="w-full">
            <div className="p-4">
              <header className="mb-2 flex justify-between">
                <div className="flex w-full gap-x-2">
                  <Avatar src={account.publicKey} size="lg" />

                  <div className="flex w-full items-center justify-between">
                    <h4 className="flex flex-col gap-1 text-sm text-muted-foreground">
                      {clickable ? (
                        <a
                          href={`/profile/${account.username || account.publicKey}`}
                          className="font-semibold hover:underline"
                        >
                          {displayName}
                        </a>
                      ) : (
                        <span className="font-semibold">{displayName}</span>
                      )}

                      <span className="text-muted">
                        {getUserName({ account: account as Account, customPrefix: '@' })}
                      </span>
                    </h4>
                  </div>
                </div>
              </header>

              <div className="mb-2 mt-4 flex items-center gap-x-2">
                {effectivePublicKey && (
                  <CopyToClipboard text={effectivePublicKey}>
                    <span className="flex items-center font-mono text-xs text-muted">
                      {shortenLongWord(effectivePublicKey, 6, 6)}
                    </span>
                  </CopyToClipboard>
                )}
              </div>

              <div className="my-4 flex items-center justify-start gap-2">
                <ProfileStat
                  count={account.followerCounts?.totalFollowers || 0}
                  label={pluralize(account.followerCounts?.totalFollowers || 0, 'Follower', {
                    includeCount: false,
                  })}
                  icon={<LuUsers />}
                  loading={loading}
                  className="gap-x-1"
                  formatterFn={(e) => parseInt(e.toString())}
                />

                <ProfileStat
                  count={account.followingCounts?.totalFollowing || 0}
                  label="Following"
                  icon={<LuUser />}
                  loading={loading}
                  className="gap-x-1"
                  formatterFn={(e) => parseInt(e.toString())}
                />

                {subscribersCount > 0 && (
                  <ProfileStat
                    count={subscribersCount}
                    label={pluralize(subscribersCount, 'Subscriber', {
                      includeCount: false,
                    })}
                    icon={<LuBadgeDollarSign className="size-5" />}
                    loading={loading}
                    className="gap-x-1"
                  />
                )}
              </div>

              <div className="my-4 line-clamp-2 max-w-[400px] text-sm text-muted">
                {getMarkdownBody(account.description ?? '')}
              </div>

              <div className="flex items-center justify-between gap-2">
                <a href={`/profile/${account.username || account.publicKey}`} title="View profile" className="w-1/4">
                  <Button variant="default" size="xs" className="flex items-center gap-2 text-xs w-full h-9">
                    <LuUser />
                    Profile
                  </Button>
                </a>

                <a
                  href={`${EXPLORER_URLS.desoWallet}/?user=${effectivePublicKey}`}
                  target="_blank"
                  title="View on wallet"
                  className="w-1/4"
                  rel="noreferrer"
                >
                  <Button variant="outline" size="xs" className="flex items-center gap-2 text-xs w-full h-9">
                    <LuExternalLink size={16} className="text-sm text-muted" />
                    Wallet
                  </Button>
                </a>

                <a
                  href={`${DESO_EXPLORER_URL}/u/${effectivePublicKey}`}
                  target="_blank"
                  title="View on explorer"
                  className="w-1/4"
                  rel="noreferrer"
                >
                  <Button size="xs" variant="outline" className="flex items-center gap-2 text-xs w-full h-9">
                    <LuExternalLink size={16} className="text-sm text-muted" />
                    Explorer
                  </Button>
                </a>

                <a
                  href={`${FOCUS_URL}/${effectivePublicKey}`}
                  target="_blank"
                  title="View on focus"
                  rel="noreferrer"
                  className="w-1/4"
                >
                  <Button variant="outline" size="xs" className="flex items-center gap-2 text-xs w-full h-9">
                    <LuExternalLink size={16} className="text-sm text-muted" />
                    {FOCUS_TOKEN_USERNAME}
                  </Button>
                </a>
              </div>
            </div>

            <AccountStats
              account={account}
              accountFirstTransaction={accountFirstTransaction}
              className="px-4 mt-0 gap-2"
            />
          </div>
        </div>
      </StopPropagationWrapper>
    );
  }, [effectivePublicKey, account, clickable, publicKey, loading, accountFirstTransaction]);

  return (
    <>
      <Popover open={hasMouse && open} onOpenChange={setOpen} modal={modal}>
        <PopoverTrigger
          onClickCapture={(ev) => {
            if (hasMouse) return;

            ev.preventDefault();
            ev.stopPropagation();

            setModal(true);
            setOpen(true);
          }}
          onPointerEnter={(ev) => {
            if (ev.pointerType === 'touch') return;

            showTimeoutRef.current = setTimeout(() => {
              setModal(false);
              setOpen(true);
            }, 200);
          }}
          onPointerLeave={(ev) => {
            if (ev.pointerType === 'touch') return;

            clearTimeout(showTimeoutRef.current);

            hideTimeoutRef.current = setTimeout(() => {
              setOpen(false);
            }, 100);
          }}
          style={{ textShadow: 'inherit' }}
        >
          {children}
        </PopoverTrigger>
        <PopoverContent
          side={popoverSide}
          className="z-[100] w-full max-w-[90vw] p-0"
          onPointerEnter={(ev) => {
            if (ev.pointerType === 'touch') return;

            clearTimeout(hideTimeoutRef.current);
          }}
          onPointerLeave={(ev) => {
            if (ev.pointerType === 'touch') return;
            setOpen(false);
          }}
          onCloseAutoFocus={(ev) => ev.preventDefault()}
        >
          {open && cardContent}
        </PopoverContent>
      </Popover>
      <Drawer open={!hasMouse && open} onOpenChange={setOpen}>
        <DrawerContent>
          <DrawerTitle className="hidden">{account?.username || ''}</DrawerTitle>
          {cardContent}
        </DrawerContent>
      </Drawer>
    </>
  );
}
