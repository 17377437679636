import { Logo } from 'components/core/Logo';
import { RouteLink } from 'components/core/RouteLink';
import { Text } from 'components/core/Text';
import { Button } from 'components/shadcn/ui/button';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { ReactNode, useContext, useState } from 'react';
import { Link, Outlet, useOutletContext } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { deso } from 'services';
import { classNames } from 'utils/classNames';

export type OnboardingData = {
  username: string;
  displayName: string;
  email: string;
  shortDesc: string;
  longDesc: string;
  featuredImageUrl: string;
  profilePicUrl: string;
  websiteUrl: string;
  discordUrl: string;
  diamondUrl: string;
  twitterUrl: string;
};
interface OnboardingRouteContext {
  onboardingData: OnboardingData;
  setOnboardingData: (data: OnboardingData) => void;
  onboardingType: string;
}
type OnboardingContext = OnboardingRouteContext;
interface OnboardingProps {
  onboardingType: 'PROJECT' | 'USER';
}
export function Onboarding({ onboardingType }: OnboardingProps) {
  const { currentUser } = useContext(OpenfundContext);

  const [onboardingData, setOnboardingData] = useState<OnboardingData>({
    username: currentUser?.ProfileEntryResponse?.Username ?? '',
    profilePicUrl: currentUser ? deso.profilePicUrl(currentUser.PublicKeyBase58Check) : '',
    shortDesc: currentUser?.ProfileEntryResponse?.Description ?? '',
    email: currentUser?.appUser?.Email ?? '',
    displayName: currentUser?.ProfileEntryResponse?.ExtraData?.DisplayName ?? '',
    longDesc: currentUser?.ProfileEntryResponse?.ExtraData?.MarkdownDescription ?? '',
    featuredImageUrl: currentUser?.ProfileEntryResponse?.ExtraData?.FeaturedImageURL ?? '',
    websiteUrl: currentUser?.ProfileEntryResponse?.ExtraData?.WebsiteURL ?? '',
    discordUrl: currentUser?.ProfileEntryResponse?.ExtraData?.DiscordURL ?? '',
    twitterUrl: currentUser?.ProfileEntryResponse?.ExtraData?.TwitterURL ?? '',
    diamondUrl: '',
  });

  return (
    <div className="flex items-center justify-center m-auto w-full sm:w-6/12 flex-col mt-8">
      <div className="m-auto">
        <Link to={Routes.home()}>
          <Logo />
        </Link>
      </div>
      <Outlet context={{ onboardingData, onboardingType, setOnboardingData }} />
    </div>
  );
}

type OnboardingLayoutProps = {
  title: string;
  disclaimer?: string;
  content: ReactNode;
  controls?: ReactNode[] | undefined;
  hasExitLink?: boolean;
};
export function OnboardingLayout({
  title,
  content,
  disclaimer = '',
  controls,
  hasExitLink = true,
}: OnboardingLayoutProps) {
  const verticalSpacing = 'mb-2 lg:mb-6';

  return (
    <>
      <div className="w-full text-center my-12">
        <div className="text-xl text-muted-foreground font-semibold mb-6">{title}</div>
        <div className={classNames('', verticalSpacing)}>{content}</div>
        {/* NOTE: we render this even if it's empty to preserve the spacing across pages */}
        {disclaimer && (
          <Text color="secondary" size="sm" className={`${verticalSpacing} ${!disclaimer && 'invisible'}`}>
            {disclaimer || 'placeholder'}
          </Text>
        )}
        <div className={verticalSpacing}>{controls?.map((control, i) => <span key={i}>{control}</span>)}</div>
      </div>
      {hasExitLink && (
        <RouteLink to={Routes.home()} size="sm" className="mb-8">
          <Button variant="ghost">Exit onboarding</Button>
        </RouteLink>
      )}
    </>
  );
}

export function useOnboardingContext(): OnboardingContext {
  return useOutletContext();
}

export default Onboarding;
