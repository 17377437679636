import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { signal } from '@preact/signals-react';
import { FullPageError } from 'components/app-ui/FullPageError';
import { OpenOrdersTable } from 'components/app-ui/OpenOrdersTable';
import { PriceLevelBook } from 'components/app-ui/PriceLevelBook';
import { ToggleProjectTradingStatus } from 'components/app-ui/ToggleProjectTradingStatus';
import { TradeCard } from 'components/app-ui/TradeCard';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import {
  Select as SelectNew,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select';
import { Skeleton } from 'components/shadcn/ui/skeleton';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { AVAILABLE_QUOTE_CURRENCIES, QuoteCurrencyContext } from 'contexts/QuoteCurrencyContext';
import { ProfileEntryResponse } from 'deso-protocol';
import { client } from 'graphql/client';
import {
  DaoCoinStat,
  DaoCoinStatDocument,
  DaoCoinStatsDocument,
  DaoCoinStatsOrderBy,
  DeSoTokenLimitOrderSubscriptionDocument,
  TokenLimitOrdersDesoBuyingDocument,
  TokenLimitOrdersDesoSellingDocument,
  TokenLimitOrdersNoDesoDocument,
} from 'graphql/codegen/graphql';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useToast } from 'components/hooks/use-toast';
import maxBy from 'lodash/maxBy';
import range from 'lodash/range';
import uniqBy from 'lodash/uniqBy';
import { ReactNode, useContext, useEffect, useMemo, useState, useTransition, useDeferredValue } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { deso, openfund } from 'services';
import { TokenLimitOrderEntryResponse } from 'services/Deso';
import {
  DESO_BTC_PUBLIC_KEY,
  DESO_ETH_PUBLIC_KEY,
  DESO_SOL_PUBLIC_KEY,
  DESO_USDC_PUBLIC_KEY,
  DESO_ZERO_PUBLIC_KEY,
  FOCUS_TOKEN_PUBLIC_KEY,
  OPENFUND_TOKEN_PUBLIC_KEY,
} from '../../constants/AppConstants';
import {
  DEFAULT_DESO_PROJECT,
  DEFAULT_DUSD_PROJECT,
  DEFAULT_FOCUS_PROJECT,
  DESO_PROJECT_NAME,
  DESO_TICKER,
  DESO_TOKEN_PUBLIC_KEY,
  DESO_USDC_TICKER,
  FOCUS_TICKER,
} from '../../constants/TradeConstants';
import { TradeContextProvider } from '../../contexts/TradeContext';
import { formatDecimalValue, MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING } from '../../utils/currency';
import { getWrappedAsset } from '../../utils/deso';
import { DESO, gqlWsOrderToTokenLimitEntry } from '../../utils/orderbook';
import { getUSDBalanceBaseUnits } from '../../utils/user';
import { AdvancedTradingViewChart } from '../app-ui/AdvancedTradingViewChart';
import { CoinInfoWidget } from '../app-ui/CoinInfoWidget';
import LowNumFormatter from '../app-ui/LowNumFormatter';
import BaseCurrencySelect from '../core/BaseCurrencySelect';
import { LuRefreshCcw } from 'react-icons/lu';
import { Dialog, DialogContent, DialogTrigger } from 'components/shadcn/ui/dialog';
import { cn } from '../../utils/shadcn';
import { DesoTokenOverviewWidget } from '../app-ui/DesoTokenOverviewWidget';
import { FOCUS_TOKEN_SUPPLY, ONE_TRILLION } from '../../utils/constants';
import { getTopicForTwoPublicKeys, TopicPrefix } from '../../utils/subscriptions';

export const priceUpdateSignal = signal({ timestamp: 0 });

// const getOrderBookFilter = (soldCoinPublicKey: string, buyCoinPublicKey: string) => {
//   let sellPayload;
//   let buyPayload;
//
//   if (soldCoinPublicKey === DESO_ZERO_PUBLIC_KEY) {
//     sellPayload = {
//       sellingDaoCoinCreatorPkid: {
//         equalTo: DESO_ZERO_PUBLIC_KEY,
//       },
//     };
//   } else {
//     sellPayload = {
//       creatorSoldAccount: {
//         publicKey: {
//           equalTo: soldCoinPublicKey,
//         },
//       },
//     };
//   }
//
//   if (buyCoinPublicKey === DESO_ZERO_PUBLIC_KEY) {
//     buyPayload = {
//       buyingDaoCoinCreatorPkid: {
//         equalTo: DESO_ZERO_PUBLIC_KEY,
//       },
//     };
//   } else {
//     buyPayload = {
//       creatorBoughtAccount: {
//         publicKey: {
//           equalTo: buyCoinPublicKey,
//         },
//       },
//     };
//   }
//
//   return {
//     filter: {
//       ...sellPayload,
//       ...buyPayload,
//     },
//     first: 500,
//     orderBy: DesoTokenLimitOrdersOrderBy.ScaledExchangeRateCoinsToSellPerCoinToBuyNumericDesc,
//   };
// };

const searchParamsToQuoteCurrency = (searchParams: URLSearchParams) => {
  const quoteCurrency = (searchParams.get('quote') ?? '').trim().toLowerCase();
  if (quoteCurrency === FOCUS_TICKER.toLowerCase()) {
    return FOCUS_TOKEN_PUBLIC_KEY;
  } else if (quoteCurrency === DESO_TICKER.toLowerCase()) {
    return DESO_ZERO_PUBLIC_KEY;
  } else if (quoteCurrency === DESO_USDC_TICKER.toLowerCase()) {
    return DESO_USDC_PUBLIC_KEY;
  }

  return;
};

export function Trade() {
  // username represents the token username being traded
  const { username = '' } = useParams();
  useDocumentTitle('Trade');

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryParams = location.search;

  const forcedQuoteCurrencyPublicKey = searchParamsToQuoteCurrency(searchParams);

  const { currentUser, setCurrentUser } = useContext(OpenfundContext);

  const [fetchOrderBookLazyDesoBuying] = useLazyQuery(TokenLimitOrdersDesoBuyingDocument, {
    client,
  });
  const [fetchOrderBookLazyDesoSelling] = useLazyQuery(TokenLimitOrdersDesoSellingDocument, {
    client,
  });
  const [fetchOrderBookLazyNoDeso] = useLazyQuery(TokenLimitOrdersNoDesoDocument, {
    client,
  });

  const [isPending, startTransition] = useTransition();
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    error: null,
    isTradingToggleReady: false,
  });
  const deferredLoadingState = useDeferredValue(loadingState);

  const [refreshing, setIsRefreshing] = useState(true);

  const [selectedProject, setSelectedProject] = useState<ProfileEntryResponse>();
  const [selectedTokenOrderBook, setSelectedTokenOrderBook] = useState<TokenLimitOrderEntryResponse[]>([]);
  const [transactorOrders, setTransactorOrders] = useState<TokenLimitOrderEntryResponse[]>([]);

  const [userTokenBalanceBaseUnits, setUserTokenBalanceBaseUnits] = useState(BigInt(0));
  const [focusBalanceBaseUnits, setFocusBalanceBaseUnits] = useState(BigInt(0));
  const [limitOrderPriceFromOrderBook, setLimitOrderPriceFromOrderBook] = useState<{
    price: number;
    usdPrice: number;
  } | null>(null);

  const [daoCoinStatLoading, setDaoCoinStatLoading] = useState(true);
  const [fetchCoinStat] = useLazyQuery(DaoCoinStatDocument, {
    client,
  });
  const [daoCoinStat, setDaoCoinStat] = useState<DaoCoinStat | undefined>();

  const toast = useToast();
  const isTradingEnabled =
    selectedProject?.DAOCoinEntry?.TransferRestrictionStatus === 'unrestricted' ||
    selectedProject?.DAOCoinEntry?.TransferRestrictionStatus === 'permanently_unrestricted';

  const { quoteCurrencyPublicKey, setQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const topic = useMemo(() => {
    return getTopicForTwoPublicKeys(
      TopicPrefix.DAOCoinLimitOrderEntries,
      (selectedProject?.PublicKeyBase58Check === DESO_TOKEN_PUBLIC_KEY
        ? DESO_ZERO_PUBLIC_KEY
        : selectedProject?.PublicKeyBase58Check) || '',
      quoteCurrencyPublicKey,
    );
  }, [selectedProject, quoteCurrencyPublicKey]);

  useSubscription(DeSoTokenLimitOrderSubscriptionDocument, {
    variables: {
      topic,
    },
    onData: ({ data }) => {
      if (!data) return;
      const operation = data.data?.listen?.operation;
      const newOrder = gqlWsOrderToTokenLimitEntry(data.data);
      if (!newOrder) return;

      // For DELETE operations, we can exit early if the order doesn't exist in the selectedTokenOrderBook.
      // For INSERT and UPDATE operations, we can exit early if the order already exists in the selectedTokenOrderBook
      // and is effectively the same as the order currently in the selectedTokenOrderBook.
      const existingOrder = selectedTokenOrderBook.find((order) => order.OrderID === newOrder.OrderID);
      if (operation === 'DELETE' && !existingOrder) {
        return;
      }
      if ((operation === 'INSERT' || operation === 'UPDATE') && existingOrder) {
        if (existingOrder.Price === newOrder.Price && existingOrder.Quantity === newOrder.Quantity) {
          return;
        }
      }

      priceUpdateSignal.value = {
        timestamp: Date.now(),
      };
      setSelectedTokenOrderBook((prev) => {
        if (operation === 'DELETE') {
          return prev.filter((order) => order.OrderID !== newOrder.OrderID);
        }
        if (operation === 'UPDATE') {
          // if the order is already in the order book, we update it. Otherwise,
          // we treat it as an insert.
          if (prev.find((order) => order.OrderID === newOrder.OrderID)) {
            return prev.map((order) => (order.OrderID === newOrder.OrderID ? newOrder : order));
          }
        }
        return uniqBy([...prev, newOrder], 'OrderID');
      });
    },
  });

  const wrappedAsset =
    getWrappedAsset(username, 'name') ||
    getWrappedAsset(username, 'heroswapName') ||
    getWrappedAsset(username, 'displayName');
  const isWrapped = !!wrappedAsset;

  const selectedProjectUsername = wrappedAsset?.name || username;

  useEffect(() => {
    if (selectedProject) {
      setDaoCoinStatLoading(true);

      fetchCoinStat({
        variables: {
          coinPublicKey:
            selectedProject.PublicKeyBase58Check === DESO_PROJECT_NAME
              ? DESO_ZERO_PUBLIC_KEY
              : selectedProject.PublicKeyBase58Check,
        },
      })
        .then((e) => {
          if (!e.data?.daoCoinStat) {
            setDaoCoinStat({
              coinUsername: selectedProject.Username,
              coinPublicKey: selectedProject.PublicKeyBase58Check,
            } as DaoCoinStat);
            return;
          }

          setDaoCoinStat(e.data.daoCoinStat as DaoCoinStat);
        })
        .finally(() => {
          setDaoCoinStatLoading(false);
        });
    }
  }, [selectedProject?.PublicKeyBase58Check]);

  useEffectOnce(() => {
    if (wrappedAsset && username !== wrappedAsset.displayName) {
      // Replace the route to the formatted one, like BTC, ETH etc.
      navigate(`/trade/${wrappedAsset.displayName}${queryParams}`, { replace: true });
    }
  });

  const getOrderBook = async (
    buyCoinPublicKey: string,
    sellCoinPublicKey: string,
  ): Promise<TokenLimitOrderEntryResponse[]> => {
    // TODO: make sure this is right.

    if ([buyCoinPublicKey, sellCoinPublicKey].every((pk) => pk === DESO_ZERO_PUBLIC_KEY || pk === DESO_PROJECT_NAME)) {
      return [];
    }

    return deso.getAllTokenLimitOrders(
      buyCoinPublicKey === DESO_ZERO_PUBLIC_KEY ? DESO_PROJECT_NAME : buyCoinPublicKey,
      sellCoinPublicKey === DESO_ZERO_PUBLIC_KEY ? DESO_PROJECT_NAME : sellCoinPublicKey,
    );
    // }
    // TODO: The following code utilizes graphql queries, which aren't ready for prime
    // time yet. Once they are, we should switch to them because they're more performant
    // and scalable.
    // if (buyCoinPublicKey === DESO_ZERO_PUBLIC_KEY) {
    //   return Promise.all([
    //     fetchOrderBookLazyDesoBuying({
    //       variables: getOrderBookFilter(sellCoinPublicKey, buyCoinPublicKey),
    //       fetchPolicy: 'network-only',
    //     }),
    //     fetchOrderBookLazyDesoSelling({
    //       variables: getOrderBookFilter(buyCoinPublicKey, sellCoinPublicKey),
    //       fetchPolicy: 'network-only',
    //     }),
    //   ]).then((results) => graphQLOrderBookToTokenLimitEntry(results) || []);
    // }
    // if (sellCoinPublicKey === DESO_ZERO_PUBLIC_KEY) {
    //   return Promise.all([
    //     fetchOrderBookLazyDesoBuying({
    //       variables: getOrderBookFilter(buyCoinPublicKey, sellCoinPublicKey),
    //       fetchPolicy: 'network-only',
    //     }),
    //     fetchOrderBookLazyDesoSelling({
    //       variables: getOrderBookFilter(sellCoinPublicKey, buyCoinPublicKey),
    //       fetchPolicy: 'network-only',
    //     }),
    //   ]).then((results) => graphQLOrderBookToTokenLimitEntry(results) || []);
    // }
    // return Promise.all([
    //   fetchOrderBookLazyNoDeso({
    //     variables: getOrderBookFilter(buyCoinPublicKey, sellCoinPublicKey),
    //     fetchPolicy: 'network-only',
    //   }),
    //   fetchOrderBookLazyNoDeso({
    //     variables: getOrderBookFilter(sellCoinPublicKey, buyCoinPublicKey),
    //     fetchPolicy: 'network-only',
    //   }),
    // ]).then((results) => graphQLOrderBookToTokenLimitEntry(results) || []);
  };

  const featuredProjectForQuoteCurrency = useMemo(() => {
    switch (quoteCurrencyPublicKey) {
      case DESO_USDC_PUBLIC_KEY:
        return DEFAULT_DUSD_PROJECT;
      case FOCUS_TOKEN_PUBLIC_KEY:
        return DEFAULT_FOCUS_PROJECT;
      case DESO_ZERO_PUBLIC_KEY:
      default:
        return DEFAULT_DESO_PROJECT;
    }
  }, [quoteCurrencyPublicKey]);

  const loadProfileWithOrderbook = () => {
    setLoadingState({
      isLoading: true,
      error: null,
      isTradingToggleReady: false,
    });

    deso
      .getProfileByUsername(selectedProjectUsername, true, true)
      .then((res) => {
        if ((!res || !res.Profile) && selectedProjectUsername !== DESO_PROJECT_NAME) {
          throw new Error('Project profile does not exist');
        }

        const profile = (
          selectedProjectUsername === DESO_PROJECT_NAME
            ? {
                Username: DESO_PROJECT_NAME,
                PublicKeyBase58Check: DESO_PROJECT_NAME,
                DAOCoinEntry: { TransferRestrictionStatus: 'unrestricted', CoinsInCirculationNanos: '0x0' },
              }
            : isWrapped
              ? {
                  ...res.Profile,
                  PublicKeyBase58Check:
                    res?.Profile?.Username === 'deso'
                      ? DESO_PROJECT_NAME
                      : (res?.Profile?.PublicKeyBase58Check as string),
                }
              : res.Profile
        ) as ProfileEntryResponse;

        const fetchOrderBook = (quoteCurrency: string, profilePublicKey: string) =>
          getOrderBook(quoteCurrency, profilePublicKey).then((orders) => ({
            quoteCurrency,
            orders,
          }));

        const dominantMarketPromise = Promise.all([
          profile.PublicKeyBase58Check !== DESO_ZERO_PUBLIC_KEY && profile.Username !== DESO
            ? fetchOrderBook(DESO_ZERO_PUBLIC_KEY, profile.PublicKeyBase58Check)
            : Promise.resolve({ orders: [], quoteCurrency: DESO_ZERO_PUBLIC_KEY }),
          fetchOrderBook(DESO_USDC_PUBLIC_KEY, profile.PublicKeyBase58Check),
          profile.PublicKeyBase58Check !== DESO_ZERO_PUBLIC_KEY && profile.Username !== DESO
            ? fetchOrderBook(FOCUS_TOKEN_PUBLIC_KEY, profile.PublicKeyBase58Check)
            : Promise.resolve({ orders: [], quoteCurrency: FOCUS_TOKEN_PUBLIC_KEY }),
        ]).then((results) => {
          // if all markets have the same amount of orders we do nothing
          if (results.every((result) => result.orders.length === results[0].orders.length)) {
            if (forcedQuoteCurrencyPublicKey) {
              setQuoteCurrencyPublicKey(forcedQuoteCurrencyPublicKey);
            }
            return null;
          }

          const dominantMarket = maxBy(results, 'orders.length');

          if (!dominantMarket) {
            if (forcedQuoteCurrencyPublicKey) {
              setQuoteCurrencyPublicKey(forcedQuoteCurrencyPublicKey);
            }
            return null;
          }

          setQuoteCurrencyPublicKey(forcedQuoteCurrencyPublicKey ?? dominantMarket.quoteCurrency);
          return dominantMarket.orders;
        });

        return dominantMarketPromise.then((orders) => {
          const buyCoinPublicKey = isWrapped ? DESO_USDC_PUBLIC_KEY : quoteCurrencyPublicKey;
          const sellCoinPublicKey =
            profile.PublicKeyBase58Check === DESO_PROJECT_NAME ? DESO_ZERO_PUBLIC_KEY : profile.PublicKeyBase58Check;

          const fetchOrderBookRequest =
            orders !== null ? Promise.resolve(orders) : getOrderBook(buyCoinPublicKey, sellCoinPublicKey);

          return Promise.all([profile, fetchOrderBookRequest]);
        });
      })
      .then(([profile, orders]) => {
        setSelectedProject(profile);
        setSelectedTokenOrderBook(orders || []);

        return profile;
      })
      .catch((e) => {
        // if we can't fetch the project profile or token holdings, we swallow the error and set the balance to 0
        setUserTokenBalanceBaseUnits(BigInt(0));

        setLoadingState((prevState) => ({
          ...prevState,
          error: e,
        }));
        throw e;
      })
      .finally(() => {
        setLoadingState({
          isLoading: false,
          error: null,
          isTradingToggleReady: true,
        });
      });
  };

  useEffect(() => {
    // If no project is selected, then we pick one of the featured ones and reroute
    // to the project's trading page
    if (!selectedProjectUsername) {
      navigate(Routes.tradeToken(featuredProjectForQuoteCurrency), { replace: true });
      return;
    }

    startTransition(() => {
      // If a project username has been selected, then load the project profile, user profile, and user holdings, and order book
      loadProfileWithOrderbook();
    });
  }, [selectedProjectUsername, isWrapped]);

  const refreshData = async (refetchOrderbook: boolean = true) => {
    setIsRefreshing(true);

    priceUpdateSignal.value = {
      timestamp: Date.now(),
    };

    // When an order is successfully placed, we want to refresh three things:
    // 1. The order book
    // 2. The user's token & FOCUS holdings
    // 3. The user's deso balance
    if (selectedProject) {
      const sellCoinPublicKey =
        selectedProject.PublicKeyBase58Check === DESO_PROJECT_NAME
          ? DESO_ZERO_PUBLIC_KEY
          : selectedProject.PublicKeyBase58Check;

      if (refetchOrderbook) {
        // Refresh order book
        await getOrderBook(quoteCurrencyPublicKey, sellCoinPublicKey).then((results) => {
          setSelectedTokenOrderBook(results || []);
        });
      }

      if (currentUser && selectedProject.PublicKeyBase58Check !== DESO_PROJECT_NAME) {
        // Refresh user's FOCUS and token holdings
        await Promise.all([
          deso.getProjectHolding(currentUser.PublicKeyBase58Check, FOCUS_TOKEN_PUBLIC_KEY),
          deso.getProjectHolding(currentUser.PublicKeyBase58Check, selectedProject.PublicKeyBase58Check),
        ])
          .then(([focusBalance, tokenBalance]) => {
            if (focusBalance) {
              setFocusBalanceBaseUnits(BigInt(focusBalance.BalanceNanosUint256.toString()));
            } else {
              setFocusBalanceBaseUnits(BigInt(0));
            }

            if (tokenBalance) {
              setUserTokenBalanceBaseUnits(BigInt(tokenBalance.BalanceNanosUint256.toString()));
            } else {
              setUserTokenBalanceBaseUnits(BigInt(0));
            }
          })
          .catch((e) => {
            // if we can't fetch the project profile or token holdings, we swallow the error and set the balance to 0
            setUserTokenBalanceBaseUnits(BigInt(0));
            setFocusBalanceBaseUnits(BigInt(0));
            throw e;
          });
      }
    }

    if (currentUser?.PublicKeyBase58Check) {
      // Refresh user's deso balance
      await Promise.all([
        deso.getAllTransactorTokenLimitOrders(currentUser.PublicKeyBase58Check).then((orders) => {
          setTransactorOrders(orders);
        }),
        openfund.reloadCurrentUserData().then((res) => setCurrentUser(res)),
      ]);
    }

    setIsRefreshing(false);
  };

  useEffect(() => {
    refreshData(false);
  }, [quoteCurrencyPublicKey, selectedProject?.PublicKeyBase58Check, currentUser?.PublicKeyBase58Check]);

  const candleChartSection = selectedProject && <AdvancedTradingViewChart selectedProject={selectedProject} />;

  const tradeCardSection = selectedProject && (
    <section key="trade">
      <TradeCard
        project={selectedProject}
        currentUser={currentUser}
        refreshing={refreshing}
        setRefreshing={setIsRefreshing}
        userTokenBalanceBaseUnits={userTokenBalanceBaseUnits}
        focusBalanceBaseUnits={focusBalanceBaseUnits}
        refreshOrderBookCallback={refreshData}
        usdBalanceBaseUnits={currentUser ? getUSDBalanceBaseUnits(currentUser) : BigInt(0)}
        limitOrderPriceFromOrderBook={limitOrderPriceFromOrderBook}
        onMarketOrderTabSelected={() => {
          setLimitOrderPriceFromOrderBook(null);
        }}
      />
    </section>
  );

  const priceLevelBookSection = selectedProject && (
    <section key="book">
      <PriceLevelBook
        tokenOrderbook={selectedTokenOrderBook}
        loggedInPublicKey={currentUser?.PublicKeyBase58Check ?? ''}
        project={selectedProject}
        onClickPriceRow={setLimitOrderPriceFromOrderBook}
      />
    </section>
  );

  const selectedProjectOpenOrdersTable =
    selectedProjectUsername === DESO_PROJECT_NAME
      ? {
          PublicKeyBase58Check: DESO_ZERO_PUBLIC_KEY,
          Username: 'DESO',
        }
      : selectedProject;

  const myOrdersSection = selectedProjectOpenOrdersTable && (
    <>
      <section key="open-orders">
        <OpenOrdersTable
          loggedInPublicKey={currentUser?.PublicKeyBase58Check}
          project={selectedProjectOpenOrdersTable}
          refreshOrderBookCallback={refreshData}
        />
      </section>
    </>
  );

  // Check if we should show loading state - use deferredLoadingState to smooth transitions
  const isPageLoading = deferredLoadingState.isLoading || isPending;
  const hasError = !!deferredLoadingState.error;
  const isTradingToggleReady = deferredLoadingState.isTradingToggleReady;

  return (
    <TradeContextProvider selectedTokenOrderBook={selectedTokenOrderBook} transactorOrders={transactorOrders}>
      <DexSubHeader
        daoCoinStat={daoCoinStat}
        daoCoinStatLoading={daoCoinStatLoading}
        right={
          <div className="absolute bottom-[14px] lg:bottom-0 scale-75 lg:scale-100 lg:relative toggle-wrapper flex flex-row gap-4 justify-end items-center text-right">
            <ButtonNew
              variant="outline"
              onClick={() => {
                const icon = document.querySelector('.refresh-icon');
                icon?.classList.add('animate-spin');
                priceUpdateSignal.value = {
                  timestamp: Date.now(),
                };
                refreshData().finally(() => {
                  icon?.classList.remove('animate-spin');
                });
              }}
            >
              <div className={'flex items-center ml-auto fill-muted hover:fill-white gap-2'}>
                <div className="hidden lg:flex">Refresh</div>
                <LuRefreshCcw className="refresh-icon" />
              </div>
            </ButtonNew>
          </div>
        }
        isToggleReady={isTradingToggleReady}
        selectedProject={selectedProject}
      />

      <div className="w-full max-w-[1800px] border-x mx-auto">
        <div className="sm:block lg:flex flex-row justify-between mx-auto">
          {/* Use single isPageLoading state to prevent flickering */}
          {isPageLoading ? (
            <div className="text-center mx-auto justify-center mt-12 lg:mt-48 pt-12 lg:pt-64">
              <Spinner />
            </div>
          ) : hasError ? (
            <FullPageError error={deferredLoadingState.error} />
          ) : (
            <>
              {selectedProject && (
                <>
                  <div className="w-full lg:w-9/12 relative">
                    {isTradingEnabled ? (
                      <>
                        <div className="border-b border-border flex flex-col lg:flex-row">
                          <div className="w-full lg:w-[60%] xl:w-2/3 border-b lg:border-b-0">{candleChartSection}</div>
                          <div className="w-full lg:w-[40%] xl:w-1/3 relative p-4 lg:p-2 lg:border-l">
                            {priceLevelBookSection}
                          </div>
                        </div>
                        {!isPageLoading && <div>{myOrdersSection}</div>}
                        <div className="p-4 fixed bottom-0 w-full z-10 bg-gradient-to-t from-background via-background/90 to-transparent">
                          <Dialog>
                            <DialogTrigger asChild>
                              <ButtonNew className="w-full shadow-xl sm:hidden" variant="success">
                                Buy ${selectedProject.Username}
                              </ButtonNew>
                            </DialogTrigger>
                            <DialogContent className="p-4">{tradeCardSection}</DialogContent>
                          </Dialog>
                        </div>
                      </>
                    ) : (
                      <div className="p-6 flex flex-col gap-4 items-center justify-center h-[85vh]">
                        <ToggleProjectTradingStatus
                          projectProfile={selectedProject}
                          className="mx-auto w-full m-6"
                          onStatusToggleSuccess={(status) => {
                            if (status === 'unrestricted') {
                              toast.toast({
                                description: <>Successfully enabled trading for your project!</>,
                                variant: 'success',
                              });
                            }

                            loadProfileWithOrderbook();
                          }}
                        />
                        {!isTradingEnabled && (
                          <div className="mt-4 pt-4 text-center leading-6 text-muted text-balance w-2/3 mx-auto">
                            <h3 className="text-lg mb-2 text-muted-foreground font-semibold font-sans">
                              Token trading is disabled
                            </h3>
                            {currentUser?.PublicKeyBase58Check === selectedProject?.PublicKeyBase58Check
                              ? 'You need to enable trading in order to activate the order-book exchange for your token.'
                              : 'The project owner needs to enable trading in order to activate the order-book exchange for this token. Message them or post on their project page to learn more about their planned listing timeline.'}
                          </div>
                        )}
                        <RouteLink to={Routes.fund(selectedProject.Username)}>
                          <ButtonNew variant="default">View Project</ButtonNew>
                        </RouteLink>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="border-l border-border w-full lg:w-3/12 pb-[60px] lg:pb-0">
                {isTradingEnabled && (
                  <>
                    <div className="sm:block p-4 border-b hidden border-t lg:border-t-0">{tradeCardSection}</div>
                    {selectedProject && (
                      <div className="relative p-4">
                        {selectedProject.PublicKeyBase58Check === DESO_TOKEN_PUBLIC_KEY ? (
                          <DesoTokenOverviewWidget
                            currentUser={currentUser}
                            marketCapUsd={daoCoinStat?.marketCapUsd || 0}
                            loading={daoCoinStatLoading}
                          />
                        ) : (
                          <CoinInfoWidget publicKey={selectedProject.PublicKeyBase58Check} fallback={<>N/A</>} />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </TradeContextProvider>
  );
}

const DexSubHeader = ({
  daoCoinStat,
  daoCoinStatLoading,
  right,
  isToggleReady,
  selectedProject,
}: {
  daoCoinStat?: DaoCoinStat;
  daoCoinStatLoading: boolean;
  right: React.ReactNode;
  isToggleReady: boolean;
  selectedProject?: ProfileEntryResponse;
}) => {
  const price = daoCoinStat?.priceUsd ?? 0;
  const marketCapField = daoCoinStat?.marketCapUsd ?? 0;
  const volume24h = daoCoinStat?.volumeUsdPast24H ?? 0;
  const pricePctChange24h =
    Math.abs(daoCoinStat?.priceUsdPctChange24H || 0) < MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING
      ? 0
      : (daoCoinStat?.priceUsdPctChange24H ?? 0);

  const wrappedAsset =
    selectedProject &&
    selectedProject.PublicKeyBase58Check !== DESO_PROJECT_NAME &&
    getWrappedAsset(selectedProject?.PublicKeyBase58Check, 'publicKey');

  return (
    <div className="w-full relative block lg:flex lg:items-center lg:justify-between p-0 border-b border-border">
      <div className="flex flex-col lg:flex-row lg:items-center lg:gap-4 w-full">
        <div className="flex items-start flex-col w-full lg:w-auto">
          <DexTickerSearch daoCoinStat={daoCoinStat} isToggleReady={isToggleReady} selectedProject={selectedProject} />
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-[repeat(4,auto)] gap-3 lg:gap-6 p-4 lg:p-0 lg:pl-2">
          {daoCoinStatLoading || !selectedProject ? (
            <>
              {range(0, wrappedAsset ? 2 : 3).map((_, i) => (
                <Skeleton key={i} className="h-12 w-[80px]" />
              ))}
            </>
          ) : (
            <>
              <DexMetric label="Price" value={<LowNumFormatter price={price} className="text-base lg:text-lg" />} />
              {!wrappedAsset &&
                (selectedProject.PublicKeyBase58Check === FOCUS_TOKEN_PUBLIC_KEY ? (
                  <DexMetric
                    label="Market Cap"
                    value={
                      <LowNumFormatter
                        price={price * FOCUS_TOKEN_SUPPLY}
                        className="text-base lg:text-lg"
                        abbreviatePriceThreshold={ONE_TRILLION}
                      />
                    }
                  />
                ) : (
                  <DexMetric
                    label="Market Cap"
                    value={
                      <LowNumFormatter
                        price={marketCapField}
                        className="text-base lg:text-lg"
                        abbreviatePriceThreshold={ONE_TRILLION}
                      />
                    }
                  />
                ))}
              <DexMetric
                label="24H Volume"
                value={<LowNumFormatter price={volume24h} className="text-base lg:text-lg" />}
              />
              <DexMetric
                label="24H Change"
                value={<span className="text-base md:text-lg">{formatDecimalValue(pricePctChange24h)}%</span>}
                trending={
                  pricePctChange24h !== 0
                    ? pricePctChange24h >= MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING
                      ? 'up'
                      : 'down'
                    : undefined
                }
              />
            </>
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 pr-4">{right}</div>
    </div>
  );
};

const DexTickerSearch = ({
  isToggleReady,
  selectedProject,
  daoCoinStat,
}: {
  isToggleReady: boolean;
  selectedProject?: ProfileEntryResponse;
  daoCoinStat?: DaoCoinStat;
}) => {
  const navigate = useNavigate();

  const { quoteCurrencyPublicKey, setQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);
  const selectedProjectPublicKey =
    selectedProject?.PublicKeyBase58Check === DESO_PROJECT_NAME
      ? DESO_ZERO_PUBLIC_KEY
      : selectedProject?.PublicKeyBase58Check;

  const PINNED_PROJECTS = [
    DESO_ZERO_PUBLIC_KEY,
    DESO_BTC_PUBLIC_KEY,
    DESO_ETH_PUBLIC_KEY,
    DESO_SOL_PUBLIC_KEY,
    OPENFUND_TOKEN_PUBLIC_KEY,
    FOCUS_TOKEN_PUBLIC_KEY,
  ];

  const { data: pinnedAccounts } = useQuery(DaoCoinStatsDocument, {
    variables: {
      filter: {
        coinPublicKey: {
          inInsensitive: PINNED_PROJECTS,
        },
      },
    },
  });
  const { data: topAccounts } = useQuery(DaoCoinStatsDocument, {
    variables: {
      filter: {
        coinPublicKey: {
          notInInsensitive: PINNED_PROJECTS,
        },
      },
      orderBy: DaoCoinStatsOrderBy.VolumeUsdPast_24HDesc,
      first: 30,
    },
  });

  const pinnedAccountStats = [...((pinnedAccounts?.daoCoinStats?.nodes as DaoCoinStat[]) || [])].sort((a, b) => {
    return PINNED_PROJECTS.indexOf(a.coinPublicKey) - PINNED_PROJECTS.indexOf(b.coinPublicKey);
  });
  const topAccountStats = topAccounts?.daoCoinStats?.nodes as DaoCoinStat[];

  return (
    <div className="flex items-center gap-4 border-b lg:border-b-0 w-full lg:w-auto lg:border-r p-4 lg:pr-6">
      <div className="w-auto flex flex-col gap-1">
        <div className="text-muted text-xs">Select Token</div>
        <BaseCurrencySelect
          selectedAccount={daoCoinStat ?? null}
          customLink={(username: string) => {
            const selectedWrappedAsset = getWrappedAsset(username);
            return `/trade/${!!selectedWrappedAsset ? selectedWrappedAsset?.displayName : username}`;
          }}
          pinnedAccounts={[...(pinnedAccountStats || []), ...(topAccountStats || [])]}
          onSelect={(account) => {
            if (account?.coinUsername) {
              navigate(Routes.tradeToken(account.coinUsername));
            }
          }}
        />
      </div>
      <div className="text-muted text-xs relative top-[8px]">/</div>
      <div className="w-auto flex flex-col gap-1">
        <div className="text-muted text-xs">Quote Currency</div>
        {isToggleReady ? (
          <>
            <SelectNew
              value={quoteCurrencyPublicKey}
              onValueChange={(value) => {
                setQuoteCurrencyPublicKey(value);
              }}
            >
              <SelectTrigger className="rounded-full">
                <SelectValue placeholder="Select a base pair" />
              </SelectTrigger>
              <SelectContent>
                {AVAILABLE_QUOTE_CURRENCIES.map(({ publicKey, displayName }) => (
                  <SelectItem
                    key={publicKey}
                    value={publicKey}
                    disabled={
                      publicKey === selectedProjectPublicKey ||
                      (publicKey === FOCUS_TOKEN_PUBLIC_KEY && selectedProjectPublicKey === DESO_ZERO_PUBLIC_KEY)
                    }
                    className="cursor-pointer"
                  >
                    {displayName}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectNew>
          </>
        ) : (
          <div className="flex items-center h-[38px]">
            <Spinner size={32} />
          </div>
        )}
      </div>
    </div>
  );
};

interface DexMetricProps {
  label: string;
  value: string | ReactNode;
  trending?: 'up' | 'down' | null;
}

const DexMetric: React.FC<DexMetricProps> = ({ label, value, trending }) => {
  return (
    <div className="flex items-start flex-col gap-1">
      <div className="text-muted text-xs">{label}</div>
      <div className="flex items-center gap-2 font-mono">
        {trending ? (
          <div className={cn(`text-muted-foreground`, trending === 'up' ? 'text-green-600' : 'text-red-600')}>
            {value}
          </div>
        ) : (
          <div className="text-muted-foreground">{value}</div>
        )}
      </div>
    </div>
  );
};

export default Trade;
