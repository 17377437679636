import { ProfilePicEditor } from 'components/app-ui/ProfilePicEditor';
import { UsernameInput, UsernameInputState } from 'components/app-ui/UsernameInput';
import { ExternalLink } from 'components/core/ExternalLink';
import { Heading } from 'components/core/Heading';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { useSettingsContext } from 'components/pages/Settings';
import { Button } from 'components/shadcn/ui/button';
import { Label } from 'components/shadcn/ui/label';
import { Textarea } from 'components/shadcn/ui/textarea';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { getExchangeRates, identity } from 'deso-protocol';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { useContext, useEffect, useRef, useState } from 'react';
import { IoOpenOutline } from 'react-icons/io5';
import { deso, openfund } from 'services';
import { checkFormValidity } from 'utils/checkFormValidity';
import { desoToUSD, formatUSD } from 'utils/currency';
import { getErrorMsg } from 'utils/getErrorMsg';
import { GetExchangeRateUpdatedResponse } from '../../../services/Deso';

const DESO_COST_TO_CREATE_PROFILE = 0.001;

interface FormState {
  username: string;
  profilePicUrl: string;
  description: string;
  email: string;
}

export function Profile() {
  const { setHeadingSuffix } = useSettingsContext();
  useDocumentTitle(`Edit my profile settings`);
  useEffectOnce(() => setHeadingSuffix('My Profile'));
  const { currentUser } = useSettingsContext();
  const { setCurrentUser } = useContext(OpenfundContext);
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const isMounted = useIsMounted();
  const [xRates, setXRates] = useState<GetExchangeRateUpdatedResponse>();
  const [formState, setFormState] = useState<FormState>({
    profilePicUrl: currentUser.profilePicDataUrl ?? deso.profilePicUrl(currentUser.PublicKeyBase58Check),
    username: currentUser.ProfileEntryResponse?.Username ?? '',
    description: currentUser.ProfileEntryResponse?.Description ?? '',
    email: currentUser.appUser?.Email ?? '',
  });
  const derivedKey = useRef('');

  useEffectOnce(() => {
    openfund
      .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.PROFILE)
      .then(({ DerivedPublicKeyBase58Check }) => {
        derivedKey.current = DerivedPublicKeyBase58Check;
      });
  });

  useEffect(() => {
    if (!currentUser.CanCreateProfile) {
      getExchangeRates().then((res) => {
        if (isMounted.current) {
          setXRates(res);
        }
      });
      return;
    }

    setFormState((prevState) => ({
      ...prevState,
      profilePicUrl: currentUser.profilePicDataUrl ?? deso.profilePicUrl(currentUser.PublicKeyBase58Check),
      username: currentUser.ProfileEntryResponse?.Username ?? '',
      description: currentUser.ProfileEntryResponse?.Description ?? '',
      email: currentUser.appUser?.Email ?? '',
    }));
  }, [currentUser]);

  return (
    <div>
      {!currentUser.ProfileEntryResponse && !currentUser.CanCreateProfile ? (
        <div className="text-center">
          <Heading level={2} className="pb-4">
            Create a profile
          </Heading>
          <div className="text-center">
            <Text size="md" className="mb-2">
              Creating a profile costs
              <span className="font-bold">
                &nbsp;{DESO_COST_TO_CREATE_PROFILE} DESO (≈{' '}
                {xRates ? formatUSD(desoToUSD(DESO_COST_TO_CREATE_PROFILE, xRates.USDCentsPerDeSoCoinbase)) : '$0.03'}{' '}
                USD)
              </span>
            </Text>
            <Text className="mb-4">
              Your account doesn't have enough DeSo to create a profile. Get enough starter DESO to create your account
              and more by verifying your phone number.
            </Text>
            <Text className="mb-4">...or buy DESO to get started</Text>
            <div className="flex justify-center flex-row-reverse gap-2">
              <ExternalLink href="https://buy.deso.org" target="_blank">
                Buy DESO <IoOpenOutline className="ml-2" />
              </ExternalLink>
              <Button
                variant={'outline'}
                onClick={async () => {
                  try {
                    await identity.verifyPhoneNumber();
                    setCurrentUser(await openfund.reloadCurrentUserData());
                  } catch (e) {
                    toast.show({ message: getErrorMsg(e), type: 'error' });
                  }
                }}
              >
                Verify phone number
              </Button>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2 mb-8">
            <div className="text-xl font-semibold font-sans text-muted-foreground">
              {currentUser.ProfileEntryResponse ? 'Edit profile' : 'Create profile'}
            </div>
            <div className="text-muted text-sm">* Indicates required fields</div>
          </div>

          <form
            ref={formRef}
            onSubmit={async (ev) => {
              ev.preventDefault();
              if (checkFormValidity(formRef.current) && formState.username.length > 0) {
                try {
                  await openfund.updateUserProfile(
                    {
                      Username: formState.username,
                      Email: formState.email,
                      Description: formState.description,
                      ProfilePic: formState.profilePicUrl.startsWith('data:') ? formState.profilePicUrl : '',
                      DisplayName: currentUser?.ProfileEntryResponse?.ExtraData?.DisplayName ?? '',
                      FeaturedImageURL: currentUser?.ProfileEntryResponse?.ExtraData?.FeaturedImageURL ?? '',
                      WebsiteURL: currentUser?.ProfileEntryResponse?.ExtraData?.WebsiteURL ?? '',
                      TwitterURL: currentUser?.ProfileEntryResponse?.ExtraData?.TwitterURL ?? '',
                      DiscordURL: currentUser?.ProfileEntryResponse?.ExtraData?.DiscordURL ?? '',
                      MarkdownDescription: currentUser?.ProfileEntryResponse?.ExtraData?.MarkdownDescription ?? '',
                      EnableDaoNFTGallery: currentUser?.appUser?.EnableDaoNFTGallery ?? true,
                    },
                    () => {
                      setIsLoading(true);
                    },
                    derivedKey.current,
                  );
                  const authUser = await openfund.reloadCurrentUserData();
                  if (authUser) {
                    authUser.profilePicDataUrl = formState.profilePicUrl;
                  }
                  setCurrentUser(authUser);
                  toast.show({ message: 'Profile updated successfully', type: 'success', sticky: false });
                } catch (e) {
                  toast.show({
                    message: getErrorMsg(e),
                    type: 'error',
                  });
                }
                setIsLoading(false);
              }
            }}
          >
            <div className="flex flex-col pb-6 mt-4 w-full text-left">
              <div className="mb-2 text-sm">Profile Photo</div>
              <ProfilePicEditor
                profilePicUrl={formState.profilePicUrl}
                handleImageUrl={(url) => {
                  setFormState({ ...formState, profilePicUrl: url });
                }}
              />
            </div>
            <div className="flex flex-col text-left pb-6 mb-2 w-full">
              <UsernameInput
                placeholder={currentUser.isProjectOwner ? 'myAwesomeProject' : ''}
                initialValue={formState.username ?? ''}
                onInputStateChange={({ state, value }) => {
                  if (!formRef.current) return;
                  const isSettableState = state === 'success' || state === 'warning';
                  setIsFormValid(checkFormValidity(formRef.current) && isSettableState);
                  if (isSettableState) {
                    setFormState({ ...formState, username: value.trim() });
                  }
                }}
              />
              <p className="text-muted text-xs mt-2">
                Your username is the same across all DeSo apps. For tokens, it is also used for the ticker. This makes
                sure your username or ticker is always unique and can't be duplicated by anyone else.
              </p>
            </div>
            <div className="flex flex-col text-left pb-6 mb-2 w-full">
              <Label htmlFor="pitch" className="text-sm font-medium mb-2">
                Profile Bio
              </Label>
              <Textarea
                name="pitch"
                placeholder="Introduce yourself in a few words."
                maxLength={180}
                rows={3}
                value={formState.description ?? ''}
                onInput={(ev) => {
                  setFormState({ ...formState, description: ev.currentTarget.value });
                }}
              />
            </div>
            {/* <div className="flex flex-col pb-6 mb-2 w-full text-left">
              <Input
                name="email"
                type="email"
                required={true}
                labelText="Email Address"
                placeholder="you@example.com"
                className="w-full"
                value={formState.email}
                onInput={(ev) => {
                  if (!formRef.current) return;
                  setIsValidEmail(true);
                  const isEmailValid = ev.currentTarget.checkValidity();
                  debounce(() => setIsFormValid(isEmailValid && usernameInputState === 'success'), 300);
                  setFormState({ ...formState, email: ev.currentTarget.value.trim() });
                }}
                state={isValidEmail ? 'default' : 'error'}
                hint={isValidEmail ? '' : 'Please enter a valid email address'}
                onBlur={(ev) => setIsValidEmail(ev.currentTarget.checkValidity())}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    setIsValidEmail(ev.currentTarget.checkValidity());
                  }
                }}
              />
              <Text size="sm" color="secondary" className="mt-2 text-muted text-xs">
                We will never share your email with anyone. It will be stored securely off-chain.
              </Text>
            </div> */}
            <Button type="submit" disabled={!isFormValid}>
              Save Changes
            </Button>
          </form>
        </>
      )}
    </div>
  );
}

export default Profile;
