import { useEffect, useState } from 'react';
import { deso } from '../services';
import { GetDaoCoinMarketFeesResponse } from '../services/Deso';
import { DESO_TOKEN_PUBLIC_KEY } from '../constants/TradeConstants';
import { DESO_ZERO_PUBLIC_KEY } from '../constants/AppConstants';

export const useCreatorCoinFee = ({ publicKey, username }: { publicKey: string; username: string }) => {
  const [loadingDaoCoinMarketFees, setLoadingDaoCoinMarketFees] = useState(true);
  const [creatorCoinMarketFee, setCreatorCoinMarketFee] = useState<GetDaoCoinMarketFeesResponse | null>(null);

  if (!publicKey) {
    throw new Error('Account is required');
  }

  const feeBasisPointByPk = creatorCoinMarketFee?.FeeBasisPointsByPublicKey[publicKey];
  const founderRewardFeePercentage = feeBasisPointByPk ? `${feeBasisPointByPk / 100}%` : '';

  useEffect(() => {
    const fetchDaoCoinMarketFees = async () => {
      if (!publicKey) {
        return;
      }

      setLoadingDaoCoinMarketFees(true);

      try {
        const effectivePublicKey = publicKey === DESO_TOKEN_PUBLIC_KEY ? DESO_ZERO_PUBLIC_KEY : publicKey;
        const data = await deso.getDaoCoinMarketFees(effectivePublicKey);

        setCreatorCoinMarketFee(data);
      } finally {
        setLoadingDaoCoinMarketFees(false);
      }
    };

    fetchDaoCoinMarketFees();
  }, [publicKey, username]);

  return {
    loading: loadingDaoCoinMarketFees,
    founderRewardFeePercentage,
    creatorCoinMarketFee,
  };
};
