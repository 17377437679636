'use client';

import { useLazyQuery, useSubscription } from '@apollo/client';
import type { PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { LuRefreshCcw } from 'react-icons/lu';

import TablePagination from './TablePagination';

import { columns as tableColumns } from './ActivityTableColumns';
import { Button } from '../shadcn/ui/button';
import { Skeleton } from '../shadcn/ui/skeleton';
import GenericTable from './GenericTable';
import {
  activityTabs,
  ActivityTabSelection,
  ActivityTabTypes,
  COLUMNS_WIDTH,
  formatActivityTabFilters,
  getOrderByKey,
} from '../../utils/activityTable';
import {
  FilledOrderWithPriceAggSubscriptionDocument,
  PageInfo,
  TradingRecentTrade,
  TradingRecentTradeFilter,
  TradingRecentTradesDocument,
  TradingRecentTradesOrderBy,
} from '../../graphql/codegen/graphql';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import { getTopicForSinglePublicKey, TopicPrefix } from '../../utils/subscriptions';
import { cn } from 'utils/shadcn';

type SortingState = {
  id: TradingRecentTradesOrderBy;
  desc: boolean;
};

export const TradeActivityTableWrapper = ({
  tokenPublicKey,
  traderPublicKey,
  title = 'Recent Trades',
  defaultFilter = {},
}: {
  tokenPublicKey: string;
  traderPublicKey?: string;
  title?: string;
  subtitle?: string;
  defaultFilter?: TradingRecentTradeFilter;
}) => {
  const [activityCoins, setActivityCoins] = useState<TradingRecentTrade[]>([]);
  const [activeTab, setActiveTab] = useState<ActivityTabSelection>(activityTabs[0]);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [itemsPerPage] = useState(20);
  const [updatingDataAfterFiltering, setUpdatingDataAfterFiltering] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [columnSorting, setColumnSorting] = useState<SortingState[]>([
    {
      id: TradingRecentTradesOrderBy.TradeTimestampDesc,
      desc: true,
    },
  ]);
  const [rowsToAnimate, setRowsToAnimate] = useState<string[]>([]);
  const [loadingActivityCoins, setLoadingActivityCoins] = useState(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: itemsPerPage,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const [fetchActivityData, { data: activityData }] = useLazyQuery(TradingRecentTradesDocument, {
    fetchPolicy: 'network-only',
  });

  const columns = useMemo(() => tableColumns(), []);

  const refetchWithUpdatedParams = async (
    params: {
      first?: number;
      offset?: number;
    } = { first: itemsPerPage, offset: pageOffset },
    skipCurrentRowsSet = false,
  ) => {
    const shouldApplyTradeValueFilter = activeTab.type !== ActivityTabTypes.All;
    let tradeValueFilter = {};

    if (activeTab.type === ActivityTabTypes.Shrimp) {
      const activeFilters = formatActivityTabFilters(activeTab);

      tradeValueFilter = {
        greaterThanOrEqualTo: activeFilters.min,
        lessThanOrEqualTo: activeFilters.max,
      };
    } else if (shouldApplyTradeValueFilter) {
      const activeFilters = formatActivityTabFilters(activeTab);

      tradeValueFilter = {
        greaterThanOrEqualTo: activeFilters.min,
      };
    }

    const variables: {
      orderBy: TradingRecentTradesOrderBy;
      filter: TradingRecentTradeFilter;
    } = {
      orderBy: getOrderByKey(columnSorting, TradingRecentTradesOrderBy, TradingRecentTradesOrderBy.TradeTimestampDesc),
      filter: {
        ...defaultFilter,
        ...(tokenPublicKey ? { tokenPublicKey: { equalTo: tokenPublicKey } } : {}),
        ...(traderPublicKey ? { traderPublicKey: { equalTo: traderPublicKey } } : {}),
        ...(isEmpty(tradeValueFilter) ? {} : { tradeValueUsd: tradeValueFilter }),
      },
    };

    if (!skipCurrentRowsSet) {
      setLoadingActivityCoins(true);
    }

    const { data } = await fetchActivityData({
      variables: {
        ...variables,
        ...params,
      },
    });

    setPageInfo(data?.tradingRecentTrades?.pageInfo);
    const coins = (data?.tradingRecentTrades?.nodes.filter(Boolean) ?? []) as Array<TradingRecentTrade>;
    setActivityCoins(coins);
    setTotalCount(data?.tradingRecentTrades?.totalCount ?? 0);
    if (isFirstLoad || !skipCurrentRowsSet) {
      setRowsToAnimate([]);
      setIsFirstLoad(false);
    }

    setLoadingActivityCoins(false);

    return Promise.resolve(coins);
  };

  const onPaginate = async (nextPageIndex: number) => {
    const offset = nextPageIndex * itemsPerPage;

    setPagination({
      pageIndex: nextPageIndex,
      pageSize: itemsPerPage,
    });

    refetchWithUpdatedParams({
      first: itemsPerPage,
      offset,
    });

    setPageOffset(offset);
  };

  const topic = useMemo(() => {
    return getTopicForSinglePublicKey(TopicPrefix.FilledDaoCoinOrdersWithPricesAggs, tokenPublicKey);
  }, [tokenPublicKey]);

  useSubscription(FilledOrderWithPriceAggSubscriptionDocument, {
    variables: {
      topic,
    },
    onData: ({ data }) => {
      const listenLevel = data.data?.listen;
      if (!listenLevel) {
        return;
      }
      const relatedNode = listenLevel.relatedNode as any;
      if (!relatedNode) {
        return;
      }

      const rowIds = activityData?.tradingRecentTrades?.nodes.map((e) => e?.rowId) as string[];
      // New trade arrives, refetch data with current filters and sorting
      refetchWithUpdatedParams(
        {
          first: itemsPerPage,
          offset: pageOffset,
        },
        true,
      ).then((coins) => {
        if (pagination.pageIndex > 0) {
          return;
        }
        const updatedCoinIds = coins?.map((coin) => coin.rowId) as string[];
        setRowsToAnimate(difference(updatedCoinIds, rowIds));

        setTimeout(() => {
          setRowsToAnimate([]);
        }, 500);
      });
    },
  });

  const handleActiveTab = (tab: ActivityTabSelection) => {
    setActiveTab(tab);
    resetPagination();
    setUpdatingDataAfterFiltering(true);
  };

  const resetPagination = () => {
    setPagination({
      pageIndex: 0,
      pageSize: itemsPerPage,
    });
    setPageOffset(0);
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetchWithUpdatedParams();
    setRefreshing(false);
  };

  useEffect(() => {
    (async () => {
      if (updatingDataAfterFiltering) {
        await refetchWithUpdatedParams();
        setUpdatingDataAfterFiltering(false);
      }
    })();
  }, [updatingDataAfterFiltering]);

  useEffect(() => {
    refetchWithUpdatedParams();
  }, [tokenPublicKey]);

  useEffect(() => {
    resetPagination();
    setUpdatingDataAfterFiltering(true);
  }, [columnSorting]);

  return isFirstLoad ? (
    <div className="flex flex-col">
      <div className="flex w-full flex-col items-start justify-normal md:flex-row md:items-end md:justify-between">
        <Skeleton className="mb-4 h-[38px] w-full md:mb-0 md:w-[250px]" />
        <Skeleton className="h-[38px] w-full md:w-[535px]" />
      </div>
      <Skeleton className="mt-4 h-[620px] w-full" />
    </div>
  ) : (
    <div className="h-auto">
      <div className="flex flex-col md:mb-0 md:items-center justify-between md:flex-row">
        <div className="mb-4 md:mb-0">
          <h2 className="flex gap-2 items-center text-lg font-sans font-semibold text-muted-foreground">
            {title}
            <Button
              variant="link"
              className="flex items-center gap-2 py-0 text-muted underline-offset-2 hover:underline"
              size="sm"
              disabled={refreshing}
              onClick={handleRefresh}
            >
              <LuRefreshCcw /> Refresh
            </Button>
          </h2>
        </div>

        <div className="flex w-full flex-col justify-between gap-6 sm:flex-row sm:items-center sm:gap-2 md:w-auto md:justify-normal mb-4">
          <div className="flex min-w-[315px] gap-x-1.5">
            {activityTabs.map((tab) => (
              <Button
                key={tab.type}
                onClick={() => handleActiveTab(tab)}
                variant={activeTab.type === tab.type ? 'outlinePrimary' : 'outline'}
                disabled={loadingActivityCoins}
                size="lg"
                className="px-4 py-2"
              >
                {tab.content}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <GenericTable<TradingRecentTrade>
        loading={loadingActivityCoins || updatingDataAfterFiltering}
        columns={columns}
        data={activityCoins ?? []}
        columnSorting={columnSorting}
        setColumnSorting={(sorting) => setColumnSorting(sorting as SortingState[])}
        wrapperClassName={!activityCoins.length ? 'min-w-full' : 'min-w-[1024px]'}
        pagination={pagination}
        pageOffset={pageOffset}
        columnsWidth={COLUMNS_WIDTH}
        refetchData={refetchWithUpdatedParams}
        getRowClassName={(row) => {
          return cn(
            'transition-background duration-500 ease-linear',
            rowsToAnimate?.includes(row.rowId) &&
              'bg-yellow-300/30 dark:bg-gray-800/70 radar-light:bg-red-700/30 radar-dark:bg-green-700/30 price-flash',
          );
        }}
      />

      <TablePagination
        loading={loadingActivityCoins || updatingDataAfterFiltering}
        pagination={pagination}
        pageInfo={pageInfo}
        onPaginate={onPaginate}
        itemsPerPage={itemsPerPage}
        pageOffset={pageOffset}
        totalCount={totalCount}
      />
    </div>
  );
};
