import { ReactNode } from 'react';

export function formatPercentChangeString(value: number): ReactNode {
  if (value > 0) {
    return <span className="text-green">&#9650;{value}%</span>;
  }
  if (value < 0) {
    return <span className="text-red">&#9660;{-value}%</span>;
  }
  return <span>-</span>;
}
