import { useEffect, useState } from 'react';
import { PreviewRowItem } from './CoinInfoWidget';
import { DESO_PROJECT_NAME } from '../../constants/TradeConstants';
import { APP_NAME_GENERIC } from '../../utils/constants';
import { CopyToClipboard } from './CopyToClipboard';
import { DESO_ZERO_PUBLIC_KEY } from '../../constants/AppConstants';
import { formatDecimalValue, formatUSD } from '../../utils/currency';
import LowNumFormatter from './LowNumFormatter';
import { OpenfundUser } from '../../services/Openfund';
import { Skeleton } from '../shadcn/ui/skeleton';
import { deso } from 'services';
import { desoBigIntToFloat } from 'utils/orderbook';

export function DesoTokenOverviewWidget({
  marketCapUsd,
  currentUser,
  loading,
}: {
  marketCapUsd: number;
  currentUser: OpenfundUser | null;
  loading: boolean;
}) {
  const [totalSupply, setTotalSupply] = useState<number | null>(null);
  const [loadingTotalSupply, setLoadingTotalSupply] = useState(true);

  useEffect(() => {
    deso.getTotalSupply().then((supply) => {
      setTotalSupply(supply);
      setLoadingTotalSupply(false);
    });
  }, []);

  if (loading || loadingTotalSupply) {
    return <Skeleton className="h-[285px]" />;
  }

  return (
    <div className="grid w-full items-start rounded-xl border bg-accent">
      <div className="flex flex-col items-start text-left text-xs">
        <PreviewRowItem label="Name" value={DESO_PROJECT_NAME} />
        <PreviewRowItem
          label="Address"
          value={<CopyToClipboard text={DESO_ZERO_PUBLIC_KEY} width={6} />}
          footerText={
            <footer className="text-xs w-full text-right text-muted pt-2">
              The $DESO address is the zero public key
            </footer>
          }
        />
        <PreviewRowItem
          label="Market Cap"
          value={formatUSD(marketCapUsd || 0)}
          tooltipText={`Market Caps shown in ${APP_NAME_GENERIC} are computed by multiplying the total number of tokens by the price.`}
        />
        <PreviewRowItem
          label="Total Supply"
          value={formatDecimalValue(totalSupply || 0)}
          tooltipText="The total supply of DESO tokens."
        />
        {currentUser && (
          <PreviewRowItem
            label="Your Balance"
            value={
              <LowNumFormatter
                price={desoBigIntToFloat(BigInt(currentUser.BalanceNanos ?? 0))}
                highPriceThreshold={10}
                isUsd={false}
                className="text-xs"
              />
            }
          />
        )}
      </div>
    </div>
  );
}
