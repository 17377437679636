import { CurrencyInputWithTickerToggle } from 'components/app-ui/CurrencyInputWithTickerToggle';
import { DepositEventsList } from 'components/app-ui/DepositEventsList';
import { FullPageError } from 'components/app-ui/FullPageError';
import { NumberInputWithMaxToggle } from 'components/app-ui/NumberInputWithMaxToggle';
import { Input } from 'components/core/Input';
import { Select } from 'components/core/Select';
import { Text } from 'components/core/Text';
import { Button } from 'components/shadcn/ui/button';
import {
  DESO_BTC_PUBLIC_KEY,
  DESO_DOLLAR_PROFILE_NAME,
  DESO_ETH_PUBLIC_KEY,
  DESO_SOL_PUBLIC_KEY,
  INVESTMENT_NETWORK_FEE_NANOS_BUFFER,
} from 'constants/AppConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import { deso, heroswap } from 'services';
import { DepositAddressGroup, DepositEvent, SupportedDestinationTickers } from 'services/HeroSwapper';
import {
  desoNanosToDeso,
  desoToDesoNanos,
  desoToUSD,
  formatDecimalValue,
  parseFloatWithCommas,
  toHex,
  tokenToBaseUnits,
  usdCentsToUSD,
  usdToDeso,
  usdToUSDCents,
} from 'utils/currency';
import { debounce } from 'utils/debounce';
import { getErrorMsg } from 'utils/getErrorMsg';
import { quantityBigIntToFloat } from 'utils/orderbook';
import { getWrappedAsset } from '../../utils/deso';
import { formatTickerWithTooltip, Ticker, wrappedAssetTickerToPublicKey } from '../../utils/tickers';
import { CurrencyNetworkAlert } from '../core/CurrencyNetworkAlert';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../shadcn/ui/dialog';
import LowNumFormatter from './LowNumFormatter';
import { RouteLink } from '../core/RouteLink';
import { Skeleton } from '../shadcn/ui/skeleton';

const DEFAULT_DESTINATION_TICKER = 'USDC';
const INITIAL_CASH_OUT_STATE = {
  amountToCashOut: '0',
  estimatedReceivingAmount: '0',
  txFee: '0',
  cashOutTicker: 'DUSD',
};
const LAST_USED_CASH_OUT_ADDRESS_STORAGE_KEY = 'lastUsedCashOutAddress';
const formatDesoValue = (desoValue: string | number, usdCentsPerDeso?: number) => {
  return (
    <>
      {usdCentsPerDeso && <LowNumFormatter price={desoToUSD(desoValue, usdCentsPerDeso)} />} (
      <LowNumFormatter price={parseFloatWithCommas(desoValue.toString())} isUsd={false} /> $DESO)`
    </>
  );
};

interface CashOutFormProps {
  defaultCashOutTicker: SupportedDestinationTickers;
  onTransferFailed?: (error: any) => void;
  destinationTicker?: SupportedDestinationTickers;
  amount?: number;
  onRefresh: (updatedValue: number) => void;
}
export function CashOutForm({
  defaultCashOutTicker,
  onTransferFailed,
  destinationTicker = DEFAULT_DESTINATION_TICKER,
  amount,
  onRefresh,
}: CashOutFormProps) {
  // we have input amount separately to have it controlled and be able to reset it
  const [inputAmount, setInputAmount] = useState('0');
  const [cashOutState, setCashOutState] = useState<{
    amountToCashOut: string;
    estimatedReceivingAmount: string;
    txFee: string;
    cashOutTicker: SupportedDestinationTickers;
  }>({
    ...INITIAL_CASH_OUT_STATE,
    cashOutTicker: defaultCashOutTicker,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getLastUsedCashoutLocalStorageKey = () => {
    if (destinationTicker === 'USDC') {
      return `${LAST_USED_CASH_OUT_ADDRESS_STORAGE_KEY}`;
    }
    return `${LAST_USED_CASH_OUT_ADDRESS_STORAGE_KEY}-${destinationTicker}`;
  };
  const [megaSwapAddressGroup, setMegaSwapAddressGroup] = useState<DepositAddressGroup | null>(null);
  const { currentUser, loadingUser } = useContext(OpenfundContext);
  const [usdCentsPerCashOutTickerUnit, setUSDCentsPerCashOutTickerUnit] = useState<number | undefined>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>();
  const [destinationAddress, setDestinationAddress] = useState(
    window.localStorage.getItem(getLastUsedCashoutLocalStorageKey()) ?? '',
  );
  const [depositAddressError, setDepositAddressError] = useState<any>();
  const [depositEvents, setDepositEvents] = useState<DepositEvent[]>([]);
  const [errorMessage, setErrorMessage] = useState<null | ReactNode>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const rawUSDBalance = currentUser?.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0';
  const wrappedAsset = getWrappedAsset(defaultCashOutTicker, 'heroswapName');
  const isWrapped = !!wrappedAsset;
  const [maxAvailableToCashOut, setMaxAvailableToCashOut] = useState<number>(0);

  const getMaxAvailableToCashout = (newAmount?: number): Promise<number> => {
    if (!currentUser) {
      return Promise.resolve(0);
    }
    if (newAmount) {
      return Promise.resolve(newAmount);
    }
    if (newAmount || cashOutState.cashOutTicker === 'DUSD') {
      const value = quantityBigIntToFloat(BigInt(rawUSDBalance));
      return Promise.resolve(value);
    }
    if (cashOutState.cashOutTicker === 'DESO') {
      const value = desoNanosToDeso(
        currentUser?.BalanceNanos && currentUser?.BalanceNanos > INVESTMENT_NETWORK_FEE_NANOS_BUFFER
          ? currentUser.BalanceNanos - INVESTMENT_NETWORK_FEE_NANOS_BUFFER
          : 0,
      );
      return Promise.resolve(value);
    }
    return deso
      .getProjectHolding(
        currentUser.PublicKeyBase58Check,
        wrappedAssetTickerToPublicKey(cashOutState.cashOutTicker as Ticker),
      )
      .then((balanceEntry) => {
        const value = balanceEntry ? quantityBigIntToFloat(BigInt(balanceEntry.BalanceNanosUint256.toString())) : 0;
        return value;
      });
  };

  useEffect(() => {
    getMaxAvailableToCashout(amount).then((value) => {
      setMaxAvailableToCashOut(value);
    });
  }, [cashOutState.cashOutTicker, amount, currentUser, rawUSDBalance]);

  const previouslyStoredAddress = window.localStorage.getItem(getLastUsedCashoutLocalStorageKey());
  const formatBalance = (value: string | number) => {
    if (isWrapped) {
      return <LowNumFormatter price={parseFloatWithCommas(value.toString())} maxZeroDigits={4} isUsd={false} />;
    }

    return <LowNumFormatter price={parseFloatWithCommas(value.toString())} />;
  };

  useEffect(() => {
    heroswap
      .calcCurrencySwapAmount(cashOutState.cashOutTicker, destinationTicker, isWrapped ? '10' : '1')
      .then((res) => {
        setUSDCentsPerCashOutTickerUnit(usdToUSDCents(res.SwapRateDestinationTickerPerDepositTicker));
        setCashOutState((prev) => ({
          ...prev,
          txFee: res.DepositFeeDeducted,
        }));
      })
      .catch((e) => {
        setLoadingError(e);
      })
      .finally(() => {
        setLoading(false);
      });

    if (destinationAddress) {
      heroswap
        .getOrCreateCashOutAddressGroup(destinationAddress, destinationTicker)
        .then((group) => {
          setMegaSwapAddressGroup(group);

          heroswap
            .getRecentDepositEventsForDepositAddress(
              cashOutState.cashOutTicker,
              group.DepositAddresses[cashOutState.cashOutTicker],
            )
            .then((res) => {
              setDepositEvents(res);
            });
        })
        .catch((e) => {
          setDepositAddressError(e);
        });
    }
  }, [cashOutState.cashOutTicker, destinationAddress, destinationTicker, isWrapped]);

  const refreshDepositEvents = async () => {
    const depositAddress = megaSwapAddressGroup?.DepositAddresses[cashOutState.cashOutTicker];
    if (!depositAddress) {
      setErrorMessage(
        `There was an unexpected network error. Please reload the page or try again in a few minutes. ${getErrorMsg(
          depositAddressError,
        )}`,
      );

      return;
    }
    if (isRefreshing) {
      return;
    }
    setIsRefreshing(true);
    try {
      await heroswap.pollNewDepositOnce(depositAddress, cashOutState.cashOutTicker);
      await heroswap.getRecentDepositEventsForDepositAddress(cashOutState.cashOutTicker, depositAddress).then((res) => {
        setDepositEvents(res);
      });
    } catch (e) {
      setErrorMessage(
        `There was an unexpected network error. Please reload the page or try again in a few minutes. ${getErrorMsg(
          e,
        )}`,
      );
    }
    setIsRefreshing(false);
  };

  if (loading || loadingUser || !currentUser) {
    return (
      <div className="flex flex-col gap-4">
        <Skeleton className="h-[50px] w-full" />
        <Skeleton className="h-[120px] w-full" />
        <Skeleton className="h-[50px] w-full" />
        <Skeleton className="h-[120px] w-full" />
      </div>
    );
  }

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  if (cashOutState.cashOutTicker === 'DESO' && typeof usdCentsPerCashOutTickerUnit === 'undefined') {
    return <FullPageError error={loadingError} />;
  }

  const displayTickerName = isWrapped ? wrappedAsset?.heroswapDisplayNameExtended : cashOutState.cashOutTicker;

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();

        if (isProcessing) {
          return;
        }

        if (Number(cashOutState.estimatedReceivingAmount) <= 0) {
          setErrorMessage("Unable to cash out. You'll receive $0.00 after network fees.");
          return;
        }

        if (Number(cashOutState.amountToCashOut) <= 0) {
          setErrorMessage('Please enter an amount to cash out.');
          return;
        }

        if (Number(cashOutState.amountToCashOut) > maxAvailableToCashOut) {
          if (cashOutState.cashOutTicker === 'DESO') {
            setErrorMessage(
              <>
                {formatDesoValue(cashOutState.amountToCashOut, usdCentsPerCashOutTickerUnit)} exceeds your available
                balance of {formatDesoValue(maxAvailableToCashOut, usdCentsPerCashOutTickerUnit)}
              </>,
            );
          } else {
            setErrorMessage(
              <>
                {formatBalance(cashOutState.amountToCashOut)} exceeds your available balance of $
                {formatBalance(maxAvailableToCashOut)}
              </>,
            );
          }
          return;
        }
        const depositAddress = megaSwapAddressGroup?.DepositAddresses[cashOutState.cashOutTicker];
        if (!depositAddress) {
          setErrorMessage(
            `There was an unexpected network error. Please reload the page or try again in a few minutes. ${getErrorMsg(
              depositAddressError,
            )}`,
          );

          return;
        }

        // clear any validation errors if we made it this far
        setErrorMessage('');
        setIsProcessing(true);
        window.localStorage.setItem(getLastUsedCashoutLocalStorageKey(), destinationAddress);

        try {
          if (!depositAddress) {
            throw new Error('Can not cash out with a missing deposit address');
          }

          switch (cashOutState.cashOutTicker) {
            case 'DESO':
              await deso.sendDeso(
                currentUser.PublicKeyBase58Check,
                depositAddress,
                desoToDesoNanos(Number(cashOutState.amountToCashOut)),
              );
              break;
            case 'DBTC':
              await deso.transferTokens(
                DESO_BTC_PUBLIC_KEY,
                depositAddress,
                toHex(tokenToBaseUnits(cashOutState.amountToCashOut)),
              );
              break;
            case 'DSOL':
              await deso.transferTokens(
                DESO_SOL_PUBLIC_KEY,
                depositAddress,
                toHex(tokenToBaseUnits(cashOutState.amountToCashOut)),
              );
              break;
            case 'DETH':
              await deso.transferTokens(
                DESO_ETH_PUBLIC_KEY,
                depositAddress,
                toHex(tokenToBaseUnits(cashOutState.amountToCashOut)),
              );
              break;
            case 'DUSD':
              await deso.transferTokens(
                DESO_DOLLAR_PROFILE_NAME,
                depositAddress,
                cashOutState.amountToCashOut === maxAvailableToCashOut.toString()
                  ? rawUSDBalance
                  : toHex(tokenToBaseUnits(cashOutState.amountToCashOut)),
              );
              break;
          }
          await heroswap.pollNewDepositOnce(depositAddress, cashOutState.cashOutTicker);
          await heroswap
            .getRecentDepositEventsForDepositAddress(cashOutState.cashOutTicker, depositAddress)
            .then((res) => {
              setDepositEvents(res);
            });

          setInputAmount('0');
          setCashOutState((prevState) => ({
            ...prevState,
            amountToCashOut: '0',
          }));

          const updatedValue = await getMaxAvailableToCashout();
          setMaxAvailableToCashOut(updatedValue);

          onRefresh(updatedValue);

          setIsDialogOpen(true);
        } catch (e) {
          onTransferFailed?.(e);
          if (!megaSwapAddressGroup) {
            setErrorMessage(
              `There was an unexpected network error. Please reload the page or try again in a few minutes. ${getErrorMsg(
                e,
              )}`,
            );
            return;
          }
        }
        setIsProcessing(false);
        setErrorMessage('');
      }}
    >
      {!isWrapped && (
        <div className="mb-4">
          <label htmlFor="cash-out-ticker-select">Currency to withdraw: </label>
          <Select
            className="py-0.5 ml-2"
            id="cash-out-ticker-select"
            value={cashOutState.cashOutTicker}
            onChange={(e) => {
              setErrorMessage('');
              setInputAmount('0');
              setCashOutState({
                ...INITIAL_CASH_OUT_STATE,
                cashOutTicker: e.currentTarget.value as 'DESO' | 'DUSD',
              });
            }}
          >
            <option value="DESO">DESO</option>
            <option value="DUSD">USDC</option>
          </Select>
        </div>
      )}

      <div className="mb-4">
        {cashOutState.cashOutTicker === 'DESO' && usdCentsPerCashOutTickerUnit && (
          <CurrencyInputWithTickerToggle
            labelText="Amount to Cash Out ($DESO)"
            value={inputAmount}
            defaultTicker="USD"
            alternateTicker="DESO"
            onStateChange={async (value, ticker) => {
              setInputAmount(value);
              const amountDesoToSwap =
                ticker === 'USD' ? usdToDeso(value, usdCentsPerCashOutTickerUnit).toString() : value;
              if (Number(amountDesoToSwap) > maxAvailableToCashOut) {
                setErrorMessage(
                  <>
                    {formatDesoValue(amountDesoToSwap, usdCentsPerCashOutTickerUnit)} exceeds your available balance of{' '}
                    {formatDesoValue(maxAvailableToCashOut, usdCentsPerCashOutTickerUnit)}
                  </>,
                );
              } else {
                setErrorMessage('');
              }
              try {
                const res = await heroswap.calcCurrencySwapAmount(
                  cashOutState.cashOutTicker,
                  destinationTicker,
                  amountDesoToSwap,
                );

                setCashOutState({
                  ...cashOutState,
                  amountToCashOut: amountDesoToSwap,
                  estimatedReceivingAmount: res.DestinationAmount,
                  txFee: res.DepositFeeDeducted,
                });
              } catch (e) {
                setErrorMessage(getErrorMsg(e));
              }
            }}
            onMax={(selectedTicker) => {
              setInputAmount(
                selectedTicker === 'USD'
                  ? desoToUSD(maxAvailableToCashOut, usdCentsPerCashOutTickerUnit).toString()
                  : maxAvailableToCashOut.toString(),
              );
              setErrorMessage('');
              heroswap
                .calcCurrencySwapAmount(cashOutState.cashOutTicker, destinationTicker, maxAvailableToCashOut.toString())
                .then((res) => {
                  setCashOutState({
                    ...cashOutState,
                    amountToCashOut: maxAvailableToCashOut.toString(),
                    estimatedReceivingAmount: res.DestinationAmount,
                    txFee: res.DepositFeeDeducted,
                  });
                  if (Number(res.DestinationAmount) <= 0) {
                    setErrorMessage("Unable to cash out. You'll receive $0.00 after network fees.");
                    return;
                  }
                });

              switch (selectedTicker) {
                case 'DESO':
                  return maxAvailableToCashOut;
                case 'USD':
                  return desoToUSD(maxAvailableToCashOut, usdCentsPerCashOutTickerUnit);
                default:
                  return 0;
              }
            }}
          />
        )}
        {cashOutState.cashOutTicker !== 'DESO' && (
          <NumberInputWithMaxToggle
            prefix={cashOutState.cashOutTicker === 'DUSD' ? '$' : ''}
            labelText={
              <div className="flex items-center gap-1">
                {formatTickerWithTooltip(cashOutState.cashOutTicker, 16, 'ml-0')} <span>amount to cash out</span>
              </div>
            }
            value={inputAmount}
            allowedDecimalPlaces={10}
            onValueChange={async (value) => {
              setInputAmount(value);
              if (Number(value) > maxAvailableToCashOut) {
                setErrorMessage(
                  <>
                    {formatBalance(value)} exceeds your available balance of {formatBalance(maxAvailableToCashOut)}
                  </>,
                );
              } else {
                setErrorMessage('');
              }
              const res = await heroswap.calcCurrencySwapAmount(cashOutState.cashOutTicker, destinationTicker, value);
              setCashOutState({
                ...cashOutState,
                amountToCashOut: value,
                estimatedReceivingAmount: res.DestinationAmount,
                txFee: res.DepositFeeDeducted,
              });
            }}
            onMax={() => {
              setInputAmount(maxAvailableToCashOut.toString());
              setErrorMessage('');
              heroswap
                .calcCurrencySwapAmount(cashOutState.cashOutTicker, destinationTicker, maxAvailableToCashOut.toString())
                .then((res) => {
                  setCashOutState({
                    ...cashOutState,
                    amountToCashOut: maxAvailableToCashOut.toString(),
                    estimatedReceivingAmount: res.DestinationAmount,
                    txFee: res.DepositFeeDeducted,
                  });
                  if (Number(res.DestinationAmount) <= 0) {
                    setErrorMessage("Unable to cash out. You'll receive $0.00 after network fees.");
                    return;
                  }
                });

              return formatDecimalValue(maxAvailableToCashOut, 9);
            }}
          />
        )}
      </div>

      <Input
        containerClasses="mb-4"
        labelText={`${destinationTicker} Withdrawal address`}
        spellCheck={false}
        autoComplete="off"
        hint={
          depositAddressError
            ? getErrorMsg(depositAddressError)
            : previouslyStoredAddress && destinationAddress === previouslyStoredAddress
              ? 'Prefilled with the last address you used'
              : 'This is your deposit address on an exchange like Coinbase or wallet.'
        }
        state={depositAddressError ? 'error' : 'default'}
        value={destinationAddress}
        onInput={async (ev) => {
          setDestinationAddress(ev.currentTarget.value);
          debounce(
            async (inputAddress: string) => {
              try {
                setMegaSwapAddressGroup(
                  !inputAddress.trim()
                    ? null
                    : await heroswap.getOrCreateCashOutAddressGroup(inputAddress, destinationTicker),
                );
                setDepositAddressError(null);
              } catch (e) {
                setMegaSwapAddressGroup(null);
                setDepositAddressError(e);
              }
            },
            300,
            ev.currentTarget.value,
          );
        }}
      />

      <CurrencyNetworkAlert ticker={destinationTicker} alertType="receive" />

      <div className="border border-border rounded-2xl text-sm bg-accent">
        <dl className="px-4 py-2 flex justify-between border-b border-border-light">
          <dt className="text-muted">Available Balance</dt>
          <dd className="font-mono text-muted-foreground">
            {cashOutState.cashOutTicker === 'DESO' ? (
              <>{formatDesoValue(maxAvailableToCashOut, usdCentsPerCashOutTickerUnit)}</>
            ) : (
              <>
                {formatBalance(maxAvailableToCashOut)} {displayTickerName}
              </>
            )}
          </dd>
        </dl>

        {!!usdCentsPerCashOutTickerUnit && (
          <dl className="px-4 py-2 flex justify-between border-b border-border-light">
            <dt className="text-muted">Conversion Rate</dt>
            <dd className="font-mono text-muted-foreground">
              {formatBalance(
                cashOutState.cashOutTicker === 'DESO'
                  ? desoToUSD(1, usdCentsPerCashOutTickerUnit)
                  : usdCentsToUSD(usdCentsPerCashOutTickerUnit),
              )}{' '}
              ${destinationTicker} per ${displayTickerName}
            </dd>
          </dl>
        )}

        <div className="px-4 py-2">
          <dl className="flex justify-between">
            <dt className="text-muted">Amount of ${destinationTicker} You'll Receive</dt>
            <dd className="font-mono text-muted-foreground">
              {formatBalance(
                Number(cashOutState.amountToCashOut) > maxAvailableToCashOut
                  ? 0
                  : cashOutState.estimatedReceivingAmount,
              )}{' '}
              ${destinationTicker}
            </dd>
          </dl>
          <div className="text-muted text-xs mt-1">
            Includes a $
            {formatBalance(
              cashOutState.cashOutTicker === 'DESO'
                ? desoToUSD(cashOutState.txFee, usdCentsPerCashOutTickerUnit ?? 0)
                : cashOutState.txFee,
            )}{' '}
            network transaction fee.
          </div>
          {!!errorMessage && <Text className="text-red-500 py-2 text-xs">{errorMessage}</Text>}
        </div>
      </div>

      <div className="flex gap-2 mt-6">
        <Button
          type="submit"
          variant="default"
          disabled={
            Number(cashOutState.amountToCashOut) <= 0 ||
            !destinationAddress ||
            Number(cashOutState.amountToCashOut) > maxAvailableToCashOut ||
            !!errorMessage
          }
        >
          {isProcessing ? <FiLoader className="rotate inline" /> : 'Cash Out'}
        </Button>
        <Button variant="outline" onClick={refreshDepositEvents}>
          Refresh
        </Button>
      </div>
      {isRefreshing ? (
        <div className="py-4 px-10">
          <FiLoader className="rotate h-8 w-8" />
        </div>
      ) : (
        depositEvents.length > 0 &&
        megaSwapAddressGroup?.DepositAddresses[cashOutState.cashOutTicker] && (
          <DepositEventsList
            className="mt-4"
            events={depositEvents}
            ticker={cashOutState.cashOutTicker}
            depositAddress={megaSwapAddressGroup?.DepositAddresses[cashOutState.cashOutTicker]}
          />
        )
      )}

      <div className="my-4 text-center text-xs text-muted mt-6">
        Need help?{' '}
        <RouteLink
          className="cursor-pointer underline underline-offset-4 hover:text-foreground"
          to="https://t.me/heroswap"
          target="_blank"
        >
          Message HeroSwap Support
        </RouteLink>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-[400px]">
          <DialogHeader>
            <DialogTitle>Success 🎉</DialogTitle>
          </DialogHeader>
          <p className="p-4">
            Your cashout was submitted successfully! Hit “Refresh” to see its status. Sometimes it can take a minute to
            show up.
          </p>
          <DialogFooter className="justify-center">
            <Button variant="success" onClick={() => setIsDialogOpen(false)}>
              Got it!
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </form>
  );
}
