import { CurrencyInputWithTickerToggle } from 'components/app-ui/CurrencyInputWithTickerToggle';
import { Text } from 'components/core/Text';
import { Button } from 'components/shadcn/ui/button';
import { DESO_DOLLAR_PROFILE_NAME } from 'constants/AppConstants.dev';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import { deso, heroswap } from 'services';
import { OpenfundUser } from 'services/Openfund';
import { baseUnitsToTokens, desoToUSD, formatDecimalValue, formatUSD, toHex, usdToDeso } from 'utils/currency';
import { debounce } from 'utils/debounce';
import { getErrorMsg } from 'utils/getErrorMsg';
import { quantityDecimalStringToBigInt } from 'utils/orderbook';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';

const getSwapExchangeRate = (usdToSwap: number): Promise<GetExchangeRateUpdatedResponse> => {
  const amount = Math.max(usdToSwap, 1);
  return heroswap.calcCurrencySwapAmount('DUSD', 'DESO', amount.toString()).then((res) => ({
    USDCentsPerDeSoCoinbase: Number(
      (BigInt(1e18) * BigInt(100)) / quantityDecimalStringToBigInt(res.SwapRateDestinationTickerPerDepositTicker),
    ),
    SatoshisPerDeSoExchangeRate: 0,
    USDCentsPerBitcoinExchangeRate: 0,
    NanosPerETHExchangeRate: 0,
    USDCentsPerETHExchangeRate: 0,
    NanosSold: 0,
    USDCentsPerDeSoReserveExchangeRate: 0,
    BuyDeSoFeeBasisPoints: 0,
    SatoshisPerBitCloutExchangeRate: 0,
    USDCentsPerBitCloutExchangeRate: 0,
    USDCentsPerBitCloutReserveExchangeRate: 0,
    USDCentsPerDeSoExchangeRate: 0,
  })) as Promise<GetExchangeRateUpdatedResponse>;
};

interface SwapDesoForUSDProps {
  onSendUSDSuccess: () => void;
  onSendUSDError: (err: any) => void;
  onUSDAmountChange?: (amount: number) => void;
  usdDepositAddress: string;
  currentUser: OpenfundUser;
}
export const SwapUSDForDeso = ({
  currentUser,
  usdDepositAddress,
  onSendUSDSuccess,
  onSendUSDError,
  onUSDAmountChange,
}: SwapDesoForUSDProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSendingDesoDollar, setIsSendingDesoDollar] = useState(false);
  const [dusdBaseUnitsHexToSwapForDeso, setDUSDBaseUnitsHexToSwapForDeso] = useState('0x0');
  const [swapExchangeRates, setSwapExchangeRate] = useState<GetExchangeRateUpdatedResponse | undefined>();
  const [getSwapExchangeRateError, setGetSwapExchangeRateError] = useState<any>();
  const isMounted = useIsMounted();
  const maxSpendableDUSDBaseUnitsHex = currentUser?.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0';
  const availableUSDBalance = baseUnitsToTokens(maxSpendableDUSDBaseUnitsHex);

  useEffectOnce(() => {
    getSwapExchangeRate(1)
      .then((swapExchangeRate) => {
        if (!isMounted) return;
        setSwapExchangeRate(swapExchangeRate);
      })
      .catch((e) => {
        if (!isMounted) return;
        setGetSwapExchangeRateError(e);
      });
  });

  useEffect(() => {
    if (BigInt(dusdBaseUnitsHexToSwapForDeso) > BigInt(maxSpendableDUSDBaseUnitsHex)) {
      setErrorMessage(
        `${formatUSD(baseUnitsToTokens(dusdBaseUnitsHexToSwapForDeso))} exceeds your available balance of ${formatUSD(
          availableUSDBalance,
        )}`,
      );
    } else {
      setErrorMessage('');
    }
  }, [dusdBaseUnitsHexToSwapForDeso, maxSpendableDUSDBaseUnitsHex, availableUSDBalance]);

  useEffect(() => {
    if (onUSDAmountChange) {
      onUSDAmountChange(baseUnitsToTokens(dusdBaseUnitsHexToSwapForDeso));
    }
  }, [dusdBaseUnitsHexToSwapForDeso]);

  if (getSwapExchangeRateError) {
    return <Text color="error">{getErrorMsg(getSwapExchangeRateError)}</Text>;
  }

  if (!swapExchangeRates) {
    return (
      <div className="flex items-center justify-center">
        <FiLoader className="inline rotate h-10 w-10" />
      </div>
    );
  }

  return (
    <form
      className="flex sm:items-center flex-col sm:flex-row"
      onSubmit={async (ev) => {
        ev.preventDefault();
        if (BigInt(dusdBaseUnitsHexToSwapForDeso) <= 0) {
          setErrorMessage('Please enter a non-zero value.');
          return;
        }

        setIsSendingDesoDollar(true);
        try {
          await deso.transferTokens(DESO_DOLLAR_PROFILE_NAME, usdDepositAddress, dusdBaseUnitsHexToSwapForDeso);
          setDUSDBaseUnitsHexToSwapForDeso('0x0');
          onSendUSDSuccess();
        } catch (e) {
          onSendUSDError(e);
        }
        setIsSendingDesoDollar(false);
      }}
    >
      <div className="flex-1">
        {isSendingDesoDollar ? (
          // Dummy input for loading state so the real input gets its state reset after
          // transferring.
          <div className="animate-pulse">
            <CurrencyInputWithTickerToggle
              disabled={true}
              labelText="USDC-DESO amount to convert to DESO"
              initialValue={0}
              defaultTicker="USD"
              alternateTicker="DESO"
              onStateChange={() => {}}
              hint={`Available balance: ${formatUSD(availableUSDBalance)}`}
              onMax={() => formatDecimalValue(availableUSDBalance)}
            />
          </div>
        ) : (
          <CurrencyInputWithTickerToggle
            labelText="USDC-DESO amount to convert to DESO"
            initialValue={0}
            defaultTicker="USD"
            alternateTicker="DESO"
            state={!!errorMessage ? 'error' : 'default'}
            onStateChange={(v, t) => {
              const usdAmount = t === 'USD' ? v : desoToUSD(v, swapExchangeRates.USDCentsPerDeSoCoinbase).toFixed(5);
              setDUSDBaseUnitsHexToSwapForDeso(toHex(quantityDecimalStringToBigInt(usdAmount)));
              debounce(
                (amount: string) => {
                  getSwapExchangeRate(Number(amount)).then((swapExchangeRate) => {
                    if (!isMounted) return;
                    setSwapExchangeRate(swapExchangeRate);
                  });
                },
                300,
                [usdAmount],
              );
            }}
            hint={
              !!errorMessage ? (
                errorMessage
              ) : (
                <>
                  Available balance:{' '}
                  <span className="font-mono text-green-500 font-shadow-green">{formatUSD(availableUSDBalance)}</span>
                </>
              )
            }
            onMax={(selectedTicker) => {
              setErrorMessage('');
              setDUSDBaseUnitsHexToSwapForDeso(maxSpendableDUSDBaseUnitsHex);
              switch (selectedTicker) {
                case 'DESO':
                  return formatDecimalValue(usdToDeso(availableUSDBalance, swapExchangeRates.USDCentsPerDeSoCoinbase));
                case 'USD':
                  return formatDecimalValue(availableUSDBalance);
                default:
                  return 0;
              }
            }}
          />
        )}
      </div>
      <Button
        variant="outline"
        type="submit"
        className="w-full block mt-4 sm:w-auto sm:-mt-4 sm:ml-4"
        disabled={!!errorMessage || BigInt(dusdBaseUnitsHexToSwapForDeso) <= BigInt(0)}
        loading={isSendingDesoDollar}
      >
        {isSendingDesoDollar ? <FiLoader className="rotate inline" /> : 'Convert'}
      </Button>
    </form>
  );
};
