import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/core/Button';
import { RouteLink } from 'components/core/RouteLink';
import { Text } from 'components/core/Text';
import { useToast } from 'hooks/useToast';
import { IoCopyOutline } from 'react-icons/io5';
import { Routes } from 'RoutePaths';
import { FundingRound, ProjectReferral } from 'services/Openfund';
import { baseUnitsToTokens, desoNanosToDeso, desoNanosToUSD, formatDecimalValue, formatUSD } from 'utils/currency';
import { getErrorMsg } from 'utils/getErrorMsg';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';

interface ProjectAdvocatesLeaderBoardProps {
  advocates: ProjectReferral[];
  exchangeRates: GetExchangeRateUpdatedResponse;
  referralLink?: string;
  fundingRound: FundingRound;
}

export function ProjectAdvocatesLeaderBoard({
  advocates,
  exchangeRates,
  referralLink,
  fundingRound,
}: ProjectAdvocatesLeaderBoardProps) {
  const toast = useToast();

  return (
    <div className="overflow-hidden">
      {advocates.length > 0 ? (
        advocates.map((a) => (
          <div
            className={'flex items-center py-2 px-4 text-sm border-b border-border-light last:border-b-0'}
            key={a.ReferrerPkidBase58check}
          >
            <div className="flex items-center flex-1">
              <Avatar className="flex-shrink-0 bg-gray-faint" src={a.ReferrerPkidBase58check} />
              {a.ReferrerUsername ? (
                <RouteLink to={Routes.profile(a.ReferrerUsername)} kind="text-only-light" className="ml-2">
                  <div className="text-muted">@{a.ReferrerUsername}</div>
                </RouteLink>
              ) : (
                <div className="text-muted">@{a.ReferrerUsername}</div>
              )}
            </div>
            <div className="flex-1">
              <div className="font-semibold text-muted-foreground">
                {a.ReferralCount} {a.ReferralCount === 1 ? 'Referral' : 'Referrals'}
              </div>
            </div>
            <div className="flex-1 text-right">
              {fundingRound.TreasuryCurrencyUnit === 'DESO' ? (
                <div className="flex flex-col gap-1">
                  <div className="text-muted text-sm">
                    <span className="text-muted-foreground text-xs font-mono">
                      {formatUSD(
                        desoNanosToUSD(a.ReferralAmountEarnedDesoNanos, exchangeRates.USDCentsPerDeSoCoinbase),
                      )}{' '}
                      Earned
                    </span>
                  </div>
                  <div className="text-muted text-xs">
                    ≈ {formatDecimalValue(desoNanosToDeso(a.ReferralAmountEarnedDesoNanos), 7)} $DESO
                  </div>
                </div>
              ) : (
                <Text className="font-bold text-sm">
                  Amount Earned {formatUSD(baseUnitsToTokens(a.ReferralAmountEarnedDaoCoinsHex))}
                </Text>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="p-6 text-center text-muted">
          This project doesn't have any referrers yet.
          {referralLink && (
            <>
              Be the first by sharing this referral link and
              <b> earn {fundingRound.GlobalReferralRateBasisPoints / 100}% of every contribution</b>:{' '}
              <Button
                kind="text-only-light"
                size="sm"
                aria-label={`Copy referral link ${referralLink}`}
                title={referralLink}
                className="hover:underline"
                onClick={() => {
                  window.navigator.clipboard
                    .writeText(referralLink ?? '')
                    .then(() => {
                      toast.show({ message: `Copied referral link clipboard.`, type: 'success', sticky: false });
                    })
                    .catch((e) => {
                      toast.show({ message: getErrorMsg(e), type: 'error', sticky: false });
                    });
                }}
              >
                {referralLink}
                <IoCopyOutline className="inline ml-1" />
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
