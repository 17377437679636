import { FeaturedImage } from './FeaturedImage';
import { Avatar } from '../core/Avatar';
import { Text } from '../core/Text';
import { shortenLongWord } from '../../utils/deso';
import { Button } from '../shadcn/ui/button';
import { ExternalLink } from '../core/ExternalLink';
import { DESO_EXPLORER_URL, FOCUS_URL } from '../../constants/AppConstants';
import { LuActivity, LuExternalLink, LuWallet } from 'react-icons/lu';
import { getErrorMsg } from '../../utils/getErrorMsg';
import { centerEllipsis } from '../../utils/text';
import { IoCopyOutline, IoOpenOutline } from 'react-icons/io5';
import { TradeActivityTableWrapper } from './ActivityTableWrapper';
import { ProjectHodlersLeaderBoard } from './ProjectHodlersLeaderBoard';
import { useToast } from '../../hooks/useToast';
import { BalanceEntryResponse } from 'deso-protocol';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { Tabs } from '../core/Tabs';
import MyOrders from '../pages/MyOrders';
import { useAccountDetails } from '../../hooks/useAccountDetails';
import { AccountStats } from './AccountStats';
import { FOCUS_TOKEN_USERNAME } from 'utils/constants';
import { Skeleton } from 'components/shadcn/ui/skeleton';

interface NakedPublicKeyProfileProps {
  publicKey: string;
  holdings: BalanceEntryResponse[];
  exchangeRates: GetExchangeRateUpdatedResponse;
}

export function NakedPublicKeyProfile({ publicKey, holdings, exchangeRates }: NakedPublicKeyProfileProps) {
  const toast = useToast();
  const { loading, account, accountFirstTransaction } = useAccountDetails(publicKey, true);

  const tabs = [
    {
      tab: 'Activity',
      panel: <TradeActivityTableWrapper traderPublicKey={publicKey} tokenPublicKey={''} />,
    },
    {
      tab: 'Holdings',
      panel: (
        <div className="flex flex-col gap-4">
          <ProjectHodlersLeaderBoard
            hodlers={holdings}
            exchangeRates={exchangeRates}
            // just a dummy stub to make ts happy
            project={
              {
                Username: '',
                DAOCoinEntry: {
                  CoinsInCirculationNanos: '',
                },
              } as any
            }
            holdingsView={true}
          />
        </div>
      ),
    },
    {
      tab: 'Open Orders',
      panel: (
        <MyOrders
          publicKey={publicKey}
          className="text-sm"
          overflow={false}
          heading={
            <h2 className="flex gap-2 items-center text-lg font-sans font-bold text-muted-foreground">Open Orders</h2>
          }
        />
      ),
    },
  ];

  if (loading) {
    return (
      <div className="text-center py-8">
        <Skeleton className="h-[50px]" />
      </div>
    );
  }

  return (
    <div className="flex mt-6 md:max-w-[600px] flex-col md:mx-auto items-start md:w-full px-6 md:px-0">
      <aside className="w-full">
        <div>
          <div className="border rounded-2xl">
            <div className="overflow-y-hidden h-[200px]">
              <FeaturedImage style={{ border: 'none', borderRadius: '1rem 1rem 0 0' }} />
            </div>
            <div className="mb-2 border-t border-t-gray-333 p-4">
              <div className="flex items-center">
                <Avatar className="border-2 border-background -mt-12 z-10 bg-background p-1 rounded-full min-w-24 min-h-24" />
                <div className="flex items-start justify-between w-full">
                  <div className="ml-4">
                    <Text className="text-lg font-bold text-muted-foreground flex items-center">
                      {shortenLongWord(publicKey)}
                    </Text>
                    <Text tag="span" className="text-sm text-muted">
                      {shortenLongWord(publicKey)}
                    </Text>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center gap-4 border-t p-6 py-4 mt-2">
              <Button variant="outline" size="xs">
                <ExternalLink target="_blank" kind="text-only" size="sm" href={`${DESO_EXPLORER_URL}/u/${publicKey}`}>
                  <LuActivity className="inline mr-2 text-foreground" />
                  <span className="text-foreground">Explorer</span>
                </ExternalLink>
              </Button>
              <Button variant="outline" size="xs">
                <ExternalLink target="_blank" kind="text-only" size="sm" href={`${DESO_EXPLORER_URL}/u/${publicKey}`}>
                  <LuWallet className="inline mr-2 text-foreground" />
                  <span className="text-foreground">Wallet</span>
                </ExternalLink>
              </Button>
              <Button variant="outline" size="xs">
                <ExternalLink target="_blank" kind="text-only" size="sm" href={`${FOCUS_URL}/${publicKey}`}>
                  <LuExternalLink className="inline mr-2 text-foreground" />
                  <span className="text-foreground">{FOCUS_TOKEN_USERNAME}</span>
                </ExternalLink>
              </Button>

              <div>
                <Button
                  size="sm"
                  aria-label={`Copy public key ${publicKey}`}
                  title={publicKey}
                  className="hover:underline p-0 font-mono"
                  variant="ghost"
                  onClick={() => {
                    window.navigator.clipboard
                      .writeText(publicKey)
                      .then(() => {
                        toast.show({
                          message: `Copied public key to clipboard.`,
                          type: 'success',
                          sticky: false,
                        });
                      })
                      .catch((e) => {
                        toast.show({ message: getErrorMsg(e), type: 'error', sticky: false });
                      });
                  }}
                >
                  {centerEllipsis(publicKey, 12)}
                  <IoCopyOutline className="inline ml-1" />
                </Button>
              </div>
            </div>

            {account && <AccountStats account={account} accountFirstTransaction={accountFirstTransaction} />}

            <div className="border-border border-t">
              <div className="text-sm hover:text-blue">
                <ExternalLink
                  href={`${DESO_EXPLORER_URL}/txn?transactors=${publicKey}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-0 justify-center ml-auto no-underline"
                >
                  <Button variant="ghost">View Transaction History</Button>
                  <IoOpenOutline className="inline" />
                </ExternalLink>{' '}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <Tabs selectedIndex={0} showSelect={false} tabs={tabs} />
          </div>
        </div>
      </aside>
    </div>
  );
}
