import { useEffect, useState } from 'react';

export const MOBILE_WIDTH_BREAKPOINT = 768;
export const LARGE_WIDTH_BREAKPOINT = 1024;

export function useMobile(initialWidth = typeof window === 'object' ? window.innerWidth : LARGE_WIDTH_BREAKPOINT) {
  const [width, setWidth] = useState<number>(initialWidth);
  const [hasMouse, setHasMouse] = useState(true);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    function handleTouchStart() {
      setHasMouse(false);
    }

    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  return {
    isMobile: width <= MOBILE_WIDTH_BREAKPOINT,
    isTablet: width > MOBILE_WIDTH_BREAKPOINT && width < LARGE_WIDTH_BREAKPOINT,
    isDevice: width <= LARGE_WIDTH_BREAKPOINT,
    isLarge: width >= LARGE_WIDTH_BREAKPOINT,
    hasMouse,
  };
}
