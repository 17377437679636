import type { PaginationState } from '@tanstack/react-table';

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from '../shadcn/ui/pagination';
import { PageInfo } from '../../graphql/codegen/graphql';

interface TablePaginationProps {
  loading: boolean;
  pagination: PaginationState;
  pageInfo?: PageInfo;
  onPaginate: (pageIndex: number) => void;
  itemsPerPage: number;
  pageOffset: number;
  totalCount: number;
}

const TablePagination = ({
  loading,
  pagination,
  pageInfo,
  onPaginate,
  itemsPerPage,
  pageOffset,
  totalCount,
}: TablePaginationProps) => {
  return (
    <Pagination className="my-4">
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            variant="outline"
            size="sm"
            disabled={pagination.pageIndex === 0 || loading || pageInfo?.hasPreviousPage === false}
            onClick={async () => {
              const nextPageIndex = pagination.pageIndex - 1;
              onPaginate(nextPageIndex);
            }}
          />
        </PaginationItem>

        <span className="mx-2 text-sm">Page {pagination.pageIndex + 1}</span>

        <PaginationItem>
          <PaginationNext
            variant="outline"
            size="sm"
            disabled={
              loading || totalCount <= itemsPerPage || totalCount <= pageOffset + 1 || pageInfo?.hasNextPage === false
            }
            onClick={() => {
              const nextPageIndex = pagination.pageIndex + 1;
              onPaginate(nextPageIndex);
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default TablePagination;
