import { NumberInput, NumberInputProps } from 'components/app-ui/NumberInput';
import { Text } from 'components/core/Text';
import { useContext, useEffect, useState } from 'react';
import { desoToUSD, focusToUSD, formatDecimalValue, formatUSD, usdToDeso, usdToFocus } from 'utils/currency';
import difference from 'lodash/difference';
import { ExchangeRatesByTokenPublicKey, QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { DESO_ZERO_PUBLIC_KEY, FOCUS_TOKEN_PUBLIC_KEY } from '../../constants/AppConstants';

import {
  Select as SelectNew,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select';

function getInputPrefix(ticker: string) {
  switch (ticker) {
    case 'USD':
      return '$';
    default:
      return '';
  }
}

function convertCurrencyToUSD(ticker: string, amount: number | string, exchangeRates: ExchangeRatesByTokenPublicKey) {
  switch (ticker) {
    case 'DESO':
      return desoToUSD(amount, exchangeRates[DESO_ZERO_PUBLIC_KEY]);
    case 'FOCUS':
      return focusToUSD(amount, exchangeRates[FOCUS_TOKEN_PUBLIC_KEY]);
    default:
      return 0;
  }
}

function getInputHintText(
  fromTicker: string,
  toTicker: string,
  exchangeRates: ExchangeRatesByTokenPublicKey,
  inputValue: string,
) {
  switch (toTicker) {
    case 'USD':
      return `≈ ${formatUSD(convertCurrencyToUSD(fromTicker, inputValue, exchangeRates), true, 5)} ${toTicker}`;
    case 'DESO':
      return `≈ ${formatDecimalValue(usdToDeso(inputValue, exchangeRates[DESO_ZERO_PUBLIC_KEY]), 5)} ${toTicker}`;
    case 'FOCUS':
      return `≈ ${formatDecimalValue(usdToFocus(inputValue, exchangeRates[FOCUS_TOKEN_PUBLIC_KEY]), 5)} ${toTicker}`;
    default:
      return '';
  }
}

type CurrencyInputWithTickerToggleProps = {
  defaultTicker: string;
  alternateTicker: string;
  onStateChange: (value: string, ticker: string) => void;
  onMax?: (selectedTicker: string) => string | number;
  allowedDecimalPlaces?: number | undefined;
  hideTicker?: boolean;
  hideHint?: boolean;
} & NumberInputProps;
export function CurrencyInputWithTickerToggle({
  defaultTicker,
  alternateTicker,
  onStateChange,
  initialValue,
  onMax,
  allowedDecimalPlaces,
  hint,
  hideTicker = false,
  hideHint = false,
  ...rest
}: CurrencyInputWithTickerToggleProps) {
  if (difference(['USD', 'USDC'], [defaultTicker, alternateTicker]).length === 0) {
    throw new Error('Either defaultTicker or alternateTicker must be USD or USDC');
  }

  const { exchangeRates } = useContext(QuoteCurrencyContext);

  const [currentValue, setCurrentValue] = useState(initialValue?.toString().replace(/[^\d.]/g, '') ?? '0');
  const [currentTicker, setCurrentTicker] = useState(defaultTicker);
  const [valueHint, setValueHint] = useState('');

  const setHint = (hintText: string) => {
    if (hideHint) {
      setValueHint('');
      return;
    }

    setValueHint(hintText);
  };

  useEffect(() => {
    setHint(getInputHintText(defaultTicker, alternateTicker, exchangeRates, currentValue));
  }, [hideHint]);

  return (
    <div className="relative">
      <NumberInput
        prefix={getInputPrefix(currentTicker)}
        initialValue={currentValue}
        allowedDecimalPlaces={currentTicker === 'USD' || currentTicker === 'USDC' ? 12 : allowedDecimalPlaces}
        hint={valueHint}
        rightContent={
          <div className="absolute -top-[8px] right-2 flex items-center">
            {typeof onMax !== 'undefined' && (
              <button
                type="button"
                className="text-foreground hover:text-primary text-sm mr-4"
                onClick={() => {
                  const newValue = onMax(currentTicker).toLocaleString('en-US', { maximumFractionDigits: 38 });
                  setCurrentValue(newValue);
                  if (currentTicker !== defaultTicker) {
                    setHint(getInputHintText(alternateTicker, defaultTicker, exchangeRates, newValue));
                  } else {
                    setHint(getInputHintText(defaultTicker, alternateTicker, exchangeRates, newValue));
                  }
                }}
              >
                Max
              </button>
            )}
            {!hideTicker && (
              <>
                <SelectNew
                  defaultValue={currentTicker}
                  onValueChange={(newTicker) => {
                    setCurrentTicker(newTicker);

                    if (newTicker !== defaultTicker) {
                      setHint(getInputHintText(alternateTicker, defaultTicker, exchangeRates, currentValue));
                    } else {
                      setHint(getInputHintText(defaultTicker, alternateTicker, exchangeRates, currentValue));
                    }

                    onStateChange?.(currentValue, newTicker);
                  }}
                >
                  <SelectTrigger className="inline-flex">
                    <SelectValue defaultValue={currentTicker} />
                  </SelectTrigger>
                  <SelectContent>
                    {[defaultTicker, alternateTicker].map((t) => (
                      <SelectItem key={t} value={t}>
                        {t}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectNew>
              </>
            )}
          </div>
        }
        {...rest}
        onValueChange={(value) => {
          onStateChange?.(value, currentTicker);
          setCurrentValue(value);
          if (currentTicker !== defaultTicker) {
            setHint(getInputHintText(alternateTicker, defaultTicker, exchangeRates, value));
          } else {
            setHint(getInputHintText(defaultTicker, alternateTicker, exchangeRates, value));
          }
        }}
      />
      <Text size="sm" color={rest.state === 'error' ? 'error' : 'secondary'}>
        {hint}
      </Text>
    </div>
  );
}
