import { Text } from '../../components/core/Text';
import { useState } from 'react';
import { formatUSD } from '../../utils/currency';
import { classNames } from '../../utils/classNames';

export type WealthBadgeProps = {
  wealthUSD: number;
  showEmoji?: boolean;
  showUSDAmount?: boolean;
  inModal?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function WealthBadge({
  wealthUSD,
  className,
  showEmoji = true,
  showUSDAmount = true,
  inModal = false,
}: WealthBadgeProps) {
  const [isShown, setIsShown] = useState(false);

  let wealthEmoji = <></>;
  if (showEmoji) {
    if (wealthUSD >= 10 && wealthUSD < 500) {
      wealthEmoji = <>🐒</>;
    } else if (wealthUSD >= 500) {
      wealthEmoji = <>🦍</>;
    }
  }

  let usdAmount = <></>;
  if (showUSDAmount) {
    usdAmount = <>≈ {formatUSD(wealthUSD)}</>;
  }

  return (
    <div
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      className={classNames(className, 'cursor-pointer text-muted inline-block')}
    >
      {usdAmount} {wealthEmoji}
      {isShown && (
        <div style={{ position: `${inModal ? 'relative' : 'absolute'}`, zIndex: 1000 }}>
          <div
            className="border border-gray-light shadow-xl z-10 text-sm leading-5 text-black absolute bg-white rounded-lg p-6 wrap-text-balance"
            style={{
              maxWidth: '25rem',
              minWidth: '20rem',
            }}
          >
            <Text className="mb-4 font-bold">Total Assets Badge</Text>
            <Text className="mb-4">
              This value is <span className="font-bold">"total assets"</span> computed as the sum of wallet balance and
              creator coin value locked. Soon it will incorprate your portfolio value.
            </Text>
            <Text className="mb-4">
              {' '}
              It can help you distinguish real accounts from bots, since assets can't be faked.
            </Text>
            <Text className="mb-2">&bull; At $10 in assets you become a 🐒</Text>
            <Text className="mb-0">&bull; At $500 in assets you become a fully-grown 🦍</Text>
          </div>
        </div>
      )}
    </div>
  );
}
