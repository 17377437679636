import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/shadcn/ui/tooltip';
import React from 'react';

type BadgeProps = {
  text?: string;
  hideTooltip?: boolean;
};

const NewBadge: React.FC<BadgeProps> = ({ text = 'NEW', hideTooltip = false }) => {
  const tooltipContent = (
    <div className="text-balance">
      USDC, BTC, ETH, and SOL are represented in your wallet as wrapped assets, namely USDC-DESO, BTC-DESO, ETH-DESO,
      and SOL-DESO respectively. This allows you to trade them on the DeSo DEX, which is the first cross-chain
      order-book DEX, and to transact with virtually no gas!
      <br />
      You can convert real assets to wrapped assets, and vice versa, at any time simply by adding funds and cashing out
      in your DeSo wallet.
      <br />
      Give it a try!
    </div>
  );

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <img
            src={'/images/icon-verified.gif'}
            alt="New"
            className="w-[18px] h-[18px] relative left-[4px] top-[1px]"
          />
        </TooltipTrigger>
        {!hideTooltip && <TooltipContent className="max-w-[320px] text-center">{tooltipContent}</TooltipContent>}
      </Tooltip>
    </TooltipProvider>
  );
};

export default NewBadge;
