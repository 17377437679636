import { CurrencyInputWithTickerToggle } from 'components/app-ui/CurrencyInputWithTickerToggle';
import { Button } from 'components/shadcn/ui/button';
import { Text } from 'components/core/Text';
import { INVESTMENT_NETWORK_FEE_NANOS_BUFFER } from 'constants/AppConstants';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import { deso, heroswap } from 'services';
import { OpenfundUser } from 'services/Openfund';
import {
  desoNanosToDeso,
  desoNanosToUSD,
  desoToDesoNanos,
  formatDecimalValue,
  formatUSD,
  usdToDeso,
  usdToUSDCents,
} from 'utils/currency';
import { debounce } from 'utils/debounce';
import { getErrorMsg } from 'utils/getErrorMsg';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';

const getSwapExchangeRate = (desoAmountToSwap: number): Promise<GetExchangeRateUpdatedResponse> => {
  const amount = Math.max(Number(desoAmountToSwap), 1);
  return heroswap.calcCurrencySwapAmount('DESO', 'DUSD', amount.toString()).then((res) => ({
    USDCentsPerDeSoCoinbase: usdToUSDCents(res.SwapRateDestinationTickerPerDepositTicker),
    SatoshisPerDeSoExchangeRate: 0,
    USDCentsPerBitcoinExchangeRate: 0,
    NanosPerETHExchangeRate: 0,
    USDCentsPerETHExchangeRate: 0,
    NanosSold: 0,
    USDCentsPerDeSoReserveExchangeRate: 0,
    BuyDeSoFeeBasisPoints: 0,
    SatoshisPerBitCloutExchangeRate: 0,
    USDCentsPerBitCloutExchangeRate: 0,
    USDCentsPerBitCloutReserveExchangeRate: 0,
    USDCentsPerDeSoExchangeRate: 0,
  })) as Promise<GetExchangeRateUpdatedResponse>;
};

interface SwapDesoForUSDProps {
  onSendDesoSuccess: () => void;
  onSendDesoError: (err: any) => void;
  onDESOAmountChange?: (amount: number) => void;
  desoDepositAddress: string;
  currentUser: OpenfundUser;
}
export const SwapDesoForUSD = ({
  currentUser,
  desoDepositAddress,
  onSendDesoSuccess,
  onSendDesoError,
  onDESOAmountChange,
}: SwapDesoForUSDProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSendingDeso, setIsSendingDeso] = useState(false);
  const [desoNanosAmountToSwapForDUSD, setDesoNanosAmountToSwapForUSD] = useState(0);
  const [swapExchangeRates, setSwapExchangeRate] = useState<GetExchangeRateUpdatedResponse>();
  const [getSwapExchangeRateError, setGetSwapExchangeRateError] = useState<any>();
  const isMounted = useIsMounted();
  const spendableDesoNanos =
    currentUser?.BalanceNanos && currentUser?.BalanceNanos > INVESTMENT_NETWORK_FEE_NANOS_BUFFER
      ? currentUser.BalanceNanos - INVESTMENT_NETWORK_FEE_NANOS_BUFFER
      : 0;

  useEffectOnce(() => {
    getSwapExchangeRate(1)
      .then((swapExchangeRate) => {
        if (!isMounted) return;
        setSwapExchangeRate(swapExchangeRate);
      })
      .catch((e) => {
        if (!isMounted) return;
        setGetSwapExchangeRateError(e);
      });
  });

  useEffect(() => {
    if (swapExchangeRates && desoNanosAmountToSwapForDUSD > spendableDesoNanos) {
      setErrorMessage(
        `${formatUSD(desoNanosToUSD(desoNanosAmountToSwapForDUSD, swapExchangeRates.USDCentsPerDeSoCoinbase))} (
            ${desoNanosToDeso(desoNanosAmountToSwapForDUSD)} $DESO) exceeds available balance.`,
      );
    } else {
      setErrorMessage('');
    }
  }, [desoNanosAmountToSwapForDUSD, spendableDesoNanos, swapExchangeRates]);

  useEffect(() => {
    if (onDESOAmountChange) {
      onDESOAmountChange(desoNanosToDeso(desoNanosAmountToSwapForDUSD));
    }
  }, [desoNanosAmountToSwapForDUSD]);

  if (getSwapExchangeRateError) {
    return <Text color="error">{getErrorMsg(getSwapExchangeRateError)}</Text>;
  }

  if (!swapExchangeRates) {
    return (
      <div className="flex items-center justify-center">
        <FiLoader className="inline rotate h-10 w-10" />
      </div>
    );
  }

  return (
    <form
      className="flex items-center flex-col sm:flex-row"
      onSubmit={async (ev) => {
        ev.preventDefault();
        if (desoNanosAmountToSwapForDUSD <= 0) {
          setErrorMessage('Please enter a non-zero value.');
          return;
        }

        setIsSendingDeso(true);
        try {
          await deso.sendDeso(currentUser.PublicKeyBase58Check, desoDepositAddress, desoNanosAmountToSwapForDUSD);
          setDesoNanosAmountToSwapForUSD(0);
          onSendDesoSuccess();
        } catch (e) {
          onSendDesoError(e);
        }
        setIsSendingDeso(false);
      }}
    >
      <div className="flex-1">
        {isSendingDeso ? (
          // Dummy input for loading state so the real input gets its state reset after
          // transferring.
          <div className="animate-pulse">
            <CurrencyInputWithTickerToggle
              disabled={true}
              labelText="DESO amount to convert to USDC-DESO"
              initialValue={0}
              defaultTicker="USD"
              alternateTicker="DESO"
              onStateChange={() => {}}
              hint={`Available balance: ${formatUSD(
                desoNanosToUSD(spendableDesoNanos, swapExchangeRates.USDCentsPerDeSoCoinbase),
              )} (${desoNanosToDeso(spendableDesoNanos)} $DESO)`}
              onMax={() => desoNanosToUSD(spendableDesoNanos, swapExchangeRates.USDCentsPerDeSoCoinbase)}
            />
          </div>
        ) : (
          <CurrencyInputWithTickerToggle
            labelText="DESO amount to convert to USDC-DESO"
            initialValue={0}
            defaultTicker="USD"
            alternateTicker="DESO"
            state={!!errorMessage ? 'error' : 'default'}
            onStateChange={(v, t) => {
              const desoAmount = t === 'DESO' ? v : usdToDeso(v, swapExchangeRates.USDCentsPerDeSoCoinbase).toFixed(5);
              setDesoNanosAmountToSwapForUSD(desoToDesoNanos(desoAmount));
              debounce(
                (amount: string) => {
                  getSwapExchangeRate(Number(amount)).then((swapExchangeRate) => {
                    if (!isMounted) return;
                    setSwapExchangeRate(swapExchangeRate);
                  });
                },
                300,
                [desoAmount],
              );
            }}
            hint={
              !!errorMessage
                ? errorMessage
                : `Available balance: ${formatUSD(
                    desoNanosToUSD(spendableDesoNanos, swapExchangeRates.USDCentsPerDeSoCoinbase),
                  )} (${formatDecimalValue(desoNanosToDeso(spendableDesoNanos))} $DESO)`
            }
            onMax={(selectedTicker) => {
              setDesoNanosAmountToSwapForUSD(spendableDesoNanos);
              switch (selectedTicker) {
                case 'DESO':
                  return desoNanosToDeso(spendableDesoNanos);
                case 'USD':
                  return desoNanosToUSD(spendableDesoNanos, swapExchangeRates.USDCentsPerDeSoCoinbase);
                default:
                  return 0;
              }
            }}
          />
        )}
      </div>
      <Button
        className="w-full block mt-4 sm:w-auto sm:-mt-4 sm:ml-4"
        type="submit"
        disabled={!!errorMessage || desoNanosAmountToSwapForDUSD <= 0}
        loading={isSendingDeso}
      >
        {isSendingDeso ? <FiLoader className="rotate inline" /> : 'Convert'}
      </Button>
    </form>
  );
};
