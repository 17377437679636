import { useDocumentTitle } from 'hooks/useDocumentTitle';

export function OnePager() {
  useDocumentTitle('One Pager');

  return (
    <object data="/images/one-pager.pdf" type="application/pdf" width="100%" height="1000px">
      <p>
        View the one pager{' '}
        <a href="https://docs.google.com/document/d/1fQCraFaTV-UvnmcaqTo1aqyJO1uA2ddc2_Pn7z5QZBQ/edit?usp=sharing">
          here!
        </a>
      </p>
    </object>
  );
}

export default OnePager;
