import { Switch } from '@headlessui/react';
import { NumberInput } from 'components/app-ui/NumberInput';
import { Button } from 'components/shadcn/ui/button';
import { Spinner } from 'components/core/Spinner';
import { useSettingsContext } from 'components/pages/Settings';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useToast } from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { openfund } from 'services';
import { toHex } from 'utils/currency';

export function Proposals() {
  useDocumentTitle(`Edit proposal settings`);
  const { setHeadingSuffix, currentUser } = useSettingsContext();
  const [isLoading, setIsLoading] = useState(true);
  const [holdersCanVote, setHoldersCanVote] = useState(false);
  const [proposalsEnabled, setProposalsEnabled] = useState(false);
  const [tokenThreshold, setTokenThreshold] = useState(100000);
  const toast = useToast();
  const navigate = useNavigate();

  function handleProposalsEnabledClick() {
    // If the proposals are about to be disabled, also disable holders can vote.
    if (proposalsEnabled && holdersCanVote) {
      setHoldersCanVote(false);
    }
    setProposalsEnabled(!proposalsEnabled);
  }

  function handleHoldersCanVoteClick() {
    // If the proposals are disabled, ignore click.
    if (proposalsEnabled) {
      setHoldersCanVote(!holdersCanVote);
    }
  }

  useEffect(() => {
    if (!currentUser.ProfileEntryResponse) {
      navigate(Routes.settingsProfile());
      return;
    }

    // Initialize values based on status
    if (
      currentUser.appUser?.DaoProposalsEnabledStatus === '' ||
      currentUser.appUser?.DaoProposalsEnabledStatus === 'DISABLED'
    ) {
      setProposalsEnabled(false);
    } else if (currentUser.appUser?.DaoProposalsEnabledStatus === 'DAO_ONLY') {
      setProposalsEnabled(true);
      setHoldersCanVote(false);
    } else {
      setProposalsEnabled(true);
      setHoldersCanVote(true);
    }

    if (currentUser.appUser?.DaoProposalThresholdHex !== '') {
      const threshold = Math.round(Number(currentUser.appUser?.DaoProposalThresholdHex) / 1e18);
      setTokenThreshold(threshold);
    }

    setHeadingSuffix('Proposal Settings');
    setIsLoading(false);
  }, [currentUser]);

  return (
    <section>
      {isLoading ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : (
        <form
          id="edit-fundraising-details-form"
          onSubmit={async (ev) => {
            ev.preventDefault();
            let ProposalsEnabledStatus: string;
            if (!proposalsEnabled) {
              ProposalsEnabledStatus = 'DISABLED';
            } else if (!holdersCanVote) {
              ProposalsEnabledStatus = 'DAO_ONLY';
            } else {
              ProposalsEnabledStatus = 'HOLDERS';
            }

            const ProposalThresholdHex = toHex(tokenThreshold * 1e18);

            if (currentUser.appUser !== null) {
              openfund
                .registerProfile(
                  currentUser.appUser.PkidBase58check,
                  currentUser.appUser.Username,
                  currentUser.appUser.Email,
                  ProposalsEnabledStatus,
                  ProposalThresholdHex,
                )
                .then(() => {
                  toast.show({
                    message: 'Proposal settings updated successfully!',
                    type: 'success',
                    sticky: false,
                  });
                });
            }
          }}
        >
          <div className="mb-4 font-semibold text-xl text-muted-foreground">Edit proposal settings</div>
          <div className="flex items-center border border-border-light p-4 sm:p-6 rounded-2xl">
            <div className="flex flex-col gap-1">
              <div className="font-medium text-sm text-foreground">Enable Proposals</div>
              <div className="text-muted text-sm">
                This will allow proposals to be created that your token holders can vote on.
              </div>
            </div>
            <div style={{ justifyContent: 'flex-end' }} className="w-2/5 flex">
              <Switch
                checked={proposalsEnabled}
                onChange={handleProposalsEnabledClick}
                className={`${
                  proposalsEnabled ? 'bg-blue' : 'bg-gray'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    proposalsEnabled ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white text-black`}
                />
              </Switch>
            </div>
          </div>
          {!proposalsEnabled && (
            <div className="rounded-2xl border border-border-light mt-6">
              <div className="border-b p-4 sm:p-6 border-gray-faint flex items-start justify-between">
                <div className="flex flex-col gap-1">
                  <div className="font-medium text-sm text-foreground">Enable Proposals for All Holders</div>
                  <div className="text-muted text-sm">Allows anyone that holds your token to create a proposal</div>
                </div>
                <div className="flex">
                  <Switch
                    checked={holdersCanVote}
                    onChange={handleHoldersCanVoteClick}
                    className={`${
                      holdersCanVote ? 'bg-blue' : 'bg-gray'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span
                      className={`${
                        holdersCanVote ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white text-black`}
                    />
                  </Switch>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-4 items-start justify-between w-full p-4 sm:p-6">
                <div className="w-full sm:w-[60%] flex flex-col gap-1">
                  <div className="font-medium text-sm text-foreground">Set Threshold for Creating Proposals</div>
                  <div className="text-muted text-sm">
                    How many tokens should voters have in order to create a proposal? You must have proposals enabled
                    for all token holders.
                  </div>
                </div>
                <div className="w-full sm:w-[40%] flex justify-start md:justify-end">
                  <NumberInput
                    prefix=""
                    initialValue={tokenThreshold}
                    labelText="Amount of Tokens"
                    placeholder="10,000,000"
                    className="text-center"
                    allowedDecimalPlaces={0}
                    hint=""
                    onValueChange={(value) => {
                      setTokenThreshold(Number(value));
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <Button type="submit" className="mt-6">
            Save Changes
          </Button>
        </form>
      )}
    </section>
  );
}

export default Proposals;
