import { Input, InputProps } from 'components/core/Input';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { User } from 'deso-protocol';
import { useIsMounted } from 'hooks/useIsMounted';
import { useContext, useEffect, useRef, useState } from 'react';
import { deso } from 'services';
import debounce from 'lodash/debounce';

interface InputState {
  hint: string | undefined;
  state: InputProps['state'];
}
export type UsernameInputState = {
  state: InputProps['state'] | 'pending';
  value: string;
};
interface UsernameInputProps {
  initialValue: string;
  autoFocus?: boolean;
  labelSrOnly?: boolean;
  containerClasses?: string;
  placeholder?: string;
  id?: string;
  onInputStateChange: (inputState: UsernameInputState) => void;
}
export function UsernameInput({
  initialValue = '',
  autoFocus = false,
  labelSrOnly = false,
  containerClasses = '',
  placeholder = '',
  id,
  onInputStateChange,
}: UsernameInputProps) {
  const [inputState, setInputState] = useState<InputState>({
    state: 'default',
    hint: '',
  });
  const [inputValue, setInputValue] = useState(initialValue);
  const { currentUser } = useContext(OpenfundContext);
  const isMounted = useIsMounted();
  const didRender = useRef(false);

  useEffect(() => {
    // If this is the first render and the field is empty,
    // we don't want to validate it.
    if (!didRender.current && !initialValue) {
      return;
    }

    didRender.current = true;

    const newInputValue = initialValue ?? currentUser?.ProfileEntryResponse?.Username ?? '';
    setInputValue(newInputValue);
    onInputStateChange({ state: 'pending', value: newInputValue });
    getState(newInputValue, currentUser).then((state) => {
      if (isMounted.current) {
        setInputState(state);
        onInputStateChange({ state: state.state, value: newInputValue });
      }
    });
  }, [initialValue]);

  const debouncedGetState = useRef(
    debounce((name: string) => {
      getState(name, currentUser).then((state) => {
        setInputState(state);
        onInputStateChange({ state: state.state, value: name.trim() });
      });
    }, 500),
  ).current;

  return (
    <Input
      id={id}
      labelText="Username"
      placeholder={placeholder}
      className="w-full"
      containerClasses={containerClasses}
      autoFocus={autoFocus}
      value={inputValue}
      autoComplete="off"
      spellCheck="false"
      state={inputState.state}
      hint={inputState.hint}
      required={true}
      labelSrOnly={labelSrOnly}
      maxLength={25}
      onInput={(ev) => {
        const name = ev.currentTarget.value;
        setInputValue(name);
        onInputStateChange({ state: 'pending', value: name });
        debouncedGetState(name);
      }}
    />
  );
}

const usernameQueryCache: Record<string, boolean> = {};

async function checkDuplicateName(name: string) {
  if (typeof usernameQueryCache[name] === 'boolean') {
    return usernameQueryCache[name];
  }

  const profile = await deso.getProfileByUsername(name, false);
  const isDuplicate = !!profile;
  usernameQueryCache[name] = isDuplicate;
  return isDuplicate;
}

async function getState(currentValue: string, currentUser: User | null): Promise<InputState> {
  if (!currentValue) {
    return {
      hint: 'Please enter a username',
      state: 'error',
    };
  }

  if (!/^\w+$/.test(currentValue)) {
    return {
      hint: 'Only letters, numbers, and underscores are valid',
      state: 'error',
    };
  }

  if (currentValue.toLowerCase() === currentUser?.ProfileEntryResponse?.Username?.toLowerCase()) {
    return {
      hint: `${currentValue} is available`,
      state: 'success',
    };
  }

  if (await checkDuplicateName(currentValue)) {
    return {
      hint: `${currentValue} is not available`,
      state: 'error',
    };
  }

  if (currentValue.length > 0) {
    return {
      hint: `${currentValue} is available`,
      state: 'success',
    };
  }

  return {
    hint: undefined,
    state: 'default',
  };
}
