import React, { useEffect, useState } from 'react';
import { Button } from 'components/shadcn/ui/button';
import { Dialog, DialogContent } from 'components/shadcn/ui/dialog';
import { confetti } from '../../services';
import { ExternalLink } from 'components/core/ExternalLink';
import Typewriter from '../core/Typewriter';

const WelcomeToOpenfundModal = ({ delay = 0 }: { delay?: number }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenWelcomeModal');
    if (!hasSeenModal) {
      setTimeout(() => {
        setIsOpen(true);
      }, delay);
    }
  }, []);

  const handleClose = () => {
    confetti.celebrate();
    localStorage.setItem('hasSeenWelcomeModal', 'true');
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent className="text-center p-12" containerClassName="overflow-y-visible" onClose={handleClose}>
        <img
          className="mx-auto mb-4 w-24 h-24 rounded-full shadow-lg"
          alt="error-image"
          src="/images/icon-OPENFUND.png"
        />
        <div className="text-xl md:text-2xl font-bold text-muted-foreground text-center mb-4 mt-2 Typewriter__wrapper">
          <Typewriter text="Welcome to Openfund V2" loop={false} />
        </div>
        <p className="text-base text-muted leading-6 text-center text-balance">
          Openfund is the first fully on-chain order-book spot-trading exchange. It combines the efficiency and ease of
          use of centralized exchanges with all the benefits of DeFi.{' '}
          <ExternalLink
            href="https://docs.deso.org/openfund/what-is-openfund"
            target="_blank"
            className="underline underline-offset-4 hover:text-muted-foreground"
          >
            Learn more
          </ExternalLink>
        </p>
        <Button onClick={handleClose} className="mt-4 mx-auto">
          Let&apos;s Go!
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeToOpenfundModal;
