import { SortingState } from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import upperFirst from 'lodash/upperFirst';
import { TransactionVolumeBadge } from '../components/app-ui/TransactionVolumeBadge';
import { TradingRecentTradesOrderBy } from '../graphql/codegen/graphql';

export interface ActivityTabSelection {
  content: string | React.ReactNode;
  type: ActivityTabTypes;
}

export enum ActivityTabWhaleValuesType {
  Whale = 10000,
  Dolphin = 1000,
  Fish = 100,
  Shrimp = 99,
}

export enum ActivityTabTypes {
  All = 'all',
  Whale = 'Whale',
  Dolphin = 'Dolphin',
  Fish = 'Fish',
  Shrimp = 'Shrimp',
}

export const activityTabs: Array<ActivityTabSelection> = [
  {
    content: 'All',
    type: ActivityTabTypes.All,
  },
  {
    content: <TransactionVolumeBadge type={ActivityTabTypes.Whale} text=">$10k" width={28} />,
    type: ActivityTabTypes.Whale,
  },
  {
    content: <TransactionVolumeBadge type={ActivityTabTypes.Dolphin} text=">$1k" />,
    type: ActivityTabTypes.Dolphin,
  },
  {
    content: <TransactionVolumeBadge type={ActivityTabTypes.Fish} text=">$100" />,
    type: ActivityTabTypes.Fish,
  },
  {
    content: <TransactionVolumeBadge type={ActivityTabTypes.Shrimp} text="$1-$99" />,
    type: ActivityTabTypes.Shrimp,
  },
];

export const COLUMNS_WIDTH = [6, 7, 18, 18, 14, 12, 11, 18];

export enum ActivityFilterFields {
  tokenPublicKey = 'tokenPublicKey',
  traderPublicKey = 'traderPublicKey',
  traderAssetsUsd = 'traderAssetsUsd',
  tradeValueUsd = 'tradeValueUsd',
  tradeTimestamp = 'tradeTimestamp',
}

export const transformTradeOrderByKey = (key: string) => {
  if (/^(VolumeUsd|PriceUsdPctChange)/.test(key)) {
    return key.replace(/(VolumeUsdPast|PriceUsdPctChange)(\d+[HD])_/, '$1_$2');
  }
  return key.replaceAll('_', '');
};

export const getOrderByKey = <T extends typeof TradingRecentTradesOrderBy>(
  columnSorting: SortingState,
  orderByEnum: T,
  defaultValue: T[keyof T],
): T[keyof T] => {
  if (!columnSorting.length) {
    return defaultValue;
  }

  const getOrderByKeyInternal = transformTradeOrderByKey(
    `${upperFirst(columnSorting[0].id)}_${capitalize(columnSorting[0]?.desc ? 'Desc' : 'Asc')}`,
  );

  return orderByEnum[getOrderByKeyInternal as keyof T] || defaultValue;
};

export const formatActivityTabFilters = (
  tab: ActivityTabSelection,
): {
  min: number;
  max?: number;
} => {
  const type = tab.type;

  switch (type) {
    case ActivityTabTypes.Shrimp:
      return {
        min: 1,
        max: ActivityTabWhaleValuesType.Shrimp,
      };
    default:
      return {
        min: ActivityTabWhaleValuesType[type as keyof typeof ActivityTabWhaleValuesType],
      };
  }
};
