import { ProfileEntryResponse } from 'deso-protocol';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { FollowTable } from '../app-ui/FollowTable';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../shadcn/ui/dialog';

interface FollowModalProps {
  profile: ProfileEntryResponse;
  exchangeRates: GetExchangeRateUpdatedResponse;
  followModalState: FollowModalState;
  onClose: () => void;
}

type FollowState = 'FOLLOWERS' | 'FOLLOWING';
export interface FollowModalState {
  isOpen: boolean;
  followState: FollowState;
}

export const DEFAULT_FOLLOW_MODAL_STATE: FollowModalState = Object.freeze({
  isOpen: false,
  followState: 'FOLLOWERS',
});

function GetTitleFromModalState(modalState: FollowModalState): string {
  return modalState.followState === 'FOLLOWERS' ? 'Followers' : 'Following';
}

export function FollowModal({ profile, exchangeRates, followModalState, onClose }: FollowModalProps) {
  useDocumentTitle(`${profile.Username} ${GetTitleFromModalState(followModalState)}`);

  useEffectOnce(() => {});

  return (
    <Dialog open={followModalState.isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{GetTitleFromModalState(followModalState)}</DialogTitle>
        </DialogHeader>
        <div className="relative">
          <FollowTable
            username={profile.Username}
            getEntriesFollowingUsername={followModalState.followState === 'FOLLOWERS'}
            exchangeRates={exchangeRates as GetExchangeRateUpdatedResponse}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
