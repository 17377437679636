import { ProjectLeaderBoard, ProjectLeaderBoardOrderBy } from 'components/app-ui/ProjectLeaderBoard';
import { FeaturedProjectsContainer } from 'components/app-ui/FeaturedProjects';
import { Heading } from 'components/core/Heading';
import { Text } from 'components/core/Text';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { isMobile } from '../../utils/isMobile';
import { AVAILABLE_QUOTE_CURRENCIES, QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { Select } from '../core/Select';

export function Discover() {
  useDocumentTitle('Discover');
  const [searchParams] = useSearchParams();
  const orderBy = searchParams.get('orderBy') as ProjectLeaderBoardOrderBy;

  const { quoteCurrencyPublicKey, setQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  return (
    <div className="max-w-7xl w-full m-auto flex flex-col-reverse lg:flex-col">
      <section className="hidden md:block">
        <Heading level={1} className="mb-4 text-center font-sans xl:mb-8">
          Featured Projects
        </Heading>
        <FeaturedProjectsContainer />
      </section>
      <section className="pb-4 lg:pb-0 lg:border-t lg:border-gray-333 lg:pt-12">
        <div className="mb-4 lg:mb-8 flex justify-between items-center lg:items-end">
          <div>
            <Heading level={2} className="font-sans mb-2">
              {isMobile() ? 'Discover' : 'Browse all projects'}
            </Heading>
            <Text className="text-gray hidden lg:block" size="sm">
              Search below for all listed projects. All dollar amounts are shown in USD.
            </Text>
          </div>

          <div className="min-w-[200px]">
            <Select
              className="w-full bg-black"
              value={quoteCurrencyPublicKey}
              onChange={(ev) => {
                setQuoteCurrencyPublicKey(ev.currentTarget.value);
              }}
            >
              {AVAILABLE_QUOTE_CURRENCIES.map(({ publicKey, displayName }) => (
                <option key={publicKey} value={publicKey}>
                  {displayName}
                </option>
              ))}
            </Select>
          </div>
        </div>
        {/*TODO: not commit, this is completely wrong*/}
        <ProjectLeaderBoard
          compact={false}
          limit={50}
          localStoragePrefix={'discover'}
          orderByOverride={orderBy}
          isLegacyView={true}
        />
      </section>
    </div>
  );
}

export default Discover;
