import { AxiosInstance } from 'axios';
import { Ticker } from 'utils/tickers';
import { OPEN_FUND_MEGASWAP_AFFILIATE_ADDRESS } from 'constants/AppConstants';

export type SupportedDestinationTickers =
  | 'DUSD'
  | 'DESO'
  | 'USDC'
  | 'DBTC'
  | 'DETH'
  | 'DSOL'
  | 'BTC'
  | 'ETH'
  | 'SOL'
  | 'USDT';
export interface DepositAddressGroup {
  DepositAddressGroupToken?: string; // may be missing if optional DestinationAddress exists
  DepositAddresses: {
    BTC: string;
    ETH: string;
    SOL: string;
    'USDC-SOL': string;
    USDC: string;
    USDT: string;
    DUSD: string;
    DESO: string;
    DBTC: string;
    DETH: string;
    DSOL: string;
  };
  DestinationAddress?: string;
  DestinationTicker: SupportedDestinationTickers;
}

export interface DepositAddressGroups {
  DUSD: DepositAddressGroup;
  DESO: DepositAddressGroup;
  DBTC: DepositAddressGroup;
  DETH: DepositAddressGroup;
  DSOL: DepositAddressGroup;
}

interface GetAddrsParams {
  DestinationAddress?: string;
}

export interface DestinationAmountForDepositAmount {
  DestinationTicker: SupportedDestinationTickers;
  DepositTicker: Ticker;
  DepositFeeDeducted: string;
  DepositAmount: string;
  DestinationAmount: string;
  SwapRateDestinationTickerPerDepositTicker: string;
}

export type DepositStatus =
  | 'DEPOSIT_PENDING'
  | 'DEPOSIT_CONFIRMATION_FAILED'
  | 'DEPOSIT_CONFIRMED'
  | 'DEPOSIT_CANCELLED'
  | 'DESTINATION_TRANSFER_RUNNING'
  | 'DESTINATION_TRANSFER_PENDING'
  | 'DESTINATION_TRANSFER_FAILED'
  | 'DESTINATION_TRANSFER_TERMINATED'
  | 'DESTINATION_TRANSFER_CONFIRMATION_FAILED'
  | 'DESTINATION_TRANSFER_RETRIED'
  | 'DESTINATION_TRANSFER_CONFIRMED'
  | 'DESTINATION_TRANSFER_CANCELLED';

export interface DepositEvent {
  DepositTicker: Ticker;
  DepositTxId: string;
  DepositAddress: string;
  DepositAmount: string;
  DepositStatus: DepositStatus;
  DestinationTicker: SupportedDestinationTickers;
  DestinationTxId: string;
  DestinationAddress: string;
  DestinationAmount: string;
  UpdatedAt: string;
  CreatedAt: string;
  NetworkFeeInDepositTicker: string;
}

interface PollNewDepositsResponse {
  Deposits: DepositEvent[];
}

const POLL_NEW_DEPOSITS_DURATION = 5000;
const POLL_GET_DEPOSITS_DURATION = 2000;
const MAX_POLL_COUNT = 300;

export class HeroSwapper {
  private axios: AxiosInstance;
  private depositAddressGroupsCache: Record<string, DepositAddressGroups> = {};
  private confirmedDestinationTransferPollId: number | undefined;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getDepositAddressGroups({ DestinationAddress }: GetAddrsParams = {}): Promise<DepositAddressGroups> {
    if (DestinationAddress === null || DestinationAddress === undefined || DestinationAddress === '') {
      throw new Error('Unable to generate deposit addresses. DestinationAddress is invalid.');
    }

    if (this.depositAddressGroupsCache[DestinationAddress]) {
      return this.depositAddressGroupsCache[DestinationAddress];
    }

    const [deSoUSDCreateResp, desoCreateResp, dBTCCreateResp, dETHCreateResp, dSOLCreateResp] = await Promise.all([
      this.fetchAddress('DUSD', DestinationAddress),
      this.fetchAddress('DESO', DestinationAddress),
      this.fetchAddress('DBTC', DestinationAddress),
      this.fetchAddress('DETH', DestinationAddress),
      this.fetchAddress('DSOL', DestinationAddress),
    ]);

    this.depositAddressGroupsCache[DestinationAddress] = {
      DUSD: deSoUSDCreateResp.data,
      DESO: desoCreateResp.data,
      DBTC: dBTCCreateResp.data,
      DETH: dETHCreateResp.data,
      DSOL: dSOLCreateResp.data,
    };

    return this.depositAddressGroupsCache[DestinationAddress];
  }

  async getOrCreateCashOutAddressGroup(
    cashOutAddress: string,
    destinationAddress: SupportedDestinationTickers = 'USDC',
  ): Promise<DepositAddressGroup> {
    const { data } = await this.axios.post('addrs', {
      DestinationAddress: cashOutAddress,
      DestinationTicker: destinationAddress,
      AffiliateAddress: OPEN_FUND_MEGASWAP_AFFILIATE_ADDRESS,
    });

    return data;
  }

  pollNewDepositOnce(depositAddress: string, ticker: string): Promise<DepositEvent | null> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`new-deposits/${ticker}/${depositAddress}`)
        .then((res) => {
          const pollResponse = res.data as PollNewDepositsResponse;
          if (!pollResponse.Deposits?.length) {
            resolve(null);
          }
          const confirmed = pollResponse.Deposits.find(({ DepositStatus }) => DepositStatus === 'DEPOSIT_CONFIRMED');
          if (confirmed) {
            resolve(confirmed);
          } else {
            resolve(null);
          }
        })
        .catch(reject);
    });
  }

  pollNewDeposits(depositAddress: string, ticker: string): Promise<DepositEvent> {
    return new Promise(async (resolve, reject) => {
      // Call it once before setInterval gets going
      {
        let res = await this.pollNewDepositOnce(depositAddress, ticker).catch((e) => {
          reject(e);
        });
        if (res) {
          resolve(res);
        }
      }

      let pollCount = 0;
      const timerId = setInterval(async () => {
        if (pollCount >= MAX_POLL_COUNT) {
          clearInterval(timerId);
          reject(new Error('Error discovering new deposits. Max poll count reached.'));
          return;
        }

        pollCount++;
        let res = await this.pollNewDepositOnce(depositAddress, ticker).catch((e) => {
          clearInterval(timerId);
          reject(e);
        });
        if (res) {
          clearInterval(timerId);
          resolve(res);
        }
      }, POLL_NEW_DEPOSITS_DURATION);
    });
  }

  async getRecentDepositEventsForDepositAddress(ticker: Ticker, depositAddress: string): Promise<DepositEvent[]> {
    const { data } = await this.axios.get(`deposits/${ticker}/${depositAddress}`);
    return (
      (data.Deposits as DepositEvent[])?.sort((a, b) => {
        return new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime();
      }) ?? []
    );
  }

  async pollForConfirmedDestinationTransfer(
    ticker: Ticker,
    depositAddress: string,
    txId: string,
    afterEachPoll?: (allEvents: DepositEvent[]) => void,
  ): Promise<DepositEvent | null> {
    return new Promise((resolve, reject) => {
      const pollCount = 0;
      const intervalCallback = () => {
        if (pollCount >= MAX_POLL_COUNT) {
          clearInterval(this.confirmedDestinationTransferPollId);
          reject(new Error('Error detecting deposit transfer status. Max poll count reached.'));
          return;
        }

        this.axios
          .get(`deposits/${ticker}/${depositAddress}`)
          .then((res) => {
            const pollResponse = res.data as PollNewDepositsResponse;
            if (!pollResponse.Deposits?.length) {
              resolve(null);
            }

            pollResponse.Deposits.sort((a, b) => {
              return new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime();
            });

            afterEachPoll?.(pollResponse.Deposits);

            const confirmed = pollResponse.Deposits.find(
              ({ DepositStatus, DepositTxId }) =>
                DepositTxId === txId && DepositStatus === 'DESTINATION_TRANSFER_CONFIRMED',
            );

            if (confirmed) {
              clearInterval(this.confirmedDestinationTransferPollId);
              resolve(confirmed);
            }
          })
          .catch((e) => {
            reject(e);
            clearInterval(this.confirmedDestinationTransferPollId);
          });
      };
      // call once eagerly
      intervalCallback();
      // start polling
      this.confirmedDestinationTransferPollId = window.setInterval(intervalCallback, POLL_GET_DEPOSITS_DURATION);
    });
  }

  cancelPollingForConfirmedDestinationTransfer() {
    clearInterval(this.confirmedDestinationTransferPollId);
  }

  pollDepositTransferStatusOnce(depositAddress: string, ticker: string, txId: string): Promise<DepositEvent | null> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`deposits/${ticker}/${depositAddress}`)
        .then((res) => {
          const pollResponse = res.data as PollNewDepositsResponse;
          if (!pollResponse.Deposits?.length) {
            resolve(null);
          }
          const confirmed = pollResponse.Deposits.find(
            ({ DepositStatus, DepositTxId }) =>
              DepositTxId === txId && DepositStatus === 'DESTINATION_TRANSFER_CONFIRMED',
          );
          if (confirmed) {
            resolve(confirmed);
          } else {
            resolve(null);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  pollDepositTransferStatus(depositAddress: string, ticker: string, txId: string): Promise<DepositEvent> {
    return new Promise((resolve, reject) => {
      const pollCount = 0;
      const timerId = setInterval(() => {
        if (pollCount >= MAX_POLL_COUNT) {
          clearInterval(timerId);
          reject(new Error('Error detecting deposit transfer status. Max poll count reached.'));
          return;
        }

        this.pollDepositTransferStatusOnce(depositAddress, ticker, txId).then(
          (res) => {
            if (res) {
              resolve(res);
              clearInterval(timerId);
              return;
            }
          },
          (err) => {
            reject(err);
            clearInterval(timerId);
            return;
          },
        );
      }, POLL_GET_DEPOSITS_DURATION);
    });
  }

  async calcCurrencySwapAmount(
    depositTicker: Ticker,
    destinationTicker: SupportedDestinationTickers,
    amount: string,
  ): Promise<DestinationAmountForDepositAmount> {
    if (Number(amount) === 0) {
      return {
        DestinationTicker: destinationTicker,
        DepositTicker: depositTicker,
        DepositFeeDeducted: '0',
        DepositAmount: '0',
        DestinationAmount: '0',
        SwapRateDestinationTickerPerDepositTicker: '0',
      };
    }

    const { data } = await this.axios.get(
      `destination-amount-for-deposit-amount/${depositTicker}/${destinationTicker}/${amount}`,
    );

    return data;
  }

  private fetchAddress(ticker: SupportedDestinationTickers, destinationAddress: string) {
    return this.axios
      .post('addrs', {
        DestinationAddress: destinationAddress,
        DestinationTicker: ticker,
        AffiliateAddress: OPEN_FUND_MEGASWAP_AFFILIATE_ADDRESS,
      })
      .catch(() => ({ data: null }));
  }
}
