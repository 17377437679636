'use client';

import { createUserAssociation, deleteUserAssociation, getUserAssociations } from 'deso-protocol';
import { useContext, useState } from 'react';
import { LuExternalLink, LuEye, LuEyeOff, LuFlame, LuSendHorizontal, LuWrench, LuEllipsis } from 'react-icons/lu';

import { AssociationDeSoTokenWhitelistValue, DESO_TOKEN_NAME } from '../../utils/constants';

import { OpenfundContext } from '../../contexts/OpenfundContext';
import { toast } from '../hooks/use-toast';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../shadcn/ui/dropdown-menu';
import { Button } from '../shadcn/ui/button';
import { useWaitForTransaction } from '../../hooks/useWaitForTransaction';
import { FOCUS_URL } from '../../constants/AppConstants';

interface WalletTokenActionsProps {
  coinUsername: string;
  coinPublicKey: string;
  refetchData?: () => void;
  isMyWallet?: boolean;
  isWhitelist?: boolean;
  setLoading?: (loading: boolean) => void;
  onSendTokens: () => void;
  onBurnTokens: () => void;
}

const WalletTokenActions = ({
  coinUsername,
  coinPublicKey,
  refetchData,
  isMyWallet = false,
  isWhitelist = true,
  setLoading,
  onSendTokens,
  onBurnTokens,
}: WalletTokenActionsProps) => {
  const { currentUser } = useContext(OpenfundContext);
  const { waitForTxn } = useWaitForTransaction();

  const [open, setOpen] = useState(false);

  const handleRefetchData = async () => {
    await refetchData?.();
    setOpen(false);
  };

  const setWhitelistStatus = async () => {
    if (!currentUser) {
      return;
    }

    setLoading?.(true);

    const payload = {
      TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      TargetUserPublicKeyBase58Check: coinPublicKey,
      AssociationType: AssociationDeSoTokenWhitelistValue.DeSoTokenWhitelistAssociationKey,
      AssociationValue: AssociationDeSoTokenWhitelistValue.DeSoTokenWhitelistAssociationKey,
    };

    try {
      if (isWhitelist) {
        const { Associations } = await getUserAssociations(payload);

        if (!Associations?.length) {
          toast({
            title: 'Error',
            description: `The coin cannot be moved right now. Please try again later.`,
            variant: 'destructive',
          });

          setLoading?.(false);

          return;
        }

        const [{ TransactorPublicKeyBase58Check, AssociationID }] = Associations;

        const data = await deleteUserAssociation({
          TransactorPublicKeyBase58Check,
          AssociationID,
        });

        if (!data.submittedTransactionResponse?.TxnHashHex) {
          throw new Error('Your coin was not moved properly. Please try again.');
        }

        await waitForTxn(data.submittedTransactionResponse.TxnHashHex);

        toast({
          title: 'Success',
          description: `${coinUsername} was successfully moved to your Hidden Tokens`,
          variant: 'success',
        });
      } else {
        const data = await createUserAssociation({
          TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
          TargetUserPublicKeyBase58Check: coinPublicKey,
          AssociationType: AssociationDeSoTokenWhitelistValue.DeSoTokenWhitelistAssociationKey,
          AssociationValue: AssociationDeSoTokenWhitelistValue.DeSoTokenWhitelistAssociationKey,
        });

        if (!data.submittedTransactionResponse?.TxnHashHex) {
          throw new Error('Your coin was not moved properly. Please try again.');
        }

        await waitForTxn(data.submittedTransactionResponse.TxnHashHex);

        toast({
          title: 'Success',
          description: `${coinUsername} was successfully moved to your ${DESO_TOKEN_NAME}s`,
          variant: 'success',
        });
      }

      await handleRefetchData();
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: `An error happened when moving your ${DESO_TOKEN_NAME}s`,
      });

      setLoading?.(false);
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="size-full rounded-full border-none text-sm hover:bg-transparent"
        >
          <LuEllipsis className="size-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent sideOffset={-8}>
        {coinPublicKey === currentUser?.PublicKeyBase58Check && (
          <>
            <DropdownMenuItem className="cursor-pointer text-inherit p-0">
              <a
                href={`${FOCUS_URL}/settings/trade-coin`}
                target="_blank"
                className="py-1.5 px-2 w-full flex items-center gap-2"
                rel="noreferrer"
              >
                <LuWrench className="size-4 !text-muted" />
                Manage Your Token
              </a>
            </DropdownMenuItem>
          </>
        )}
        <DropdownMenuItem className="cursor-pointer text-inherit p-0">
          <a
            href={`/trade/${coinUsername}`}
            target="_blank"
            className="py-1.5 px-2 w-full flex items-center gap-2"
            rel="noreferrer"
          >
            <LuExternalLink className="size-4 text-muted" />
            Trade
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer text-inherit gap-2" onClick={onSendTokens}>
          <LuSendHorizontal className="size-4 text-muted" />
          Send Tokens
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer text-inherit gap-2" onClick={onBurnTokens}>
          <LuFlame className="size-4 text-muted" />
          Burn Tokens
        </DropdownMenuItem>
        {!isMyWallet && (
          <DropdownMenuItem
            className="flex cursor-pointer items-center gap-2 text-inherit"
            onClick={setWhitelistStatus}
          >
            {isWhitelist ? <LuEyeOff className="size-4 text-muted" /> : <LuEye className="size-4 text-muted" />}
            Move To {isWhitelist ? 'Hidden' : 'My Tokens'}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default WalletTokenActions;
