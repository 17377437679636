import { useLazyQuery } from '@apollo/client';
import isNil from 'lodash/isNil';
import { useContext, useEffect, useState } from 'react';
import { LuClock, LuLock } from 'react-icons/lu';

import LowNumFormatter from './LowNumFormatter';
import { Button } from '../shadcn/ui/button';
import { cn } from 'utils/shadcn';
import { OpenfundContext } from '../../contexts/OpenfundContext';
import {
  LockedBalanceEntriesOrderBy,
  LockedBalanceEntry,
  LockedTokenBalancesDocument,
} from '../../graphql/codegen/graphql';
import { UnlockTokensModal } from './UnlockTokensModal';

export const WalletLockedTokensCell = ({
  totalLockedUsdValue,
  coinPublicKey,
  coinUsername,
  refetchData,
  coinPrice,
  showValue = true,
  triggerBtnClassName = '',
}: {
  totalLockedUsdValue?: number | null;
  coinPublicKey?: string | null;
  coinUsername?: string | null;
  refetchData?: () => void;
  coinPrice?: number | null;
  showValue?: boolean;
  triggerBtnClassName?: string;
}) => {
  const { currentUser, loadingUser } = useContext(OpenfundContext);

  const [getLockedBalances] = useLazyQuery(LockedTokenBalancesDocument);
  const [open, setOpen] = useState(false);
  const [lockedBalancesEntries, setLockedBalancesEntries] = useState<Partial<LockedBalanceEntry>[]>([]);

  useEffect(() => {
    (async () => {
      if (!coinPublicKey || !currentUser || loadingUser) return;

      const { data } = await getLockedBalances({
        variables: {
          filter: {
            profilePkid: {
              equalTo: coinPublicKey,
            },
            hodlerPkid: {
              equalTo: currentUser.PublicKeyBase58Check,
            },
          },
          orderBy: LockedBalanceEntriesOrderBy.UnlockTimestampNanoSecsAsc,
        },
        fetchPolicy: 'network-only',
      });

      const newLockedBalancesEntries = (data?.lockedBalanceEntries?.nodes ?? []) as Array<LockedBalanceEntry>;

      setLockedBalancesEntries(newLockedBalancesEntries);
    })();
  }, [coinPublicKey, currentUser, loadingUser, getLockedBalances, totalLockedUsdValue]);

  return (
    <>
      {open && !!lockedBalancesEntries.length && !!coinPublicKey && !!coinUsername && !isNil(coinPrice) && (
        <UnlockTokensModal
          {...{
            lockedBalancesEntries,
            coinPublicKey,
            coinUsername,
            coinPrice,
            open,
            setOpen,
          }}
          onUnlockSuccess={refetchData}
        />
      )}

      {!!totalLockedUsdValue && totalLockedUsdValue > 0 && (
        <div className="flex flex-col gap-2">
          {showValue && (
            <div className="flex items-center gap-1 text-muted">
              <LuLock />
              <LowNumFormatter price={totalLockedUsdValue} />
            </div>
          )}
          <Button
            variant="ghost"
            size="xs"
            onClick={() => {
              setOpen(true);
            }}
            className={cn('m-0 mb-2 flex h-0 items-center gap-1 p-0', triggerBtnClassName)}
          >
            <LuClock />
            View Unlocks
          </Button>
        </div>
      )}
    </>
  );
};
