import { getCoinProperties, GetCoinPropertiesResponse } from 'deso-protocol';
import { useContext, useEffect, useState } from 'react';
import { BaseCurrencyPriceEntry } from 'services/Deso';
import { AMM_URL, DESO_ZERO_PUBLIC_KEY } from '../constants/AppConstants';
import { DESO_TOKEN_PUBLIC_KEY } from '../constants/TradeConstants';
import { deso } from '../services';
import { QuoteCurrencyContext } from '../contexts/QuoteCurrencyContext';

type UseCoinPropertiesProps = {
  publicKey: string;
};

export type CoinPropertiesResponse = {
  coinProperties: GetCoinPropertiesResponse;
  coinPrice: BaseCurrencyPriceEntry | null;
  ammRunning: boolean;
  hasCoin: boolean;
  hasAmmConfigs: boolean;
};

export const useCoinProperties = ({ publicKey }: UseCoinPropertiesProps) => {
  const { quoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const [data, setData] = useState<CoinPropertiesResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const coinPublicKey = publicKey === DESO_TOKEN_PUBLIC_KEY ? DESO_ZERO_PUBLIC_KEY : publicKey;

  useEffect(() => {
    const fetchData = async () => {
      if (!coinPublicKey) {
        setData(null);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const [coinProperties, coinPrice] = await Promise.all([
          getCoinProperties(
            {
              BaseCurrencyPublicKey: coinPublicKey,
            },
            {
              nodeURI: AMM_URL,
            },
          ),
          deso.getBaseCurrencyPrice([
            {
              BaseCurrencyPublicKeyBase58Check: coinPublicKey,
              QuoteCurrencyPublicKeyBase58Check: quoteCurrencyPublicKey,
              BaseCurrencyQuantityToSell: 1,
            },
          ]),
        ]);

        const coinRunAtDate = coinProperties?.RunAt?.[0];
        const ammRunning = coinRunAtDate ? new Date(coinRunAtDate).getTime() < Date.now() : true;
        const hasAmmConfigs = Boolean(coinProperties?.AmmConfigs?.length);
        const hasCoin =
          parseInt((coinProperties?.Profile?.DAOCoinEntry?.CoinsInCirculationNanos as unknown as string) || '0x0', 16) >
          0;

        setData({
          coinProperties,
          coinPrice: hasAmmConfigs || hasCoin ? (coinPrice?.[0] ?? null) : null,
          ammRunning,
          hasAmmConfigs,
          hasCoin,
        });
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [coinPublicKey, quoteCurrencyPublicKey]);

  return { data, loading, error };
};
