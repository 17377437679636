import { ExternalLink } from 'components/core/ExternalLink';
import { useSettingsContext } from 'components/pages/Settings';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';

export function ProjectDistribution() {
  useDocumentTitle('Distribution');
  const { setHeadingSuffix } = useSettingsContext();
  useEffectOnce(() => setHeadingSuffix('Distribution'));

  return (
    <div className="w-full">
      <div className="font-semibold text-xl text-muted-foreground">Distribution</div>
      <div className="mt-4 flex flex-col gap-4 text-muted text-sm">
        <p>This page will allow you to manage distributions of funds back to your token-holders.</p>
        <p>
          You will be able to deposit and distribute tokens using either a pro rata scheme or a custom distribution
          scheme, where you can detail exactly how much each holder should receive.
        </p>
        <p>
          Currently, you can use{' '}
          <ExternalLink href="https://desoops.com" target="_blank" className="underline underline-offset-4">
            DeSo Ops
          </ExternalLink>{' '}
          to manage your token distributions instead.
        </p>
      </div>
    </div>
  );
}

export default ProjectDistribution;
