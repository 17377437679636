import { Input, InputProps } from 'components/core/Input';
import { useEffect, useState } from 'react';

const DECIMAL_NUMBER_REGEX = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
function mask(prefix: string, suffix: string, value: string, allowedDecimalPlaces?: number): string {
  const cleanedInput = value.replace(/[^\d.]/g, '');
  const parsedNumber =
    (cleanedInput.startsWith('.') ? `0${cleanedInput}` : cleanedInput).match(DECIMAL_NUMBER_REGEX)?.[0] ?? '0';
  const [whole, decimal] = parsedNumber.split('.');
  const formattedWhole =
    Number(whole) === 0 ? value.replace(/[^\d.,]/g, '').split('.')[0] : Number(whole).toLocaleString('en-US');

  return `${prefix}${formattedWhole}${
    typeof decimal !== 'undefined' &&
    ((typeof allowedDecimalPlaces === 'number' && allowedDecimalPlaces > 0) ||
      typeof allowedDecimalPlaces === 'undefined')
      ? `.${typeof allowedDecimalPlaces === 'number' ? decimal.substring(0, allowedDecimalPlaces) : decimal}`
      : ''
  }${suffix}`;
}

function acceptValue(maskedValue: string) {
  const result = maskedValue.replace(/[^\d.]/g, '');
  return result.startsWith('.') ? `0${result}` : result;
}

export type NumberInputProps = {
  prefix?: string;
  suffix?: string;
  initialValue?: string | number;
  allowedDecimalPlaces?: number | undefined;
  onValueChange?: (value: string) => void;
} & InputProps;

export function NumberInput({
  prefix = '',
  suffix = '',
  initialValue = 0,
  value,
  allowedDecimalPlaces,
  onValueChange,
  max,
  ...rest
}: NumberInputProps) {
  // it is very easy to accidentally pass the value prop, but we need to manage
  // it internally and things go haywire with value set externally and
  // internally. So if value is passed, we just use it as the initial value. If
  // both of them are passed we favor value.
  const passedValue = typeof value !== 'undefined' ? value : initialValue;
  const [internalValue, setInternalValue] = useState(
    mask(prefix, suffix, passedValue.toString(), allowedDecimalPlaces),
  );

  useEffect(() => {
    const maskedValue = mask(prefix, suffix, passedValue.toString(), allowedDecimalPlaces);
    setInternalValue(maskedValue);
    onValueChange?.(acceptValue(maskedValue));
  }, [prefix, suffix, passedValue, allowedDecimalPlaces]);

  return (
    <Input
      role="spinbutton"
      value={internalValue}
      onInput={(ev) => {
        const val = ev.currentTarget.value;
        const currSelectionStart = ev.currentTarget.selectionStart ?? val.length;
        const currSelectionEnd = ev.currentTarget.selectionEnd ?? val.length;
        const maskedValue = mask(prefix, suffix, val, allowedDecimalPlaces);
        const offset = maskedValue.length - val.length;
        ev.currentTarget.value = maskedValue;
        setInternalValue(maskedValue);
        onValueChange?.(acceptValue(maskedValue));
        ev.currentTarget.setSelectionRange(currSelectionStart + offset, currSelectionEnd + offset);
      }}
      onKeyDown={(ev) => {
        const val = ev.currentTarget.value;
        const cleanedInput = val.replace(/[^\d.]/g, '');
        const parsedNumber = cleanedInput.match(DECIMAL_NUMBER_REGEX)?.[0] ?? '0';
        const [whole, decimal] = parsedNumber.split('.');
        const wholeNumber = Number(whole);
        let newWholeNumberValue: number | undefined;

        switch (ev.key) {
          case 'ArrowUp':
            newWholeNumberValue =
              typeof max !== 'undefined' && wholeNumber === Number(max) ? wholeNumber : wholeNumber + 1;
            break;
          case 'ArrowDown':
            if (wholeNumber > 0) {
              newWholeNumberValue = wholeNumber - 1;
            }
            break;
        }

        if (typeof newWholeNumberValue !== 'undefined') {
          const newInputValue = `${newWholeNumberValue}${
            typeof decimal !== 'undefined' && newWholeNumberValue !== Number(max) ? `.${decimal}` : ''
          }`;
          const maskedValue = mask(prefix, suffix, newInputValue, allowedDecimalPlaces);
          ev.currentTarget.value = maskedValue;
          setInternalValue(maskedValue);
          onValueChange?.(acceptValue(maskedValue));
        }
      }}
      {...rest}
    />
  );
}
