import React, { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '../shadcn/ui/dialog';
import { Button } from '../shadcn/ui/button';
import { Checkbox } from '../shadcn/ui/checkbox';
import { ExternalLink } from './ExternalLink';

interface LoginModalProps {
  children: React.ReactNode;
  onClick: (closeModal: () => void) => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ children, onClick }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (checkedState: boolean) => {
    setChecked(checkedState);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="p-12">
        <div className="flex flex-col gap-6 items-center justify-center">
          <img src="/images/logo-deso.svg" alt="DeSo Logo" width={80} height={0} />
          <p className="text-xl text-muted-foreground font-sans font-bold">Create or Import DeSo Wallet</p>
        </div>
        <div className="mt-4 flex items-center flex-col text-center text-balance">
          <p>
            Your DeSo Wallet is required to trade tokens on Openfund. Your wallet can be used to transfer and store your
            assets.
          </p>
        </div>

        <div className="flex items-center mt-4 gap-2 justify-center mx-auto text-muted p-2 px-3 rounded-full pr-3 bg-card w-fit">
          <Checkbox
            id="terms"
            checked={checked}
            onCheckedChange={handleCheckboxChange}
            className="relative inline-block"
          />
          <label
            htmlFor="terms"
            className="text-xs font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-auto"
          >
            I have read, understood & agree to be bound by the{' '}
            <ExternalLink
              href="https://docs.google.com/document/u/2/d/e/2PACX-1vRR2-RecgUjNE8HEiD2Mg2PBtMfJ3BJ9Z6fZ0SFxMSuSstMaoLHGwrkppQp3aseiIh_gWSrqgUv9Yh9/pub"
              target="_blank"
              className="text-underline text-muted hover:text-muted underline-offset-4"
            >
              Terms of Service
            </ExternalLink>
          </label>
        </div>

        <DialogFooter className="flex justify-center p-0 mt-6">
          <Button onClick={() => onClick(() => setOpen(false))} disabled={!checked}>
            Create or Import Wallet
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
