import { Menu, Transition } from '@headlessui/react';
import { CreatePost } from 'components/app-ui/CreatePost';
import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/core/Button';
import { DiamondLogo } from 'components/core/DiamondLogo';
import { Modal } from 'components/core/Modal';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { DESO_ZERO_PUBLIC_KEY, FOCUS_URL, MIN_FEE_RATE_NANOS_PER_KB } from 'constants/AppConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { PostEntryResponse, ProfileEntryResponse } from 'deso-protocol';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { Fragment, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import {
  IoChatbubbleEllipsesOutline,
  IoEllipsisHorizontal,
  IoEyeOffOutline,
  IoHeart,
  IoHeartOutline,
  IoPencil,
  IoRepeatOutline,
} from 'react-icons/io5';
import { LuRepeat2 } from 'react-icons/lu';
import { Mention, MentionsInput } from 'react-mentions';
import { NavLink } from 'react-router-dom';
import { confetti, deso, openfund } from 'services';
import { ConfettiSvg } from 'services/Confetti';
import { classNames } from 'utils/classNames';
import { desoNanosToUSD, formatUSD, totalAssets } from 'utils/currency';
import { getWrappedAsset, getWrappedAssetIcon } from 'utils/deso';
import { getErrorMsg } from 'utils/getErrorMsg';
import { v4 as uuid } from 'uuid';
import { Routes } from '../../RoutePaths';
import { getDateDifferenceStringFromTstampNanos } from '../../utils/date';
import { RouteLink } from '../core/RouteLink';
import { ActivityFeedContentBody } from './ActivityFeedContentBody';
import { PostImage } from './PostImage';
import { PostVideo } from './PostVideo';
import { WealthBadge } from './WealthBadge';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { ExternalLink } from 'components/core/ExternalLink';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/shadcn/ui/dropdown-menu';

type ActivityFeedItemProps = {
  post: PostEntryResponse;
  truncateContent?: boolean;
  showCommentsByDefault?: boolean;
  onNavigateToDetailView?: (post: PostEntryResponse) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type ActivityFeedContentProps = {
  post: PostEntryResponse;
  postLevel: number;
  truncateContent?: boolean;
  onCommentClick?: (ev: MouseEvent) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function GetPosterAvatar(post: PostEntryResponse) {
  const url =
    IsVanillaRepost(post) && post.RepostedPostEntryResponse
      ? post.RepostedPostEntryResponse.PosterPublicKeyBase58Check
      : (post.PosterPublicKeyBase58Check ?? '');

  return deso.profilePicUrl(url);
}

function IsVanillaRepost(post: PostEntryResponse): boolean {
  return !!post.RepostedPostEntryResponse && !(post.Body || post.ImageURLs || post.VideoURLs);
}

function GetPostContent(post: PostEntryResponse): PostEntryResponse {
  return IsVanillaRepost(post) && post.RepostedPostEntryResponse ? post.RepostedPostEntryResponse : post;
}

type CommentInputProps = {
  inputId?: string;
  parentPostHashHex: string;
  onCommentCreated: (comment: PostEntryResponse) => void;
  replyMention?: string;
} & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
function CommentInput({
  parentPostHashHex,
  className,
  onCommentCreated,
  replyMention = '',
  inputId,
  ...rest
}: CommentInputProps) {
  const { currentUser } = useContext(OpenfundContext);
  const suggestedProfiles = useRef<{ [key: string]: ProfileEntryResponse }>({});
  const [postBody, setPostBody] = useState(replyMention.length > 0 ? `${replyMention} ` : '');
  const [mentionsInputValue, setMentionsInputValue] = useState(replyMention.length > 0 ? `${replyMention} ` : '');
  const [isPendingCreatePost, setIsPendingCreatePost] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (replyMention) {
      const autoMention = `${replyMention} `;
      setPostBody(autoMention);
      setMentionsInputValue(autoMention);
    }
  }, [replyMention]);

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      className={className}
      onSubmit={async (ev) => {
        ev.preventDefault();
        if (isPendingCreatePost) return;

        let PublicKeyBase58Check = currentUser?.PublicKeyBase58Check;

        if (!currentUser) {
          try {
            const user = await openfund.login();
            PublicKeyBase58Check = user?.PublicKeyBase58Check;
          } catch (e) {
            toast.show({ message: getErrorMsg(e), type: 'error' });
          }
        }

        if (!PublicKeyBase58Check) {
          return;
        }

        let cleanPostBody = postBody.trim();
        if (cleanPostBody.length === 0) {
          return;
        }

        setIsPendingCreatePost(true);
        try {
          const createPostResponse = await deso.createPost({
            UpdaterPublicKeyBase58Check: PublicKeyBase58Check,
            PostHashHexToModify: '',
            ParentStakeID: parentPostHashHex,
            BodyObj: {
              Body: cleanPostBody,
              ImageURLs: null,
              VideoURLs: null,
            },
            RepostedPostHashHex: '',
            PostExtraData: {},
            IsHidden: false,
            MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
            TransactionFees: null,
            InTutorial: false,
            IsFrozen: false,
          });
          if (createPostResponse.submittedTransactionResponse?.PostEntryResponse) {
            onCommentCreated(createPostResponse.submittedTransactionResponse.PostEntryResponse);
          }
        } catch (e) {
          toast.show({ message: getErrorMsg(e), type: 'error' });
        }
        setIsPendingCreatePost(false);
        setPostBody('');
        setMentionsInputValue('');
      }}
      {...rest}
    >
      <div className="flex border border-border-light items-center rounded-2xl p-4 bg-accent">
        <div className="flex-shrink-0 mr-4">
          <Avatar border="none" src={currentUser?.PublicKeyBase58Check} />
        </div>
        <div className="relative flex-1">
          <MentionsInput
            id={inputId}
            className="text-muted placeholder:text-muted"
            style={{
              control: {
                borderRadius: '0.4rem',
              },
              highlighter: {
                padding: 9,
                border: '1px solid transparent',
                // NOTE: this highlighter thing is misaligned on mobile. hiding it until time to fix it.
                opacity: 0,
              },
              input: {
                padding: 9,
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
              },
            }}
            placeholder="Add a comment..."
            a11ySuggestionsListLabel="Suggested mentions"
            value={mentionsInputValue}
            onChange={(_, newValue, newPlainTextValue) => {
              setMentionsInputValue(newValue);
              setPostBody(newPlainTextValue);
            }}
          >
            <Mention
              trigger="@"
              markup="@[__display__](__id__)"
              displayTransform={(id, display) => `${display}`}
              className="border border-border-light rounded-2xl"
              data={async (query, callback) => {
                if (!query) return;
                const res = await deso.getProfilesByUsername(query, currentUser?.PublicKeyBase58Check, 10);
                suggestedProfiles.current =
                  res.reduce((result, profile) => {
                    result[profile.PublicKeyBase58Check] = profile;
                    return result;
                  }, suggestedProfiles.current) ?? {};
                callback(res.map((p) => ({ id: p.PublicKeyBase58Check, display: `@${p.Username}` })) ?? []);
              }}
              renderSuggestion={(suggestion, query, highlightedDisplay, index, focused) => {
                return (
                  <button
                    className={classNames(
                      'w-full text-left p-4 bg-background',
                      focused && 'bg-card text-muted-foreground',
                    )}
                  >
                    <Avatar
                      border="none"
                      src={suggestedProfiles.current?.[suggestion.id]?.PublicKeyBase58Check}
                      className="mr-2"
                    />
                    <span className="text-muted-foreground">{highlightedDisplay}</span>
                  </button>
                );
              }}
              appendSpaceOnAdd={true}
              style={{
                color: '#3586ff',
                position: 'relative',
                zIndex: 1,
                top: '-2px',
                left: '-2px',
                textShadow: '1px -1px 1px #fff',
              }}
            />
          </MentionsInput>
        </div>
      </div>
      <div className="flex mt-2">
        <ButtonNew variant="outline" className="ml-auto" type="submit">
          {isPendingCreatePost ? <FiLoader className="rotate" /> : 'Submit'}
        </ButtonNew>
      </div>
    </form>
  );
}

function flattenThread(root?: PostEntryResponse | null): PostEntryResponse[] {
  if (!root) {
    return [];
  }

  const walkSubcomments = (subComment: PostEntryResponse, cb: Function) => {
    if (subComment) {
      cb(subComment);
    }

    if (Array.isArray(subComment?.Comments)) {
      walkSubcomments(subComment.Comments[0], cb);
    }
  };

  const flatten = (parent: PostEntryResponse) => {
    const thread: PostEntryResponse[] = [];

    if (Array.isArray(parent.Comments)) {
      parent.Comments.forEach((comment) => {
        walkSubcomments(comment, (subComment: PostEntryResponse) => {
          thread.push(subComment);
        });
      });
    }

    return thread;
  };

  return flatten(root);
}

type CommentSectionProps = {
  parentPostHashHex: string;
  isSubcommentSection: boolean;
  onCommentSubmitted: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
function CommentSection({
  className,
  parentPostHashHex,
  onCommentSubmitted,
  isSubcommentSection,
}: CommentSectionProps) {
  const [commentsState, setCommentsState] = useState<{
    count: number;
    offset: number;
    comments: PostEntryResponse[];
  }>({
    count: 0,
    offset: 0,
    comments: [],
  });
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [replyMention, setReplyMention] = useState('');
  const isMounted = useIsMounted();
  const replyInputId = useRef(uuid());
  const [replyParentPostHashHex, setReplyParentPostHashHex] = useState(parentPostHashHex);
  const [hasMore, setHasMore] = useState(false);
  const limit = 10;

  useEffect(() => {
    setIsLoadingComments(true);
    deso
      .getSinglePost(parentPostHashHex, {
        CommentLimit: limit,
        ThreadLevelLimit: 2,
        ThreadLeafLimit: 1,
        CommentOffset: commentsState.offset,
      })
      .then((res) => {
        if (isMounted.current) {
          setCommentsState((prev) => ({
            ...prev,
            count: res.PostFound?.CommentCount ?? 0,
            comments: isSubcommentSection
              ? commentsState.comments.concat(flattenThread(res.PostFound))
              : commentsState.comments.concat(res.PostFound?.Comments ?? []),
          }));
        }
        setHasMore((res.PostFound?.Comments?.length ?? 0) === limit);
        setReplyMention(`@${res.PostFound?.ProfileEntryResponse?.Username}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoadingComments(false);
        }
      });
  }, [parentPostHashHex, commentsState.offset]);

  return (
    <div className={className}>
      {!isSubcommentSection && (
        <CommentInput
          parentPostHashHex={parentPostHashHex}
          className="mb-2"
          onCommentCreated={(comment) => {
            onCommentSubmitted();
            setCommentsState((prev) => ({
              ...prev,
              count: prev.count + 1,
              comments: [comment, ...prev.comments],
            }));
          }}
        />
      )}
      {commentsState.comments.length === 0 && isLoadingComments ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        commentsState.comments.length > 0 && (
          <div className="mb-8">
            <div className="text-xs text-muted mb-4">
              {commentsState.count === 1 ? '1 comment' : `${commentsState.count} comments`}
            </div>
            <div className="comment-thread-list">
              {commentsState.comments.map((c) => (
                <CommentItem
                  key={c.PostHashHex}
                  comment={c}
                  onReplyClick={
                    isSubcommentSection
                      ? (comment) => {
                          if (comment.ProfileEntryResponse?.Username) {
                            setReplyMention(`@${comment.ProfileEntryResponse?.Username}`);
                            setReplyParentPostHashHex(comment.PostHashHex);
                            setTimeout(() => {
                              document.getElementById(replyInputId.current)?.focus();
                            }, 50);
                          }
                        }
                      : undefined
                  }
                  className="mb-3 comment-thread"
                />
              ))}
            </div>
            {isLoadingComments && (
              <div className="text-center">
                <Spinner />
              </div>
            )}
            {hasMore && (
              <Button
                size="sm"
                kind="text-only-underline"
                className="ml-12 mb-10"
                onClick={() => {
                  setCommentsState((prev) => ({
                    ...prev,
                    offset: prev.offset + limit,
                  }));
                }}
              >
                Load more comments
              </Button>
            )}
          </div>
        )
      )}
      {isSubcommentSection && (
        <CommentInput
          parentPostHashHex={replyParentPostHashHex}
          replyMention={replyMention}
          inputId={replyInputId.current}
          onCommentCreated={(comment) => {
            onCommentSubmitted();
            setCommentsState((prev) => ({
              ...prev,
              count: prev.count + 1,
              comments: prev.comments.concat(comment),
            }));
          }}
        />
      )}
    </div>
  );
}

interface SocialActionRowProps {
  post: PostEntryResponse;
  isComment?: boolean;
  onCommentClick?: (ev: MouseEvent) => void;
}
function SocialActionRow({ post, onCommentClick, isComment = false }: SocialActionRowProps) {
  const { diamondLevelMap, currentUser } = useContext(OpenfundContext);
  const { exchangeRates } = useContext(QuoteCurrencyContext);
  const [postState, setPostState] = useState(post);
  const [likeCount, setLikeCount] = useState(post?.LikeCount);
  const [diamondCount, setDiamondCount] = useState(post?.DiamondCount);
  const [sendingDiamonds, setSendingDiamonds] = useState(false);
  const [isPendingRepost, setIsPendingRepost] = useState(false);
  const [isQuoteRepostModalOpen, setIsQuoteRepostModalOpen] = useState(false);
  const canHideRepost = !!postState.PostEntryReaderState?.RepostedByReader;
  const toast = useToast();

  async function likePost(event: any) {
    event.stopPropagation();
    event.preventDefault();
    if (currentUser?.PublicKeyBase58Check && !postState?.PostEntryReaderState?.LikedByReader) {
      const IsUnlike = postState?.PostEntryReaderState?.LikedByReader;

      try {
        await deso.likePost({
          ReaderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
          IsUnlike,
          MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
          LikedPostHashHex: postState.PostHashHex,
          TransactionFees: null,
        });
      } catch (e) {
        toast.show({ message: getErrorMsg(e), type: 'error' });
        return;
      }

      if (postState?.PostEntryReaderState) {
        postState.PostEntryReaderState.LikedByReader = !IsUnlike;
      } else {
        postState.PostEntryReaderState = {
          DiamondLevelBestowed: 0,
          LikedByReader: true,
          RepostedByReader: false,
          RepostPostHashHex: '',
        };
      }
      setLikeCount(postState.LikeCount + 1);
      setPostState(postState);
    }
    return;
  }

  async function diamondPost(event: any, DiamondLevel: number) {
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    if (
      currentUser?.PublicKeyBase58Check &&
      currentUser.PublicKeyBase58Check !== post.PosterPublicKeyBase58Check &&
      (!postState.PostEntryReaderState?.DiamondLevelBestowed ||
        DiamondLevel > postState.PostEntryReaderState?.DiamondLevelBestowed)
    ) {
      setSendingDiamonds(true);

      try {
        await deso.diamondPost({
          SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
          ReceiverPublicKeyBase58Check: post.PosterPublicKeyBase58Check,
          DiamondPostHashHex: post.PostHashHex,
          DiamondLevel,
          MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
          TransactionFees: null,
          InTutorial: false,
        });
        confetti.celebrate([ConfettiSvg.DIAMOND]);
      } catch (e) {
        toast.show({ message: getErrorMsg(e), type: 'error' });
        setSendingDiamonds(false);
        return;
      }
      const diamondLevelDiff = DiamondLevel - (postState.PostEntryReaderState?.DiamondLevelBestowed || 0);
      if (postState?.PostEntryReaderState?.DiamondLevelBestowed) {
        postState.PostEntryReaderState.DiamondLevelBestowed = DiamondLevel;
      } else {
        postState.PostEntryReaderState = {
          DiamondLevelBestowed: DiamondLevel,
          LikedByReader: false,
          RepostedByReader: false,
          RepostPostHashHex: '',
        };
      }
      postState.DiamondCount += diamondLevelDiff;
      setDiamondCount(postState.DiamondCount);
      setPostState(postState);
      setSendingDiamonds(false);
    }
  }

  function getUSDPerDiamondLevel(diamondLevel: number): string {
    if (exchangeRates && diamondLevelMap) {
      return formatUSD(
        desoNanosToUSD(diamondLevelMap[diamondLevel], exchangeRates[DESO_ZERO_PUBLIC_KEY]),
        true,
        2,
      ).replace('<', '');
    }
    return '';
  }

  return (
    <>
      <div className={classNames('flex justify-between w-full text-muted', !isComment && 'mt-6')}>
        {typeof onCommentClick === 'function' && (
          <div className="flex items-center">
            <button className="flex hover:text-blue items-center" onClick={onCommentClick}>
              <IoChatbubbleEllipsesOutline className="inline-block text-lg md:text-xl" />
              <span className="inline-block ml-2 text-md">{post.CommentCount ?? 0}</span>
            </button>
          </div>
        )}
        <div className="flex items-center">
          <Menu>
            <Menu.Button
              className={classNames(
                'flex hover:text-green items-center',
                postState.PostEntryReaderState?.RepostedByReader && 'text-green',
              )}
              onClick={(ev: MouseEvent) => {
                ev.stopPropagation();
              }}
            >
              {isPendingRepost ? (
                <FiLoader className="rotate" />
              ) : (
                <IoRepeatOutline className="inline-block text-lg md:text-xl" />
              )}
              <span className="inline-block ml-2 text-sm">{postState.RepostCount + postState.QuoteRepostCount}</span>
            </Menu.Button>
            <div className="relative">
              <Transition
                as={Fragment}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items className="absolute top-2 right-0 z-10 overflow-hidden mt-1 origin-top-right rounded-md bg-white ring-1 ring-blue-dark focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={getMenuItemClassNames(active)}
                        onClick={async (ev) => {
                          ev.stopPropagation();
                          if (!currentUser?.PublicKeyBase58Check || isPendingRepost) {
                            return;
                          }
                          setIsPendingRepost(true);

                          try {
                            const resp = await deso.createPost({
                              UpdaterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                              PostHashHexToModify: canHideRepost
                                ? postState.PostEntryReaderState?.RepostPostHashHex
                                : '',
                              ParentStakeID: '',
                              BodyObj: {
                                Body: '',
                                ImageURLs: null,
                                VideoURLs: null,
                              },
                              RepostedPostHashHex: post.PostHashHex,
                              PostExtraData: {},
                              IsHidden: canHideRepost ? true : false,
                              MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
                              TransactionFees: null,
                              InTutorial: false,
                              IsFrozen: false,
                            });

                            const repostPost = resp.submittedTransactionResponse
                              ?.PostEntryResponse as PostEntryResponse;
                            toast.show({
                              message: repostPost.IsHidden ? 'Repost hidden successfully' : 'Repost successful',
                              type: 'success',
                              sticky: false,
                            });
                            setPostState((prev) => ({
                              ...prev,
                              RepostCount: prev.RepostCount + (repostPost.IsHidden ? -1 : 1),
                              PostEntryReaderState: {
                                ...prev.PostEntryReaderState,
                                RepostedByReader: !repostPost.IsHidden,
                                RepostPostHashHex: repostPost.PostHashHex,
                              },
                            }));
                          } catch (e) {
                            toast.show({ message: getErrorMsg(e), type: 'error' });
                          }
                          setIsPendingRepost(false);
                        }}
                      >
                        <IoRepeatOutline className="mr-2" /> {canHideRepost ? 'Hide' : 'Repost'}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={getMenuItemClassNames(active)}
                        onClick={async (ev) => {
                          ev.stopPropagation();
                          setIsQuoteRepostModalOpen(true);
                        }}
                      >
                        <IoPencil className="mr-2" /> Quote
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </div>
          </Menu>
          <Modal
            size="lg"
            isOpen={isQuoteRepostModalOpen}
            onClose={() => setIsQuoteRepostModalOpen(false)}
            title="Quote Repost"
            description={
              <CreatePost
                appendToPost={`\n\nPosted on @Openfund`}
                quoteRepostPost={post}
                autoFocus={true}
                onPostCreated={() => {
                  setIsQuoteRepostModalOpen(false);
                }}
              />
            }
          />
        </div>
        <div className="flex items-center">
          <button onClick={(event) => likePost(event)}>
            {!postState?.PostEntryReaderState?.LikedByReader && (
              <span className="hover:text-red">
                <IoHeartOutline className="inline-block text-lg md:text-xl" />{' '}
                <span className="text-sm md:text-sm ml-2">{likeCount ?? 0}</span>
              </span>
            )}
            {postState?.PostEntryReaderState?.LikedByReader && (
              <span className="hover:text-red-dark text-red">
                <IoHeart className="inline-block text-xl" />{' '}
                <span className="font-medium text-sm ml-2">{likeCount ?? 0}</span>
              </span>
            )}
          </button>
        </div>
        <div className="flex items-center bg-background">
          <div className="relative">
            <div className="diamond-holder hover:text-blue">
              <div className="absolute diamond-selector">
                <button onClick={(event) => diamondPost(event, 1)} style={{ width: '30px', height: '25px' }}></button>
                <div
                  style={{ bottom: '125px', left: '-145px' }}
                  className="flex relative bg-background p-4 shadow-xl rounded-xl border border-border-light"
                >
                  {/*TODO: Make these diamond buttons their own component*/}
                  {[1, 2, 3, 4, 5, 6].map((ii) => {
                    return (
                      <button
                        key={ii}
                        className="pr-4 last:pr-0 cursor-pointer"
                        onClick={(event) => diamondPost(event, ii)}
                      >
                        <div
                          className={classNames(
                            postState?.PostEntryReaderState?.DiamondLevelBestowed === ii ? 'text-blue' : 'text-gray',
                            'flex hover:text-blue hover:scale-110 ease-in-out duration-200 flex-col justify-center items-center',
                          )}
                        >
                          <DiamondLogo className="text-xl mb-2" />
                          <Text className="text-xs">{getUSDPerDiamondLevel(ii)}</Text>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
              <button
                className={classNames(!!postState?.PostEntryReaderState?.DiamondLevelBestowed && 'text-blue')}
                onClick={(event) => diamondPost(event, 1)}
              >
                <span className="hover:text-blue">
                  {!sendingDiamonds && <DiamondLogo className="inline-block text-lg md:text-xl" />}
                  {sendingDiamonds && <FiLoader className="inline-block text-lg md:text-xl rotate" />}
                </span>
                <span className="text-sm md:text-md ml-2">{diamondCount ?? 0}</span>
              </button>
            </div>
          </div>
        </div>
        <div className={classNames('text-gray', !isComment && 'hidden')}>
          <span className="inline-block ml-2 text-xs text-gray-light">
            {getDateDifferenceStringFromTstampNanos(post.TimestampNanos, new Date())}
          </span>
        </div>
        <div className={classNames('relative top-2', isComment && 'hidden')}>
          <div className="flex items-center">
            <span className="inline-block ml-2 text-xs text-gray-light">
              {getDateDifferenceStringFromTstampNanos(post.TimestampNanos, new Date())}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

type CommentItemProps = {
  comment: PostEntryResponse;
  onReplyClick?: (comment: PostEntryResponse) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
function CommentItem({ comment, onReplyClick, className, ...rest }: CommentItemProps) {
  const { exchangeRates } = useContext(QuoteCurrencyContext);
  const [showReplySection, setShowReplySection] = useState(false);
  const [commentState, setCommentState] = useState(comment);

  return (
    <div
      className={classNames(className, 'cursor-pointer')}
      tabIndex={0}
      onClick={(ev: MouseEvent) => {
        ev.stopPropagation();
        if (typeof onReplyClick === 'function') {
          onReplyClick(commentState);
        } else {
          setShowReplySection(!showReplySection);
        }
      }}
      {...rest}
    >
      <div className="flex items-start border border-border-light rounded-2xl p-4 bg-accent">
        <Avatar border="none" src={commentState.PosterPublicKeyBase58Check} className="relative top-0" />
        <div className="w-full ml-4">
          <div className="mb-2">
            {commentState.ProfileEntryResponse?.Username && (
              <div className="flex flex-row items-center">
                <RouteLink to={Routes.fund(commentState.ProfileEntryResponse?.Username)}>
                  <span className="text-xs text-muted-foreground relative font-regular">
                    @{commentState.ProfileEntryResponse?.Username}
                  </span>
                </RouteLink>
                <WealthBadge
                  className="text-xs relative text-gray pl-1 pt-1"
                  wealthUSD={desoNanosToUSD(
                    totalAssets(commentState.ProfileEntryResponse),
                    exchangeRates[DESO_ZERO_PUBLIC_KEY],
                  )}
                ></WealthBadge>
              </div>
            )}
          </div>
          <div className="relative">
            {commentState.Body && (
              <div className="mb-6 pr-4 text-sm">
                <ActivityFeedContentBody body={commentState.Body} />
              </div>
            )}
            {commentState.ImageURLs?.length && (
              <div className="flex-1 py-4 mb-6 pr-14 justify-center">
                <PostImage src={commentState.ImageURLs?.length ? commentState.ImageURLs[0] : ''} />
              </div>
            )}
            {commentState.VideoURLs?.length && (
              <div className="flex-1 py-4 pr-14 justify-center">
                <PostVideo src={commentState.VideoURLs?.length ? commentState.VideoURLs[0] : ''} />
              </div>
            )}
            <SocialActionRow
              post={commentState}
              isComment={true}
              onCommentClick={(ev) => {
                ev.stopPropagation();
                if (typeof onReplyClick === 'function') {
                  onReplyClick(commentState);
                } else {
                  setShowReplySection(!showReplySection);
                }
              }}
            />
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        show={showReplySection}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="ml-12">
          <CommentSection
            className="py-4"
            isSubcommentSection={true}
            parentPostHashHex={commentState.PostHashHex}
            onCommentSubmitted={() => setCommentState((prev) => ({ ...prev, CommentCount: prev.CommentCount + 1 }))}
          />
        </div>
      </Transition>
    </div>
  );
}

const getMenuItemClassNames = (active: boolean) =>
  classNames('flex items-center w-full px-6 lg:px-4 py-2', active && 'bg-gray-eee text-black');
export function ActivityFeedItem({
  post,
  className,
  truncateContent = true,
  showCommentsByDefault = false,
  onNavigateToDetailView,
  ...rest
}: ActivityFeedItemProps) {
  const [showCommentSection, setShowCommentSection] = useState(!!post.Comments?.length || showCommentsByDefault);
  const [postState, setPostState] = useState(post);
  const [isHidePostModalOpen, setIsHidePostModalOpen] = useState(false);
  const [isPendingHidePost, setIsPendingHidePost] = useState(false);
  const hideCommentsRef = useRef<HTMLButtonElement>(null);
  const isMounted = useIsMounted();
  const didScrollTo = useRef(false);
  const { currentUser } = useContext(OpenfundContext);
  const toast = useToast();

  useEffect(() => {
    if (showCommentSection && !didScrollTo.current) {
      setTimeout(() => {
        if (hideCommentsRef.current && isMounted.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: hideCommentsRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 300,
          });
          didScrollTo.current = true;
        }
      }, 300);
    }
  }, [showCommentSection]);

  return (
    <div
      className={classNames(className, 'flex flex-col text-black relative', postState.IsHidden && 'hidden')}
      {...rest}
    >
      {isPendingHidePost ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : (
        <article
          tabIndex={0}
          className="cursor-pointer hover:bg-gray-lightest p-3 md:p-6"
          onClick={(ev: MouseEvent) => {
            if (['A', 'BUTTON'].includes((ev.target as HTMLElement)?.tagName ?? '')) {
              return;
            }

            setShowCommentSection(!showCommentSection);
          }}
        >
          <div className="absolute top-3 md:top-6 right-6">
            <DropdownMenu>
              <DropdownMenuTrigger
                aria-label="Post menu options"
                onClick={(ev: MouseEvent) => {
                  ev.stopPropagation();
                }}
              >
                <IoEllipsisHorizontal className="h-6 w-6 text-muted" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem asChild>
                  <ExternalLink
                    href={`https://${FOCUS_URL}/post/${
                      IsVanillaRepost(post) && post.RepostedPostEntryResponse
                        ? post.RepostedPostEntryResponse.PostHashHex
                        : post.PostHashHex
                    }`}
                    target="_blank"
                    className="no-underline"
                    onClick={() => onNavigateToDetailView?.(postState)}
                  >
                    Link to post
                  </ExternalLink>
                </DropdownMenuItem>
                {currentUser && currentUser.PublicKeyBase58Check === postState.PosterPublicKeyBase58Check && (
                  <DropdownMenuItem
                    onClick={async (ev: MouseEvent) => {
                      ev.stopPropagation();
                      setIsHidePostModalOpen(true);
                    }}
                  >
                    Hide post
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <Modal
              size="md"
              isOpen={isHidePostModalOpen}
              onClose={() => setIsHidePostModalOpen(false)}
              title="Hide post?"
              description="This can’t be undone. The post will be removed from your profile, from search results, and from the feeds of anyone who follows you."
              footer={
                <div className="flex">
                  <Button className="ml-auto" kind="btn-tertiary" onClick={() => setIsHidePostModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    className="ml-4"
                    onClick={async (ev: MouseEvent) => {
                      ev.stopPropagation();
                      setIsPendingHidePost(true);
                      try {
                        await deso.createPost({
                          UpdaterPublicKeyBase58Check: currentUser?.PublicKeyBase58Check ?? '',
                          PostHashHexToModify: postState.PostHashHex,
                          ParentStakeID: postState.ParentStakeID,
                          BodyObj: {
                            Body: postState.Body,
                            ImageURLs: postState.ImageURLs,
                            VideoURLs: postState.VideoURLs,
                          },
                          RepostedPostHashHex: postState.RepostedPostEntryResponse?.PostHashHex ?? '',
                          PostExtraData: postState.PostExtraData,
                          IsHidden: true,
                          MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
                          TransactionFees: null,
                          InTutorial: false,
                          IsFrozen: false,
                        });
                        setPostState((prev) => ({ ...prev, IsHidden: true }));
                        toast.show({ message: 'Post hidden successfully.', type: 'success', sticky: false });
                      } catch (e) {
                        toast.show({ message: getErrorMsg(e), type: 'error' });
                      }
                      setIsPendingHidePost(false);
                      setIsHidePostModalOpen(false);
                    }}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </div>
          {IsVanillaRepost(post) && (
            <div className="pb-2 text-muted mb-4 text-sm flex items-center font-medium">
              <LuRepeat2 className="text-muted text-xl mr-2" />@{post.ProfileEntryResponse?.Username} reposted
            </div>
          )}
          <ActivityFeedContent
            truncateContent={truncateContent}
            post={GetPostContent(postState)}
            postLevel={1}
            onCommentClick={(ev) => {
              ev.stopPropagation();
              setShowCommentSection(!showCommentSection);
            }}
          />
        </article>
      )}
      <Transition
        as={Fragment}
        show={showCommentSection}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="ml-16 px-3 md:px-6">
          {!showCommentsByDefault && (
            <div>
              <ButtonNew
                ref={hideCommentsRef}
                variant="outline"
                className="w-full mt-4 mb-8"
                aria-label="Close comment section"
                onClick={() => setShowCommentSection(false)}
              >
                <IoEyeOffOutline className="text-lg mr-3" />
                Hide comments
              </ButtonNew>
            </div>
          )}
          <CommentSection
            className={classNames(showCommentsByDefault && 'mt-4')}
            isSubcommentSection={false}
            parentPostHashHex={
              IsVanillaRepost(post) && post.RepostedPostEntryResponse
                ? post.RepostedPostEntryResponse.PostHashHex
                : post.PostHashHex
            }
            onCommentSubmitted={() => setPostState((prev) => ({ ...prev, CommentCount: prev.CommentCount + 1 }))}
          />
        </div>
      </Transition>
    </div>
  );
}

export function ActivityFeedContent({
  post,
  postLevel,
  className,
  truncateContent = true,
  onCommentClick,
  ...rest
}: ActivityFeedContentProps) {
  const profileUsername = post.ProfileEntryResponse?.Username;
  const { exchangeRates } = useContext(QuoteCurrencyContext);
  const wrappedAsset = getWrappedAsset(profileUsername || '');

  const avatarURL = wrappedAsset ? getWrappedAssetIcon(wrappedAsset) : GetPosterAvatar(post);

  return (
    <>
      <div className={classNames(className, 'flex items-start')} {...rest}>
        <Avatar size="md" border="none" src={avatarURL} className="min-w-12 min-h-12" />
        <div className="w-full ml-4">
          <div className="mb-0">
            {profileUsername && (
              <div className="flex flex-col">
                <RouteLink to={wrappedAsset ? Routes.profile(wrappedAsset.displayName) : Routes.fund(profileUsername)}>
                  <span className="text-sm relative text-muted-foreground font-semibold">
                    @{wrappedAsset?.displayName || profileUsername}
                  </span>
                </RouteLink>
              </div>
            )}
          </div>
          {post.Body && (
            <div className="mt-2 mb-4 pr-4 text-sm">
              <ActivityFeedContentBody body={post.Body} truncateContent={truncateContent} />
            </div>
          )}
          {post.ImageURLs?.length && (
            <div className="flex-1 mb-6 max-w-[550px] h-auto justify-center">
              <PostImage src={post.ImageURLs?.length ? post.ImageURLs[0] : ''} />
            </div>
          )}
          {post.VideoURLs?.length && (
            <div className="flex-1 py-4 pr-14 justify-center">
              <PostVideo src={post.VideoURLs?.length ? post.VideoURLs[0] : ''} />
            </div>
          )}
          {postLevel < 2 && post.RepostedPostEntryResponse && (
            <NavLink
              to={Routes.activityDetail(post.RepostedPostEntryResponse.PostHashHex)}
              className="border border-border-light p-4 rounded-lg block max-w-[90%]"
            >
              <ActivityFeedContent post={post.RepostedPostEntryResponse} postLevel={postLevel + 1} />
            </NavLink>
          )}
          {postLevel === 1 && (
            <div className="ml-0">
              <SocialActionRow post={post} onCommentClick={onCommentClick} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
