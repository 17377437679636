import axios from 'axios';
import { INVESTMENT_NETWORK_FEE_NANOS_BUFFER } from 'constants/AppConstants';
import { BalanceEntryResponse, CoinEntryResponse, ProfileEntryResponse } from 'deso-protocol';
import { openfund } from 'services';
import { BaseCurrencyPriceEntry } from 'services/Deso';
import { quantityDecimalStringToBigInt } from 'utils/orderbook';
import { ProcessedTokensForAmountInvested } from '../services/Openfund';

const CREATOR_COIN_RESERVE_RATIO = 0.3333333;
const CREATOR_COIN_TRADE_FEED_BASIS_POINTS = 1;

export const CURRENCY_SYMBOL_MAP = Object.freeze({
  ETH: 'Ξ',
  BTC: '₿',
  DESO: 'ᗫ', // I don't think this unicode character is used for any other currency...
  SOL: '◎',
  USDC: '$',
  USD: '$',
  DOGE: 'Ð',
});

export function ethToUSD(eth: string | number, usdCentsPerETH: number) {
  return Number(eth) * (usdCentsPerETH / 100);
}

export function usdToETH(usd: string | number, usdCentsPerETH: number) {
  return Number(usd) / (usdCentsPerETH / 100);
}

export function focusToUSD(focus: string | number, usdCentsPerFocus: number) {
  return Number(focus) * (usdCentsPerFocus / 100);
}

export function usdToFocus(usdAmount: number | string, xRate: number) {
  return Number(usdAmount) / (xRate / 100);
}

export function formatDecimalValue(value: string | number, maxDecimalPlaces = 2, minDecimalPlaces = 0) {
  const minDisplayableValue = maxDecimalPlaces > 0 ? `0.${'0'.repeat(maxDecimalPlaces - 1)}1` : '1';

  if (Number(value) > 0 && Number(value) < Number(minDisplayableValue)) {
    return `<${minDisplayableValue}`;
  }

  return Number(value).toLocaleString('en-US', {
    maximumFractionDigits: maxDecimalPlaces,
    minimumFractionDigits: minDecimalPlaces,
    // @ts-ignore
    roundingPriority: 'auto',
    trailingZeroDisplay: 'stripIfInteger',
  });
}

export async function computeTokensLessReserveAndFees(
  roundID: string,
  treasuryUnit: 'DESO' | 'DAO_COIN',
  amountToInvestNanos: bigint,
): Promise<ProcessedTokensForAmountInvested> {
  // It doesn't make sense to subtract the network fee here if the treasury is
  // denominated in tokens. There is an edge case where a user does has DUSD
  // in their wallet, but they don't have enough deso to cover network fees. Not
  // sure the best way to deal with this yet.
  let amountAfterFees =
    treasuryUnit === 'DAO_COIN'
      ? amountToInvestNanos
      : amountToInvestNanos - BigInt(INVESTMENT_NETWORK_FEE_NANOS_BUFFER);
  if (amountAfterFees <= 0) {
    return new Promise<ProcessedTokensForAmountInvested>((res) => {
      res({
        TotalTokensToMint: 0.0,
        TokensToTransferToUser: 0.0,
        AmountInvestedNanos: BigInt(0),
        PricePerToken: 0.0,
      });
    });
  }

  let res = await openfund.computeTokensForAmountInvested(roundID, amountAfterFees, treasuryUnit);

  const tokensToTransferToUser = Number(res.DAOCoinToTransferToUser) / 1e18;

  return {
    TotalTokensToMint: Number(res.TotalDAOCoinToMint) / 1e18,
    TokensToTransferToUser: tokensToTransferToUser,
    AmountInvestedNanos: amountToInvestNanos,
    PricePerToken:
      treasuryUnit === 'DAO_COIN'
        ? Number(amountToInvestNanos) / Number(res.DAOCoinToTransferToUser)
        : (Number(amountToInvestNanos) * 1e9) / tokensToTransferToUser / 1e18,
  };
}

export function calcOwnershipPercent(
  hodlerBalanceProjectNanosHex: string,
  tokenNanosInCirculationHex: string = toHex(0),
) {
  if (Number(tokenNanosInCirculationHex) === 0) {
    return 0;
  }

  return (Number(hodlerBalanceProjectNanosHex) / Number(tokenNanosInCirculationHex)) * 100;
}

export function calcUSDPricePerToken(tokenBaseUnitsPerDesoHex: string, usdToDesoXRate: number) {
  let priceDesoPerToken = calcDESOPerToken(tokenBaseUnitsPerDesoHex);
  return desoToUSD(priceDesoPerToken, usdToDesoXRate);
}

export function calcDESOPerToken(tokenBaseUnitsPerDesoHex: string) {
  const tokensIssuedPerOneDeso = baseUnitsToTokens(tokenBaseUnitsPerDesoHex);
  const priceDesoPerToken = 1 / tokensIssuedPerOneDeso;
  return priceDesoPerToken;
}

// Calculates the amount of deso one would receive if they sold an amount equal to creatorCoinAmountNano
// given the current state of a creator's coin as defined by the coinEntry
// Taken from the reference deso frontend: https://github.com/deso-protocol/frontend/blob/v2.1.0/src/app/global-vars.service.ts#L548
export function creatorCoinValue(creatorCoinAmountNano: number, coinEntry: CoinEntryResponse): number {
  // These calculations are derived from the Bancor pricing formula, which
  // is proportional to a polynomial price curve (and equivalent to Uniswap
  // under certain assumptions). For more information, see the comment on
  // CreatorCoinSlope in constants.go and check out the Mathematica notebook
  // linked in that comment.
  //
  // This is the formula:
  // - B0 * (1 - (1 - dS / S0)^(1/RR))
  // - where:
  //     dS = bigDeltaCreatorCoin,
  //     B0 = bigCurrentDeSoLocked
  //     S0 = bigCurrentCreatorCoinSupply
  //     RR = params.CreatorCoinReserveRatio
  const desoLockedNanos = coinEntry.DeSoLockedNanos;
  // NOTE: we do this weird cast to string and back to number because
  // deso-protocol-types converts uint256 to a number[] type (which is right in
  // golang but wrong in javascript, it is actually represented as a hex string
  // in the api response)
  const currentCreatorCoinSupply = Number(coinEntry.CoinsInCirculationNanos.toString());
  const desoBeforeFeesNanos =
    desoLockedNanos *
    (1 - Math.pow(1 - creatorCoinAmountNano / currentCreatorCoinSupply, 1 / CREATOR_COIN_RESERVE_RATIO));

  return (desoBeforeFeesNanos * (100 * 100 - CREATOR_COIN_TRADE_FEED_BASIS_POINTS)) / (100 * 100);
}

export function getHoldingPublicKey(balanceEntry: BalanceEntryResponse, holdingsView: boolean): string {
  return holdingsView ? balanceEntry.CreatorPublicKeyBase58Check : balanceEntry.HODLerPublicKeyBase58Check;
}

export function getWealthFromBalanceEntry(balanceEntry: BalanceEntryResponse, holdingsView: boolean): number {
  const desoBalance = holdingsView
    ? ((balanceEntry.ProfileEntryResponse as any)?.DESOBalanceNanos ?? 0)
    : ((balanceEntry as any).HodlerDESOBalanceNanos ?? 0);
  const desoLocked = balanceEntry.ProfileEntryResponse?.CoinEntry?.DeSoLockedNanos ?? 0;
  return desoBalance + desoLocked;
}

export function getWealthFromProfileEntryResponse(profile: ProfileEntryResponse) {
  return ((profile as any)?.DESOBalanceNanos ?? 0) + (profile?.CoinEntry?.DeSoLockedNanos ?? 0);
}

// For now this just converts to a "whole token" unit.  we can modify this to
// scale the value for displaying however we want. This should only be used for
// displaying in the UI.
export function formatTokenBaseUnits(baseUnitsHex: string | number) {
  return formatDecimalValue(baseUnitsToTokens(baseUnitsHex), 4);
}

export function formatApproximateUSD(usdAmount: number | string) {
  const numAmount = Number(usdAmount);
  return `${numAmount < 0.01 ? '' : '≈ '}${formatUSD(numAmount)}`;
}

export function formatUSD(usdAmount: number | string, withDecimal = true, numDecimals = 2) {
  const number = Number(usdAmount);
  if (number <= 0) {
    return '$0.00';
  }

  if (withDecimal && number < 0.01 && numDecimals <= 2) {
    return '< $0.01';
  }

  if (!withDecimal && number < 1) {
    return `< $1`;
  }

  return `${number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: withDecimal ? numDecimals : 0,
    minimumFractionDigits: withDecimal ? numDecimals : 0,
  })}`;
}

export function baseUnitsToTokens(baseUnits: string | number | bigint) {
  return Number(baseUnits) / 1e18;
}

export function tokenToBaseUnits(amount: number | string) {
  return quantityDecimalStringToBigInt(amount.toString());
}

export function usdCentsToUSD(usdCentsAmount: number) {
  return usdCentsAmount / 100;
}

export function toHex(value: number | bigint) {
  return `0x${value.toString(16)}`;
}

export function usdToUSDCents(usdAmount: number | string) {
  return Math.floor(Number(usdAmount) * 100);
}

export function usdToDeso(usdAmount: number | string, xRate: number) {
  return Number(usdAmount) / (xRate / 100);
}

export function usdCentsToDeso(usdCentsAmount: number, xRate: number) {
  return usdToDeso(usdCentsToUSD(usdCentsAmount), xRate);
}

export function desoToUSD(desoAmount: number | string, xRate: number) {
  return Number(desoAmount) * (xRate / 100);
}

export function desoNanosToUSD(nanos: number | string | bigint, xRate: number) {
  return ((Number(nanos) / 1e9) * xRate) / 100;
}

// This function converts an exchange rate on the profile object into
// a token market price in deso. It then computes MIN(marketPrice, roundPrice)
// If a marketPrice is not found, then the roundPrice is returned. If neither
// is found, then zero is returned.
export function getTokenPrice(profile: ProfileEntryResponse, roundPriceDesoPerToken: number = 0): number {
  const marketPriceDesoPerProjectToken = (profile as any)?.BestExchangeRateDESOPerDAOCoin;

  return marketPriceDesoPerProjectToken > 0
    ? Math.min(marketPriceDesoPerProjectToken, roundPriceDesoPerToken)
    : roundPriceDesoPerToken;
}

export function desoToDesoNanos(deso: number | string | bigint) {
  return Math.floor(Number(deso) * 1e9);
}

export function desoNanosToDeso(nanos: number | string | bigint) {
  return Number(nanos) / 1e9;
}

export function formatDesoNanosToDeso(nanos: number | string, decimals: number | undefined = undefined): string {
  return formatDecimalValue(desoNanosToDeso(nanos), decimals);
}

export function basisPointsToPercent(basisPoints: number) {
  return basisPoints / 100;
}

export function percentToBasisPoints(percent: number) {
  return percent * 100;
}

export function totalAssets(profileEntry: Partial<ProfileEntryResponse>): number {
  return (profileEntry.CoinEntry?.DeSoLockedNanos || 0) + ((profileEntry as any)?.DESOBalanceNanos || 0);
}

export function parseFloatWithCommas(f: string): number {
  return parseFloat(f.replace(/,/g, ''));
}

export function stripCommas(f: string): string {
  if (f.indexOf('e') > -1) {
    f = parseFloatWithCommas(f).toFixed(38);
  }
  return f.replace(/,/g, '');
}

export async function fetchUSDCExchangeRate(): Promise<number> {
  return fetchWrappedAssetExchangeRate('USDC');
}

export async function fetchWrappedAssetExchangeRate(wrappedAssetDisplayName: string): Promise<number> {
  const res = await axios.get(`https://api.coinbase.com/v2/prices/${wrappedAssetDisplayName.toLowerCase()}-usd/spot`);
  return parseFloat(res.data.data.amount);
}

export function dateNanosToInteger(nanos: number | string | bigint) {
  return Math.trunc(Number(nanos) / 1_000_000);
}

export function formatUSDCents(cents: number, numDecimals?: number) {
  return formatUSD(cents / 100, true, numDecimals);
}

export function getVisiblePrice(coinPrice: BaseCurrencyPriceEntry) {
  const liquidPrice = [coinPrice.MidPriceInUsd, coinPrice.BestAskInUsd, coinPrice.BestBidInUsd]
    .map(Number)
    .find((price) => price !== 0);

  return liquidPrice || 0;
}

export function tokenNanosToInteger(nanos: number | string | bigint) {
  return Number(nanos) / 1e18;
}

export const MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING = 0.01;
