import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Spinner } from 'components/core/Spinner';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

export type PaginationRequestParams = {
  [key: string]: unknown;
};

type PaginationRequestDTO = {
  initialRequest: () => Promise<void>;
  fetchRequest: () => Promise<void>;
  hasMoreItems: boolean;
  skipRequest?: boolean;
  initialLoading?: boolean;
  spinner?: ReactNode;
};

export const usePaginationRequest = ({
  initialRequest,
  fetchRequest,
  hasMoreItems,
  skipRequest = false,
  initialLoading: _initialLoading = true,
  spinner = <Spinner />,
}: PaginationRequestDTO) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [initialLoading, setInitialLoading] = useState(_initialLoading);
  const [error, setError] = useState<Error | null>(null);
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
    rootMargin: '100px',
  });

  const loadMoreItems = useCallback(async () => {
    if (hasMoreItems && !loadingMore && !skipRequest) {
      setLoadingMore(true);
      try {
        await fetchRequest();
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setLoadingMore(false);
      }
    }
  }, [fetchRequest, hasMoreItems, loadingMore, skipRequest]);

  useEffect(() => {
    if (isIntersecting && !initialLoading && !loadingMore) {
      loadMoreItems();
    }
  }, [isIntersecting, initialLoading, loadMoreItems, loadingMore]);

  useEffect(() => {
    if (initialLoading && !loadingMore) {
      setLoadingMore(true);
      initialRequest()
        .then(() => {
          setInitialLoading(false);
          setLoadingMore(false);
        })
        .catch((err) => {
          setError(err instanceof Error ? err : new Error(String(err)));
          setInitialLoading(false);
          setLoadingMore(false);
        });
    }
  }, [initialLoading, initialRequest, loadingMore]);

  const loadingMarkup = hasMoreItems ? (
    <div ref={ref} className="flex h-[30px] items-center justify-center p-4">
      {(initialLoading || loadingMore) && spinner}
    </div>
  ) : initialLoading ? (
    <Spinner className="my-2 flex justify-center" size={24} />
  ) : null;

  const reset = useCallback(() => {
    setInitialLoading(true);
    setError(null);
  }, []);

  return {
    loadingMarkup,
    setInitialLoading,
    initialLoading,
    loadingMore,
    hasMoreItems,
    ref,
    loadMoreItems,
    reset,
    error,
    setError,
  };
};
