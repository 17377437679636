'use client';

import { InfoTooltip } from '../core/InfoTooltip';
import { cn } from '../../utils/shadcn';

interface TransactionVolumeBadgeProps {
  type: keyof typeof TransactionVolumeBadgeType;
  text: React.ReactNode;
  width?: number;
  className?: string;
  imageClassName?: string;
}

export enum TransactionVolumeBadgeType {
  Whale = 'Whale',
  Dolphin = 'Dolphin',
  Fish = 'Fish',
  Shrimp = 'Shrimp',
}

const TransactionVolumeBadgeIconMap = {
  [TransactionVolumeBadgeType.Whale]: '/images/user-badges/whale.gif',
  [TransactionVolumeBadgeType.Dolphin]: '/images/user-badges/dolphin.gif',
  [TransactionVolumeBadgeType.Fish]: '/images/user-badges/fish.webp',
  [TransactionVolumeBadgeType.Shrimp]: '/images/user-badges/shrimp.png',
};

export const TransactionVolumeBadge: React.FC<TransactionVolumeBadgeProps> = ({
  type = TransactionVolumeBadgeType.Whale,
  width = 24,
  className = '',
  text,
  imageClassName = '',
}) => {
  return (
    <>
      <InfoTooltip className={className} text={<span>{text}</span>}>
        <div>
          <img
            src={TransactionVolumeBadgeIconMap[type] || TransactionVolumeBadgeIconMap.Whale}
            width={width}
            height={0}
            alt={`${type} badge`}
            className={cn(imageClassName)}
          />
        </div>
      </InfoTooltip>
    </>
  );
};
