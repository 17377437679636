import React, { createContext, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ToastProps, ToastShow, ToastCloseProps } from '../components/core/Toast';

export type AppContextType = {
  toast: ToastShow;
  toasts: ToastProps[];
  setWaitingForDeposit: (isWaiting: boolean, txid: string, ticker: string) => void;
};

export const AppContext = createContext<AppContextType>({
  toast: {
    show: () => {},
    removeToastWithManualID: () => {},
  },
  toasts: [],
  setWaitingForDeposit: () => {},
});

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const toast = useMemo(
    () => ({
      show: (props: ToastProps) => {
        setToasts((prev) => [{ id: uuid(), ...props }, ...prev]);
      },
      removeToastWithManualID: ({ manualID }: ToastCloseProps) => {
        setToasts((prev) => prev.filter((t) => t.manualID !== manualID));
      },
    }),
    [],
  );

  const setWaitingForDeposit = (isWaiting: boolean, txid: string, ticker: string) => {
    if (txid) {
      if (isWaiting) {
        let explorerLink = '';
        switch (ticker) {
          case 'ETH':
            explorerLink = `https://etherscan.io/tx/${txid}`;
            break;
          case 'BTC':
            explorerLink = `https://www.blockchain.com/btc/tx/${txid}`;
            break;
          case 'SOL':
            explorerLink = `https://explorer.solana.com/tx/${txid}`;
            break;
        }

        toast.show({
          message: (
            <div>
              <div>Waiting for transaction to confirm</div>
              <div className="underline dark:text-gray-light dark:hover:text-gray-faint">
                Click to check transaction status
              </div>
            </div>
          ),
          type: 'waiting',
          manualID: txid,
          link: explorerLink,
        });
      } else {
        toast.removeToastWithManualID({
          manualID: txid,
        });
      }
    }
  };

  return <AppContext.Provider value={{ toast, toasts, setWaitingForDeposit }}>{children}</AppContext.Provider>;
};
