import { FiLoader } from 'react-icons/fi';
import { Button } from 'components/shadcn/ui/button';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { ProfileEntryResponse } from 'deso-protocol';
import { useToast } from 'hooks/useToast';
import { useContext, useState } from 'react';
import { deso, openfund } from 'services';
import { getErrorMsg } from 'utils/getErrorMsg';
import { InteractiveElementProps } from 'utils/getInteractiveElementClassName';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/ui/dialog';

export const ToggleProjectTradingStatus = ({
  projectProfile,
  onStatusToggleSuccess,
  ...rest
}: {
  projectProfile: ProfileEntryResponse;
  onStatusToggleSuccess?: (status: 'unrestricted' | 'profile_owner_only') => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  InteractiveElementProps) => {
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const [isPendingStatusUpdate, setIsPendingStatusUpdate] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const toast = useToast();
  const isTradingEnabled =
    currentUser?.ProfileEntryResponse?.DAOCoinEntry?.TransferRestrictionStatus === 'unrestricted' ||
    currentUser?.ProfileEntryResponse?.DAOCoinEntry?.TransferRestrictionStatus === 'permanently_unrestricted';

  // only render this if the current user is the project owner.
  if (!currentUser || currentUser?.PublicKeyBase58Check !== projectProfile.PublicKeyBase58Check) {
    return null;
  }

  return currentUser?.ProfileEntryResponse?.DAOCoinEntry?.TransferRestrictionStatus !== 'permanently_unrestricted' ? (
    <>
      <Button
        variant={isTradingEnabled ? 'outline' : 'success'}
        onClick={() => {
          setIsConfirmationOpen(true);
        }}
      >
        {isTradingEnabled ? 'Disable Trading' : 'Enable Trading'}
      </Button>
      <Dialog open={isConfirmationOpen} onOpenChange={setIsConfirmationOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{isTradingEnabled ? 'Disable Trading' : 'Enable Trading'}</DialogTitle>
            <DialogDescription className="text-sm text-muted leading-6">
              {isTradingEnabled
                ? "You're about to disable trading for your token! This will result in people who hold your token being unable to transact with each other. Are you sure you want to do this?"
                : 'Hurray! You are about to enable trading for your token. Are you ready?'}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex flex-row gap-2">
            <Button
              variant={isTradingEnabled ? 'destructive' : 'success'}
              onClick={async () => {
                if (isPendingStatusUpdate) return;
                setIsPendingStatusUpdate(true);
                const transferRestrictionStatus = isTradingEnabled ? 'profile_owner_only' : 'unrestricted';
                try {
                  await deso.changeMyTokenTransferRestrictionStatus(transferRestrictionStatus);
                  await openfund.reloadCurrentUserData().then(setCurrentUser);
                  onStatusToggleSuccess?.(transferRestrictionStatus);
                } catch (e) {
                  toast.show({ type: 'error', message: getErrorMsg(e) });
                }
                setIsPendingStatusUpdate(false);
                setIsConfirmationOpen(false);
              }}
            >
              {isPendingStatusUpdate ? (
                <FiLoader className="inline rotate" />
              ) : isTradingEnabled ? (
                'Yes, Disable Trading'
              ) : (
                'Yes, Enable Trading'
              )}
            </Button>
            <Button variant="outline" onClick={() => setIsConfirmationOpen(false)}>
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <></>
  );
};
