import { ExternalLink } from 'components/core/ExternalLink';
import { RouteLink } from 'components/core/RouteLink';
import { Text } from 'components/core/Text';
import { ProfileEntryResponse } from 'deso-protocol';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { FundingRound, ProcessedTokensForAmountInvested } from 'services/Openfund';
import {
  baseUnitsToTokens,
  basisPointsToPercent,
  desoToUSD,
  formatDecimalValue,
  formatUSD,
  usdToDeso,
} from 'utils/currency';
import { getBondingCurveUnitText } from 'utils/fundingRound';
import { Routes } from '../../RoutePaths';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';

interface ContributionDetailProps {
  projectProfile: ProfileEntryResponse;
  fundingRound: FundingRound;
  numTokens: ProcessedTokensForAmountInvested | undefined;
  exchangeRates: GetExchangeRateUpdatedResponse;
}
export function ContributionDetail({
  projectProfile,
  numTokens,
  fundingRound,
  exchangeRates,
}: ContributionDetailProps) {
  let warningLowerPrice = <></>;
  let marketPriceDesoPerToken = (projectProfile as any)?.BestExchangeRateDESOPerDAOCoin ?? 0;
  let roundPrice = numTokens?.PricePerToken ?? 0;
  const usdPricePerToken =
    fundingRound.TreasuryCurrencyUnit === 'DESO'
      ? desoToUSD(roundPrice, exchangeRates.USDCentsPerDeSoCoinbase)
      : roundPrice;
  const desoPricePerToken =
    fundingRound.TreasuryCurrencyUnit === 'DESO'
      ? roundPrice
      : usdToDeso(roundPrice, exchangeRates.USDCentsPerDeSoCoinbase);

  if (marketPriceDesoPerToken && marketPriceDesoPerToken < desoPricePerToken) {
    warningLowerPrice = (
      <p style={{ color: '#ff4d4d' }}>
        Warning: A better price of{' '}
        <b>
          {formatUSD(desoToUSD(marketPriceDesoPerToken, exchangeRates.USDCentsPerDeSoCoinbase), true, 5)} USD per
          token{' '}
        </b>
        can be found{' '}
        <RouteLink
          style={{ color: '#ff4d4d', textDecoration: 'underline' }}
          className="m-auto h-1/2 w-1/5 text-gray dark:text-gray-light hover:text-black dark:hover:text-white"
          to={Routes.tradeToken(projectProfile.Username)}
        >
          on exchange.
        </RouteLink>
      </p>
    );
  }

  return (
    <>
      <dl className="bg-accent border border-border rounded-2xl">
        <div className="p-4 border-b border-border-light">
          <div className="flex items-center mb-1">
            <dt className="font-semibold text-foreground">You will receive </dt>
            <dd className="ml-auto text-muted">
              <span className="mr-2 font-mono text-muted-foreground">
                {formatDecimalValue(numTokens?.TokensToTransferToUser ?? 0)}
              </span>
              <span>${projectProfile.Username}</span> Tokens
            </dd>
          </div>
          <div className="flex items-center text-muted mb-1">
            <dt className="text-sm">Tokens in circulation after purchase</dt>
            <dd className="ml-auto text-sm">
              <span className="mr-2 font-mono text-muted-foreground">
                {formatDecimalValue(
                  baseUnitsToTokens(projectProfile.DAOCoinEntry!.CoinsInCirculationNanos.toString()) +
                    (numTokens?.TotalTokensToMint ?? 0),
                  2,
                )}
              </span>
              Tokens
            </dd>
          </div>
          <div className=" flex items-center text-muted mb-1">
            <dt className="text-sm">Percent of total supply after purchase</dt>
            <dd className="ml-auto text-sm">
              <span className="mr-1 font-mono text-muted-foreground">
                {formatDecimalValue(
                  (100 * (numTokens?.TokensToTransferToUser ?? 0)) /
                    (baseUnitsToTokens(projectProfile.DAOCoinEntry!.CoinsInCirculationNanos.toString()) +
                      (numTokens?.TotalTokensToMint ?? 0)) || 0,
                )}
              </span>
              <span>%</span>
            </dd>
          </div>
          {fundingRound.ReserveRateBasisPoints > 0 && (
            <Text tag="div" color="secondary" size="sm" className="mt-1">
              <IoInformationCircleOutline className="inline h-5 w-5" /> The project has a{' '}
              {basisPointsToPercent(fundingRound.ReserveRateBasisPoints)}% reserve rate (already factored in)
            </Text>
          )}
        </div>
        <div className="border-b border-border-light p-4">
          <div className="flex items-center">
            <dt className="font-semibold">Price per token you receive </dt>
            <dd className="ml-auto">
              <div className="font-mono text-muted-foreground">
                {fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
                  ? formatUSD(numTokens?.PricePerToken ?? 0)
                  : formatUSD(desoToUSD(numTokens?.PricePerToken ?? 0, exchangeRates.USDCentsPerDeSoCoinbase), true, 5)}
              </div>
            </dd>
          </div>
          {fundingRound.PriceIncreaseAmount > 0 && fundingRound.PriceIncreaseIncrementBasisPoints > 0 && (
            <div className="flex items-center text-muted mt-1 text-sm gap-1">
              Price increases by{' '}
              <span className="text-muted-foreground font-mono">
                {basisPointsToPercent(fundingRound.PriceIncreaseIncrementBasisPoints)}%
              </span>{' '}
              <span className="text-muted">
                {getBondingCurveUnitText(fundingRound, exchangeRates.USDCentsPerDeSoCoinbase)}.
              </span>
            </div>
          )}
          {(!fundingRound.PriceIncreaseAmount || !fundingRound.PriceIncreaseIncrementBasisPoints) && (
            <div className="flex items-center text-muted mt-1 text-sm gap-2">The price of this round is constant.</div>
          )}
        </div>
        <div className="p-4">
          <div className="flex items-center mb-1">
            <dt className="font-bold">Effective market cap after purchase</dt>
            <dd className="ml-auto font-mono text-muted-foreground">
              {formatUSD(
                usdPricePerToken *
                  (baseUnitsToTokens(projectProfile.DAOCoinEntry!.CoinsInCirculationNanos.toString()) +
                    (numTokens?.TotalTokensToMint ?? 0)),
              )}
            </dd>
          </div>
          <div className="flex items-center text-muted mt-1 text-sm gap-2">
            This is the price you're getting times the number of tokens in circulation.
          </div>
        </div>
        {warningLowerPrice}
      </dl>
      {!!fundingRound.TermsAndConditions && (
        <div className="text-muted py-4 text-xs">
          By proceeding, you agree to the{' '}
          <ExternalLink href={fundingRound.TermsAndConditions} target="_blank" className="underline underline-offset-4">
            Contribution Terms
          </ExternalLink>
          .
        </div>
      )}
    </>
  );
}
