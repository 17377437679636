import { classNames } from 'utils/classNames';
import { centerEllipsis } from 'utils/text';
import { useToast } from 'components/hooks/use-toast';
import { getErrorMsg } from 'utils/getErrorMsg';
import { ReactNode } from 'react';
import { LuCopy } from 'react-icons/lu';

interface CopyToClipboardProps {
  text: string;
  width?: number;
  className?: string;
  children?: ReactNode;
  label?: string;
  compact?: boolean;
}
export function CopyToClipboard({
  text,
  className = '',
  width = 10,
  children,
  label = 'Copy',
  compact = false,
}: CopyToClipboardProps) {
  const toast = useToast();
  return (
    <button
      title={text}
      className={classNames(
        compact
          ? 'font-mono flex items-center text-xs sm:text-sm p-0 active:text-muted'
          : 'rounded-md font-mono flex items-center border text-xs sm:text-sm border-border px-2 md:px-4 py-2 active:text-muted',
        className,
      )}
      onClick={() => {
        window.navigator.clipboard
          .writeText(text)
          .then(() => {
            toast.toast({ description: `Successfully copied to clipboard.`, variant: 'success' });
          })
          .catch((e) => {
            toast.toast({ description: getErrorMsg(e), variant: 'error' });
          });
      }}
    >
      {children || centerEllipsis(text, width)}
      <div className="ml-4 border-l flex items-center gap-1 border-border-light text-muted hover:text-muted-foreground">
        <LuCopy className="inline ml-2" /> {label && <span className="text-xs">{label}</span>}
      </div>
    </button>
  );
}
