import { ActivityFeedContent } from 'components/app-ui/ActivityFeedItem';
import { Avatar } from 'components/core/Avatar';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import { RouteLink } from 'components/core/RouteLink';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { MIN_FEE_RATE_NANOS_PER_KB } from 'constants/AppConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { PostEntryResponse, ProfileEntryResponse } from 'deso-protocol';
import { useToast } from 'hooks/useToast';
import { useContext, useRef, useState } from 'react';
import { IoImageOutline, IoVideocamOutline } from 'react-icons/io5';
import { Mention, MentionsInput } from 'react-mentions';
import { Routes } from 'RoutePaths';
import { deso, openfund } from 'services';
import { classNames } from 'utils/classNames';
import { getErrorMsg } from 'utils/getErrorMsg';
import { SubmittedPosts } from '../../services/Openfund';
import { PostImage } from './PostImage';
import { PostVideo } from './PostVideo';

type CreatePostProps = {
  containerClasses?: string;
  // Passed in to append to the end of the post message if posting from a project's page
  appendToPost?: string;
  projectPublicKey?: string;
  addPostToFeed?: Function;
  postContent?: string;
  quoteRepostPost?: PostEntryResponse;
  onPostCreated?: (post: PostEntryResponse) => void;
  autoFocus?: boolean;
};

export function CreatePost({
  containerClasses,
  appendToPost,
  projectPublicKey,
  addPostToFeed,
  postContent = '',
  quoteRepostPost,
  onPostCreated,
  autoFocus = false,
}: CreatePostProps) {
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const [isPendingCreatePost, setIsPendingCreatePost] = useState(false);
  const [postBody, setPostBody] = useState(postContent);
  const [mentionsInputValue, setMentionsInputValue] = useState(postContent);
  const [imageURL, setImageURL] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const toast = useToast();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const [videoProcessing, setVideoProcessing] = useState<boolean>(false);
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const suggestedProfiles = useRef<{ [key: string]: ProfileEntryResponse }>({});

  const onError = (contentType: string) => {
    setImageUploading(false);
    setVideoProcessing(false);
    toast.show({
      message: `There was a problem processing your ${contentType}. Please try again.`,
      type: 'error',
    });
  };

  const pollForVideoReady = async function (assetId: string): Promise<void> {
    const { status } = await deso.getVideoStatus(assetId);
    if (status.phase === 'ready') {
      return;
    }
    if (status.phase === 'failed') {
      throw new Error('Video processing failed');
    }

    const startTime = Date.now();
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        deso
          .getVideoStatus(assetId)
          .then(({ status: innerStatus }) => {
            switch (innerStatus.phase) {
              case 'ready':
                clearTimeout(intervalId);
                resolve();
                return;
              case 'failed':
                clearTimeout(intervalId);
                reject(new Error('there was an error processing the video upload.'));
                return;
            }

            if (Date.now() - startTime > 3e5) {
              clearTimeout(intervalId);
              reject(new Error('timed out waiting for video to be ready'));
              return;
            }
          })
          .catch(reject);
      }, 300);
    });
  };

  function addPostToRecentCache(post: PostEntryResponse, cacheKey: string) {
    const postCacheStr = localStorage.getItem('recentPosts');
    let recentPostObjects: SubmittedPosts;

    if (!postCacheStr) {
      recentPostObjects = {};
    } else {
      recentPostObjects = JSON.parse(postCacheStr);
    }
    const currentDate = new Date();
    const recentPostObject = {
      PostEntryResponse: post,
      SubmittedAt: currentDate,
    };
    recentPostObjects[cacheKey] = recentPostObject;
    localStorage.setItem('recentPosts', JSON.stringify(recentPostObjects));
  }

  return (
    <div className={classNames('border border-border-light rounded-2xl p-4 flex relative', containerClasses)}>
      <div className="h-full lg:p-0 lg:pr-2 p-2">
        <Avatar border="none" src={currentUser?.PublicKeyBase58Check} size="md" />
      </div>
      <form
        className="flex-1 mt-1"
        onSubmit={async (ev) => {
          ev.preventDefault();
          if (isPendingCreatePost) {
            return;
          }

          let PublicKeyBase58Check = currentUser?.PublicKeyBase58Check;

          if (!PublicKeyBase58Check) {
            try {
              const authUser = await openfund.login();
              PublicKeyBase58Check = authUser?.PublicKeyBase58Check;
              setCurrentUser(authUser);
            } catch (e) {
              toast.show({ message: getErrorMsg(e), type: 'error' });
            }
          }

          if (!PublicKeyBase58Check) {
            return;
          }

          let cleanPostBody = postBody.trim();
          if (cleanPostBody.length === 0) {
            return;
          }

          if (appendToPost !== undefined) {
            cleanPostBody = cleanPostBody + appendToPost;
          }

          setIsPendingCreatePost(true);
          try {
            const createPostResponse = await deso.createPost({
              UpdaterPublicKeyBase58Check: PublicKeyBase58Check,
              PostHashHexToModify: '',
              ParentStakeID: '',
              BodyObj: {
                Body: cleanPostBody,
                ImageURLs: imageURL ? [imageURL] : null,
                VideoURLs: videoURL ? [videoURL] : null,
              },
              RepostedPostHashHex: quoteRepostPost?.PostHashHex ?? '',
              PostExtraData: {},
              IsHidden: false,
              MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
              TransactionFees: null,
              InTutorial: false,
              IsFrozen: false,
            });
            const cacheKey = projectPublicKey ? projectPublicKey : 'feed';
            const post = createPostResponse.submittedTransactionResponse?.PostEntryResponse;

            if (post) {
              addPostToRecentCache(post, cacheKey);
              if (addPostToFeed) {
                addPostToFeed(post);
              }
              toast.show({
                message: (
                  <>
                    Your post was created successfully.{' '}
                    <RouteLink kind="text-only-light" to={Routes.activityDetail(post.PostHashHex)}>
                      View post.
                    </RouteLink>
                  </>
                ),
                type: 'success',
                sticky: true,
              });
              onPostCreated?.(post);
            } else {
              throw new Error('Post was not created.');
            }
          } catch (e) {
            toast.show({ message: getErrorMsg(e), type: 'error' });
          }
          setIsPendingCreatePost(false);
          setPostBody('');
          setImageURL('');
          setMentionsInputValue('');
        }}
      >
        <div className="relative">
          <MentionsInput
            autoFocus={autoFocus}
            className="text-muted placeholder:text-muted"
            style={{
              control: {
                minHeight: 100,
              },
              highlighter: {
                padding: 9,
                // TODO: remove the opacity when there's time to fix the
                // alignment on mobile. I think it's related to the font family
                // per this issue
                // https://github.com/signavio/react-mentions/issues/172
                opacity: 0,
              },
              input: {
                padding: 9,
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
              },
            }}
            placeholder="What would you like to share?"
            a11ySuggestionsListLabel="Suggested mentions"
            value={mentionsInputValue}
            onChange={(_, newValue, newPlainTextValue) => {
              setMentionsInputValue(newValue);
              setPostBody(newPlainTextValue);
            }}
          >
            <Mention
              trigger="@"
              markup="@[__display__](__id__)"
              displayTransform={(id, display) => `${display}`}
              data={async (query, callback) => {
                if (!query) return;
                const res = await deso.getProfilesByUsername(query, currentUser?.PublicKeyBase58Check, 10);
                suggestedProfiles.current =
                  res.reduce((result, profile) => {
                    result[profile.PublicKeyBase58Check] = profile;
                    return result;
                  }, suggestedProfiles.current) ?? {};
                callback(res.map((p) => ({ id: p.PublicKeyBase58Check, display: `@${p.Username}` })) ?? []);
              }}
              renderSuggestion={(suggestion, query, highlightedDisplay, index, focused) => {
                return (
                  <button className={classNames('w-full text-left p-2', focused && 'bg-gray-eee')}>
                    <Avatar
                      border="none"
                      src={suggestedProfiles.current?.[suggestion.id]?.PublicKeyBase58Check}
                      className="mr-2"
                    />
                    <span className="font-bold">{highlightedDisplay}</span>
                  </button>
                );
              }}
              appendSpaceOnAdd={true}
              style={{
                color: '#3586ff',
                // position: 'relative',
                zIndex: 1,
              }}
            />
          </MentionsInput>
        </div>
        <PostImage
          src={imageURL}
          imageUploading={imageUploading}
          createPost={true}
          onDelete={() => {
            setImageURL('');
            setImageUploading(false);
          }}
        />
        <PostVideo
          src={videoURL}
          videoProcessing={videoProcessing}
          createPost={true}
          onDelete={() => {
            setVideoURL('');
            setVideoProcessing(false);
          }}
        />

        {quoteRepostPost && (
          <div className="h-64 overflow-auto mb-8">
            <ActivityFeedContent post={quoteRepostPost} postLevel={2} />
          </div>
        )}

        <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="flex pl-3" style={{ alignItems: 'center', justifyContent: 'start' }}>
            <IoImageOutline
              className="text-muted hover:text-muted-foreground hover:cursor-pointer"
              style={{ width: 26, height: 'auto' }}
              onClick={() => imageInputRef.current?.click()}
            />
            <input
              ref={imageInputRef}
              className="hidden"
              type="file"
              accept="image/png, image/jpeg, image/webp, image/svg, image/gif"
              onChange={async (ev) => {
                const file = ev.currentTarget.files?.[0];

                if (!file) {
                  onError('photo');
                  return;
                }
                setImageUploading(true);
                setVideoProcessing(false);
                setImageURL('');
                setVideoURL('');
                const res = await deso.uploadImage(file);

                if (!res) {
                  onError('photo');
                  return;
                }
                setImageURL(res);
                setImageUploading(false);
              }}
            />
            <IoVideocamOutline
              style={{ width: 30, height: 30 }}
              className="ml-5 text-muted hover:text-muted-foreground hover:cursor-pointer"
              onClick={() => videoInputRef.current?.click()}
            />
            <input
              ref={videoInputRef}
              className="hidden"
              type="file"
              accept="video/*"
              onChange={async (ev) => {
                const file = ev.currentTarget.files?.[0];

                if (!file) {
                  onError('video');
                  return;
                }
                setVideoProcessing(true);
                setImageUploading(false);
                setImageURL('');
                setVideoURL('');
                const res = await deso.uploadVideo(file);
                if (!res) {
                  onError('video');
                  return;
                }
                await pollForVideoReady(res.asset.id);
                setVideoURL(`https://lvpr.tv/?v=${res.asset.playbackId}`);
                setVideoProcessing(false);
              }}
            />
          </div>

          <ButtonNew type="submit" className="ml-auto lg:m-0 mb-1 mr-1">
            {isPendingCreatePost ? <LoadingSpinner className="h-6 w-6" colorHex="#fff" /> : 'Post'}
          </ButtonNew>
        </div>
      </form>
    </div>
  );
}
