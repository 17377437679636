// test net featured projects
export const AMPLITUDE_KEY = 'ecc203cf6777326fcb2da6ab2253f6e6';
export const HEAP_KEY = '1776830314';

// The default project shown for group messaging on the activity feed.
export const DEFAULT_MESSAGE_PROJECT_PUBLIC_KEY = 'tBCKYL4AENeCU5dVkJhKkgkWoE8aGj5w8j2vu1VUmtmDi7sHBskq1D';

export const FEATURED_PROJECTS = Object.freeze([
  'tBCKWRXnpJwif9UCsrujWFLDJC4K21DxZMRhUXJJWh9D6FXxiT22iw',
  'tBCKVBLbheg3qoqrsY5EczQdV36S4ZmiCpq2FJYs3zjBBuCmvRmMPa',
  'tBCKUwYHHnDX4ot5XLShL3ywnJKJbsiC7XUhKPwH37XJFUs95NB2Tm',
  'tBCKVFf2RhXm2vLRqX7H6NA2ghd1ziECe5eabMUez385cFRmLrzUFu',
]);

export const OPEN_FUND_MEGASWAP_AFFILIATE_ADDRESS = 'tBCKWZkuPzNqUiUo2N5shswPsXmhPAh6XgGcxagb5FQM76d3NpQjRB';

export const INVESTMENT_NETWORK_FEE_NANOS_BUFFER = 3000;
export const MIN_FEE_RATE_NANOS_PER_KB = 1000;

// When approving a transaction, we allow a buffer on the max deso limit
// to cover fees, among other things.
export const GLOBAL_DESO_LIMIT_BUFFER = 100000000;

export const IS_TESTNET = true;
export const NODE_URL = 'https://test.deso.org/api/v0';
export const OPENFUND_URL = 'https://dev.openfund.com/api/v0';
export const DESO_IDENTITY_URL = 'https://identity.deso.org';
export const MEGA_SWAPPER_URL = 'https://dev.heroswap.com/api/v1';
export const DESO_DOLLAR_PROFILE_NAME = 'dUSDC_';

export const EXPLORER_URLS = {
  blockcypher: 'https://live.blockcypher.com/btc-testnet',
  deso: 'https://test.deso.org',
  etherscan: 'https://goerli.etherscan.io',
  heroswap: 'https://dev.heroswap.com',
  solana: 'https://explorer.solana.com?cluster=devnet',
  desoWallet: 'https://wallet-testnet.deso.com',
};

export const OPENFUND_TOKEN_PUBLIC_KEY = 'tBCKUwYHHnDX4ot5XLShL3ywnJKJbsiC7XUhKPwH37XJFUs95NB2Tm';
export const DESO_USDC_PUBLIC_KEY = 'tBCKXMdtgvgatMZAM3k9Ypof6qvDqyuSp4dyiemsTXtWHvspZPom1W';
export const FOCUS_TOKEN_PUBLIC_KEY = 'tBCKUxRJZJnr59bVRcrTycgtmZPBhW9rzaCxAryVXUwtrZo3dkd9TA';
export const DESO_BTC_PUBLIC_KEY = 'tBCKWbPhFrJBy6URqeCBzmfwv7GMKvhbQVVxiywKAH8xtiwVboiuFm';
export const DESO_ETH_PUBLIC_KEY = 'tBCKWMUvsWRjBJmLxK2ZR3966Bkq59G8WGG9WsAwRT5LC6T6cBpwjP';
export const DESO_SOL_PUBLIC_KEY = 'tBCKVpT2MWsiMwRpAp57hq54X4kcAWBQtaypNKPCkCedgNm6fNcVqv';

export const DESO_ZERO_PUBLIC_KEY = 'tBCKQud934akEwsr8AfG9BzHDWhi6CaDmjBsxGsSgfGsoxXHfVEfxP';

export const FOCUS_URL = 'https://beta.focus.xyz';
export const DESO_EXPLORER_URL = 'https://explorer-testnet.deso.com';
export const AMM_URL = 'https://amm-dev.deso.com';
export const GRAPHQL_URL = 'https://staging-graphql.focus.xyz/graphql';
export const WS_GRAPHQL_URL = 'wss://staging-graphql.focus.xyz/graphql';
