import { ContributionDetail } from 'components/app-ui/ContributionDetail';
import { CopyToClipboard } from 'components/app-ui/CopyToClipboard';
import { CurrencyInputWithTickerToggle } from 'components/app-ui/CurrencyInputWithTickerToggle';
import { DepositEventsList } from 'components/app-ui/DepositEventsList';
import { FullPageError } from 'components/app-ui/FullPageError';
import { NumberInputWithMaxToggle } from 'components/app-ui/NumberInputWithMaxToggle';
import { SwapDesoForUSD } from 'components/app-ui/SwapDesoForUSD';
import { SwapUSDForDeso } from 'components/app-ui/SwapUSDForDeso';
import { ExternalLink } from 'components/core/ExternalLink';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { INVESTMENT_NETWORK_FEE_NANOS_BUFFER } from 'constants/AppConstants';
import { DESO_DOLLAR_PROFILE_NAME } from 'constants/AppConstants.dev';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { getExchangeRates, ProfileEntryResponse } from 'deso-protocol';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { useContext, useEffect, useRef, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import { IoExitOutline } from 'react-icons/io5';
import { Routes } from 'RoutePaths';
import { heroswap, openfund } from 'services';
import { DepositEvent } from 'services/HeroSwapper';
import { FundingRound, ProcessedTokensForAmountInvested } from 'services/Openfund';
import {
  baseUnitsToTokens,
  computeTokensLessReserveAndFees,
  desoNanosToDeso,
  desoNanosToUSD,
  desoToDesoNanos,
  formatDecimalValue,
  formatUSD,
  toHex,
  tokenToBaseUnits,
  usdToDeso,
} from 'utils/currency';
import { getErrorMsg } from 'utils/getErrorMsg';
import {
  formatTicker,
  getExplorerLink,
  getTickerCurrencyName,
  PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER,
  Ticker,
} from 'utils/tickers';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { trackingLogEvent } from '../../utils/tracking';
import { Heading } from '../core/Heading';

interface InvestWithTickerDepositProps {
  depositAddress?: string;
  ticker: Ticker;
  fundingRound: FundingRound;
  projectProfile: ProfileEntryResponse;
  initAmountUSD?: number;
  onInvest: (amountBaseUnitsToInvest: bigint, derivedKey: string, onDerivedKeyAuthorized: () => void) => Promise<void>;
}
export function InvestWithTickerDeposit({
  depositAddress,
  fundingRound,
  ticker,
  onInvest,
  projectProfile,
  initAmountUSD = 1000,
}: InvestWithTickerDepositProps) {
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const toast = useToast();
  const [isProcessing, setIsProcessing] = useState(false);
  const [exchangeRates, setExchangeRates] = useState<GetExchangeRateUpdatedResponse | undefined>();
  const [amountBaseUnitsToInvest, setAmountBaseUnitsToInvest] = useState(BigInt(0));
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<any>();
  const [tokensYouReceive, setTokensYouReceive] = useState<ProcessedTokensForAmountInvested>();
  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
  const investmentDerivedKeyRef = useRef<string>('');
  const [depositEvents, setDepositEvents] = useState<DepositEvent[]>([]);
  const [sendDesoOrUSDError, setSendDesoOrUSDError] = useState('');
  const [destinationAmountPerOneDepositUnit, setDestinationAmountPerOneDepositUnit] = useState(0);
  const spendableDesoNanos =
    currentUser?.BalanceNanos && currentUser?.BalanceNanos > INVESTMENT_NETWORK_FEE_NANOS_BUFFER
      ? currentUser.BalanceNanos - INVESTMENT_NETWORK_FEE_NANOS_BUFFER
      : 0;
  // TODO: how should we handle the case where a funding round treasury is in
  // deSoUSD, and they have a spendable deSoUSD balance, but they do not have enough
  // DESO balance to cover network fees.
  const spendableNanos = BigInt(
    fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
      ? (currentUser?.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0')
      : spendableDesoNanos,
  );
  const checkNewDeposits = () => {
    const shouldCheckDeposits = depositAddress !== currentUser?.PublicKeyBase58Check;
    return Promise.all([
      getExchangeRates(),
      depositAddress && shouldCheckDeposits && heroswap.pollNewDepositOnce(depositAddress, ticker),
    ])
      .then(([xRates]) => {
        if (!isMounted.current) return;

        setExchangeRates(xRates);

        if (shouldCheckDeposits && depositAddress) {
          return heroswap.getRecentDepositEventsForDepositAddress(ticker, depositAddress).then((res) => {
            if (!isMounted.current) return;
            setDepositEvents(res);
          });
        }
      })
      .then(() => {
        if (currentUser) {
          return openfund.reloadCurrentUserData().then((res) => {
            if (isMounted.current) {
              setCurrentUser(res);
            }
          });
        }
      });
  };

  useEffect(() => {
    const destinationTicker = fundingRound.TreasuryCurrencyUnit === 'DESO' ? 'DESO' : 'DUSD';
    if (ticker === destinationTicker) return;

    heroswap.calcCurrencySwapAmount(ticker, destinationTicker, '1').then((res) => {
      if (!isMounted.current) return;
      setDestinationAmountPerOneDepositUnit(parseFloat(res.SwapRateDestinationTickerPerDepositTicker));
    });
  }, [ticker, fundingRound.TreasuryCurrencyUnit]);

  useEffect(() => {
    let value = tokenToBaseUnits(initAmountUSD);
    if (fundingRound.TreasuryCurrencyUnit === 'DESO') {
      if (!exchangeRates) return;
      value = BigInt(desoToDesoNanos(usdToDeso(initAmountUSD, exchangeRates.USDCentsPerDeSoCoinbase)));
    }
    setAmountBaseUnitsToInvest(value);

    computeTokensLessReserveAndFees(fundingRound.RoundID, fundingRound.TreasuryCurrencyUnit, value).then(
      (tokensToMint) => {
        setTokensYouReceive(tokensToMint);
      },
    );
  }, [initAmountUSD, exchangeRates]);

  useEffect(() => {
    setIsLoading(true);
    checkNewDeposits()
      .catch((e) => {
        if (isMounted.current) {
          setLoadingError(e);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });
  }, [depositAddress]);

  if (isLoading) {
    return (
      <div className="text-center py-8">
        <Spinner />
      </div>
    );
  }

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  if (!depositAddress && !currentUser) {
    return (
      <div className="text-center py-8">
        <ButtonNew
          onClick={async () => {
            try {
              setCurrentUser(await openfund.login());
            } catch (e) {
              toast.show({ message: getErrorMsg(e), type: 'error' });
            }
          }}
        >
          Login to contribute with ${ticker}
        </ButtonNew>
      </div>
    );
  }

  if (!depositAddress) {
    return <div className="text-center py-8">Investing with {formatTicker(ticker)} is not available at this time.</div>;
  }

  return (
    <div>
      {/*{currentUser &&*/}
      {/*  depositAddress &&*/}
      {/*  ticker !== (fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? 'DUSD' : 'DESO') && (*/}
      {/*    <div className="pb-4 border-b border-gray-faint">*/}
      {/*      {!(*/}
      {/*        (ticker === 'DESO' && fundingRound.TreasuryDaoCoin === DESO_DOLLAR_PROFILE_NAME) ||*/}
      {/*        (ticker === 'DUSD' && fundingRound.TreasuryCurrencyUnit === 'DESO')*/}
      {/*      ) && (*/}
      {/*        <>*/}
      {/*          <Heading level={3} size="sm" className="mb-3 text-sm">*/}
      {/*            Send ${formatTicker(ticker)} to this address to swap for $*/}
      {/*            {formatTicker(fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? 'DUSD' : 'DESO')}.*/}
      {/*          </Heading>*/}
      {/*          <CopyToClipboard width={15} text={depositAddress} />*/}
      {/*          <ExternalLink*/}
      {/*            kind="text-only-underline-light"*/}
      {/*            target="_blank"*/}
      {/*            href={getExplorerLink(ticker, depositAddress ?? '', 'address')}*/}
      {/*            className="ml-3 hover:text-black text-sm"*/}
      {/*          >*/}
      {/*            View status*/}
      {/*            <IoExitOutline className="inline-block ml-2 h-4 w-4" />*/}
      {/*          </ExternalLink>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      {ticker === 'DESO' && fundingRound.TreasuryDaoCoin === DESO_DOLLAR_PROFILE_NAME && currentUser && (*/}
      {/*        <>*/}
      {/*          <SwapDesoForUSD*/}
      {/*            currentUser={currentUser}*/}
      {/*            desoDepositAddress={depositAddress}*/}
      {/*            onSendDesoSuccess={() => {*/}
      {/*              setSendDesoOrUSDError('');*/}
      {/*              checkNewDeposits();*/}
      {/*            }}*/}
      {/*            onSendDesoError={(err) => {*/}
      {/*              setSendDesoOrUSDError(getErrorMsg(err));*/}
      {/*            }}*/}
      {/*          />*/}
      {/*          {!!sendDesoOrUSDError && (*/}
      {/*            <Text color="error" size="sm" className="font-bold">*/}
      {/*              {sendDesoOrUSDError}*/}
      {/*            </Text>*/}
      {/*          )}*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      {ticker === 'DUSD' && fundingRound.TreasuryCurrencyUnit === 'DESO' && currentUser && (*/}
      {/*        <>*/}
      {/*          <SwapUSDForDeso*/}
      {/*            currentUser={currentUser}*/}
      {/*            usdDepositAddress={depositAddress}*/}
      {/*            onSendUSDSuccess={() => {*/}
      {/*              setSendDesoOrUSDError('');*/}
      {/*              checkNewDeposits();*/}
      {/*            }}*/}
      {/*            onSendUSDError={(err) => {*/}
      {/*              setSendDesoOrUSDError(getErrorMsg(err));*/}
      {/*            }}*/}
      {/*          />*/}
      {/*          {!!sendDesoOrUSDError && (*/}
      {/*            <Text color="error" size="sm" className="font-bold">*/}
      {/*              {sendDesoOrUSDError}*/}
      {/*            </Text>*/}
      {/*          )}*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      {destinationAmountPerOneDepositUnit && (*/}
      {/*        <Text size="sm" color="secondary" className="mt-2">*/}
      {/*          1 ${formatTicker(ticker)} ≈ {formatDecimalValue(destinationAmountPerOneDepositUnit, 10)} $*/}
      {/*          {formatTicker(fundingRound.TreasuryCurrencyUnit === 'DESO' ? 'DESO' : 'DUSD')}*/}
      {/*        </Text>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*{ticker === (fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? 'DUSD' : 'DESO') &&*/}
      {/*  (!currentUser ? (*/}
      {/*    <div className="text-sm">*/}
      {/*      <Button*/}
      {/*        kind="text-only-underline-light"*/}
      {/*        onClick={async () => {*/}
      {/*          try {*/}
      {/*            setCurrentUser(await openfund.login());*/}
      {/*          } catch (e) {*/}
      {/*            toast.show({ message: getErrorMsg(e), type: 'error' });*/}
      {/*          }*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Login or create an account*/}
      {/*      </Button>{' '}*/}
      {/*      to see your spendable {formatTicker(ticker)} Balance*/}
      {/*    </div>*/}
      {/*  ) : (*/}
      {/*    <div className="text-sm">*/}
      {/*      Get more ${formatTicker(ticker)} on{' '}*/}
      {/*      <RouteLink kind="text-only-underline-light" to={Routes.wallet()}>*/}
      {/*        your wallet page*/}
      {/*      </RouteLink>*/}
      {/*      .*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {!!exchangeRates && (
        <section className="mb-4 rounded-2xl bg-accent border border-border-light block p-4 w-full">
          <div className="block xl:flex items-center justify-between">
            <div className="text-sm flex flex-col gap-1">
              <div>
                Your spendable{' '}
                {fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
                  ? `$${getTickerCurrencyName(
                      PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER[fundingRound.TreasuryDaoCoin],
                    )}`
                  : '$DESO'}{' '}
                balance
              </div>
              <div className="text-sm text-muted">Click refresh to check your latest balance</div>
            </div>
            <div className="flex text-right items-center mt-4 xl:mt-0">
              {isRefreshingBalance ? (
                <div className="pl-6">
                  <LoadingSpinner className="h-12 w-12" />
                </div>
              ) : fundingRound.TreasuryCurrencyUnit === 'DESO' ? (
                <div className="mr-4 text-right">
                  <div className="font-mono text-muted-foreground">
                    ≈ {formatUSD(desoNanosToUSD(spendableNanos, exchangeRates.USDCentsPerDeSoCoinbase))} USD
                  </div>

                  <div className="font-mono text-muted text-sm">
                    {formatDecimalValue(desoNanosToDeso(spendableNanos), 7)} DESO
                  </div>
                </div>
              ) : (
                <div className="mr-4 ml-2 whitespace-nowrap text-right">
                  <Text size="md" className="font-bold">
                    {formatUSD(
                      baseUnitsToTokens(currentUser?.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0'),
                    )}{' '}
                    USD
                  </Text>
                </div>
              )}
              <div>
                {currentUser ? (
                  <ButtonNew
                    variant="outline"
                    onClick={async () => {
                      if (isRefreshingBalance) return;
                      setIsRefreshingBalance(true);
                      try {
                        await checkNewDeposits();
                      } catch (e) {
                        const errorMsg = getErrorMsg(e);

                        if (errorMsg.toLowerCase().includes('error discovering deposits')) {
                          return;
                        }

                        toast.show({ message: errorMsg, type: 'error' });
                      }
                      setIsRefreshingBalance(false);
                    }}
                  >
                    Refresh
                  </ButtonNew>
                ) : (
                  <ButtonNew
                    variant="outline"
                    onClick={async () => {
                      try {
                        setCurrentUser(await openfund.login());
                      } catch (e) {
                        toast.show({ message: getErrorMsg(e), type: 'error' });
                      }
                    }}
                  >
                    Login to contribute ${ticker}
                  </ButtonNew>
                )}
              </div>
            </div>
          </div>
          {depositAddress && depositEvents.length > 0 && (
            <div className="mt-2">
              <DepositEventsList
                events={depositEvents}
                ticker={ticker}
                depositAddress={depositAddress}
                onSwapConfirmed={(depositEvent) => {
                  toast.show({
                    message: `Your swap of ${formatDecimalValue(depositEvent.DepositAmount)} ${formatTicker(
                      depositEvent.DepositTicker,
                    )} for ${formatDecimalValue(depositEvent.DestinationAmount)} ${formatTicker(
                      depositEvent.DestinationTicker,
                    )} was successful!`,
                    type: 'success',
                    sticky: false,
                  });
                }}
              />
            </div>
          )}
        </section>
      )}
      <div className="p-6 border border-border-light rounded-2xl">
        {currentUser &&
          depositAddress &&
          ticker !== (fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? 'DUSD' : 'DESO') && (
            <div className="pb-4">
              {!(
                (ticker === 'DESO' && fundingRound.TreasuryDaoCoin === DESO_DOLLAR_PROFILE_NAME) ||
                (ticker === 'DUSD' && fundingRound.TreasuryCurrencyUnit === 'DESO')
              ) && (
                <>
                  <Heading level={3} size="sm" className="mb-3 text-sm">
                    Send ${formatTicker(ticker)} to this address to swap for $
                    {formatTicker(fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? 'DUSD' : 'DESO')}
                  </Heading>
                  <CopyToClipboard width={15} text={depositAddress} />
                  <ExternalLink
                    target="_blank"
                    href={getExplorerLink(ticker, depositAddress ?? '', 'address')}
                    className="ml-3 hover:text-muted-foreground text-muted underline underline-offset-2 text-sm"
                  >
                    View Status
                    <IoExitOutline className="inline-block ml-2 h-4 w-4" />
                  </ExternalLink>
                </>
              )}
              {ticker === 'DESO' && fundingRound.TreasuryDaoCoin === DESO_DOLLAR_PROFILE_NAME && currentUser && (
                <>
                  <SwapDesoForUSD
                    currentUser={currentUser}
                    desoDepositAddress={depositAddress}
                    onSendDesoSuccess={() => {
                      setSendDesoOrUSDError('');
                      checkNewDeposits();
                    }}
                    onSendDesoError={(err) => {
                      setSendDesoOrUSDError(getErrorMsg(err));
                    }}
                  />
                  {!!sendDesoOrUSDError && (
                    <Text color="error" size="sm" className="font-bold">
                      {sendDesoOrUSDError}
                    </Text>
                  )}
                </>
              )}
              {ticker === 'DUSD' && fundingRound.TreasuryCurrencyUnit === 'DESO' && currentUser && (
                <>
                  <SwapUSDForDeso
                    currentUser={currentUser}
                    usdDepositAddress={depositAddress}
                    onSendUSDSuccess={() => {
                      setSendDesoOrUSDError('');
                      checkNewDeposits();
                    }}
                    onSendUSDError={(err) => {
                      setSendDesoOrUSDError(getErrorMsg(err));
                    }}
                  />
                  {!!sendDesoOrUSDError && (
                    <Text color="error" size="sm" className="font-bold">
                      {sendDesoOrUSDError}
                    </Text>
                  )}
                </>
              )}
              {destinationAmountPerOneDepositUnit && (
                <div className="text-sm text-muted py-4">
                  <span className="font-mono text-muted-foreground">1 {formatTicker(ticker)}</span> ≈{' '}
                  <span className="font-mono text-primary">
                    {formatDecimalValue(destinationAmountPerOneDepositUnit, 4)}
                  </span>{' '}
                  <span className="font-mono text-primary">
                    {formatTicker(fundingRound.TreasuryCurrencyUnit === 'DESO' ? 'DESO' : 'DUSD')}
                  </span>
                </div>
              )}
            </div>
          )}
        {ticker === (fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? 'DUSD' : 'DESO') &&
          (!currentUser ? (
            <div className="text-sm">
              <ButtonNew
                onClick={async () => {
                  try {
                    setCurrentUser(await openfund.login());
                  } catch (e) {
                    toast.show({ message: getErrorMsg(e), type: 'error' });
                  }
                }}
              >
                Login or create an account
              </ButtonNew>{' '}
              to see your spendable {formatTicker(ticker)} Balance
            </div>
          ) : (
            <div className="text-xs pb-4 text-muted">
              Need to top-up? Get more ${formatTicker(ticker)} on{' '}
              <RouteLink kind="text-only-underline-light" to={Routes.wallet()}>
                your wallet page
              </RouteLink>
              .
            </div>
          ))}

        {!!exchangeRates && (
          <>
            <div className="w-full">
              {fundingRound.TreasuryCurrencyUnit === 'DAO_COIN' ? (
                <NumberInputWithMaxToggle
                  prefix="$"
                  labelText={`How much ${getTickerCurrencyName(
                    PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER[fundingRound.TreasuryDaoCoin],
                  )} would you like to invest?`}
                  initialValue={initAmountUSD}
                  allowedDecimalPlaces={5}
                  onValueChange={async (value) => {
                    if (currentUser && !investmentDerivedKeyRef.current) {
                      openfund
                        .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.INVESTMENT)
                        .then(({ DerivedPublicKeyBase58Check }) => {
                          investmentDerivedKeyRef.current = DerivedPublicKeyBase58Check;
                        });
                    }
                    const amountBaseUnits = tokenToBaseUnits(value);
                    let tokensToMint = await computeTokensLessReserveAndFees(
                      fundingRound.RoundID,
                      fundingRound.TreasuryCurrencyUnit,
                      amountBaseUnits,
                    );
                    setAmountBaseUnitsToInvest(amountBaseUnits);
                    setTokensYouReceive(() => tokensToMint);
                  }}
                  onMax={() => {
                    computeTokensLessReserveAndFees(
                      fundingRound.RoundID,
                      fundingRound.TreasuryCurrencyUnit,
                      spendableNanos,
                    ).then((tokensToMint) => {
                      setTokensYouReceive(() => tokensToMint);
                    });

                    setAmountBaseUnitsToInvest(spendableNanos);

                    return formatDecimalValue(baseUnitsToTokens(spendableNanos));
                  }}
                />
              ) : (
                <CurrencyInputWithTickerToggle
                  labelText="How much $DESO would you like to contribute?"
                  initialValue={initAmountUSD}
                  defaultTicker="USD"
                  alternateTicker="DESO"
                  onStateChange={async (v, t) => {
                    if (currentUser && !investmentDerivedKeyRef.current) {
                      openfund
                        .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.INVESTMENT)
                        .then(({ DerivedPublicKeyBase58Check }) => {
                          investmentDerivedKeyRef.current = DerivedPublicKeyBase58Check;
                        });
                    }

                    const amountNanos = BigInt(
                      desoToDesoNanos(t === 'DESO' ? v : usdToDeso(v, exchangeRates.USDCentsPerDeSoCoinbase)),
                    );
                    let tokensToMint = await computeTokensLessReserveAndFees(
                      fundingRound.RoundID,
                      fundingRound.TreasuryCurrencyUnit,
                      amountNanos,
                    );
                    setAmountBaseUnitsToInvest(amountNanos);
                    setTokensYouReceive(() => tokensToMint);
                  }}
                  onMax={(selectedTicker) => {
                    computeTokensLessReserveAndFees(
                      fundingRound.RoundID,
                      fundingRound.TreasuryCurrencyUnit,
                      spendableNanos,
                    ).then((tokensToMint) => {
                      setTokensYouReceive(() => tokensToMint);
                    });
                    setAmountBaseUnitsToInvest(spendableNanos);
                    switch (selectedTicker) {
                      case 'DESO':
                        return desoNanosToDeso(spendableNanos);
                      case 'USD':
                        return desoNanosToUSD(spendableNanos, exchangeRates.USDCentsPerDeSoCoinbase);
                      default:
                        return 0;
                    }
                  }}
                />
              )}
            </div>
            <div className="mt-4">
              <ContributionDetail
                projectProfile={projectProfile}
                numTokens={tokensYouReceive}
                fundingRound={fundingRound}
                exchangeRates={exchangeRates}
              />
            </div>
          </>
        )}
        <ButtonNew
          className="flex items-start"
          disabled={spendableNanos <= INVESTMENT_NETWORK_FEE_NANOS_BUFFER || amountBaseUnitsToInvest <= 0}
          onClick={async () => {
            if (isProcessing) {
              return;
            }

            trackingLogEvent('invest : contribute : clicked', {
              ticker,
              depositAddress,
              ...fundingRound,
              ...projectProfile,
              amountDesoNanosToInvest: toHex(amountBaseUnitsToInvest),
            });

            try {
              await onInvest(amountBaseUnitsToInvest, investmentDerivedKeyRef.current, () => setIsProcessing(true));
              investmentDerivedKeyRef.current = '';
            } catch (e) {
              toast.show({ message: getErrorMsg(e), type: 'error' });
            }

            setIsProcessing(false);
          }}
        >
          {isProcessing ? (
            <div className="flex justify-center">
              <FiLoader className="rotate h-6 w-6" />
            </div>
          ) : (
            'Contribute and Get Tokens'
          )}
        </ButtonNew>
        {spendableNanos <= INVESTMENT_NETWORK_FEE_NANOS_BUFFER && (
          <Text size="sm" color="secondary" className="mt-4">
            Please add funds to your account to contribute.
          </Text>
        )}
      </div>
    </div>
  );
}
