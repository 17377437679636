import { type MouseEvent, useCallback } from 'react';

export const useEnterKeyTrigger = <T>(onClick?: (e: MouseEvent<T>) => void) => {
  return useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        onClick?.(event as unknown as MouseEvent<T>);
      }
    },
    [onClick],
  );
};
