import { classNames } from 'utils/classNames';

export function Select({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) {
  return (
    <select
      className={classNames(
        'rounded-md cursor-pointer border border-border-light bg-input hover:border-blue hover:text-blue disabled:text-gray disabled:cursor-auto disabled:hover:border-gray-light disabled:hover:text-gray',
        className,
      )}
      {...rest}
    >
      {children}
    </select>
  );
}
