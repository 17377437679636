import { forwardRef } from 'react';

export const FeaturedImage = forwardRef<
  HTMLImageElement,
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
>(({ alt, src, style, ...rest }, ref) => {
  const imgSrc = src || '/images/large-image-placeholder.png';
  return (
    <div
      className="aspect-w-16 border-y border-border aspect-h-9 rounded-none overflow-hidden flex items-center justify-center  text-center"
      style={{ ...style }}
    >
      <img alt={alt} src={imgSrc} ref={ref} loading="lazy" className="object-cover" {...rest} />
    </div>
  );
});
