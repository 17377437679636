import { coinUnlock } from 'deso-protocol';
import sumBy from 'lodash/sumBy';
import { type Dispatch, type SetStateAction, useContext, useState } from 'react';
import { LuCoins, LuInfo } from 'react-icons/lu';

import { Alert, AlertDescription, AlertTitle } from 'components/shadcn/ui/alert';
import { Button } from 'components/shadcn/ui/button';
import { Switch } from 'components/shadcn/ui/switch';
import LowNumFormatter from './LowNumFormatter';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/ui/dialog';
import { TimestampTooltip } from './TimestampTooltip';
import { cn } from 'utils/shadcn';
import { Avatar } from '../core/Avatar';
import { baseUnitsToTokens, formatDecimalValue } from '../../utils/currency';
import { LockedBalanceEntry } from '../../graphql/codegen/graphql';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useToast } from '../../hooks/useToast';
import { useWaitForTransaction } from '../../hooks/useWaitForTransaction';
import { OpenfundContext } from '../../contexts/OpenfundContext';

dayjs.extend(relativeTime);

const nanoSecondsToMilliseconds = (nanoseconds: number): number => {
  return Math.floor(nanoseconds / 1e6);
};
const formatUTCDateFromNow = (utcDateInMillis: number): string => {
  return dayjs(utcDateInMillis).utc().fromNow();
};
const msToLocaleString = (milliseconds: number): string => {
  return new Date(milliseconds).toLocaleString();
};

export const UnlockTokensModal = ({
  open,
  setOpen,
  coinPublicKey,
  coinUsername,
  coinPrice,
  lockedBalancesEntries,
  onUnlockSuccess,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  lockedBalancesEntries: Partial<LockedBalanceEntry>[];
  coinPublicKey: string;
  coinUsername: string;
  coinPrice: number;
  onUnlockSuccess?: () => void;
}) => {
  const { waitForTxn } = useWaitForTransaction();

  const { currentUser } = useContext(OpenfundContext);

  const [showUnlockSchedule, setShowUnlockSchedule] = useState(false);
  const currentDateInMillis = Date.now();
  const [unlocking, setUnlocking] = useState(false);
  const toast = useToast();

  const allLockedBalancesValueBaseUnits = lockedBalancesEntries
    .filter((x) => {
      const unlockDateInMillis = Number(x.unlockTimestampNanoSecs) / 1e6;
      return unlockDateInMillis < currentDateInMillis;
    })
    .reduce((acc, entry) => acc + Number(entry.balanceBaseUnits), 0);

  const onUnlockClick = async () => {
    if (unlocking || !currentUser) return;

    try {
      setUnlocking(true);
      const response = await coinUnlock({
        TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
        ProfilePublicKeyBase58Check: coinPublicKey,
      });

      if (response?.submittedTransactionResponse?.TxnHashHex) {
        await waitForTxn(response?.submittedTransactionResponse?.TxnHashHex);
      }
      toast.show({
        message: 'Your tokens have been unlocked',
        type: 'success',
      });
      onUnlockSuccess?.();
      setOpen(false);
    } catch (error) {
      toast.show({
        type: 'error',
        message: `An error happened when unlocking your tokens`,
      });
    } finally {
      setUnlocking(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="custom-scrollbar flex max-h-[80vh] max-w-(--breakpoint-sm) flex-col gap-0 overflow-auto p-0">
        <DialogHeader className="text-left">
          <DialogTitle className="flex items-center gap-4">
            <LuCoins className="text-muted" /> Unlock ${coinUsername}
          </DialogTitle>
          <DialogDescription className="hidden">Unlock ${coinUsername}</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col space-y-6 px-6 py-4">
          <div className="bg-secondary/20 rounded-lg p-4">
            <div className="flex flex-col items-center justify-between gap-2 md:flex-row md:gap-0">
              <p className="text-sm font-medium text-secondary-foreground">Your Unlockable Balance:</p>
              <div className="flex items-center gap-1">
                {/* Note, no ability to pass extra data here */}
                <Avatar src={coinPublicKey} size="md" className="mr-1" />
                <div className="flex flex-col">
                  <div className="flex items-center gap-1">
                    <LowNumFormatter
                      price={baseUnitsToTokens(allLockedBalancesValueBaseUnits)}
                      className="text-lg font-semibold"
                      isUsd={false}
                    />
                    <span className="text-muted">${coinUsername}</span>
                  </div>
                  <div className="flex items-center text-muted">
                    ~
                    <div className="flex items-center gap-1">
                      <LowNumFormatter price={baseUnitsToTokens(allLockedBalancesValueBaseUnits) * coinPrice} />
                      <span className="text-xs">USD</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {allLockedBalancesValueBaseUnits === 0 && (
            <Alert variant="warning">
              <LuInfo className="size-4" />
              <AlertTitle>Heads up!</AlertTitle>

              <AlertDescription>
                You are currently unable to unlock any coins. Check the unlock schedule below to see when your coins
                will become available!
              </AlertDescription>
            </Alert>
          )}

          <section>
            <div className="mb-4 flex items-center justify-between px-4">
              <p className="text-sm font-medium text-secondary-foreground">Show Unlock Schedule:</p>
              <Switch checked={showUnlockSchedule} onCheckedChange={setShowUnlockSchedule} />
            </div>

            {showUnlockSchedule &&
              lockedBalancesEntries.map((entry, index) => {
                const unlockDateInMillis = nanoSecondsToMilliseconds(Number(entry.unlockTimestampNanoSecs));

                const isUnlocked = unlockDateInMillis <= Date.now();
                const coinsToUnlock = baseUnitsToTokens(entry.balanceBaseUnits || 0);
                const totalTokensToUnlock = sumBy(lockedBalancesEntries.slice(0, index + 1), (e) =>
                  baseUnitsToTokens(e.balanceBaseUnits || 0),
                );

                return (
                  <div
                    key={entry.unlockTimestampNanoSecs}
                    className={cn(
                      'mb-2 flex flex-col md:flex-row items-start md:items-center justify-between rounded-md border border-border-light px-4 py-1 gap-1 md:gap-0',
                      isUnlocked ? 'bg-secondary/10' : 'bg-accent',
                    )}
                  >
                    <div className="order-2 ml-[33px] text-sm text-secondary-foreground sm:order-none md:ml-0">
                      {msToLocaleString(unlockDateInMillis)}

                      <div className={cn('text-xs', isUnlocked ? 'text-green-500' : '')}>
                        {isUnlocked ? 'Unlocked' : 'Next Unlock'}:{' '}
                        <TimestampTooltip timestamp={new Date(unlockDateInMillis).toString()}>
                          <span>{formatUTCDateFromNow(unlockDateInMillis)}</span>
                        </TimestampTooltip>
                      </div>
                    </div>

                    <div className="order-1 flex min-w-[200px] items-start gap-2 sm:order-none md:items-center">
                      <Avatar src={coinPublicKey} size="sm" className="mt-[4px] md:mt-0" />
                      <div className="flex flex-col">
                        <div className="flex flex-wrap items-center gap-1">
                          <span className="text-sm">{formatDecimalValue(coinsToUnlock)}</span>
                          <span className="text-xs text-muted">${coinUsername}</span>

                          <div className="text-xs text-muted">
                            (~
                            <LowNumFormatter price={coinsToUnlock * coinPrice} /> USD)
                          </div>
                        </div>

                        <div className="text-xs text-muted">
                          Total: {formatDecimalValue(totalTokensToUnlock)} ${coinUsername} (
                          <LowNumFormatter price={totalTokensToUnlock * coinPrice} className="text-xs" /> USD)
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </section>
        </div>

        <DialogFooter className="block text-center">
          <Button
            size="lg"
            disabled={allLockedBalancesValueBaseUnits === 0 || unlocking}
            onClick={onUnlockClick}
            className="max-w-full whitespace-normal sm:whitespace-nowrap"
          >
            {unlocking
              ? 'Unlocking...'
              : `Unlock ${baseUnitsToTokens(allLockedBalancesValueBaseUnits)} $${coinUsername} Tokens`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
