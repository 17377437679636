import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { useToast } from '../../hooks/useToast';
import { openfund } from '../../services';
import { Text } from '../core/Text';

export function VerifyEmail() {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  useDocumentTitle('Verify Email');
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffectOnce(() => {
    setIsLoading(true);
    const publicKey = searchParams.get('publicKey');
    if (!publicKey || publicKey === undefined || publicKey === null) {
      toast.show({ message: 'Username not provided', type: 'error' });
      return;
    }
    if (code !== undefined) {
      openfund.submitEmailVerification(code, publicKey).then(() => {
        setIsLoading(false);
      });
    }
  });

  return (
    <div>
      {!isLoading && (
        <div className="flex justify-center flex-col items-center">
          <IoCheckmarkCircleOutline
            style={{ height: '90px', width: '90px' }}
            className="mb-4"
          ></IoCheckmarkCircleOutline>
          <Text size="lg">Email Verified</Text>
        </div>
      )}
      {isLoading && (
        <div className="flex justify-center flex-col items-center">
          <Text size="lg">Verifying Email</Text>
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;
