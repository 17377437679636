import axios from 'axios';
import { MEGA_SWAPPER_URL, NODE_URL, OPENFUND_URL } from 'constants/AppConstants';
import { Deso } from 'services/Deso';
import { HeroSwapper } from 'services/HeroSwapper';
import { Openfund } from 'services/Openfund';
import { devModeCache } from '../utils/axios';
import { Confetti } from './Confetti';

interface OpenfundSingletons {
  deso: Deso;
  heroswap: HeroSwapper;
  openfund: Openfund;
  confetti: Confetti;
}

interface OpenfundSingletonsContainer extends Window {
  __openfundSingletons: {
    deso: Deso;
    heroswap: HeroSwapper;
    openfund: Openfund;
    confetti: Confetti;
  };
}

function singletonFactory(): OpenfundSingletons {
  const deso = new Deso(devModeCache(axios.create({ baseURL: NODE_URL, withCredentials: true })));
  const heroswap = new HeroSwapper(devModeCache(axios.create({ baseURL: MEGA_SWAPPER_URL })));
  const openfund = new Openfund(devModeCache(axios.create({ baseURL: OPENFUND_URL })), heroswap, deso);
  const confetti = new Confetti();

  return { deso, heroswap, openfund, confetti };
}

/**
 * IMPORTANT: We do this funky thing because of the way code splitting works.
 * We lazy load by page, so we can't just import the singletons from this file
 * directly. If we did that, they would get initialized more than once because
 * multiple bundles may import this file. This is a workaround to ensure that
 * they are only initialized once ever, even during hot module replacement.
 * This also makes it so that we don't lose state when hot reload happens during
 * development which causes spurious errors.
 */
(window as unknown as OpenfundSingletonsContainer).__openfundSingletons =
  (window as unknown as OpenfundSingletonsContainer).__openfundSingletons ?? singletonFactory();

export const { deso, heroswap, openfund, confetti }: OpenfundSingletons = (
  window as unknown as OpenfundSingletonsContainer
).__openfundSingletons;
