import { FullPageError } from 'components/app-ui/FullPageError';
import { Spinner } from 'components/core/Spinner';
import { ProfileEntryResponse } from 'deso-protocol';
import { useState } from 'react';
import { deso } from 'services';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { centerEllipsis } from '../../utils/text';
import { Avatar } from '../core/Avatar';
import { LuActivity, LuWallet } from 'react-icons/lu';
import { ExternalLink } from 'components/core/ExternalLink';
import { Button } from 'components/shadcn/ui/button';
import { DESO_EXPLORER_URL } from 'constants/AppConstants';
import { AccountSocialCard } from './AccountSocialCard';
import { usePaginationRequest } from 'hooks/usePaginationRequest';

interface FollowTableProps {
  username: string;
  getEntriesFollowingUsername: boolean;
  exchangeRates: GetExchangeRateUpdatedResponse;
}

export function FollowTable({ username, getEntriesFollowingUsername, exchangeRates }: FollowTableProps) {
  const [followers, setFollowers] = useState<Partial<ProfileEntryResponse>[]>([]);
  const [lastPublicKey, setLastPublicKey] = useState<string>('');
  const [totalFollowers, setTotalFollowers] = useState<number>(0);

  const hasMoreItems = followers.length < totalFollowers;

  const getFollows: () => Promise<void> = async () => {
    const followRes = await deso.getFollows(username, getEntriesFollowingUsername, lastPublicKey);
    if (followRes !== null) {
      setTotalFollowers(followRes.NumFollowers);
      const newFollowers = Object.entries(followRes.PublicKeyToProfileEntry)
        .map(([pubKey, profile]) => {
          return profile || { PublicKeyBase58Check: pubKey };
        })
        .sort((ii, jj) => {
          if (ii.CoinEntry && jj.CoinEntry) {
            return jj.CoinEntry.DeSoLockedNanos - ii.CoinEntry.DeSoLockedNanos;
          }
          if (ii.CoinEntry && !jj.CoinEntry) {
            return -1;
          }
          if (jj.CoinPriceDeSoNanos && !ii.CoinEntry) {
            return 1;
          }
          return (jj.PublicKeyBase58Check || '') > (ii.PublicKeyBase58Check || '') ? 1 : -1;
        });
      setFollowers(followers.concat(newFollowers));
      setLastPublicKey(newFollowers[newFollowers.length - 1]?.PublicKeyBase58Check || '');
    }
  };

  const { loadingMarkup, error, initialLoading } = usePaginationRequest({
    initialRequest: getFollows,
    fetchRequest: getFollows,
    hasMoreItems: hasMoreItems,
    spinner: <Spinner size={24} />,
  });

  if (error) {
    return <FullPageError error={error} />;
  }

  if (initialLoading) {
    return (
      <div className="text-center py-8">
        <Spinner size={32} />
      </div>
    );
  }

  return (
    <div>
      {!!followers.length ? (
        <div className="flex p-2 border-b border-border-light justify-between items-center">
          <div className="text-muted text-xs">Username</div>
          <div className="text-muted text-xs mr-3">Links</div>
        </div>
      ) : (
        <div className="flex p-2">
          {username} {getEntriesFollowingUsername ? 'has no followers' : "isn't following any users"} yet.
        </div>
      )}
      <div className="overflow-y-auto max-h-[560px]">
        {followers.map((profile, ii) => (
          <div
            className="flex p-2 border-b border-border-light"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
            key={ii}
          >
            <a
              className="flex"
              href={profile.Username ? `/d/${profile.Username}` : undefined}
              style={{ alignItems: 'center' }}
            >
              <AccountSocialCard publicKey={profile.PublicKeyBase58Check}>
                <div className="mr-2 flex" style={{ alignItems: 'center' }}>
                  <Avatar src={deso.profilePicUrl(profile.PublicKeyBase58Check)} />
                </div>
              </AccountSocialCard>

              <div className="flex flex-col gap-0">
                <div className="text-xs font-semibold text-muted-foreground no-underline hover:underline pr-3 text-gray hover:text-muted-foreground text-md  rounded-md inline-block active">
                  @
                  {profile?.Username
                    ? profile.Username
                    : profile.PublicKeyBase58Check
                      ? centerEllipsis(profile.PublicKeyBase58Check as string, 7)
                      : 'anonymous'}
                </div>
                <div className="text-muted text-xs font-mono">
                  {centerEllipsis(profile.PublicKeyBase58Check as string, 5)}
                </div>
              </div>
            </a>
            <div className="flex flex-row justify-end gap-2 items-center">
              <Button variant="outline" size="xs">
                <ExternalLink
                  target="_blank"
                  kind="text-only"
                  size="sm"
                  href={`${DESO_EXPLORER_URL}/u/${profile.Username}`}
                >
                  <LuActivity className="inline mr-2 text-foreground" />
                  <span className="text-foreground">Explorer</span>
                </ExternalLink>
              </Button>
              <Button variant="outline" size="xs">
                <ExternalLink
                  target="_blank"
                  kind="text-only"
                  size="sm"
                  href={`${DESO_EXPLORER_URL}/u/${profile.Username}`}
                >
                  <LuWallet className="inline mr-2 text-foreground" />
                  <span className="text-foreground">Wallet</span>
                </ExternalLink>
              </Button>
            </div>
          </div>
        ))}
        {loadingMarkup}
      </div>
    </div>
  );
}
