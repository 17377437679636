import { Button } from './Button';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { classNames } from '../../utils/classNames';

interface PaginationFooterProps {
  currentPage: number;
  onCurrentPageChange: (page: number) => void;
  totalItems: number;
  pageSize: number;
}

export function PaginationFooter({ currentPage, onCurrentPageChange, totalItems, pageSize }: PaginationFooterProps) {
  const numPages = Math.ceil(totalItems / pageSize);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        {currentPage > 0 ? (
          <Button
            kind="text-only"
            shape="square"
            onClick={() => onCurrentPageChange(currentPage - 1)}
            className="hover:text-gray relative inline-flex items-center px-4 py-2 border border-gray text-sm font-medium rounded-md"
          >
            {' '}
            Previous{' '}
          </Button>
        ) : (
          <div></div>
        )}
        {currentPage < numPages - 1 ? (
          <Button
            kind="text-only"
            shape="square"
            onClick={() => onCurrentPageChange(currentPage + 1)}
            className="hover:text-gray ml-3 relative inline-flex items-center px-4 py-2 border border-gray text-sm font-medium rounded-md"
          >
            {' '}
            Next{' '}
          </Button>
        ) : (
          <div></div>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <b>{1 + currentPage * pageSize}</b> to <b>{Math.min((currentPage + 1) * pageSize, totalItems)}</b>{' '}
            of <b>{totalItems}</b> results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            {currentPage > 0 && (
              <Button
                kind="text-only"
                shape="square"
                className="hover:text-gray relative inline-flex items-center px-2 py-2 rounded-l-md rounded-r-none border border-gray text-sm font-medium"
                onClick={() => onCurrentPageChange(currentPage - 1)}
              >
                <span className="sr-only">Previous</span>
                <IoChevronBack />
              </Button>
            )}

            {Array.from({ length: Math.ceil(totalItems / pageSize) }, (x, i) => i).map((pageNum: number) => {
              return Math.abs(currentPage - pageNum) < 2 || pageNum < 2 || pageNum > numPages - 2 ? (
                <Button
                  kind="text-only"
                  shape="square"
                  aria-current="page"
                  key={`pagination-pg-${pageNum}-btn`}
                  onClick={() => onCurrentPageChange(pageNum)}
                  className={classNames(
                    currentPage === pageNum ? 'font-bold border-2' : '',
                    'hover:text-gray z-10 relative inline-flex items-center px-4 py-2 border border-gray text-sm font-medium rounded-none',
                  )}
                >
                  {pageNum + 1}
                </Button>
              ) : Math.abs(pageNum - currentPage) === 2 ? (
                <span
                  className="relative inline-flex items-center px-4 py-2 border border-gray text-sm font-medium text-gray"
                  key={`pagination-pg-${pageNum}-ellipses`}
                >
                  {' '}
                  ...{' '}
                </span>
              ) : (
                <div className="hidden" key={`pagination-pg-${pageNum}-hidden`}></div>
              );
            })}
            {currentPage < numPages - 1 && (
              <Button
                kind="text-only"
                shape="square"
                className="hover:text-gray relative inline-flex items-center px-2 py-2 rounded-r-md rounded-l-none border border-gray text-sm font-medium text-gray"
                onClick={() => onCurrentPageChange(currentPage + 1)}
              >
                <span className="sr-only">Next</span>
                <IoChevronForward />
              </Button>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
