import { IoCloudUpload, IoCloudUploadOutline, IoGlobeOutline, IoLogoDiscord, IoLogoTwitter } from 'react-icons/io5';
import { FeaturedImage } from 'components/app-ui/FeaturedImage';
import { Button } from 'components/shadcn/ui/button';
import { Switch } from '@headlessui/react';
import { Input, InputProps } from 'components/core/Input';
import { MarkdownEditor } from 'components/core/MarkdownEditor';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { useSettingsContext } from 'components/pages/Settings';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { ReactNode, useContext, useRef, useState } from 'react';
import { deso, openfund } from 'services';
import { classNames } from 'utils/classNames';
import { getErrorMsg } from 'utils/getErrorMsg';

const IMAGE_SIZE_LIMIT_15MB = 15 * (1024 * 1024);

function FormControlGroup({ children: formControl, className }: React.HTMLAttributes<HTMLElement>) {
  return <div className={classNames('flex flex-col text-left pb-6 mb-2', className)}>{formControl}</div>;
}
interface InputData {
  value: string;
  state: InputProps['state'];
}
type FormModel = {
  featuredImageUrl: string;
  longDesc: string;
  displayName: InputData;
  websiteUrl: InputData;
  discordUrl: InputData;
  twitterUrl: InputData;
  EnableDaoNFTGallery: boolean;
};
interface WebsiteLinkConfig {
  field: 'websiteUrl' | 'discordUrl' | 'twitterUrl';
  label: string;
  icon: ReactNode;
}

export function Project() {
  const { setHeadingSuffix, currentUser } = useSettingsContext();
  useDocumentTitle(`Edit project details`);
  const [featuredImagePreviewUrl, setFeaturedImagePreviewUrl] = useState<string>();
  // TODO: this info should come from profile extra data once it is supported
  const [formState, setFormState] = useState<FormModel>({
    featuredImageUrl: currentUser.ProfileEntryResponse?.ExtraData?.FeaturedImageURL ?? '',
    longDesc: currentUser.ProfileEntryResponse?.ExtraData?.MarkdownDescription ?? '',
    displayName: { value: currentUser.ProfileEntryResponse?.ExtraData?.DisplayName ?? '', state: 'default' },
    websiteUrl: { value: currentUser.ProfileEntryResponse?.ExtraData?.WebsiteURL ?? '', state: 'default' },
    discordUrl: { value: currentUser.ProfileEntryResponse?.ExtraData?.DiscordURL ?? '', state: 'default' },
    twitterUrl: { value: currentUser.ProfileEntryResponse?.ExtraData?.TwitterURL ?? '', state: 'default' },
    EnableDaoNFTGallery: currentUser.appUser?.EnableDaoNFTGallery ?? true,
  });
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const { setCurrentUser } = useContext(OpenfundContext);
  const imageInputId = 'featured-image-input';
  const imgRef = useRef<HTMLImageElement>(null);
  const selectFile = () => document.getElementById(imageInputId)?.click();
  const isMounted = useIsMounted();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const derivedKey = useRef('');
  const externalLinks: WebsiteLinkConfig[] = [
    {
      field: 'websiteUrl',
      label: 'Website',
      icon: <IoGlobeOutline />,
    },
    {
      field: 'discordUrl',
      label: 'Discord',
      icon: <IoLogoDiscord />,
    },
    {
      field: 'twitterUrl',
      label: 'Twitter / X',
      icon: <IoLogoTwitter />,
    },
  ];

  useEffectOnce(() => {
    setHeadingSuffix(currentUser.isProjectOwner ? 'Project Details' : 'Profile Details');
    openfund
      .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.PROFILE)
      .then(({ DerivedPublicKeyBase58Check }) => {
        derivedKey.current = DerivedPublicKeyBase58Check;
      });
  });
  //
  // async function createGroupChat() {
  //   if (currentUser.appUser !== null && currentUser.appUser.DesoMessengerGroupChatId === '') {
  //     await openfund.createGroupMessage(currentUser.appUser);
  //     toast.show({
  //       message: 'Group chat created successfully.',
  //       type: 'success',
  //       sticky: false,
  //     });
  //   }
  // }

  return (
    <div>
      {isLoading ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="font-semibold text-xl font-sans text-muted-foreground mb-6">
            {currentUser.isProjectOwner ? 'Edit project details' : 'Edit profile details'}
          </div>
          <form
            id="project-details-form"
            onSubmit={async (ev) => {
              ev.preventDefault();
              try {
                await openfund.updateUserProfile(
                  {
                    Username: currentUser.ProfileEntryResponse?.Username ?? '',
                    Description: currentUser.ProfileEntryResponse?.Description ?? '',
                    Email: currentUser.appUser?.Email ?? '',
                    DisplayName: formState.displayName.value,
                    FeaturedImageURL: formState.featuredImageUrl,
                    WebsiteURL: formState.websiteUrl.value,
                    TwitterURL: formState.twitterUrl.value,
                    DiscordURL: formState.discordUrl.value,
                    MarkdownDescription: formState.longDesc,
                    EnableDaoNFTGallery: formState.EnableDaoNFTGallery,
                  },
                  () => {
                    setIsLoading(true);
                  },
                  derivedKey.current,
                );
                setCurrentUser(await openfund.reloadCurrentUserData());
                toast.show({
                  message: 'Profile updated successfully.',
                  type: 'success',
                  sticky: false,
                });
              } catch (e) {
                toast.show({
                  message: getErrorMsg(e),
                  type: 'error',
                });
              }
              setIsLoading(false);
            }}
          >
            <FormControlGroup>
              <div className="text-sm mb-2">Cover Image</div>
              <div
                className={classNames(
                  'border border-border rounded-2xl overflow-hidden relative aspect-w-16 aspect-h-9',
                  isUploadingImage ? 'blur-md animate-pulse' : '',
                )}
              >
                {isUploadingImage && (
                  <span role="status" className="sr-only">
                    Uploading image
                  </span>
                )}
                {(formState.featuredImageUrl || featuredImagePreviewUrl) && (
                  <span className="absolute top-0 right-0 ml-auto h-fit w-fit p-4 z-10">
                    <Button aria-label="Change featured image" size="sm" className="shadow-md" onClick={selectFile}>
                      <IoCloudUpload className="h-6 w-6" />
                    </Button>
                  </span>
                )}
                <FeaturedImage
                  hidden={!(featuredImagePreviewUrl || formState.featuredImageUrl)}
                  ref={imgRef}
                  src={featuredImagePreviewUrl || formState.featuredImageUrl}
                  alt={formState.displayName.value}
                />
                {!(formState.featuredImageUrl || featuredImagePreviewUrl) && (
                  <Button
                    type="button"
                    onClick={selectFile}
                    variant="ghost"
                    className="items-center justify-center h-full text-muted gap-2 flex flex-col gap-2"
                  >
                    <IoCloudUploadOutline className="text-muted w-[30px] h-[30px]" />
                    Upload Cover Image
                  </Button>
                )}
              </div>
              <Input
                className="hidden"
                id={imageInputId}
                type="file"
                labelText="Featured image"
                labelSrOnly={true}
                onChange={(ev) => {
                  const file = ev.currentTarget.files?.[0];
                  if (!file) return;

                  if (file.size > IMAGE_SIZE_LIMIT_15MB) {
                    toast.show({
                      message:
                        'Your file is larger than the upload size limit of 15MB. Please select a smaller photo and try again.',
                      type: 'error',
                    });
                    return;
                  }

                  const reader = new FileReader();
                  reader.addEventListener(
                    'load',
                    async () => {
                      if (imgRef.current && reader.result) {
                        // immediately show preview with raw file data
                        setFeaturedImagePreviewUrl(reader.result.toString());
                        // Upload file in the background
                        setIsUploadingImage(true);
                        try {
                          const url = await deso.uploadImage(file);
                          if (isMounted.current) {
                            setFormState({ ...formState, featuredImageUrl: url });
                          }
                        } catch (e) {
                          setFeaturedImagePreviewUrl(undefined);
                          toast.show({
                            message: getErrorMsg(e),
                            type: 'error',
                          });
                        }
                        setIsUploadingImage(false);
                      }
                    },
                    false,
                  );
                  reader.addEventListener('error', (err) => {
                    toast.show({
                      message: getErrorMsg(err),
                      type: 'error',
                    });
                    throw err;
                  });

                  reader.readAsDataURL(file);
                }}
              />
            </FormControlGroup>
            <FormControlGroup>
              <Input
                labelText="Display Name"
                value={formState.displayName.value}
                onInput={(ev) => {
                  setFormState({ ...formState, displayName: { value: ev.currentTarget.value, state: 'default' } });
                }}
              />
            </FormControlGroup>
            <FormControlGroup className="!hidden">
              <div className="text-sm mb-2">Social Profiles</div>
              <div className="bg-accent p-6 border border-border-light rounded-2xlcol gap-6">
                {externalLinks.map(({ field, label, icon }, i) => {
                  return (
                    <div key={i}>
                      <div className="flex mb-3" key={i}>
                        <span className="relative top-1 text-muted">{icon}</span>
                        <Text className="ml-2" size="md">
                          {label}
                        </Text>
                      </div>
                      <Input
                        labelText={label}
                        type="text"
                        textSize="sm"
                        className="w-full px-4"
                        labelSrOnly={true}
                        placeholder="https://..."
                        state={formState[field].state}
                        value={formState[field].value}
                        hint={formState[field].state === 'error' ? 'Please enter a valid url' : ''}
                        onInput={(ev) => {
                          setFormState({ ...formState, [field]: { value: ev.currentTarget.value, state: 'default' } });
                        }}
                        onBlur={(ev) => {
                          const value = ev.currentTarget.value;
                          const trimmed = value.trim();
                          const state = !trimmed || ev.currentTarget.checkValidity() ? 'default' : 'error';
                          setFormState({ ...formState, [field]: { value, state } });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {/*{currentUser?.appUser !== null && currentUser?.appUser.DesoMessengerGroupChatId === '' && (*/}
              {/*  <Button onClick={() => createGroupChat()} className="mt-4" shape="rounded">*/}
              {/*    Create group chat*/}
              {/*  </Button>*/}
              {/*)}*/}
            </FormControlGroup>
            <FormControlGroup>
              <MarkdownEditor
                title={currentUser.isProjectOwner ? 'Project Description' : 'Description'}
                placeholder={
                  currentUser.isProjectOwner
                    ? 'Give a detailed description of your project...'
                    : 'Give a detailed description of yourself...'
                }
                initialValue={formState.longDesc}
                onEdit={(markdown) => setFormState({ ...formState, longDesc: markdown })}
              />
            </FormControlGroup>
            <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row items-start justify-between border mb-6 rounded-xl border-gray-light p-6 bg-accent">
              <div className="w-full sm:w-3/5 flex flex-col gap-2">
                <div className="text-md font-bold text-muted-foreground">Enable NFT Gallery</div>
                <p className="text-muted text-sm leading-5">
                  If enabled, this will display a gallery of all NFTs minted by your project profile.
                </p>
              </div>
              <div className="w-full sm:w-2/5 flex">
                <Switch
                  checked={formState.EnableDaoNFTGallery}
                  onChange={() =>
                    setFormState((prevState) => ({ ...prevState, EnableDaoNFTGallery: !prevState.EnableDaoNFTGallery }))
                  }
                  className={`${
                    formState.EnableDaoNFTGallery ? 'bg-primary' : 'bg-card hover:bg-accent'
                  } relative inline-flex h-6 w-11 items-center bg-card rounded-full border border-border`}
                >
                  <span
                    className={`${
                      formState.EnableDaoNFTGallery ? 'translate-x-6 bg-primary-foreground' : 'bg-muted translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full`}
                  />
                </Switch>
              </div>
            </div>
            <Button type="submit">Save Changes</Button>
          </form>
        </>
      )}
    </div>
  );
}

export default Project;
