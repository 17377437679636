import { useEffect } from 'react';

const buildTitle = (prefix: string): string => `${prefix} | Openfund`;

export function useDocumentTitle(suffix: string) {
  useEffect(() => {
    if (typeof window == undefined) {
      return;
    }
    const newTitle = buildTitle(suffix);
    document.title = newTitle;

    const titleChangeAnnouncer = document.getElementById('sr-page-title-change-announcement');
    if (titleChangeAnnouncer) {
      titleChangeAnnouncer.textContent = newTitle;
    }

    return () => {
      document.title = buildTitle('Fund your next big idea (Powered by DeSo Blockchain)');
    };
  });
}
