import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';

export function useToast() {
  const { toast } = useContext(AppContext);

  if (!toast) {
    throw new Error('toast is not defined.');
  }

  return toast;
}
