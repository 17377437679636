import { sha256 } from '@noble/hashes/sha256';

export const getTopicForSinglePublicKey = (entityName: TopicPrefix, publicKey: string) => {
  return getTopicForFullTopicStr([entityName.toString(), publicKey].join(':'));
};

export const getTopicForTwoPublicKeys = (entityName: TopicPrefix, publicKey1: string, publicKey2: string) => {
  return getTopicForFullTopicStr(
    [entityName.toString()].concat([publicKey1, publicKey2].sort((a, b) => (a > b ? 1 : -1))).join(':'),
  );
};

export const getTopicForFullTopicStr = (fullTopicStr: string) => {
  return btoa(String.fromCharCode.apply(null, Array.from(sha256(fullTopicStr))))
    .replaceAll('=', '')
    .replaceAll('/', '_')
    .replaceAll('+', '-')
    .substr(0, 40);
};

export enum TopicPrefix {
  FilledDaoCoinOrdersWithPricesAggs = 'filled_dao_coin_orders_with_prices_aggs',
  DAOCoinLimitOrderEntries = 'dao_coin_limit_order_entries',
}
