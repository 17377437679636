import React from 'react';
import { TimestampTooltip } from './TimestampTooltip';
import LowNumFormatter from './LowNumFormatter';
import { usdCentsToUSD } from 'utils/currency';
import { pluralize } from 'utils/text';

interface AccountStatsProps {
  account: any;
  accountFirstTransaction: any;
  className?: string;
}

export const AccountStats: React.FC<AccountStatsProps> = ({ account, accountFirstTransaction, className = '' }) => {
  if (!account) return null;

  return (
    <div className={`flex items-center gap-4 border-t px-6 mt-2 [&>*]:grow [&>*]:basis-[33%] ${className}`}>
      <div className="border-r border-border-light py-4 w-1/3" key="assets">
        <p className="text-muted text-xs">Assets</p>
        <LowNumFormatter
          className="font-medium text-muted-foreground"
          price={account.totalBalanceUsdCents ? usdCentsToUSD(account.totalBalanceUsdCents) : 0}
        />
      </div>
      <div className="border-r border-border-light py-4 w-1/3" key="tokens">
        <p className="text-muted text-xs">
          {pluralize(account.tokenBalanceSummariesAsHodler.totalCount ?? 0, 'Token', {
            includeCount: false,
          })}{' '}
          Owned
        </p>
        <span className="font-medium text-muted-foreground text-sm">
          {account.tokenBalanceSummariesAsHodler.totalCount ?? 0}
        </span>
      </div>
      <div className="py-4 w-1/3" key="created">
        <p className="text-muted text-xs">Join Date</p>
        <TimestampTooltip
          className="font-medium text-sm text-muted-foreground"
          timestamp={new Date((accountFirstTransaction?.BlockInfo?.TimestampSecs ?? 0) * 1000).toISOString()}
          format="MMM DD, YYYY"
        />
      </div>
    </div>
  );
};
