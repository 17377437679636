import type { CellContext, ColumnDef } from '@tanstack/react-table';

import LowNumFormatter from './LowNumFormatter';
import { Badge } from '../shadcn/ui/badge';
import { TimestampTooltip } from './TimestampTooltip';
import { TradingRecentTrade } from '../../graphql/codegen/graphql';
import { cn } from '../../utils/shadcn';
import { renderSortingHeader } from './TableSortingHeader';
import { FOCUS_TOKEN_DISPLAY_NAME } from '../../utils/constants';
import TransactionCopyToClipboard from './TransactionCopyToClipboard';
import { TransactionVolumeBadge, TransactionVolumeBadgeType } from './TransactionVolumeBadge';
import { Avatar } from '../core/Avatar';
import { getWrappedAsset, getWrappedAssetIcon, shortenLongWord } from '../../utils/deso';
import { RouteLink } from '../core/RouteLink';
import { Routes } from '../../RoutePaths';
import { DESO_EXPLORER_URL, DESO_ZERO_PUBLIC_KEY, FOCUS_TOKEN_PUBLIC_KEY } from '../../constants/AppConstants';
import { DESO_PROJECT_NAME, DESO_TOKEN_PUBLIC_KEY } from '../../constants/TradeConstants';
import { AccountSocialCard } from './AccountSocialCard';

interface CustomCellContext extends CellContext<TradingRecentTrade, unknown> {
  focusExchangeRate: number;
  quoteCurrencyPublicKey: string;
  pageOffset: number;
  cookiePublicKey: string;
  refetchData: () => void;
}

export enum TradeType {
  Buy = 'BUY',
  Sell = 'SELL',
}

const getWrappedAssetForTrade = (coinPublicKey: string) => {
  const publicKey = coinPublicKey === DESO_ZERO_PUBLIC_KEY ? DESO_TOKEN_PUBLIC_KEY : coinPublicKey;
  return getWrappedAsset(publicKey || '', 'publicKey');
};

const renderTransactionVolumeBadgeBasedOnValue = (valueUsd: number) => {
  const badgeConfigs = [
    { type: TransactionVolumeBadgeType.Whale, min: 10000, width: 22 },
    { type: TransactionVolumeBadgeType.Dolphin, min: 1000, width: 18 },
    { type: TransactionVolumeBadgeType.Fish, min: 100, width: 18 },
    { type: TransactionVolumeBadgeType.Shrimp, min: 0, width: 18 },
  ];

  const badgeConfig = badgeConfigs.find((config) => valueUsd >= config.min);

  if (!badgeConfig) {
    return null;
  }

  return (
    <TransactionVolumeBadge
      type={badgeConfig.type}
      text={
        <LowNumFormatter price={valueUsd} abbreviatePriceThreshold={Number.MAX_SAFE_INTEGER} highPriceThreshold={1} />
      }
      width={badgeConfig.width}
    />
  );
};

export const columns = (): ColumnDef<TradingRecentTrade>[] => {
  return [
    {
      accessorKey: 'tradeTimestamp',
      header: ({ column }) => renderSortingHeader({ column, title: 'Time', className: 'text-xxs' }),
      cell: ({ row }) => (
        <TimestampTooltip
          timestamp={row.original.tradeTimestamp}
          className={cn('cursor-pointer', row.original.tradeType === TradeType.Buy ? 'text-green-500' : 'text-red-500')}
        />
      ),
    },
    {
      accessorKey: 'tradeType',
      header: () => <span className="text-xxs">Type</span>,
      cell: ({ row }) => (
        <Badge variant={row.original.tradeType === TradeType.Buy ? 'success' : 'destructive'} className="text-xs">
          {row.original.tradeType}
        </Badge>
      ),
    },
    {
      accessorKey: 'traderPublicKey',
      header: () => <span className="text-xxs">Trader</span>,
      cell: ({ row }) => {
        if (!row.original.traderPublicKey) {
          return (
            <div className="flex items-center gap-1">
              <Avatar /> <span className="truncate font-sans text-sm text-muted underline-offset-4">Unknown</span>
            </div>
          );
        }

        return (
          <AccountSocialCard publicKey={row.original.traderPublicKey}>
            <div className="flex items-center gap-2 pl-0 text-left grow font-normal overflow-hidden underline-offset-4 truncate text-xs text-muted-foreground leading-5">
              <RouteLink
                to={Routes.profile(row.original.traderUsername || row.original.traderPublicKey)}
                className="!contents"
              >
                <Avatar
                  src={row.original.traderPublicKey}
                  alt={row.original.traderUsername || row.original.traderPublicKey}
                  className="border border-border w-8 h-8"
                />
              </RouteLink>
              <div className="flex flex-col gap-0">
                <RouteLink
                  to={Routes.profile(row.original.traderUsername || row.original.traderPublicKey)}
                  className="text-xs font-semibold text-muted-foreground no-underline hover:underline pr-3"
                >
                  {row.original.traderUsername
                    ? `@${row.original.traderUsername}`
                    : shortenLongWord(row.original.traderPublicKey)}
                </RouteLink>
                <a
                  href={`${DESO_EXPLORER_URL}/u/${row.original.traderPublicKey}`}
                  target="_blank"
                  className="text-xs text-muted font-mono no-underline border-b border-transparent hover:border-foreground hover:text-foreground w-fit"
                  rel="noreferrer"
                >
                  {shortenLongWord(row.original.traderPublicKey)}
                </a>
              </div>
            </div>
          </AccountSocialCard>
        );
      },
    },
    {
      accessorKey: 'tokenPublicKey',
      header: () => <span className="text-xxs">Token</span>,
      cell: ({ row }) => {
        if (!row.original.tokenPublicKey) {
          return (
            <div className="flex items-center gap-1">
              <Avatar /> <span className="truncate font-sans text-sm text-muted underline-offset-4">Unknown</span>
            </div>
          );
        }

        const wrappedAsset = getWrappedAsset(row.original.tokenUsername || '');

        return (
          <div className="flex mr-4 pr-4 items-center gap-2 pl-0 text-left font-normal underline-offset-4 truncate text-xs text-muted-foreground leading-5">
            <RouteLink
              to={Routes.profile(
                wrappedAsset?.displayName || row.original.tokenUsername || row.original.tokenPublicKey,
              )}
              className="w-fit"
            >
              <Avatar
                src={!!wrappedAsset ? getWrappedAssetIcon(wrappedAsset) : row.original.tokenPublicKey}
                alt={wrappedAsset?.displayName || row.original.tokenUsername || row.original.tokenPublicKey}
                className="border border-border unforce-radius h-8 w-8 min-h-8 min-w-8"
              />
            </RouteLink>
            <div className="flex flex-col gap-0">
              <RouteLink
                to={Routes.profile(
                  wrappedAsset?.displayName || row.original.tokenUsername || row.original.tokenPublicKey,
                )}
                className="text-xs font-semibold text-muted-foreground no-underline hover:underline pr-3"
              >
                $
                {wrappedAsset?.displayName ||
                  row.original.tokenUsername ||
                  shortenLongWord(row.original.tokenPublicKey)}
              </RouteLink>
              <a
                href={`${DESO_EXPLORER_URL}/u/${row.original.tokenPublicKey}`}
                target="_blank"
                className="text-xs text-muted font-mono no-underline border-b border-transparent hover:border-foreground hover:text-foreground w-fit"
                rel="noreferrer"
              >
                {shortenLongWord(row.original.tokenPublicKey)}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'tradeValueUsd',
      header: ({ column }) => renderSortingHeader({ column, title: 'Value', className: 'text-xxs' }),
      cell: (props) => {
        const { row } = props as CustomCellContext;

        const valueUsd = row.original.tradeValueUsd || 0;
        return (
          <div className="flex items-center justify-start gap-0">
            <div className="flex flex-col gap-0 text-left font-mono text-xs text-foreground">
              <span
                className={cn(
                  'flex items-center gap-1',
                  row.original.tradeType === TradeType.Buy ? 'text-green-600' : 'text-red-600',
                )}
              >
                <LowNumFormatter
                  price={valueUsd}
                  highPriceThreshold={0.01}
                  abbreviatePriceThreshold={Number.MAX_SAFE_INTEGER}
                />
                <div className="relative -top-[1px]">{renderTransactionVolumeBadgeBasedOnValue(valueUsd)}</div>
              </span>
              {row.original.denominatedCoinPublicKey === FOCUS_TOKEN_PUBLIC_KEY &&
                Number.isFinite(row.original.tradeValueFocus) && (
                  <div className="text-xs text-muted hover:text-foreground">
                    <LowNumFormatter
                      price={
                        (row.original.tradeType === TradeType.Buy
                          ? row.original.tradeSellQuantity
                          : row.original.tradeBuyQuantity) ?? 0
                      }
                      highPriceThreshold={10}
                      isUsd={false}
                      className="text-xs"
                    />{' '}
                    <span>{FOCUS_TOKEN_DISPLAY_NAME}</span>
                  </div>
                )}
              {row.original.denominatedCoinPublicKey === DESO_ZERO_PUBLIC_KEY &&
                Number.isFinite(
                  row.original.tradeType === TradeType.Buy
                    ? row.original.tradeSellQuantity
                    : row.original.tradeBuyQuantity,
                ) && (
                  <div className="text-xs text-muted hover:text-foreground">
                    <LowNumFormatter
                      price={
                        (row.original.tradeType === TradeType.Buy
                          ? row.original.tradeSellQuantity
                          : row.original.tradeBuyQuantity) ?? 0
                      }
                      highPriceThreshold={10}
                      isUsd={false}
                      className="text-xs"
                    />{' '}
                    <span>{DESO_PROJECT_NAME}</span>
                  </div>
                )}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'tradeQuantity',
      header: () => <span className="text-xxs">Quantity</span>,
      cell: (props) => {
        const { row } = props as CustomCellContext;
        const isBuy = row.original.tradeType === TradeType.Buy;

        const wrappedAssetBuy = getWrappedAssetForTrade(row.original.tradeBuyCoinPublicKey ?? '');
        const wrappedAssetSell = getWrappedAssetForTrade(row.original.tradeSellCoinPublicKey ?? '');

        return (
          <div className="flex items-center gap-2 text-left font-mono">
            {isBuy ? (
              <div className="flex items-center gap-2">
                <Avatar
                  size={'xs'}
                  src={
                    wrappedAssetBuy ? getWrappedAssetIcon(wrappedAssetBuy) : (row.original.tradeBuyCoinPublicKey ?? '')
                  }
                  alt={
                    wrappedAssetBuy ? getWrappedAssetIcon(wrappedAssetBuy) : (row.original.tradeBuyCoinPublicKey ?? '')
                  }
                  className="border unforce-radius border-border"
                />{' '}
                <LowNumFormatter
                  price={row.original.tradeBuyQuantity ?? 0}
                  isUsd={false}
                  highPriceThreshold={10}
                  className="text-sm"
                />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Avatar
                  size={'xs'}
                  src={
                    wrappedAssetSell
                      ? getWrappedAssetIcon(wrappedAssetSell)
                      : (row.original.tradeSellCoinPublicKey ?? '')
                  }
                  alt={
                    wrappedAssetSell
                      ? getWrappedAssetIcon(wrappedAssetSell)
                      : (row.original.tradeSellCoinPublicKey ?? '')
                  }
                  className="border unforce-radius border-border"
                />{' '}
                <LowNumFormatter
                  price={row.original.tradeSellQuantity ?? 0}
                  isUsd={false}
                  highPriceThreshold={10}
                  className="text-sm"
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'tradePriceUsd',
      header: ({ column }) => renderSortingHeader({ column, title: 'Price', className: 'text-xxs' }),
      cell: (props) => {
        const { row } = props as CustomCellContext;
        const priceUsd = row.original.tradePriceUsd || 0;

        return (
          <div className="flex flex-col gap-0 text-left font-mono">
            <LowNumFormatter price={priceUsd || 0} className="text-sm text-muted-foreground" />
            {row.original.denominatedCoinPublicKey === FOCUS_TOKEN_PUBLIC_KEY &&
              Number.isFinite(row.original.tradePriceFocus) && (
                <div className="flex items-center gap-1 text-xs text-muted hover:text-foreground">
                  <LowNumFormatter
                    price={row.original.tradePriceFocus ?? 0}
                    highPriceThreshold={10}
                    isUsd={false}
                    className="text-xs"
                  />{' '}
                  <span>{FOCUS_TOKEN_DISPLAY_NAME}</span>
                </div>
              )}
            {row.original.denominatedCoinPublicKey === DESO_ZERO_PUBLIC_KEY &&
              Number.isFinite(row.original.tradePriceDeso) && (
                <div className="flex items-center gap-1 text-xs text-muted hover:text-foreground">
                  <LowNumFormatter
                    price={row.original.tradePriceDeso ?? 0}
                    highPriceThreshold={10}
                    isUsd={false}
                    className="text-xs"
                  />{' '}
                  <span>{DESO_PROJECT_NAME}</span>
                </div>
              )}
          </div>
        );
      },
    },
    {
      accessorKey: 'txnHashHex',
      header: () => <span className="text-xxs">Txn</span>,
      cell: (props) => {
        const { row } = props as CustomCellContext;
        if (!row.original.txnHashHex) return null;

        return (
          <TransactionCopyToClipboard
            transactionHash={row.original.txnHashHex}
            timestamp={row.original.tradeTimestamp}
            txnClassName="text-xs"
          />
        );
      },
    },
  ];
};
