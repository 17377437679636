import { useMemo } from 'react';
import { basisPointsToPercent } from 'utils/currency';
import { FOCUS_TOKEN_PUBLIC_KEY, OPENFUND_TOKEN_PUBLIC_KEY } from '../../constants/AppConstants';
import { APP_NAME_GENERIC, FOCUS_TOKEN_DISPLAY_NAME } from '../../utils/constants';
import { getUserName } from '../../utils/user';
import { useCreatorCoinFee } from '../../hooks/useCreatorCoinFee';
import { Account } from '../../graphql/codegen/graphql';
import { Skeleton } from '../shadcn/ui/skeleton';
import { cn } from '../../utils/shadcn';

const DEFAULT_LEADING_TEXT = `Openfund allows a token's creator to set fees for their market (thanks to the DeSo DEX). These fees are already included in the price, and they are listed below. All fees are taker-only right now (maker fees are zero). Fees paid to Focus and Openfund are used to buy & burn $FOCUS and $OPENFUND respectively.'`;

interface PricePerTokenProps {
  username: string;
  publicKey: string;
  leadingText?: string;
}

const PricePerToken = ({ username, publicKey, leadingText = DEFAULT_LEADING_TEXT }: PricePerTokenProps) => {
  const { loading: loadingCreatorCoinFees, creatorCoinMarketFee } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCreatorCoinFee({
      username,
      publicKey,
    });

  const pricePerCoinTooltip = useMemo(() => {
    const accountPublicKey = publicKey ?? '';
    const focusAppKey = FOCUS_TOKEN_PUBLIC_KEY;
    const openfundAppKey = OPENFUND_TOKEN_PUBLIC_KEY;

    const accountFee = creatorCoinMarketFee?.FeeBasisPointsByPublicKey[accountPublicKey] ?? 0;
    const focusAppFee =
      accountPublicKey === focusAppKey ? 0 : (creatorCoinMarketFee?.FeeBasisPointsByPublicKey[focusAppKey] ?? 0);
    const openfundAppFee =
      accountPublicKey === openfundAppKey ? 0 : (creatorCoinMarketFee?.FeeBasisPointsByPublicKey[openfundAppKey] ?? 0);

    const otherFees = Object.entries(creatorCoinMarketFee?.FeeBasisPointsByPublicKey || {})
      .filter(([key]) => key !== publicKey && key !== focusAppKey && key !== openfundAppKey)
      .reduce((total, [, fee]) => total + fee, 0);

    const totalFee = accountFee + focusAppFee + openfundAppFee + otherFees;

    return (
      <section>
        <div>{leadingText}</div>

        <ul className={cn('mt-2 list-disc', !loadingCreatorCoinFees && 'ml-4')}>
          {loadingCreatorCoinFees ? (
            <div className="flex flex-col gap-0.5">
              <Skeleton className="h-3 w-1/2" />
              <Skeleton className="h-3 w-1/2" />
              <Skeleton className="h-3 w-1/2" />
            </div>
          ) : creatorCoinMarketFee ? (
            <>
              {username && (
                <li>
                  {getUserName({ account: { id: '', username, publicKey } as Account, customPrefix: '' })}:{' '}
                  {basisPointsToPercent(accountFee)}%
                </li>
              )}

              {publicKey !== OPENFUND_TOKEN_PUBLIC_KEY && (
                <li>
                  {APP_NAME_GENERIC}: {basisPointsToPercent(openfundAppFee)}%
                </li>
              )}

              {publicKey !== FOCUS_TOKEN_PUBLIC_KEY && (
                <li>
                  {FOCUS_TOKEN_DISPLAY_NAME}: {basisPointsToPercent(focusAppFee)}%
                </li>
              )}

              {otherFees > 0 && <li>Referral: {basisPointsToPercent(otherFees)}%</li>}

              <li>= Total: {basisPointsToPercent(totalFee)}%</li>
            </>
          ) : (
            <li>0.00%</li>
          )}
        </ul>
      </section>
    );
  }, [username, publicKey, creatorCoinMarketFee, loadingCreatorCoinFees]);

  return <div>{pricePerCoinTooltip}</div>;
};

export default PricePerToken;
