import { FullPageError } from 'components/app-ui/FullPageError';
import { NFTCard } from 'components/app-ui/NFTCard';
import { SwiperCarousel } from 'components/app-ui/SwiperCarousel';
import { Spinner } from 'components/core/Spinner';
import { GetNFTsCreatedByPublicKeyResponse } from 'deso-protocol';
import { useIsMounted } from 'hooks/useIsMounted';
import { ReactNode, useEffect, useState } from 'react';

const IS_LG_SCREEN = window.innerWidth > 1280;
interface NFTCarouselProps {
  nftsPromise: Promise<GetNFTsCreatedByPublicKeyResponse | null>;
}
export function NFTCarousel({ nftsPromise }: NFTCarouselProps) {
  const [slides, setSlides] = useState<ReactNode[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<any>();
  const isMounted = useIsMounted();
  const [slidesPerView, setSlidesPerView] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    setLoadingError(undefined);
    nftsPromise
      .then((res) => {
        if (!isMounted.current || !res?.NFTs) return;
        const _slidesPerView = IS_LG_SCREEN && res.NFTs.length > 1 ? 2 : 1;
        setSlidesPerView(_slidesPerView);
        setSlides(
          res.NFTs.map((nft) => {
            return <NFTCard key={nft.NFTCollectionResponse?.PostEntryResponse?.PostHashHex} nft={nft} />;
          }),
        );
      })
      .catch((e) => {
        if (!isMounted.current) return;
        setLoadingError(e);
      })
      .finally(() => {
        if (!isMounted.current) return;
        setIsLoading(false);
      });
  }, [nftsPromise]);

  if (isLoading) {
    return <Spinner />;
  }

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  if (!slides) {
    return <></>;
  }

  return (
    <div className="overflow-hidden">
      <SwiperCarousel
        slides={slides}
        spaceBetween={slidesPerView > 1 ? 30 : 0}
        pagination={true}
        slidesPerView={2}
        slidesPerGroup={slidesPerView}
        navigation={true}
      />
    </div>
  );
}
