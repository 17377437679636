'use client';

import { useLazyQuery } from '@apollo/client';
import type { CellContext, Column, ColumnDef, PaginationState, Row, SortingState } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LuBadgeDollarSign, LuFlame, LuLock, LuTriangleAlert, LuX } from 'react-icons/lu';

import {
  renderApyTooltip,
  renderCirculatingSupplyTableCell,
  renderCirculatingSupplyTooltip,
  renderCoinUserTableCell,
  renderHoldersCell,
  renderMarketCapTableCell,
  renderMarketCapTooltip,
  renderPriceUsdTableCell,
  renderVolumeTableCell,
} from './WalletTokenHoldingsColumns';
import CoinPriceChangePercentageFormatter from './CoinPriceChangePercentageFormatter';

import { deso } from '../../services';
import GenericTable from './GenericTable';
import { InfoTooltip } from 'components/core/InfoTooltip';
import { Skeleton } from '../shadcn/ui/skeleton';
import { useToast } from '../hooks/use-toast';
import { getOrderByKey } from '../../utils/activityTable';
import {
  basisPointsToPercent,
  MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING,
  tokenNanosToInteger,
} from 'utils/currency';
import { pluralize } from '../../utils/text';
import { PageInfo, UserDaoCoinStat, UserDaoCoinStatsDocument, UserDaoCoinStatsOrderBy } from 'graphql/codegen/graphql';
import {
  DESO_BTC_PUBLIC_KEY,
  DESO_ETH_PUBLIC_KEY,
  DESO_SOL_PUBLIC_KEY,
  DESO_USDC_PUBLIC_KEY,
  FOCUS_TOKEN_PUBLIC_KEY,
} from '../../constants/AppConstants';
import { cn } from 'utils/shadcn';
import { renderSortingHeader } from './TableSortingHeader';
import LowNumFormatter from './LowNumFormatter';
import { Tabs } from '../core/Tabs';
import TablePagination from './TablePagination';
import { DESO_APP_NAME, DESO_TOKEN_DISPLAY_NAME, LOCAL_STORAGE_KEYS } from '../../utils/constants';
import WalletTokenActions from './WalletTokenActions';
import { WalletAction } from '../pages/MyWallet';
import { useWaitForTransaction } from '../../hooks/useWaitForTransaction';
import { getDominantMarketQuoteCurrencyPublicKey } from '../../utils/orderbook';
import { Alert } from '../shadcn/ui/alert';
import useLocalStorage from '../../hooks/useLocalStorage';
import { WalletLockedTokensCell } from './WalletLockedTokensCell';

interface WalletTokenHoldingsProps {
  publicKey: string;
  isMyWallet: boolean;
  hasCoin: boolean;
  onSendTokens: (
    publicKey: string,
    username: string,
    balanceNanos: string,
    action: WalletAction,
    onSuccess: (txnHashHex: string) => void,
  ) => void;
  onBurnTokens: (
    publicKey: string,
    username: string,
    balanceNanos: string,
    action: WalletAction,
    onSuccess: (txnHashHex: string) => void,
  ) => void;
}

const COLUMNS_WIDTH = [3, 14, 9, 9, 9, 8, 7, 10, 10, 11, 6, 4];
const DEFAULT_ITEMS_PER_PAGE = 10;

interface CustomCellContext extends CellContext<UserDaoCoinStat, unknown> {
  focusExchangeRate: number;
  desoExchangeRate: number;
  pageOffset: number;
  publicKey: string; // current user public key
  refetchData: () => void;
}

enum TabsValue {
  MyTokens = 'myTokens',
  HiddenTokens = 'hiddenTokens',
}

const WalletTokenHoldings = ({
  publicKey,
  isMyWallet,
  hasCoin,
  onSendTokens,
  onBurnTokens,
}: WalletTokenHoldingsProps) => {
  const { toast } = useToast();
  const { waitForTxn } = useWaitForTransaction();

  const [loadingMyToken, setLoadingMyToken] = useState(true);
  const [myToken, setMyToken] = useState<UserDaoCoinStat | null>(null);
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userDesoTokenHoldings, setUserDesoTokenHoldings] = useState<Partial<UserDaoCoinStat>[]>([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [itemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [totalCount, setTotalCount] = useState(0);
  const [columnSorting, setColumnSorting] = useState<SortingState>([{ id: 'priceUsdPctChange24H', desc: true }]);
  const [currentTab, setCurrentTab] = useState<string>(TabsValue.MyTokens);
  const [showHiddenTokensAlert, setShowHiddenTokensAlert] = useLocalStorage(LOCAL_STORAGE_KEYS.showTokensAlert, true);

  const TabItems = useMemo(
    () => [
      {
        value: TabsValue.MyTokens,
        label: `${isMyWallet ? 'My' : 'Their'} Tokens`,
        tooltip: `Tokens that ${isMyWallet ? 'you' : "they've"} buy or explicitly whitelist 
            will appear in this tab. This prevents people from polluting ${isMyWallet ? 'your' : 'their'} 
            wallet with spam tokens that ${isMyWallet ? "you've" : "they've"} never bought or heard of. 
            ${isMyWallet ? 'You' : 'Token owners'} can always move a token back and forth 
            from the spam folder by using the option in three dots menu on the side`,
      },
      {
        value: TabsValue.HiddenTokens,
        label: 'Hidden Tokens',
        tooltip: `When someone sends you a token that you've never bought 
        or explicitly whitelisted, it will appear in the "Hidden Tokens" 
        tab. This prevents people from polluting your wallet with spam 
        tokens that you've never bought or heard of. You can always move 
        a token back and forth from the Hidden tab by using the option 
        in three dots menu on the side.`,
      },
    ],
    [isMyWallet],
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: itemsPerPage,
  });

  const [fetchUserDaoCoinStats] = useLazyQuery(UserDaoCoinStatsDocument);

  const fetchMyToken = async () => {
    if (!hasCoin) {
      return;
    }

    setLoadingMyToken(true);

    try {
      const { data } = await fetchUserDaoCoinStats({
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            userPublicKey: {
              equalTo: publicKey,
            },
            coinUsername: {
              notEqualTo: '',
            },
            coinPublicKey: {
              equalTo: publicKey,
            },
          },
        },
      });

      setMyToken(data?.userDaoCoinStats?.nodes.filter(Boolean)?.[0] || null);
    } catch (e) {
      toast({
        variant: 'error',
        description: `An error happened when fetching your ${DESO_TOKEN_DISPLAY_NAME} token`,
      });
    } finally {
      setLoadingMyToken(false);
    }
  };

  const refetchWithUpdatedParams = useCallback(
    async (
      params: {
        first?: number;
        offset?: number;
      } = { first: itemsPerPage, offset: pageOffset },
    ) => {
      setLoading(true);

      try {
        const desoTokenStatsResponse = await fetchUserDaoCoinStats({
          fetchPolicy: 'network-only',
          variables: {
            orderBy: getOrderByKey(
              columnSorting,
              UserDaoCoinStatsOrderBy as any,
              UserDaoCoinStatsOrderBy.PriceUsdPctChange_24HDesc,
            ),
            filter: {
              userPublicKey: {
                equalTo: publicKey,
              },
              coinUsername: {
                notEqualTo: '',
              },
              coinPublicKey: {
                notIn: [
                  FOCUS_TOKEN_PUBLIC_KEY,
                  DESO_USDC_PUBLIC_KEY,
                  DESO_BTC_PUBLIC_KEY,
                  DESO_ETH_PUBLIC_KEY,
                  DESO_SOL_PUBLIC_KEY,
                ],
              },
              isUserWhitelisted: {
                equalTo: currentTab === TabsValue.MyTokens,
              },
            },
            ...params,
          },
        });

        const desoTokenStats = (desoTokenStatsResponse.data?.userDaoCoinStats?.nodes.filter(Boolean) ||
          []) as Array<UserDaoCoinStat>;

        const prices = await deso.getBaseCurrencyPrice(
          desoTokenStats.map((coin) => ({
            BaseCurrencyPublicKeyBase58Check: coin.coinPublicKey ?? '',
            BaseCurrencyQuantityToSell: tokenNanosToInteger(Number(coin.userBalanceNanos ?? '0')),
            QuoteCurrencyPublicKeyBase58Check: getDominantMarketQuoteCurrencyPublicKey(coin),
          })),
        );
        const parsedDesoTokenStats = desoTokenStats.map((coin, index) => ({
          ...coin,
          userBalanceUsd: Number(prices[index].ReceiveAmountInUsd),
        }));

        setUserDesoTokenHoldings(parsedDesoTokenStats);
        setPageInfo(desoTokenStatsResponse.data?.userDaoCoinStats?.pageInfo);
        setTotalCount(desoTokenStatsResponse.data?.userDaoCoinStats?.totalCount ?? 0);
      } catch (e) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: `An error happened when fetching your ${DESO_TOKEN_DISPLAY_NAME} tokens`,
        });
      } finally {
        setFirstLoading(false);
        setLoading(false);
      }
    },
    [columnSorting, fetchUserDaoCoinStats, itemsPerPage, pageOffset, publicKey, currentTab],
  );

  const onPaginate = async (nextPageIndex: number) => {
    const offset = nextPageIndex * itemsPerPage;

    setPagination({
      pageIndex: nextPageIndex,
      pageSize: itemsPerPage,
    });

    await refetchWithUpdatedParams({
      first: itemsPerPage,
      offset,
    });

    setPageOffset(offset);
  };

  const getVisibleColumns = ({ sortable }: { sortable: boolean }) => [
    {
      accessorKey: 'id',
      header: () => <span className="text-xxs text-muted">#</span>,
      cell: ({ row, pageOffset: cellPageOffset }: CustomCellContext) => {
        const { id } = row;
        return <span className="text-xs text-muted">{+id + cellPageOffset + 1}</span>;
      },
    },
    {
      accessorKey: 'coinPublicKey',
      header: <span className="text-xxs">Coin</span>,
      cell: ({ row, publicKey: currentUserPublicKey }: CustomCellContext) => {
        const { coinUsername, coinPublicKey, coinCategory } = row.original;

        return renderCoinUserTableCell({
          coinUsername: coinUsername ?? '',
          coinPublicKey: coinPublicKey ?? '',
          coinCategory: coinCategory ?? '',
          clickable: true,
          isTokenCreator: coinPublicKey === currentUserPublicKey,
        });
      },
    },
    {
      accessorKey: 'priceUsd',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Price',
          sortable,
          className: 'text-xxs',
        }),
      cell: (props: CustomCellContext) => {
        const { row, focusExchangeRate, desoExchangeRate } = props;
        return renderPriceUsdTableCell(row.original, focusExchangeRate, desoExchangeRate);
      },
    },
    {
      accessorKey: 'userBalanceUsd',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Value',
          sortable,
          infoTooltip: (
            <InfoTooltip
              iconSize={16}
              text="This column shows the USD amount you would get if you market
          sold all of your holdings (including locked holdings). If some
          of your holdings are locked, the unlocked cashout value will be
          shown underneath the total value. If you have locked coins, you
          can see the full unlock schedule by clicking on the lock icon in
          the second row."
            />
          ),
          className: 'text-xxs',
        }),
      cell: ({ row }: CustomCellContext) => {
        const totalUsdValue = row.original.userBalanceUsd ?? 0;
        const totalLockedUsdValue = row.original.userLockedBalanceUsd;

        return (
          <div className="flex flex-col gap-1 text-left text-xs">
            <LowNumFormatter
              price={totalUsdValue}
              highPriceThreshold={10}
              className="font-mono font-semibold text-muted-foreground"
            />
            <WalletLockedTokensCell
              refetchData={refetchWithUpdatedParams}
              totalLockedUsdValue={totalLockedUsdValue}
              coinPublicKey={row.original.coinPublicKey}
              coinUsername={row.original.coinUsername}
              coinPrice={row.original.priceUsd}
            />

            {/*  TODO: LOCKED BALANCES*/}
          </div>
        );
      },
    },
    {
      accessorKey: 'userBalanceUsdChange24H',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Value (24h)',
          sortable,
          infoTooltip: (
            <InfoTooltip
              iconSize={16}
              className="max-w-[400px]"
              text={
                <>
                  This column is an ESTIMATE of the change in the value of your holdings in the last 24h. It is computed
                  by first computing the percent change in price in the last 24h, and then using the following formula:
                  <br />
                  <br />
                  <span className="bg-accent px-2 py-3 font-mono text-xxs font-semibold">
                    valueChange = valueToday * (1 - 1 / (1 + percentChange))
                  </span>
                  <br />
                  <br />
                  This is an estimate because the percent change in price is not always equal to the percent change in
                  cashout value due to slippage. The more liquid the market, however, the more accurate this estimate
                  is.
                </>
              }
            />
          ),
          className: 'text-xxs',
        }),
      cell: ({ row }: CustomCellContext) => {
        const totalUsdValueChange24h = row.original.userBalanceUsdChange24H ?? 0;
        const totalUsdPricePctChange24h = row.original.priceUsdPctChange24H ?? 0;

        const priceChanged =
          totalUsdPricePctChange24h < 0 || totalUsdPricePctChange24h >= MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING;
        const isPositiveChange = totalUsdPricePctChange24h >= MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING;
        const wrapperClassName = priceChanged ? (isPositiveChange ? 'text-green-600' : 'text-red-600') : 'text-muted';

        return (
          <div className={cn('flex flex-col gap-1', wrapperClassName)}>
            <div className="flex items-center">
              {isPositiveChange ? '+' : ''}
              <LowNumFormatter
                price={totalUsdValueChange24h}
                highPriceThreshold={10}
                className="font-mono font-semibold"
              />
            </div>
            <div className="flex flex-row items-center gap-1 text-left text-xs">
              <CoinPriceChangePercentageFormatter
                price={row.original.priceUsdPctChange24H ?? 0}
                showArrow={true}
                arrowSize={12}
                className="inline-flex font-mono"
              />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'userBalanceNanos',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Balance',
          sortable,
          className: 'text-xxs',
        }),
      cell: ({ row }: { row: Row<Partial<UserDaoCoinStat>> }) => {
        const totalBalanceValueNanos = Number(row.original.userBalanceNanos ?? '0');
        const totalLockedBalanceValueNanos = Number(row.original.userLockedBalanceNanos ?? '0');

        return (
          <div className="flex flex-col gap-1 text-left text-xs">
            <LowNumFormatter
              price={tokenNanosToInteger(totalBalanceValueNanos)}
              isUsd={false}
              highPriceThreshold={10}
              className="font-mono font-semibold text-foreground"
            />
            {totalLockedBalanceValueNanos > 0 && (
              <div className="flex items-center gap-1 text-muted">
                <LuLock />
                <LowNumFormatter
                  price={tokenNanosToInteger(totalLockedBalanceValueNanos)}
                  isUsd={false}
                  className="font-mono text-muted"
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'apyBasisPoints',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'APY',
          sortable,
          infoTooltip: renderApyTooltip(),
          className: 'text-xxs',
        }),
      cell: ({ row }: CustomCellContext) => (
        <div className="flex flex-col gap-0 text-left font-mono text-xs text-foreground">
          <CoinPriceChangePercentageFormatter
            price={basisPointsToPercent(Number(row.original.apyBasisPoints))}
            showArrow={false}
            className="text-foreground"
          />
        </div>
      ),
    },
    {
      accessorKey: 'volumeUsdPast24H',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Volume (24h)',
          sortable,
          className: 'text-xxs',
        }),
      cell: (props: CustomCellContext) => {
        const { row, focusExchangeRate, desoExchangeRate } = props;

        return renderVolumeTableCell({
          coinStat: row.original,
          focusExchangeRate,
          desoExchangeRate,
        });
      },
    },
    {
      accessorKey: 'marketCapUsd',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Market Cap',
          sortable,
          infoTooltip: renderMarketCapTooltip(),
          className: 'text-xxs',
        }),
      cell: (props: CustomCellContext) => {
        const { row, focusExchangeRate, desoExchangeRate } = props;

        return renderMarketCapTableCell({
          coinStat: row.original,
          focusExchangeRate,
          desoExchangeRate,
        });
      },
    },
    {
      accessorKey: 'circulatingSupply',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Circ. Supply',
          sortable,
          infoTooltip: renderCirculatingSupplyTooltip(),
          className: 'text-xxs',
        }),
      cell: ({ row }: CustomCellContext) => {
        return renderCirculatingSupplyTableCell({
          circulatingSupply: Number(row.original.circulatingSupply ?? '0'),
          totalSupply: Number(row.original.totalSupply ?? '0'),
        });
      },
    },
    {
      accessorKey: 'numHolders',
      header: ({ column }: { column: Column<UserDaoCoinStat, unknown> }) =>
        renderSortingHeader({
          column,
          title: 'Holders',
          sortable,
          className: 'text-xxs',
        }),
      cell: ({ row }: CustomCellContext) => {
        return renderHoldersCell(row.original.numHolders ?? 0);
      },
    },
    {
      id: 'actions',
      cell: ({ row, refetchData }: CustomCellContext) => {
        return (
          <WalletTokenActions
            coinPublicKey={row.original.coinPublicKey ?? ''}
            coinUsername={row.original.coinUsername ?? ''}
            setLoading={setLoading}
            refetchData={refetchData}
            onSendTokens={() =>
              onSendTokens(
                row.original.coinPublicKey ?? '',
                row.original.coinUsername ?? '',
                row.original.userBalanceNanos ?? '0',
                'SEND_TOKEN',
                async (txnHashHex: string) => {
                  await waitForTxn(txnHashHex);
                  refetchData();
                },
              )
            }
            onBurnTokens={() =>
              onBurnTokens(
                row.original.coinPublicKey ?? '',
                row.original.coinUsername ?? '',
                row.original.userBalanceNanos ?? '0',
                'BURN_TOKEN',
                async (txnHashHex: string) => {
                  await waitForTxn(txnHashHex);
                  refetchData();
                },
              )
            }
            isWhitelist={currentTab === TabsValue.MyTokens}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setFirstLoading(true);
    setTotalCount(0);
    setPageOffset(0);
    setPageInfo(undefined);
    setPagination({
      pageIndex: 0,
      pageSize: DEFAULT_ITEMS_PER_PAGE,
    });
    setColumnSorting([{ id: 'priceUsdPctChange24H', desc: true }]);
    refetchWithUpdatedParams();
    fetchMyToken();
  }, [publicKey, hasCoin]);

  useEffect(() => {
    if (firstLoading || loading) {
      return;
    }
    setPageOffset(0);
    refetchWithUpdatedParams({ first: itemsPerPage, offset: 0 });
  }, [itemsPerPage]);

  useEffect(() => {
    if (firstLoading || loading) {
      return;
    }
    refetchWithUpdatedParams();
  }, [columnSorting, currentTab]);

  return (
    <div className="mb-4 w-full text-muted">
      {hasCoin && (
        <section>
          <div className="mb-4 w-full">
            <h3 className="font-shadow-green flex items-center text-green-600">
              <LuFlame className="mr-2 text-xl" />
              {isMyWallet ? 'Your' : 'Their'} Token
            </h3>
          </div>

          {loadingMyToken ? (
            <Skeleton className="mt-4 h-[120px] w-full" key="tokens-key" />
          ) : (
            <GenericTable<UserDaoCoinStat>
              wrapperClassName="min-w-[1350px]"
              loading={loadingMyToken}
              columns={getVisibleColumns({ sortable: false }) as Array<ColumnDef<UserDaoCoinStat>>}
              data={myToken ? [myToken] : []}
              columnSorting={[{ id: 'priceUsdPctChange24H', desc: true }]}
              setColumnSorting={() => {}}
              columnsWidth={COLUMNS_WIDTH}
              pagination={{
                pageIndex: 0,
                pageSize: 1,
              }}
              pageOffset={0}
              refetchData={() => {}}
              emptyTableClassName="max-w-[1000px] sticky flex items-center justify-center top-0 left-0"
            />
          )}
        </section>
      )}

      <section>
        <div className="mb-4 mt-12 w-full">
          <h3 className="flex items-center">
            <LuBadgeDollarSign className="mr-2 text-xl" />
            {DESO_APP_NAME} Tokens
          </h3>
        </div>

        {firstLoading ? (
          <Skeleton className="mt-4 h-[320px] w-full" key="tokens-key" />
        ) : (
          <>
            {showHiddenTokensAlert && (
              <Alert variant="warning" className="mb-4 text-sm p-2">
                <div className="flex justify-between w-full items-center">
                  <div className="gap-2 flex items-center">
                    <div>
                      <LuTriangleAlert />
                    </div>
                    <span>If you don't see tokens you're expecting, check your Hidden Tokens tab.</span>
                  </div>

                  <div className="group p-2 cursor-pointer" onClick={() => setShowHiddenTokensAlert(false)}>
                    <LuX className="group-hover:text-primary" size={18} />
                  </div>
                </div>
              </Alert>
            )}

            <Tabs
              onTabSelected={(e) => {
                setPageOffset(0);
                setPagination({
                  pageIndex: 0,
                  pageSize: itemsPerPage,
                });

                setCurrentTab(TabItems.find((tab) => tab.label === e.tab)?.value ?? '');
              }}
              tabs={TabItems.map((e) => {
                return {
                  tab: e.label,
                  panel: (
                    <>
                      <GenericTable<UserDaoCoinStat>
                        wrapperClassName="min-w-[1350px]"
                        loading={loading}
                        columns={getVisibleColumns({ sortable: true }) as Array<ColumnDef<UserDaoCoinStat>>}
                        data={userDesoTokenHoldings}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        pagination={pagination}
                        pageOffset={pageOffset}
                        columnsWidth={COLUMNS_WIDTH}
                        refetchData={refetchWithUpdatedParams}
                        emptyTableClassName="max-w-[1000px] sticky flex items-center justify-center top-0 left-0"
                      />
                      <TablePagination
                        loading={loading}
                        pagination={pagination}
                        pageInfo={pageInfo}
                        onPaginate={onPaginate}
                        itemsPerPage={itemsPerPage}
                        pageOffset={pageOffset}
                        totalCount={totalCount}
                      />

                      <div className="mb-4 mt-2 text-center text-xs text-muted">
                        Total: {pluralize(totalCount, `${DESO_TOKEN_DISPLAY_NAME} Token`)}
                      </div>
                    </>
                  ),
                };
              })}
            />
          </>
        )}
      </section>
    </div>
  );
};

export default WalletTokenHoldings;
