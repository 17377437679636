import { useContext, useEffect, useState } from 'react';
import { DepositAddressGroups } from 'services/HeroSwapper';
import { heroswap } from 'services';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { MEGA_SWAPPER_URL } from 'constants/AppConstants.dev';

export function useDepositAddresses() {
  const { currentUser } = useContext(OpenfundContext);
  const [addresses, setAddresses] = useState<DepositAddressGroups | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchDepositAddresses = async () => {
      if (!currentUser) {
        setLoading(false);
        setAddresses(undefined);
        setLoadingError('User is not logged in');
        return;
      }

      try {
        setLoading(true);
        setLoadingError(undefined);

        const depositAddresses = await heroswap.getDepositAddressGroups({
          DestinationAddress: currentUser.PublicKeyBase58Check,
        });

        setAddresses(depositAddresses);
      } catch (error) {
        setLoadingError(`No deposit addresses returned from ${MEGA_SWAPPER_URL}`);
        setAddresses(undefined);
      } finally {
        setLoading(false);
      }
    };

    fetchDepositAddresses();
  }, [currentUser?.PublicKeyBase58Check]);

  return { addresses, loading, loadingError };
}
