const MAX_USERNAME_LENGTH_BEFORE_GRADIENT = 8;

const getContentBlurredStyle = (contentLength = 0, maxGradientLen = MAX_USERNAME_LENGTH_BEFORE_GRADIENT) => {
  return contentLength > maxGradientLen
    ? {
        WebkitMaskImage: 'linear-gradient(90deg, #000000FF 70%, #00000000 90%)',
      }
    : undefined;
};

export default getContentBlurredStyle;
