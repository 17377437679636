import { NavLink, NavLinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { classNames } from 'utils/classNames';

export function TabNavLink({ to, children, className }: NavLinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const commonClasses = 'py-2 px-4 w-full rounded-md';
  const classes = match ? 'bg-card' : 'bg-transparent hover:bg-accent';

  return (
    <NavLink
      to={to}
      aria-current={match ? 'page' : undefined}
      className={classNames(commonClasses, classes, typeof className === 'string' ? className : '')}
    >
      {children}
    </NavLink>
  );
}
