import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useRef, useState } from 'react';

import { Account, AccountByUsernameDocument } from '../graphql/codegen/graphql';
import { isDesoPublicKey } from 'utils/deso';

export const concatUserResponses = (exactMatch?: Account | null, usersList?: Array<Account | null>): Array<Account> => {
  const nonEmptyUsers = (usersList || []).filter((e) => e !== null);

  return uniqBy(
    [exactMatch, ...nonEmptyUsers].filter((e) => !!e && e.publicKey),
    (x) => x?.publicKey,
  ).filter((e) => !!e) as Array<Account>;
};

export enum SEARCH_ENTITY {
  USERNAME = 'Username',
  PUB_KEY = 'User Public Key',
}

const validateInput = (input: string): Array<SEARCH_ENTITY> => {
  const matchedTypes = [];

  // Username: [a-zA-Z0-9_] from 1 to 26 characters
  if (/^[a-zA-Z0-9_]{1,26}$/.test(input)) matchedTypes.push(SEARCH_ENTITY.USERNAME);

  // User public key: Starts with 'BC', length 55, base58 characters
  if (isDesoPublicKey(input)) matchedTypes.push(SEARCH_ENTITY.PUB_KEY);

  return matchedTypes;
};

interface SearchResults {
  accounts: Array<Account>;
}

const getEmptySearchResults = (): SearchResults => ({
  accounts: [],
});

type UseUserSearchProps = (
  inputValue: string,
  searchTypes: Array<SEARCH_ENTITY>,
  abortController: AbortController,
  pinnedAccounts?: Array<Account>,
) => Promise<Array<Account>>;

export const useUserSearch = (
  searchValue: string,
  fetchSearchResults: UseUserSearchProps,
  loading: boolean,
  pinnedAccounts?: Array<Account>,
  usernamesToExclude: Set<string> = new Set(),
): {
  searchResults: SearchResults;
  loading: boolean;
  reset: () => void;
  search: (inputValue: string, abortController: AbortController) => Promise<void>;
  aborterRef: AbortController;
} => {
  const [aborterRef, setAbortRef] = useState(new AbortController());
  const [fetchAccountByUsername, { loading: loadingSingleAccount }] = useLazyQuery(AccountByUsernameDocument);
  const [searchResults, setSearchResults] = useState<SearchResults>(getEmptySearchResults());

  const search = async (inputValue = '', abortController: AbortController) => {
    const value = inputValue.trim();
    setSearchResults(getEmptySearchResults());

    if (!value) {
      return;
    }

    const searchTypes = validateInput(value);

    const fetchSearchResultsRequest = fetchSearchResults(value, searchTypes, abortController, pinnedAccounts);

    const exactMatch = searchTypes.includes(SEARCH_ENTITY.USERNAME)
      ? await fetchAccountByUsername({
          variables: { username: value },
        }).then((res) => res.data?.accountByUsername as Account | null)
      : null;

    const allAccounts = concatUserResponses(exactMatch, await fetchSearchResultsRequest).filter(
      (e) => !usernamesToExclude.has((e.username || '').toLowerCase()),
    );

    setSearchResults({
      accounts: uniqBy(
        [
          ...(value
            ? pinnedAccounts?.filter((account) =>
                (account?.username || '').toLowerCase().includes(value.toLowerCase()),
              ) || []
            : []),
          ...allAccounts,
        ],
        'publicKey',
      ),
    });
  };

  const searchDebounced = useRef(debounce((v, a) => search(v, a), 300)).current;

  useEffect(() => {
    aborterRef.abort();
    const newAbortController = new AbortController();
    setAbortRef(newAbortController);
    searchDebounced(searchValue, newAbortController);
  }, [searchValue, searchDebounced]);

  return {
    loading: loadingSingleAccount || loading,
    searchResults,
    reset: () => setSearchResults(getEmptySearchResults()),
    search,
    aborterRef,
  };
};
