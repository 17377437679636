import { Container } from 'components/app-ui/Container';
import { Avatar } from 'components/core/Avatar';
import { Input } from 'components/core/Input';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { getExchangeRates } from 'deso-protocol';
import { useIsMounted } from 'hooks/useIsMounted';
import { useEffect, useState } from 'react';
import { IoArrowDownOutline, IoArrowUpOutline, IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { deso, openfund } from 'services';
import { LeaderBoardData } from 'services/Openfund';
import {
  baseUnitsToTokens,
  desoNanosToDeso,
  fetchUSDCExchangeRate,
  formatDecimalValue,
  usdCentsToUSD,
} from 'utils/currency';
import { getDateDifferenceString } from 'utils/date';
import { debounce } from 'utils/debounce';
import { DESO_USDC_PUBLIC_KEY } from '../../constants/AppConstants';
import { DESO_TOKEN_PUBLIC_KEY } from '../../constants/TradeConstants';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { classNames } from '../../utils/classNames';
import { getWrappedAsset, getWrappedAssetIcon } from '../../utils/deso';
import { formatPercentChangeString } from '../../utils/percent';
import { Button } from '../core/Button';
import { PaginationFooter } from '../core/Pagination';
import { Text } from '../core/Text';
import { abbreviateNumber, USDValueWithDESO } from './USDValueWithDESO';

export enum ProjectLeaderBoardOrderBy {
  MOST_RAISED_PAST_24H = 'most_raised_past_24h',
  LEAST_RAISED_PAST_24H = 'least_raised_past_24h',
  MOST_RAISED_PAST_7D = 'most_raised_past_7d',
  LEAST_RAISED_PAST_7D = 'least_raised_past_7d',
  MOST_RAISED_ALL_TIME = 'most_raised_all_time',
  LEAST_RAISED_ALL_TIME = 'least_raised_all_time',
  HIGHEST_COIN_PRICE = 'highest_coin_price',
  LOWEST_COIN_PRICE = 'lowest_coin_price',
  INCREASE_DAY_OVER_DAY = 'increase_day_over_day',
  DECREASE_DAY_OVER_DAY = 'decrease_day_over_day',
  INCREASE_WEEK_OVER_WEEK = 'increase_week_over_week',
  DECREASE_WEEK_OVER_WEEK = 'decrease_week_over_week',
  NEWEST = 'newest',
  OLDEST = 'oldest',
  HIGHEST_VOLUME = 'highest_volume',
  LOWEST_VOLUME = 'lowest_volume',
}

const localStorageSuffix = 'dao-leaderboard-sort';

function getDefaultOrderBy(
  compact: boolean,
  localStoragePrefix: string,
  orderBy?: ProjectLeaderBoardOrderBy,
): ProjectLeaderBoardOrderBy {
  if (orderBy) {
    return orderBy;
  }

  const localStorageVal = localStorage.getItem(`${localStoragePrefix}-${localStorageSuffix}`);
  if (localStorageVal !== null) {
    return localStorageVal as ProjectLeaderBoardOrderBy;
  }

  return compact ? ProjectLeaderBoardOrderBy.MOST_RAISED_PAST_7D : ProjectLeaderBoardOrderBy.MOST_RAISED_ALL_TIME;
}

interface ProjectLeaderBoardProps {
  compact: boolean;
  limit: number;
  localStoragePrefix: string;
  orderByOverride?: ProjectLeaderBoardOrderBy;
  isLegacyView?: boolean;
}
export function ProjectLeaderBoard({
  compact = false,
  limit,
  localStoragePrefix,
  orderByOverride,
  isLegacyView = true,
}: ProjectLeaderBoardProps) {
  const [exchangeRate, setExchangeRate] = useState<GetExchangeRateUpdatedResponse>();
  const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numProjects, setNumProjects] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<ProjectLeaderBoardOrderBy>(
    getDefaultOrderBy(compact, localStoragePrefix, orderByOverride),
  );
  const [usdcExchangeRate, setUSDCExchangeRate] = useState<number>(1);
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const denominatingCoin = isLegacyView ? DESO_TOKEN_PUBLIC_KEY : DESO_USDC_PUBLIC_KEY;

  useEffect(() => {
    setIsLoading(true);

    const leaderBoardPromise = openfund
      .getTopProjects({
        limit,
        offset: currentPage,
        order: orderBy,
        denominatingCoinPublicKeyBase58Check: denominatingCoin,
      })
      .then((res) => {
        if (isMounted.current && !!res.length) {
          setLeaderBoardData(res);
        }
      });

    const exchangeRatePromise = getExchangeRates().then((res) => {
      if (isMounted.current) {
        setExchangeRate(res);
      }
    });

    const numProjectsPromise = openfund.getProjectProfilesCount().then((res) => {
      if (res) {
        setNumProjects(res.TotalDAOProfileCount);
      }
    });
    const usdcExchangeRatePromise = fetchUSDCExchangeRate().then((res) => {
      if (isMounted.current) {
        setUSDCExchangeRate(res);
      }
    });

    Promise.all([leaderBoardPromise, exchangeRatePromise, numProjectsPromise, usdcExchangeRatePromise]).finally(() => {
      if (isMounted.current) {
        setIsLoading(false);
      }
    });
  }, [denominatingCoin]);

  useEffect(() => {
    if (!orderByOverride) {
      localStorage.setItem(`${localStoragePrefix}-${localStorageSuffix}`, orderBy.toString());
    }
  }, [orderBy, localStoragePrefix, orderByOverride]);

  useEffect(() => {
    setIsLoading(true);
    openfund
      .getTopProjects({
        limit,
        offset: currentPage * limit,
        order: orderBy,
        denominatingCoinPublicKeyBase58Check: denominatingCoin,
      })
      .then((res) => {
        setLeaderBoardData(res);
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, orderBy, limit]);

  const onHeaderClicked = (ascending: ProjectLeaderBoardOrderBy, descending: ProjectLeaderBoardOrderBy): void => {
    setIsLoading(true);
    debounce(async () => {
      const newOrderBy = orderBy === ascending ? descending : ascending;
      setOrderBy(newOrderBy);
      const res = await openfund.getTopProjects({
        limit,
        offset: 0,
        order: newOrderBy,
        denominatingCoinPublicKeyBase58Check: denominatingCoin,
      });
      setLeaderBoardData(() => res);
      setIsLoading(false);
    }, 500);
  };

  return (
    <Container table="responsive">
      {!compact && (
        <div className="relative">
          <div className="bg-gray-eee pt-2 lg:relative">
            <Input
              leftIcon={IoSearch}
              type="search"
              labelText="Search"
              labelSrOnly={true}
              placeholder="Search by username"
              containerClasses="w-full px-4 py-2"
              textSize="sm"
              onChange={(ev) => {
                setIsLoading(true);
                debounce(
                  async (username: string) => {
                    const res = await openfund.getTopProjects({
                      username,
                      limit,
                      offset: 0,
                      order: orderBy,
                      denominatingCoinPublicKeyBase58Check: denominatingCoin,
                    });
                    setLeaderBoardData(() => res);
                    setIsLoading(false);
                  },
                  500,
                  ev.currentTarget.value,
                );
              }}
            />
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : leaderBoardData.length > 0 ? (
        <>
          <div className="w-full" style={{ overflowX: 'scroll' }}>
            <table className="w-full text-right" cellPadding={12}>
              <thead className="text-sm">
                <tr>
                  <th className="text-right px-2" style={{ whiteSpace: 'nowrap' }}>
                    #
                  </th>
                  <th className="text-left px-2" style={{ whiteSpace: 'nowrap' }}>
                    Token
                  </th>
                  <ProjectLeaderBoardColumnHeader
                    orderBy={orderBy}
                    AscendingOrderBy={ProjectLeaderBoardOrderBy.HIGHEST_COIN_PRICE}
                    DescendingOrderBy={ProjectLeaderBoardOrderBy.LOWEST_COIN_PRICE}
                    ColumnHeader={'Price'}
                    onClick={onHeaderClicked}
                  />
                  <ProjectLeaderBoardColumnHeader
                    orderBy={orderBy}
                    AscendingOrderBy={ProjectLeaderBoardOrderBy.INCREASE_DAY_OVER_DAY}
                    DescendingOrderBy={ProjectLeaderBoardOrderBy.DECREASE_DAY_OVER_DAY}
                    ColumnHeader={'24h %'}
                    onClick={onHeaderClicked}
                  />
                  <ProjectLeaderBoardColumnHeader
                    orderBy={orderBy}
                    AscendingOrderBy={ProjectLeaderBoardOrderBy.INCREASE_WEEK_OVER_WEEK}
                    DescendingOrderBy={ProjectLeaderBoardOrderBy.DECREASE_WEEK_OVER_WEEK}
                    ColumnHeader={'7d %'}
                    onClick={onHeaderClicked}
                  />
                  <th className="px-2" style={{ whiteSpace: 'nowrap' }}>
                    Market Cap
                  </th>
                  <ProjectLeaderBoardColumnHeader
                    orderBy={orderBy}
                    AscendingOrderBy={ProjectLeaderBoardOrderBy.HIGHEST_VOLUME}
                    DescendingOrderBy={ProjectLeaderBoardOrderBy.LOWEST_VOLUME}
                    ColumnHeader={'Volume 24h'}
                    onClick={onHeaderClicked}
                  />
                  <ProjectLeaderBoardColumnHeader
                    orderBy={orderBy}
                    AscendingOrderBy={ProjectLeaderBoardOrderBy.MOST_RAISED_ALL_TIME}
                    DescendingOrderBy={ProjectLeaderBoardOrderBy.LEAST_RAISED_ALL_TIME}
                    ColumnHeader={'Total Raised'}
                    onClick={onHeaderClicked}
                  />
                  {!compact && (
                    <ProjectLeaderBoardColumnHeader
                      orderBy={orderBy}
                      AscendingOrderBy={ProjectLeaderBoardOrderBy.MOST_RAISED_PAST_24H}
                      DescendingOrderBy={ProjectLeaderBoardOrderBy.LEAST_RAISED_PAST_24H}
                      ColumnHeader={'Raised 24h'}
                      onClick={onHeaderClicked}
                    />
                  )}
                  <ProjectLeaderBoardColumnHeader
                    orderBy={orderBy}
                    AscendingOrderBy={ProjectLeaderBoardOrderBy.MOST_RAISED_PAST_7D}
                    DescendingOrderBy={ProjectLeaderBoardOrderBy.LEAST_RAISED_PAST_7D}
                    ColumnHeader={'Raised 7d'}
                    onClick={onHeaderClicked}
                  />
                  {!compact && (
                    <th className="px-2" style={{ whiteSpace: 'nowrap' }}>
                      Hodlers
                    </th>
                  )}
                  {!compact && (
                    <ProjectLeaderBoardColumnHeader
                      orderBy={orderBy}
                      AscendingOrderBy={ProjectLeaderBoardOrderBy.NEWEST}
                      DescendingOrderBy={ProjectLeaderBoardOrderBy.OLDEST}
                      ColumnHeader={'Created'}
                      onClick={onHeaderClicked}
                    />
                  )}
                  {!compact && <th></th>}
                </tr>
              </thead>
              <tbody className="text-sm">
                {leaderBoardData.map((d, i) => {
                  const wrappedAsset = getWrappedAsset(d.Username);
                  const isWrapped = !!wrappedAsset;
                  const displayName = isWrapped ? wrappedAsset.displayName : d.Username;

                  return (
                    <tr key={d.PkidBase58check} className="border-t border-gray-faint">
                      <td className="text-right items-center align-middle relative -top-1 text-gray">
                        {currentPage * limit + i + 1}
                      </td>
                      <td className="text-left align-top">
                        <div className="flex items-center">
                          <Avatar
                            border="none"
                            size={compact ? 'sm' : 'lg'}
                            src={isWrapped ? getWrappedAssetIcon(wrappedAsset) : deso.profilePicUrl(d.PkidBase58check)}
                          />{' '}
                          <div className={classNames(!compact && 'discover-chart-max-width', 'ml-4')}>
                            <RouteLink
                              kind="text-only-light"
                              size={compact ? 'md' : 'lg'}
                              to={isWrapped ? Routes.profile(wrappedAsset.displayName) : Routes.fund(d.Username)}
                              className={compact ? 'pb-1 font-bold truncate w-[90px]' : 'pb-1 font-bold'}
                            >
                              ${displayName}
                            </RouteLink>
                            {!compact && (
                              <Text tag="p" className="text-sm text-gray line-clamp-2">
                                {d.Description}
                              </Text>
                            )}
                          </div>
                        </div>
                      </td>
                      {!exchangeRate && <td>{formatDecimalValue(d.CoinPriceDenominatingCoin)}</td>}
                      {exchangeRate && (
                        <td>
                          <span className="font-bold text-md">
                            {isLegacyView ? (
                              <USDValueWithDESO
                                showDESOValue={false}
                                showUSDLabel={false}
                                align="right"
                                desoValue={d.CoinPriceDenominatingCoin}
                                usdCentsPerDeSoExchangeRate={exchangeRate.USDCentsPerDeSoCoinbase}
                              />
                            ) : (
                              `$${abbreviateNumber(d.CoinPriceDenominatingCoin * usdcExchangeRate)}`
                            )}
                          </span>
                        </td>
                      )}
                      <td>{formatPercentChangeString(d.PercentChangeCoinPriceDenominatingCoinDayOverDay)}</td>
                      <td>{formatPercentChangeString(d.PercentChangeCoinPriceDenominatingCoinWeekOverWeek)}</td>
                      {!exchangeRate && <td>{formatDecimalValue(d.MarketCapDenominatingCoin)}</td>}
                      {exchangeRate && (
                        <td>
                          {isLegacyView ? (
                            <USDValueWithDESO
                              showDESOValue={false}
                              showUSDLabel={false}
                              align="right"
                              desoValue={d.MarketCapDenominatingCoin}
                              usdCentsPerDeSoExchangeRate={exchangeRate.USDCentsPerDeSoCoinbase}
                            />
                          ) : (
                            `$${abbreviateNumber(d.MarketCapDenominatingCoin * usdcExchangeRate)}`
                          )}
                        </td>
                      )}
                      {!exchangeRate && (
                        <td>{formatDecimalValue(desoNanosToDeso(d.VolumeDenominatingCoinBaseUnitsPastDay))}</td>
                      )}
                      {exchangeRate && (
                        <td>
                          {isLegacyView ? (
                            <USDValueWithDESO
                              showDESOValue={false}
                              showUSDLabel={false}
                              align="right"
                              desoValue={desoNanosToDeso(d.VolumeDenominatingCoinBaseUnitsPastDay)}
                              usdCentsPerDeSoExchangeRate={exchangeRate.USDCentsPerDeSoCoinbase}
                            ></USDValueWithDESO>
                          ) : (
                            `$${abbreviateNumber(
                              baseUnitsToTokens(d.VolumeDenominatingCoinBaseUnitsPastDay) * usdcExchangeRate,
                            )}`
                          )}
                        </td>
                      )}
                      <td>${abbreviateNumber(usdCentsToUSD(d.TotalInvestedUSDCents))}</td>
                      {!compact && <td>${abbreviateNumber(usdCentsToUSD(d.TotalInvestedUSDCentsPastDay))}</td>}
                      <td>${abbreviateNumber(usdCentsToUSD(d.TotalInvestedUSDCentsPastSevenDays))}</td>
                      {!compact && <td>{d.UniqueCoinHodlers}</td>}
                      {!compact && <td>{getDateDifferenceString(new Date(d.CreatedAt), new Date(), true)}</td>}
                      {!compact && (
                        <td>
                          <div className="mb-2">
                            <Button
                              size="sm"
                              shape="rounded"
                              kind="btn-black"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(Routes.tradeToken(d.Username));
                              }}
                            >
                              Trade
                            </Button>
                          </div>
                          <div className="text-center">
                            <Button
                              size="sm"
                              shape="rounded"
                              kind="text-only-light"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(Routes.tradeToken(d.Username));
                              }}
                            >
                              View
                            </Button>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginationFooter
            currentPage={currentPage}
            onCurrentPageChange={(page: number) => setCurrentPage(page)}
            totalItems={numProjects}
            pageSize={limit}
          />
        </>
      ) : (
        <div className="py-8 text-center">No results found</div>
      )}
    </Container>
  );
}

interface ProjectLeaderBoardColumnHeaderProps {
  orderBy: ProjectLeaderBoardOrderBy;
  AscendingOrderBy: ProjectLeaderBoardOrderBy;
  DescendingOrderBy: ProjectLeaderBoardOrderBy;
  ColumnHeader: string;
  onClick: (ascending: ProjectLeaderBoardOrderBy, descending: ProjectLeaderBoardOrderBy) => void;
}

export function ProjectLeaderBoardColumnHeader({
  orderBy,
  AscendingOrderBy,
  DescendingOrderBy,
  ColumnHeader,
  onClick,
}: ProjectLeaderBoardColumnHeaderProps) {
  return (
    <th className="px-2" style={{ whiteSpace: 'nowrap' }}>
      <button className="font-bold" onClick={() => onClick(AscendingOrderBy, DescendingOrderBy)}>
        {orderBy === AscendingOrderBy ? (
          <span className="inline-block top-0.5 relative">
            {' '}
            <IoArrowDownOutline />{' '}
          </span>
        ) : (
          ''
        )}
        {orderBy === DescendingOrderBy ? (
          <span className="inline-block top-0.5 relative">
            {' '}
            <IoArrowUpOutline />{' '}
          </span>
        ) : (
          ''
        )}
        &nbsp;{ColumnHeader}
      </button>
    </th>
  );
}
