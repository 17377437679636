'use client';

import { useCallback, useState } from 'react';
import { LuSearch } from 'react-icons/lu';
import { Account, AccountsDocument, AccountsOrderBy } from '../../graphql/codegen/graphql';
import { SEARCH_ENTITY, useUserSearch } from '../../hooks/useUserSearch';
import { Command, CommandInput, CommandItem, CommandList } from '../shadcn/ui/command';
import { Spinner } from './Spinner';

import { Avatar } from './Avatar';
import { getUserName } from '../../utils/user';
import { useLazyQuery } from '@apollo/client';
import { Routes } from '../../RoutePaths';
import { useNavigate } from 'react-router-dom';
import { getWrappedAsset, getWrappedAssetIcon } from '../../utils/deso';
import LowNumFormatter from '../app-ui/LowNumFormatter';
import { usdCentsToUSD } from '../../utils/currency';

interface GlobalSearchProps {
  placeholder?: string;
  baseRedirectURL?: string;
  disabled?: boolean;
  onSelect?: (account: Account) => void;
  searchWidth?: string;
}

type UserResultProps = {
  resetSearch: () => void;
  account: Account;
  /**
   * If onSelect is not provided, the user will be redirected to the provided
   * base URL with the user's username or public key appended to it. If onSelect
   * is provided, the provided function will be called instead with the selected
   * account as its argument.
   * @param account
   */
  onSelect?: (account: Account) => void;
  baseRedirectURL: string;
};

export const UserResult = ({ account, resetSearch, onSelect, baseRedirectURL }: UserResultProps) => {
  const navigate = useNavigate();

  const internalOnSelect = () => {
    if (typeof onSelect === 'function') {
      onSelect(account);
    } else {
      navigate(Routes.profile(account.username || account.publicKey));
    }
    resetSearch();
  };

  const wrappedAsset = getWrappedAsset(account.username || '');

  return (
    <CommandItem className="rounded-md p-0 cursor-pointer" onSelect={internalOnSelect}>
      <div className="flex w-full items-center truncate px-2 py-1.5">
        <Avatar
          size="xs"
          src={!!wrappedAsset ? getWrappedAssetIcon(wrappedAsset) : account.publicKey}
          className="border border-border min-h-[30px] min-w-[30px]"
        />
        <span className="ml-2 text-foreground text-xs w-full truncate">@{getUserName({ account })}</span>
      </div>

      <div className="text-foreground mr-2 font-mono">
        <LowNumFormatter
          price={account.totalBalanceUsdCents ? usdCentsToUSD(account.totalBalanceUsdCents) : 0}
          className="font-mono text-green-500 text-xs font-shadow-green"
        />
      </div>
    </CommandItem>
  );
};

const UserSearch = ({
  placeholder = 'Search...',
  baseRedirectURL = '/',
  onSelect,
  searchWidth = '240px',
}: GlobalSearchProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [fetchSearchResultsLazy, { loading: loadingAccounts }] = useLazyQuery(AccountsDocument);

  const fetchSearchResultsRequest = async (
    inputValue: string,
    searchTypes: Array<SEARCH_ENTITY>,
    abortController: AbortController,
    excludedAccounts?: Array<Account>,
  ) => {
    const { data } = await fetchSearchResultsLazy({
      variables: {
        includeAccounts: true,
        filter: {
          ...(searchTypes.includes(SEARCH_ENTITY.USERNAME)
            ? {
                username: {
                  likeInsensitive: `${inputValue}%`,
                },
              }
            : {
                publicKey: {
                  equalTo: inputValue,
                },
              }),
        },
        orderBy: AccountsOrderBy.TotalBalanceUsdCentsDesc,
        first: 15,
      },
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    });

    return data?.accounts?.nodes.filter((e) => !!e) as Array<Account>;
  };

  const {
    loading: loadingSearchResults,
    searchResults,
    search,
    aborterRef,
  } = useUserSearch(searchValue, fetchSearchResultsRequest, loadingAccounts);

  const resetSearch = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <form className={`relative flex w-full items-center max-w-[${searchWidth}]`}>
      <Command
        shouldFilter={false}
        className="rounded-full text-left"
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setIsOpen(false);
          }
        }}
      >
        <CommandInput
          placeholder={placeholder}
          className="pr-10 focus:border-border w-[250px]"
          value={searchValue}
          onFocus={() => setIsOpen(true)}
          onInput={(event) => {
            setIsOpen(true);
            setSearchValue(event.currentTarget.value);
          }}
        />

        {isOpen && (
          <CommandList className="absolute top-[40px] z-[100] mt-1 max-h-[300px] w-full overflow-y-auto overflow-x-hidden rounded-xl bg-input">
            {loadingSearchResults && (
              <Command className="rounded-xl border">
                <Spinner size={32} className="mx-auto my-2" />
              </Command>
            )}

            {searchResults.accounts.length > 0 && (
              <div className="rounded-xl border">
                {searchResults.accounts.map((account) => {
                  return (
                    <UserResult
                      key={account.publicKey}
                      {...{
                        onSelect,
                        baseRedirectURL,
                        account,
                        resetSearch,
                      }}
                    />
                  );
                })}
              </div>
            )}
          </CommandList>
        )}
      </Command>

      <LuSearch
        className="absolute right-4 top-[11px] size-4 cursor-pointer text-muted"
        onClick={() => search(searchValue, aborterRef)}
      />

      {isOpen && <div className="fixed inset-0 z-50" onClick={() => setIsOpen(false)} />}
    </form>
  );
};

export default UserSearch;
