import { getExchangeRates } from 'deso-protocol';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { DESO_USDC_PUBLIC_KEY, DESO_ZERO_PUBLIC_KEY, FOCUS_TOKEN_PUBLIC_KEY } from '../constants/AppConstants';
import {
  DESO_TICKER,
  DESO_TOKEN_PUBLIC_KEY,
  DESO_USDC_TICKER,
  FOCUS_TICKER,
  USD_TICKER,
} from '../constants/TradeConstants';
import { deso } from '../services';
import { fetchUSDCExchangeRate } from '../utils/currency';
import { getErrorMsg } from '../utils/getErrorMsg';
import { DESO } from '../utils/orderbook';

export interface ExchangeRatesByTokenPublicKey {
  [tokenPublicKey: string]: number;
}

interface QuoteCurrencyContextValue {
  quoteCurrencyPublicKey: string;
  setQuoteCurrencyPublicKey: React.Dispatch<React.SetStateAction<string>>;
  exchangeRates: ExchangeRatesByTokenPublicKey;
  loadingExchangeRates: boolean;
  denominatingCoinByQuoteCurrencyPublicKey: { [publicKey: string]: string };
  usdTickerByQuoteCurrencyPublicKey: { [publicKey: string]: string };
  tickerByQuoteCurrencyPublicKey: { [publicKey: string]: string };
}

type QuoteCurrenciesList = Array<{ publicKey: string; displayName: string }>;

export const AVAILABLE_QUOTE_CURRENCIES: QuoteCurrenciesList = [
  {
    publicKey: DESO_ZERO_PUBLIC_KEY,
    displayName: DESO,
  },
  {
    publicKey: DESO_USDC_PUBLIC_KEY,
    displayName: 'USDC',
  },
  {
    publicKey: FOCUS_TOKEN_PUBLIC_KEY,
    displayName: 'FOCUS',
  },
];
const DENOMINATING_COIN_BY_QUOTE_CURRENCY_PUBLIC_KEY = {
  [DESO_ZERO_PUBLIC_KEY]: DESO_TOKEN_PUBLIC_KEY,
  [DESO_USDC_PUBLIC_KEY]: DESO_USDC_PUBLIC_KEY,
  [FOCUS_TOKEN_PUBLIC_KEY]: FOCUS_TOKEN_PUBLIC_KEY,
};
const USD_TICKER_BY_QUOTE_CURRENCY_PUBLIC_KEY = {
  [DESO_ZERO_PUBLIC_KEY]: USD_TICKER,
  [DESO_USDC_PUBLIC_KEY]: DESO_USDC_TICKER,
  [FOCUS_TOKEN_PUBLIC_KEY]: USD_TICKER,
};
const TICKER_BY_QUOTE_CURRENCY_PUBLIC_KEY = {
  [DESO_ZERO_PUBLIC_KEY]: DESO_TICKER,
  [DESO_USDC_PUBLIC_KEY]: DESO_USDC_TICKER,
  [FOCUS_TOKEN_PUBLIC_KEY]: FOCUS_TICKER,
};
const DEFAULT_QUOTE_CURRENCY = AVAILABLE_QUOTE_CURRENCIES[0].publicKey;
const DEFAULT_EXCHANGE_RATES = {
  [DESO_ZERO_PUBLIC_KEY]: 0,
  [DESO_USDC_PUBLIC_KEY]: 0,
  [FOCUS_TOKEN_PUBLIC_KEY]: 0,
};

const initialQuoteCurrencyContextValue: QuoteCurrencyContextValue = {
  quoteCurrencyPublicKey: DEFAULT_QUOTE_CURRENCY,
  setQuoteCurrencyPublicKey: () => {},
  loadingExchangeRates: true,
  exchangeRates: { ...DEFAULT_EXCHANGE_RATES },
  denominatingCoinByQuoteCurrencyPublicKey: DENOMINATING_COIN_BY_QUOTE_CURRENCY_PUBLIC_KEY,
  usdTickerByQuoteCurrencyPublicKey: USD_TICKER_BY_QUOTE_CURRENCY_PUBLIC_KEY,
  tickerByQuoteCurrencyPublicKey: TICKER_BY_QUOTE_CURRENCY_PUBLIC_KEY,
};

export const QuoteCurrencyContext = createContext<QuoteCurrencyContextValue>(initialQuoteCurrencyContextValue);

const QUOTE_CURRENCY_LOCAL_STORAGE_KEY = 'quoteCurrency/v1';

export const QuoteCurrencyContextProvider = ({ children }: { children: ReactNode }) => {
  const [quoteCurrencyPublicKey, setQuoteCurrencyPublicKey] = useState<string>(
    localStorage.getItem(QUOTE_CURRENCY_LOCAL_STORAGE_KEY) || DEFAULT_QUOTE_CURRENCY,
  );
  const [exchangeRates, setExchangeRates] = useState<ExchangeRatesByTokenPublicKey>({ ...DEFAULT_EXCHANGE_RATES });
  const [loadingExchangeRates, setLoadingExchangeRates] = useState(true);

  useEffect(() => {
    const run = async () => {
      setLoadingExchangeRates(true);

      try {
        const [desoExchangeRateResponse, dusdExchangeRateResponse, focusExchangeRateResponse] = await Promise.all([
          getExchangeRates(),
          fetchUSDCExchangeRate(),
          deso.getCoinPriceInUsd(FOCUS_TOKEN_PUBLIC_KEY),
        ]);

        setExchangeRates({
          [DESO_ZERO_PUBLIC_KEY]: desoExchangeRateResponse.USDCentsPerDeSoExchangeRate,
          [DESO_USDC_PUBLIC_KEY]: dusdExchangeRateResponse * 100,
          [FOCUS_TOKEN_PUBLIC_KEY]: Number.parseFloat(focusExchangeRateResponse.MidPrice) * 100,
        });
      } catch (e) {
        throw new Error(getErrorMsg(e));
      } finally {
        setLoadingExchangeRates(false);
      }
    };

    run();
  }, [deso]);

  useEffect(() => {
    localStorage.setItem(QUOTE_CURRENCY_LOCAL_STORAGE_KEY, quoteCurrencyPublicKey);
  }, [quoteCurrencyPublicKey]);

  return (
    <QuoteCurrencyContext.Provider
      value={{
        quoteCurrencyPublicKey,
        setQuoteCurrencyPublicKey,
        exchangeRates,
        loadingExchangeRates,
        denominatingCoinByQuoteCurrencyPublicKey: DENOMINATING_COIN_BY_QUOTE_CURRENCY_PUBLIC_KEY,
        usdTickerByQuoteCurrencyPublicKey: USD_TICKER_BY_QUOTE_CURRENCY_PUBLIC_KEY,
        tickerByQuoteCurrencyPublicKey: TICKER_BY_QUOTE_CURRENCY_PUBLIC_KEY,
      }}
    >
      {children}
    </QuoteCurrencyContext.Provider>
  );
};
