import {
  IoAddOutline,
  IoCloudUpload,
  IoGlobeOutline,
  IoLogoDiscord,
  IoLogoTwitter,
  IoRemoveCircleOutline,
} from 'react-icons/io5';
import { FeaturedImage } from 'components/app-ui/FeaturedImage';
import { Button } from 'components/core/Button';
import { Input, InputProps } from 'components/core/Input';
import { MarkdownEditor } from 'components/core/MarkdownEditor';
import { Modal } from 'components/core/Modal';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { ReactNode, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { confetti, deso, openfund } from 'services';
import { checkFormValidity } from 'utils/checkFormValidity';
import { classNames } from 'utils/classNames';
import { getDefaultFundingRoundSettings } from 'utils/fundingRound';
import { getErrorMsg } from 'utils/getErrorMsg';
import { OnboardingLayout, useOnboardingContext } from '../Onboarding';

const IMAGE_SIZE_LIMIT_15MB = 15 * (1024 * 1024);

function FormControlGroup({ children: formControl }: React.HTMLAttributes<HTMLElement>) {
  return <div className="flex flex-col text-left pb-2 mb-2">{formControl}</div>;
}

interface InputData {
  value: string;
  state: InputProps['state'];
}
type FormModel = {
  featuredImageUrl: InputData;
  websiteUrl: InputData;
  discordUrl: InputData;
  twitterUrl: InputData;
};
interface WebsiteLinkConfig {
  field: 'websiteUrl' | 'discordUrl' | 'twitterUrl';
  label: string;
  icon: ReactNode;
}
export function ProjectDetails() {
  useDocumentTitle('Add Project Details');
  const { onboardingData, setOnboardingData } = useOnboardingContext();
  const [featuredImagePreviewUrl, setFeaturedImagePreviewUrl] = useState<string>();
  const [formModel, setFormModel] = useState<FormModel>({
    featuredImageUrl: { value: onboardingData.featuredImageUrl, state: 'default' },
    websiteUrl: { value: onboardingData.websiteUrl, state: 'default' },
    discordUrl: { value: onboardingData.discordUrl, state: 'default' },
    twitterUrl: { value: onboardingData.twitterUrl, state: 'default' },
  });
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const navigate = useNavigate();
  const { displayName, featuredImageUrl } = onboardingData;
  const imageInputId = 'featured-image-input';
  const imgRef = useRef<HTMLImageElement>(null);
  const selectFile = () => document.getElementById(imageInputId)?.click();
  const isMounted = useIsMounted();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPendingProjectCreation, setIsPendingProjectCreation] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const derivedKey = useRef('');
  const externalLinks: WebsiteLinkConfig[] = [
    {
      field: 'websiteUrl',
      label: 'Website',
      icon: <IoGlobeOutline />,
    },
    {
      field: 'discordUrl',
      label: 'Discord',
      icon: <IoLogoDiscord />,
    },
    {
      field: 'twitterUrl',
      label: 'Twitter',
      icon: <IoLogoTwitter />,
    },
  ];

  useEffectOnce(() => {
    // if (!onboardingData.email) {
    //   navigate(Routes.projectOnboardingEmail(), { replace: true });
    // }
    if (currentUser) {
      openfund
        .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.PROFILE)
        .then(({ DerivedPublicKeyBase58Check }) => {
          derivedKey.current = DerivedPublicKeyBase58Check;
        });
    }
  });

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Are you ready to change the world?"
        description='Hit the "Start Your Project" button below to create your project and start accepting contributions! You can edit your funding details at any time.'
        footer={
          <div className="flex flex-col md:flex-row">
            <Button
              kind="btn-primary"
              className="w-full md:w-auto mt-2 mr-4 md:mt-0"
              shape="rounded"
              onClick={async () => {
                if (!currentUser) {
                  throw new Error('Attempting to create funding round without a logged in user');
                }

                setIsModalOpen(false);

                try {
                  await openfund.updateProfileWithDefaultFundingRound(
                    {
                      Username: onboardingData.username,
                      Email: onboardingData.email,
                      Description: onboardingData.shortDesc,
                      ProfilePic: onboardingData.profilePicUrl.startsWith('data:') ? onboardingData.profilePicUrl : '',
                      FeaturedImageURL: onboardingData.featuredImageUrl,
                      DisplayName: onboardingData.displayName,
                      WebsiteURL: onboardingData.websiteUrl,
                      TwitterURL: onboardingData.twitterUrl,
                      DiscordURL: onboardingData.discordUrl,
                      MarkdownDescription: onboardingData.longDesc,
                      EnableDaoNFTGallery: true,
                    },
                    getDefaultFundingRoundSettings(currentUser.PublicKeyBase58Check),
                    derivedKey.current,
                    () => {
                      setIsPendingProjectCreation(true);
                    },
                  );

                  const reloadedUserData = await openfund.reloadCurrentUserData();
                  setCurrentUser(reloadedUserData);

                  // if (reloadedUserData?.appUser?.DesoMessengerGroupChatId === '') {
                  //   await openfund.createGroupMessage(reloadedUserData.appUser);
                  // }

                  navigate(Routes.fund(onboardingData.username));
                  confetti.celebrate();
                } catch (err) {
                  toast.show({
                    message: getErrorMsg(err),
                    type: 'error',
                  });
                }
                setIsPendingProjectCreation(false);
              }}
            >
              Start Your Project
            </Button>
            <Button
              kind="btn-tertiary"
              shape="rounded"
              className="w-full md:w-auto md:mr-4"
              onClick={async () => {
                if (!currentUser) {
                  throw new Error('Attempting to create funding round without a logged in user');
                }

                setIsModalOpen(false);

                try {
                  await openfund.updateUserProfile(
                    {
                      Username: onboardingData.username,
                      Email: onboardingData.email,
                      Description: onboardingData.shortDesc,
                      ProfilePic: onboardingData.profilePicUrl.startsWith('data:') ? onboardingData.profilePicUrl : '',
                      FeaturedImageURL: onboardingData.featuredImageUrl,
                      DisplayName: onboardingData.displayName,
                      WebsiteURL: onboardingData.websiteUrl,
                      TwitterURL: onboardingData.twitterUrl,
                      DiscordURL: onboardingData.discordUrl,
                      MarkdownDescription: onboardingData.longDesc,
                      EnableDaoNFTGallery: true,
                    },
                    () => {
                      setIsPendingProjectCreation(true);
                    },
                    derivedKey.current,
                  );

                  setCurrentUser(await openfund.reloadCurrentUserData());
                  navigate(Routes.settingsFundraising());
                } catch (err) {
                  toast.show({
                    message: getErrorMsg(err),
                    type: 'error',
                  });
                }
                setIsPendingProjectCreation(false);
              }}
            >
              Edit Funding Details
            </Button>
          </div>
        }
      />
      {isPendingProjectCreation ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : (
        <OnboardingLayout
          title="Describe your project"
          hasExitLink={!isPendingProjectCreation}
          content={
            <>
              <form
                id="project-details-form"
                onSubmit={async (ev) => {
                  ev.preventDefault();
                  if (checkFormValidity(ev.currentTarget)) {
                    setIsModalOpen(true);
                  }
                }}
              >
                <Button
                  aria-label="Skip editing project details"
                  type="submit"
                  kind="text-only"
                  size="sm"
                  className="underline"
                >
                  Skip (you can edit these later)
                </Button>
                <div className="bg-white text-black p-4 lg:p-8 mt-6 rounded-2xl m-auto w-full xl:w-4/5">
                  <FormControlGroup>
                    <Text className="mb-2 font-medium">Add a cover image</Text>
                    <div
                      className={classNames('bg-gray-eee', featuredImagePreviewUrl || featuredImageUrl ? '' : 'flex')}
                    >
                      <div
                        className={classNames(
                          'bg-gray-eee overflow-hidden relative aspect-w-16 aspect-h-9',
                          isUploadingImage ? 'blur-md animate-pulse' : '',
                        )}
                      >
                        <FeaturedImage
                          hidden={!(featuredImagePreviewUrl || featuredImageUrl)}
                          ref={imgRef}
                          src={featuredImagePreviewUrl || featuredImageUrl}
                          alt={displayName}
                        />
                        {isUploadingImage && (
                          <span role="status" className="sr-only">
                            Uploading image
                          </span>
                        )}
                        {(featuredImageUrl || featuredImagePreviewUrl) && (
                          <span className="absolute top-0 right-0 ml-auto aspect-auto	 h-fit w-fit p-4 z-10">
                            <Button
                              aria-label="Change featured image"
                              kind="btn-secondary"
                              shape="circle"
                              size="sm"
                              className="shadow-md"
                              onClick={selectFile}
                            >
                              <IoCloudUpload className="h-6 w-6" />
                            </Button>
                          </span>
                        )}
                      </div>
                      {!(featuredImageUrl || featuredImagePreviewUrl) && (
                        <Button onClick={selectFile} kind="btn-black" size="sm" className="m-auto" shape="rounded">
                          Upload Cover Image
                        </Button>
                      )}
                    </div>
                    <Input
                      className="hidden"
                      id={imageInputId}
                      type="file"
                      labelText="Featured image"
                      labelSrOnly={true}
                      onChange={(ev) => {
                        const file = ev.currentTarget.files?.[0];
                        if (!file) return;

                        if (file.size > IMAGE_SIZE_LIMIT_15MB) {
                          toast.show({
                            message:
                              'Your file is larger than the upload size limit of 15MB. Please select a smaller photo and try again.',
                            type: 'error',
                          });
                          return;
                        }

                        const reader = new FileReader();
                        reader.addEventListener(
                          'load',
                          async () => {
                            if (imgRef.current && reader.result) {
                              // immediately show preview with raw file data
                              setFeaturedImagePreviewUrl(reader.result.toString());
                              // Upload file in the background
                              setIsUploadingImage(true);
                              try {
                                const url = await deso.uploadImage(file);
                                if (isMounted.current) {
                                  setOnboardingData({ ...onboardingData, featuredImageUrl: url });
                                }
                              } catch (e) {
                                setFeaturedImagePreviewUrl(undefined);
                                toast.show({
                                  message: getErrorMsg(e),
                                  type: 'error',
                                });
                              }
                              setIsUploadingImage(false);
                            }
                          },
                          false,
                        );
                        reader.addEventListener('error', (err) => {
                          toast.show({
                            message: getErrorMsg(err),
                            type: 'error',
                          });
                        });

                        reader.readAsDataURL(file);
                      }}
                    />
                  </FormControlGroup>
                  <FormControlGroup>
                    <Input
                      labelText="Project Title"
                      className="mb-2 font-medium w-full"
                      placeholder="My Amazing Project!"
                      defaultValue={onboardingData.displayName}
                      onInput={(ev) =>
                        setOnboardingData({
                          ...onboardingData,
                          displayName: ev.currentTarget.value.trim(),
                        })
                      }
                    />
                  </FormControlGroup>
                  <FormControlGroup>
                    <MarkdownEditor
                      initialValue={onboardingData.longDesc}
                      title="Project Description"
                      onEdit={(markdown) => setOnboardingData({ ...onboardingData, longDesc: markdown })}
                    />
                  </FormControlGroup>
                  <div className="py-0 px-2 lg:px-4 bg-gray-eee text-center">
                    <Button
                      kind="text-only-underline"
                      onClick={() => {
                        setShowAdvancedSettings(!showAdvancedSettings);
                      }}
                    >
                      {!showAdvancedSettings && (
                        <div className="p-3 text-sm">
                          <IoAddOutline className="inline-block mr-2" />
                          <span>Add Social Links</span>
                        </div>
                      )}
                    </Button>
                    <div className={classNames(showAdvancedSettings ? 'py-0 px-2 pb-0' : 'hidden')}>
                      <FormControlGroup>
                        <Text className="mb-4 font-medium">Social profiles</Text>
                        <div className="block xl:flex justify-between">
                          {externalLinks.map(({ field, label, icon }, i) => {
                            return (
                              <div key={i} className="w-full xl:w-1/3 mb-4 xl:mb-0 mx-1">
                                <div className="flex mb-2" key={i}>
                                  {icon}
                                  <Text className="ml-2" size="sm">
                                    {label}
                                  </Text>
                                </div>
                                <Input
                                  labelText={label}
                                  textSize="sm"
                                  className="mb-2 w-full"
                                  labelSrOnly={true}
                                  placeholder="https://..."
                                  state={formModel[field].state}
                                  value={formModel[field].value}
                                  hint={formModel[field].state === 'error' ? 'Please enter a valid url' : ''}
                                  onInput={(ev) =>
                                    setFormModel({
                                      ...formModel,
                                      [field]: { value: ev.currentTarget.value, state: 'default' },
                                    })
                                  }
                                  onBlur={(ev) => {
                                    const value = ev.currentTarget.value;
                                    const trimmed = value.trim();
                                    const state = !trimmed || ev.currentTarget.checkValidity() ? 'default' : 'error';
                                    setFormModel({ ...formModel, [field]: { value, state } });

                                    if (state !== 'error') {
                                      setOnboardingData({ ...onboardingData, [field]: trimmed });
                                    }
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </FormControlGroup>
                      <Button
                        kind="text-only-underline"
                        onClick={() => {
                          setShowAdvancedSettings(!showAdvancedSettings);
                        }}
                      >
                        {showAdvancedSettings && (
                          <div className="mb-6 text-sm">
                            <IoRemoveCircleOutline className="inline-block mr-2" />
                            <span>Close</span>
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
                <Text className="mt-6 text-gray text-sm">
                  By proceeding, you agree to the{' '}
                  <a
                    href="https://docs.google.com/document/d/1QF0ofugvUA9vwMfP3Aal7SjMigdqMktSpzCrPidOOHU"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    Terms &amp; Conditions
                  </a>
                </Text>
              </form>
            </>
          }
          controls={[
            <Button kind="btn-tertiary-light" shape="rounded" className="mr-4" onClick={() => navigate(-1)}>
              Back
            </Button>,
            <Button kind="btn-primary" shape="rounded" key={0} type="submit" form="project-details-form">
              Continue
            </Button>,
          ]}
        />
      )}
    </>
  );
}

export default ProjectDetails;
