import { CREATOR_COIN_TYPES } from 'utils/constants';

interface CoinCategoryFormatterProps {
  coinCategory?: CREATOR_COIN_TYPES | string | null;
  className?: string;
}

const DEFAULT_COIN_CATEGORY = CREATOR_COIN_TYPES.creator;

const CoinCategoryFormatter = ({
  coinCategory = DEFAULT_COIN_CATEGORY,
  className = '',
}: CoinCategoryFormatterProps) => {
  return <span className={className}>{coinCategory}</span>;
};

export { CoinCategoryFormatter };
