import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useRef, useState } from 'react';

import { DaoCoinStat, DaoCoinStatsDocument } from '../graphql/codegen/graphql';
import { isDesoPublicKey } from 'utils/deso';

export const concatUserResponses = (
  exactMatch?: DaoCoinStat | null,
  usersList?: Array<DaoCoinStat | null>,
): Array<DaoCoinStat> => {
  const nonEmptyUsers = (usersList || []).filter((e) => e !== null);

  return uniqBy(
    [exactMatch, ...nonEmptyUsers].filter((e) => !!e && e.coinPublicKey),
    (x) => x?.coinPublicKey,
  ).filter((e) => !!e) as Array<DaoCoinStat>;
};

export enum SEARCH_ENTITY {
  USERNAME = 'Username',
  PUB_KEY = 'User Public Key',
}

const validateInput = (input: string): Array<SEARCH_ENTITY> => {
  const matchedTypes = [];

  // Username: [a-zA-Z0-9_] from 1 to 26 characters
  if (/^[a-zA-Z0-9_]{1,26}$/.test(input)) matchedTypes.push(SEARCH_ENTITY.USERNAME);

  // User public key: Starts with 'BC', length 55, base58 characters
  if (isDesoPublicKey(input)) matchedTypes.push(SEARCH_ENTITY.PUB_KEY);

  return matchedTypes;
};

interface SearchResults {
  coinStats: Array<DaoCoinStat>;
}

const getEmptySearchResults = (): SearchResults => ({
  coinStats: [],
});

type UseUserSearchProps = (
  inputValue: string,
  searchTypes: Array<SEARCH_ENTITY>,
  abortController: AbortController,
  pinnedDaoCoinStats?: Array<string>,
) => Promise<Array<DaoCoinStat>>;

export const useDaoCoinStatSearch = (
  searchValue: string,
  fetchSearchResults: UseUserSearchProps,
  loading: boolean,
  pinnedDaoCoinStats?: Array<DaoCoinStat>,
  usernamesToExclude: Set<string> = new Set(),
): {
  searchResults: SearchResults;
  loading: boolean;
  reset: () => void;
  search: (inputValue: string, abortController: AbortController) => Promise<void>;
  aborterRef: AbortController;
} => {
  const [aborterRef, setAbortRef] = useState(new AbortController());
  const [fetchDaoCoinStatByUsername, { loading: loadingSingleDaoCoinStat }] = useLazyQuery(DaoCoinStatsDocument);
  const [searchResults, setSearchResults] = useState<SearchResults>(getEmptySearchResults());

  const search = async (inputValue = '', abortController: AbortController) => {
    const value = inputValue.trim();
    setSearchResults(getEmptySearchResults());

    if (!value) {
      return;
    }

    const searchTypes = validateInput(value);

    const fetchSearchResultsRequest = await fetchSearchResults(
      value,
      searchTypes,
      abortController,
      (pinnedDaoCoinStats || []).map((e) => e.coinUsername || ''),
    );

    const exactMatch = searchTypes.includes(SEARCH_ENTITY.USERNAME)
      ? await fetchDaoCoinStatByUsername({
          variables: {
            filter: {
              coinUsername: { equalTo: value },
            },
          },
        }).then((res) => res.data?.daoCoinStats?.nodes?.[0] as DaoCoinStat | null)
      : null;

    const allDaoCoinStats = concatUserResponses(exactMatch, fetchSearchResultsRequest).filter(
      (e) => !usernamesToExclude.has((e.coinUsername || '').toLowerCase()),
    );

    setSearchResults({
      coinStats: uniqBy(
        [
          ...(value
            ? pinnedDaoCoinStats?.filter((stat) =>
                (stat?.coinUsername || '').toLowerCase().includes(value.toLowerCase()),
              ) || []
            : []),
          ...allDaoCoinStats,
        ],
        'coinPublicKey',
      ),
    });
  };

  const searchDebounced = useRef(debounce((v, a) => search(v, a), 300)).current;

  useEffect(() => {
    aborterRef.abort();
    const newAbortController = new AbortController();
    setAbortRef(newAbortController);
    searchDebounced(searchValue, newAbortController);
  }, [searchValue, searchDebounced]);

  return {
    loading: loadingSingleDaoCoinStat || loading,
    searchResults,
    reset: () => setSearchResults(getEmptySearchResults()),
    search,
    aborterRef,
  };
};
