import { IoLockClosed } from 'react-icons/io5';
import { NotFound } from 'components/app-ui/NotFound';
import { TabNavLink } from 'components/core/TabNavLink';
import { Button } from 'components/shadcn/ui/button';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { OpenfundUser } from 'services/Openfund';
import { Skeleton } from '../shadcn/ui/skeleton';

interface SettingsRouteContext {
  setHeadingSuffix: (suffix: string) => void;
  currentUser: OpenfundUser;
}
type SettingsContext = SettingsRouteContext;
export function Settings() {
  const [headingSuffix, setHeadingSuffix] = useState('');
  const { currentUser, loadingUser } = useContext(OpenfundContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffectOnce(() => {
    if (location.pathname === Routes.settings()) {
      navigate(Routes.settingsProfile(), { replace: true });
    }
  });

  useEffect(() => {
    if (loadingUser) {
      return;
    }

    if (
      !currentUser?.ProfileEntryResponse ||
      (!currentUser?.isProjectOwner && location.pathname === Routes.settingsProjectDistribution())
    ) {
      navigate(Routes.settingsProfile(), { replace: true });
    }
  }, [currentUser]);

  if (loadingUser) {
    return (
      <div className="w-full">
        <div className="w-full border-b border-border p-6">
          <div className="font-semibold font-sans text-xl">Settings</div>
        </div>
        <div className="w-full flex flex-col sm:flex-row items-start px-4 pt-4 sm:p-6 gap-6">
          <Skeleton className="w-full sm:w-2/12 lg:2/12 h-screen" />
          <Skeleton className="w-full sm:w-10/12 lg:w-7/12 h-screen" />
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return <NotFound />;
  }

  const navLinks = [
    {
      text: 'My Profile',
      to: Routes.settingsProfile(),
      isLocked: false,
    },
    {
      text: currentUser.isProjectOwner ? 'Project Details' : 'Profile Details',
      to: Routes.settingsProject(),
      isLocked: !currentUser.ProfileEntryResponse,
    },
    {
      text: 'Notifications',
      to: Routes.settingsNotifications(),
      isLocked: !currentUser.appUser?.Email,
    },
  ];

  if (!currentUser.ProfileEntryResponse?.DAOCoinEntry?.MintingDisabled) {
    navLinks.splice(2, 0, {
      text: currentUser.isProjectOwner ? 'Fundraising Details' : 'Start Your Project',
      to: Routes.settingsFundraising(),
      isLocked: !currentUser.ProfileEntryResponse,
    });
  }

  if (currentUser.isProjectOwner) {
    navLinks.push({
      text: 'Proposals',
      to: Routes.settingsProposals(),
      isLocked: false,
    });
    navLinks.push({
      text: 'Distributions',
      to: Routes.settingsProjectDistribution(),
      isLocked: false,
    });
    navLinks.push({
      text: 'Advanced Settings',
      to: Routes.settingsAdvanced(),
      isLocked: false,
    });
  }

  return (
    <div className="w-full">
      <div className="w-full border-b border-border p-6">
        <div className="font-semibold font-sans text-xl">Settings / {headingSuffix}</div>
      </div>
      <div className="w-full flex flex-col sm:flex-row items-start px-4 pt-4 sm:p-6 gap-6">
        <div className="flex flex-col justify-start lg:flex-row w-full sm:w-2/12 lg:2/12">
          <nav aria-label="Tabs" className="flex flex-col gap-4 w-full">
            {navLinks.map(({ text, to, isLocked }) =>
              isLocked ? (
                <Button variant={'outline'} className="rounded-md justify-start" disabled={true} key={to}>
                  {text} <IoLockClosed className="ml-2 text-gray-light" />
                </Button>
              ) : (
                <TabNavLink to={to} className="border border-border rounded-md p-3 w-full" key={to}>
                  {text}{' '}
                </TabNavLink>
              ),
            )}
            {navLinks.some(({ isLocked }) => isLocked) && (
              <div className="flex items-start mt-4">
                <span className="text-xs leading-5 text-muted">
                  {!!currentUser.ProfileEntryResponse && !currentUser.appUser?.Email
                    ? 'Update your email address to unlock additional settings.'
                    : 'Create a profile and start a project to unlock additional settings.'}
                </span>
              </div>
            )}
          </nav>
        </div>
        <div className="w-full sm:w-10/12 lg:w-7/12 max-w-[600px] border border-border rounded-2xl p-4 sm:p-8">
          <Outlet context={{ setHeadingSuffix, currentUser }} />
        </div>
      </div>
    </div>
  );
}

export function useSettingsContext(): SettingsContext {
  return useOutletContext();
}

export default Settings;
