import React, { useContext, useMemo, ForwardedRef, useRef, RefObject, useState, useEffect } from 'react';
import { Virtualizer } from '@tanstack/react-virtual';
import { desoToUSD } from '../../utils/currency';
import {
  OPERATION_TYPE,
  OPERATION_TYPES,
  priceBigIntToDecimalString,
  priceBigIntToFloat,
  PriceLevel,
  quantityBigIntToFloat,
} from '../../utils/orderbook';
import { DESO_USDC_PUBLIC_KEY } from '../../constants/AppConstants';
import { USD_TICKER } from '../../constants/TradeConstants';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { classNames } from '../../utils/classNames';
import LowNumFormatter from './LowNumFormatter';
import { Avatar } from '../core/Avatar';
import { cn } from '../../utils/shadcn';
import { AccountSocialCard } from './AccountSocialCard';
import useIsMobile from '../../hooks/useIsMobile';
import useMouse from '../../hooks/useIsMouse';
import { LARGE_WIDTH_BREAKPOINT } from 'utils/constants';
import { useDebounce } from 'use-debounce';
import round from 'lodash/round';
import { priceUpdateSignal } from 'components/pages/Trade';
import sum from 'lodash/sum';

interface TooltipValue {
  total: {
    usd: number;
    base: number;
    quote: number;
  };
}

const enum OrderSide {
  ASK = 'ASK',
  BID = 'BID',
}

const TOOLTIP_WIDTH = 240;

type HoverRow = { side: OPERATION_TYPE; index: number } | null;

type PriceLevelProps = {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  priceLevels: PriceLevel[];
  side: OPERATION_TYPE;
  displayName: string;
  handleClickPriceRow: ({ price, usdPrice }: { price: number; usdPrice: number }) => void;
};

type PriceLevelSectionProps = PriceLevelProps & {
  className?: string;
  style?: React.CSSProperties;
  emptyMessage: string;
};

const PriceLevelSection = React.forwardRef(
  (
    {
      priceLevels,
      side,
      virtualizer,
      displayName,
      handleClickPriceRow,
      className,
      style,
      emptyMessage,
    }: PriceLevelSectionProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { exchangeRates, quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);
    const [dataUpdated, setDataUpdated] = useState(false);

    const [hoveredRowIndex, setHoveredRowIndex] = useState<HoverRow>(null);
    const debouncedHoverRowIndex = useDebounce<HoverRow>(hoveredRowIndex, 50)[0];
    const containerRef = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
    const { isDevice } = useIsMobile(LARGE_WIDTH_BREAKPOINT);
    const { elX, elY, elW, elH } = useMouse(containerRef);
    const isAsk = side === OrderSide.ASK;
    const xIntersecting = elX && elX < elW;
    const yIntersecting = elY > 0 && elY < elH;
    const isIntersecting = xIntersecting && yIntersecting;

    useEffect(() => {
      const unsubscribe = priceUpdateSignal.subscribe(() => {
        setDataUpdated(true);
        const timer = setTimeout(() => {
          setDataUpdated(false);
        }, 1000);
        return () => clearTimeout(timer);
      });

      return unsubscribe;
    }, []);

    const getTooltipValue = useMemo(() => {
      if (!hoveredRowIndex) {
        return null;
      }

      const [startIndex, endIndex] =
        side === OPERATION_TYPES.ASK ? [hoveredRowIndex.index, priceLevels.length] : [0, hoveredRowIndex.index + 1];

      const totals = priceLevels.slice(startIndex, endIndex).reduce(
        (acc: TooltipValue, v: PriceLevel) => {
          return {
            total: {
              usd:
                acc.total.usd +
                desoToUSD(
                  priceBigIntToFloat(v.Price) * quantityBigIntToFloat(v.Quantity),
                  exchangeRates[quoteCurrencyPublicKey],
                ),
              base: acc.total.base + quantityBigIntToFloat(v.Quantity),
              quote: acc.total.quote + priceBigIntToFloat(v.Price) * quantityBigIntToFloat(v.Quantity),
            },
          };
        },
        {
          total: {
            usd: 0,
            base: 0,
            quote: 0,
          },
        } as TooltipValue,
      );

      return (
        <div className="flex flex-col flex-wrap gap-2 text-foreground">
          <div className="flex justify-between gap-x-6 items-start">
            <div className="w-1/2 font-semibold text-xs whitespace-nowrap">Total</div>
            <div className="w-1/2">
              <div className="flex gap-1 items-center justify-end">
                <LowNumFormatter className="font-medium font-mono text-xs" price={totals.total.usd} isUsd={true} />
                <span className="text-muted text-xs">{USD_TICKER}</span>
              </div>

              <div className="flex gap-1 items-center justify-end">
                <LowNumFormatter className="font-medium text-xs text-muted" price={totals.total.quote} isUsd={false} />
                <span className="text-muted text-xs">{tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}</span>
              </div>
            </div>
          </div>

          <hr className="border border-border border-t-0" />

          <div className="flex gap-x-6 items-start">
            <div className="w-1/2 font-semibold text-xs whitespace-nowrap">Size</div>
            <div className="w-1/2 text-right flex flex-col items-end">
              <LowNumFormatter
                className="font-medium font-mono text-right text-foreground text-xs"
                price={totals.total.base}
                isUsd={false}
              />
              <span className="text-muted text-xs">${displayName}</span>
            </div>
          </div>

          <hr className="border border-border border-t-0" />

          <div className="flex gap-x-6 items-start">
            <div className="w-1/2 font-semibold text-xs whitespace-nowrap">
              Average
              <br />
              Price
            </div>
            <div className="w-1/2">
              <div className="flex gap-1 items-center justify-end">
                <LowNumFormatter
                  className="font-medium font-mono text-xs"
                  price={totals.total.usd / totals.total.base}
                  isUsd={true}
                  abbreviatePriceThreshold={1000000}
                />
                <span className="text-muted text-xs">{USD_TICKER}</span>
              </div>

              <div className="flex gap-1 items-center justify-end">
                <LowNumFormatter
                  className="font-medium font-mono text-xs text-muted"
                  price={totals.total.quote / totals.total.base}
                  isUsd={quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY}
                  abbreviatePriceThreshold={1000000}
                />
                <span className="text-muted text-xs">{tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }, [hoveredRowIndex]);

    return (
      <div className="relative" ref={containerRef}>
        <div className={className} style={style} ref={ref}>
          {priceLevels.length === 0 ? (
            <div className="flex w-full h-full items-center justify-center text-muted text-center text-sm">
              {emptyMessage.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                  {i > 0 && <br />}
                  {line}
                </React.Fragment>
              ))}
            </div>
          ) : (
            <>
              <div
                className={cn(
                  `z-50 rounded-xl border bg-accent px-4 py-3 pointer-events-none absolute transform -translate-x-full`,
                  (isIntersecting &&
                    isAsk &&
                    !!debouncedHoverRowIndex &&
                    priceLevels?.length &&
                    debouncedHoverRowIndex?.index <= priceLevels.length - 1) ||
                    (isIntersecting && !isAsk && !!debouncedHoverRowIndex && debouncedHoverRowIndex?.index >= 0)
                    ? 'block'
                    : 'hidden',
                )}
                style={{
                  top: `${elY - 20}px`,
                  left: isDevice ? `${TOOLTIP_WIDTH}` : '0',
                  width: `${TOOLTIP_WIDTH}px`,
                }}
              >
                {getTooltipValue}
              </div>

              <PriceLevelRowWrapper
                virtualizer={virtualizer}
                priceLevels={priceLevels}
                side={side}
                displayName={displayName}
                debouncedHoverRowIndex={debouncedHoverRowIndex}
                setHoveredRowIndex={setHoveredRowIndex}
                handleClickPriceRow={handleClickPriceRow}
                dataUpdated={dataUpdated}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);

PriceLevelSection.displayName = 'PriceLevelSection';

type PriceLevelRowWrapperProps = PriceLevelProps & {
  debouncedHoverRowIndex: { side: OPERATION_TYPE; index: number } | null;
  setHoveredRowIndex: (index: { side: OPERATION_TYPE; index: number } | null) => void;
  dataUpdated: boolean;
};

const PriceLevelRowWrapper = ({
  virtualizer,
  priceLevels,
  side,
  displayName,
  debouncedHoverRowIndex,
  setHoveredRowIndex,
  handleClickPriceRow,
  dataUpdated,
}: PriceLevelRowWrapperProps) => {
  const maxTotalSize = useMemo(() => {
    const totalSizes = priceLevels
      .filter((e) => e.Side === side)
      .map((priceLevel) => priceBigIntToFloat(priceLevel.Quantity));
    return sum(totalSizes);
  }, [priceLevels, side]);

  return (
    <div className="relative w-full divide-y divide-border-light" style={{ height: `${virtualizer.getTotalSize()}px` }}>
      {virtualizer.getVirtualItems().map((virtualRow, localIndex) => {
        const priceLevel = priceLevels[virtualRow.index];

        if (!priceLevel) {
          return null;
        }

        const totalSizeUpToOrder = priceLevels
          .slice(
            side === OPERATION_TYPES.ASK ? virtualRow.index : 0,
            side === OPERATION_TYPES.ASK ? priceLevels.length : virtualRow.index + 1,
          )
          .reduce((acc: number, v: PriceLevel) => acc + priceBigIntToFloat(v.Quantity), 0);

        return (
          <div
            key={priceBigIntToDecimalString(priceLevels[virtualRow.index].Price)}
            className="absolute top-0 left-0 w-full"
            style={{
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }}
          >
            <div className="relative z-[2] h-full">
              <PriceLevelRow
                index={virtualRow.index}
                side={side}
                totalSize={totalSizeUpToOrder}
                maxTotalSize={maxTotalSize}
                priceLevel={priceLevel}
                priceLevels={priceLevels}
                displayName={displayName}
                debouncedHoverRowIndex={debouncedHoverRowIndex}
                setHoveredRowIndex={setHoveredRowIndex}
                handleClickPriceRow={handleClickPriceRow}
                virtualizer={virtualizer}
                dataUpdated={dataUpdated}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

type PriceLevelRowProps = PriceLevelProps & {
  index: number;
  priceLevel: PriceLevel;
  debouncedHoverRowIndex: { side: OPERATION_TYPE; index: number } | null;
  setHoveredRowIndex: (index: { side: OPERATION_TYPE; index: number } | null) => void;
  maxTotalSize: number;
  totalSize: number;
  dataUpdated: boolean;
};

function PriceLevelRow(props: PriceLevelRowProps) {
  const { exchangeRates, quoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);
  const color = props.side === OPERATION_TYPES.ASK ? 'text-red-600' : 'text-green-600';

  const totalValue = useMemo(() => {
    return priceBigIntToFloat(props.priceLevel.Price) * quantityBigIntToFloat(props.priceLevel.Quantity);
  }, [props.priceLevel]);

  const { screenWidth, isMobile } = useIsMobile();
  const hasMobileTooltip = screenWidth <= 1024;

  const quantityInQuoteCurrency = priceBigIntToFloat(props.priceLevel.Price);
  const usdPrice = desoToUSD(quantityInQuoteCurrency, exchangeRates[quoteCurrencyPublicKey]);
  const flashing = props.dataUpdated;

  const priceColumnValue = useMemo(() => {
    if (quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY) {
      return (
        <div role="button" tabIndex={0} className="flex items-center gap-1 xl:gap-2">
          <AccountSocialCard
            publicKey={props.priceLevel.TransactorPublicKeyBase58Check}
            popoverSide={hasMobileTooltip ? 'bottom' : 'right'}
          >
            <div className="flex items-center min-w-[25px]">
              <Avatar
                size="xs"
                src={props.priceLevel.TransactorPublicKeyBase58Check}
                className="bg-black border border-border"
              />
            </div>
          </AccountSocialCard>
          <div className="flex items-center">
            <LowNumFormatter price={usdPrice} abbreviatePriceThreshold={1000000} className="text-xs font-mono" />
          </div>
        </div>
      );
    }

    return (
      <div role="button" tabIndex={0} className="flex items-center gap-2">
        <AccountSocialCard
          publicKey={props.priceLevel.TransactorPublicKeyBase58Check}
          popoverSide={hasMobileTooltip ? 'bottom' : 'right'}
        >
          <div className="flex items-center min-w-[25px]">
            <Avatar
              size="xs"
              src={props.priceLevel.TransactorPublicKeyBase58Check}
              className="bg-black border border-border"
            />
          </div>
        </AccountSocialCard>
        <div className="flex flex-col">
          <LowNumFormatter price={usdPrice} className="text-xs font-mono" />
          <LowNumFormatter price={quantityInQuoteCurrency} isUsd={false} className="text-xs text-left opacity-70" />
        </div>
      </div>
    );
  }, [props.priceLevel.Price, props.priceLevel.TransactorPublicKeyBase58Check, usdPrice]);

  const totalUsdValue = useMemo(() => {
    return desoToUSD(totalValue, exchangeRates[quoteCurrencyPublicKey]);
  }, [totalValue, exchangeRates, quoteCurrencyPublicKey]);

  const totalColumnValue = useMemo(() => {
    if (quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY) {
      return <LowNumFormatter price={totalUsdValue} className="text-xs font-mono" highPriceThreshold={1} />;
    }

    return (
      <div className="flex flex-col items-end justify-end">
        <LowNumFormatter
          price={totalUsdValue}
          className="text-xs font-mono text-muted-foreground"
          highPriceThreshold={1}
        />
        <LowNumFormatter price={totalValue} isUsd={false} className="text-xs text-muted" />
      </div>
    );
  }, [props.priceLevel.Price, totalUsdValue]);

  const backgroundPercentageWidth = useMemo(() => {
    const percentage = round((props.totalSize / props.maxTotalSize) * 100);
    return Math.max(percentage, 2);
  }, [props.maxTotalSize, props.totalSize]);

  const handleMouseEnter = () => {
    props.setHoveredRowIndex({ side: props.side as OPERATION_TYPE, index: props.index });
  };

  return (
    <>
      <div
        onClick={() => {
          props.handleClickPriceRow({
            price: quantityInQuoteCurrency,
            usdPrice,
          });
        }}
        className={classNames(
          'text-sm text-right border-l-2 cursor-pointer flex justify-between h-full unforce-radius',
          props.index % 2 === 0 ? 'bg-background' : 'bg-background',
          props.debouncedHoverRowIndex &&
            props.debouncedHoverRowIndex.side === props.side &&
            ((props.debouncedHoverRowIndex.side === OPERATION_TYPES.ASK &&
              props.debouncedHoverRowIndex.index <= props.index) ||
              (props.debouncedHoverRowIndex.side === OPERATION_TYPES.BID &&
                props.debouncedHoverRowIndex.index >= props.index)) && [
              '!bg-card',
              'border-l-2',
              props.debouncedHoverRowIndex.side === OPERATION_TYPES.ASK ? '!border-l-red-500' : '!border-l-green-500',
            ],
        )}
        data-tooltip-id={`new-badge-tooltip-${props.priceLevel.Price}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => props.setHoveredRowIndex(null)}
        onTouchStart={() =>
          isMobile && props.setHoveredRowIndex({ side: props.side as OPERATION_TYPE, index: props.index })
        }
        onTouchEnd={() => isMobile && props.setHoveredRowIndex(null)}
      >
        <div
          className="flex items-center justify-between w-full"
          style={{
            background: `linear-gradient(270deg, ${
              props.side === OPERATION_TYPES.ASK ? 'rgba(255, 99, 132, 0.1)' : 'rgba(75, 192, 192, 0.1)'
            } ${backgroundPercentageWidth}%, transparent ${backgroundPercentageWidth}%)`,
            transition: 'background 2s ease-in-out',
          }}
        >
          <div
            style={{ width: '38%' }}
            className={classNames(
              `p-1.5 xl:pl-1.5 pl-1.5 sm:pl-0.5 text-left font-mono text-xs flex items-center ${color}`,
              flashing ? 'price-flash' : '',
            )}
          >
            {priceColumnValue}
          </div>
          <div style={{ width: '32%' }} className="py-1 px-1 xl:px-3 text-left flex items-center">
            <div className="flex flex-col w-full">
              <LowNumFormatter
                price={quantityBigIntToFloat(props.priceLevel.Quantity)}
                isUsd={false}
                className="text-xs font-mono text-muted-foreground block text-left"
              />
              <span className="block text-left text-xs text-muted font-normal truncate">${props.displayName}</span>
            </div>
          </div>
          <div
            style={{ width: '30%' }}
            className="py-1 px-1 xl:px-3 w-30 whitespace-nowrap flex items-center justify-end"
          >
            {totalColumnValue}
          </div>
        </div>
      </div>
    </>
  );
}

export { PriceLevelSection };
