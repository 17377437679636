import { useEffect, useState } from 'react';

interface TypewriterProps {
  text: string;
  speed?: number;
  loop?: boolean;
}

const Typewriter = ({ text, speed = 60, loop = false }: TypewriterProps) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const isPause = Math.random() < 0.3; // 30% chance to pause
      const timeout = setTimeout(
        () => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        },
        isPause ? speed * 2 : speed + Math.random() * 30 - 15, // Shorter and more frequent pauses
      );

      return () => clearTimeout(timeout);
    } else if (loop) {
      setTimeout(() => {
        setDisplayedText('');
        setIndex(0);
      }, 1000); // Pause before looping
    }
  }, [index, text, speed, loop]);

  return (
    <span className="flex gap-1 items-center justify-center">
      <span className="typewriter-text">{displayedText}</span>
      <span className="typewriter-cursor w-[1px] h-5 bg-current inline-flex" />
    </span>
  );
};

export default Typewriter;
