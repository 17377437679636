import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NoDesoModal } from './app-ui/NoDesoModal';
import { Toast } from './core/Toast';
import { Toaster } from 'components/shadcn/ui/toaster';
import WelcomeToOpenfundModal from './app-ui/WelcomeToOpenfundModal';
import { AppContext, AppContextProvider } from '../contexts/AppContext';

export function App() {
  const [isNoDesoModalOpen, setIsNoDesoModalOpen] = useState(false);
  const { pathname } = useLocation();
  const { toasts } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AppContextProvider>
      {[0, 1, 2, 3, 4].map((ii) => {
        return (
          <canvas
            id={`my-canvas-${ii}`}
            key={`confetti-canvas-${ii}`}
            style={{ position: 'fixed', zIndex: 2000, height: '100%', width: '100%', pointerEvents: 'none' }}
          />
        );
      })}

      <div id="sr-page-title-change-announcement" role="status" aria-live="polite" className="sr-only"></div>

      <div id="app-root">
        <Outlet />
      </div>

      <div
        aria-live="assertive"
        className="fixed inset-0 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
      >
        {toasts.map(({ id, ...rest }, i) => {
          return <Toast key={id} message={rest.message} type={rest.type} link={rest.link} sticky={rest.sticky} />;
        })}
      </div>

      <NoDesoModal isOpen={isNoDesoModalOpen} onClose={() => setIsNoDesoModalOpen(false)} />

      <Toaster />

      <WelcomeToOpenfundModal />
    </AppContextProvider>
  );
}
