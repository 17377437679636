const cleanErrorMessages = [
  {
    searchString: 'RuleErrorFollowEntryAlreadyExists',
    errorMessage: 'You already follow this user.',
  },
  {
    searchString: 'AddInputsAndChangeToTransaction: Sanity check failed',
    errorMessage: 'You do not hold enough $DESO to cover the transaction fees for this action.',
  },
  {
    searchString: 'AddInputsAndChangeToTransaction: Sanity check failed',
    errorMessage: 'You do not hold enough $DESO to cover the transaction fees for this action.',
  },
  {
    searchString: 'RuleErrorDAOCoinLimitOrderInsufficientDAOCoinsToOpenOrder',
    errorMessage: 'You do not hold enough tokens to open this order.',
  },
  {
    searchString: 'RuleErrorTxnSpendsMoreThanGlobalDESOLimit',
    errorMessage: 'This order exceeds the $DESO limit, please make a smaller order and try again.',
  },
  {
    searchString: 'CreateDAOCoinLimitOrder: Insufficient balance to open order',
    errorMessage: "You do not hold sufficient funds to create this order and fill all other orders you've created.",
  },
  {
    searchString: 'Buying this DAO coin is restricted to the creator of the DAO.',
    errorMessage: 'Buying this token is restricted to the creator of the project.',
  },
  {
    searchString: 'is not enough to cover the amount they are selling',
    errorMessage: "You do not hold sufficient funds to create this order and fill all other orders you've created.",
  },
  {
    searchString: 'Transaction Spending limit object is required',
    errorMessage:
      'This request failed due to a transaction spending limit object not being attached. Please try again.',
  },
  {
    searchString: 'CreateDAOCoinLimitOrder: The value 0 is too small to produce a scaled exchange rate',
    errorMessage: 'The price provided is too low to create a valid order. Please increase price and try again.',
  },
  {
    searchString: 'CreateDAOCoinLimitOrder: ExchangeRateCoinsToSellPerCoinToBuy must be greater than 0',
    errorMessage: 'The price provided is too low to create a valid order. Please increase price and try again.',
  },
  {
    searchString: 'RuleErrorDAOCoinLimitOrderMatchingOwnOrder',
    errorMessage:
      'This order will match against your own previously opened order. In order to place this order, please set a new limit price or cancel the previous order.',
  },
  {
    searchString: 'RuleErrorInputSpendsPreviouslySpentOutput',
    errorMessage: 'Error processing transaction - double spend error.',
  },
  {
    searchString:
      'CreateDAOCoinMarketOrder: The input quantity 0 produces a value of 0 when scaled to base units nanos',
    errorMessage:
      'The quantity provided is too low to create a valid order. Please enter a non-zero quantity and try again.',
  },
  {
    searchString: 'CreateDAOCoinMarketOrder: The input quantity 0 produces a value of 0 when scaled to DESO nanos',
    errorMessage:
      'The DESO amount provided is too low to create a valid order. Please enter a non-zero DESO amount and try again.',
  },
  {
    searchString: 'RuleErrorDAOCoinLimitOrderFillOrKillOrderUnfulfilled',
    errorMessage:
      'There is not enough liquidity in the market to fill this order completely. Please set a lower quantity and try again.',
  },
  {
    searchString: 'not a valid public address for destination ticker USDC',
    errorMessage: 'Please enter a valid USDC address.',
  },
  {
    searchString: 'RuleErrorDAOCoinTransferProfileOwnerOnlyViolation',
    errorMessage: 'Transfers are restricted by the owner until they enable trading.',
  },
];

// Take the error message, see if it matches to any cleaned error messages, return the clean message if so.
function parseCleanErrorMsg(errorMsg: string): string {
  for (var ii = 0; ii < cleanErrorMessages.length; ii++) {
    const cleanErrorMsg = cleanErrorMessages[ii];
    if (errorMsg.includes(cleanErrorMsg.searchString)) {
      return cleanErrorMsg.errorMessage;
    }
  }
  return errorMsg;
}

export function getErrorMsg(error: any, logError: boolean = true): string {
  let err;

  if (error?.response?.data?.error) {
    err = error?.response?.data?.error;
  } else if (error?.message) {
    err = error.message;
  } else {
    err = String(error);
  }

  if (logError) {
    console.error(err);
  }

  err = parseCleanErrorMsg(err);

  return err;
}
