import { Button } from '../core/Button';
import { IoCloseCircleOutline } from 'react-icons/io5';

type PostVideoProps = {
  src: string;
  videoProcessing?: boolean;
  createPost?: boolean;
  onDelete?: () => void;
};
const livePeerRegex =
  /^https:\/\/lvpr.tv\/\?v=[A-Za-z0-9]+$|^https:\/\/iframe\.videodelivery\.net\/[A-Za-z0-9]+[A-Za-z0-9]+(\?([A-Za-z0-9]+\=[A-Za-z0-9]+\&?)*)?$/;
export function PostVideo({ src, videoProcessing, createPost, onDelete }: PostVideoProps) {
  let isValidVideoSrc = false;
  let updatedSrc = src;
  if (src.split('/').length > 2 && src.split('/')[2] === 'iframe.videodelivery.net') {
    isValidVideoSrc = true;
    updatedSrc = `https://videos.deso.org/${src.split('/').pop()}`;
  }
  if (src.indexOf('.mypinata.cloud/ipfs/') > -1) {
    isValidVideoSrc = true;
    updatedSrc = src;
  }

  const match = src.match(livePeerRegex);
  const isValidIframeSrc = match && match.length > 0 && match[0];
  return (
    <div className={`${!src && !videoProcessing && 'hidden'}`}>
      {videoProcessing && <div>Processing Video Upload ...</div>}

      {src && (isValidVideoSrc || isValidIframeSrc) && (
        <div className="post-video-container">
          {createPost ? (
            <Button kind="text-only" onClick={onDelete} className="text-4xl post-delete-media hover:text-black">
              <IoCloseCircleOutline style={{ zIndex: 1000 }} />
            </Button>
          ) : (
            <></>
          )}
          {isValidVideoSrc ? (
            <video src={updatedSrc} controls className="post-video-iframe" />
          ) : (
            <iframe
              src={src}
              rel="noopener noreferrer"
              sandbox="allow-scripts allow-same-origin allow-forms"
              className="post-video-iframe"
              allow="picture-in-picture; clipboard-write; encrypted-media; gyroscope; accelerometer;"
            />
          )}
        </div>
      )}
    </div>
  );
}
