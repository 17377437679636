export const AMPLITUDE_KEY = '92fcd49d8c49f33a7d1c35866128d075';
export const HEAP_KEY = '1776830314';

// The default project shown for group messaging on the activity feed.
export const DEFAULT_MESSAGE_PROJECT_PUBLIC_KEY = 'BC1YLj3zNA7hRAqBVkvsTeqw7oi4H6ogKiAFL1VXhZy6pYeZcZ6TDRY';

export const FEATURED_PROJECTS = Object.freeze([
  'BC1YLj3zNA7hRAqBVkvsTeqw7oi4H6ogKiAFL1VXhZy6pYeZcZ6TDRY', // @Openfund
  'BC1YLhKstBdPujJeaBE7PH1skiVHbuFGKkzENRYjyCa2BhXYa4PtRXg', // @NodeBitsDAO
  'BC1YLhajq5sg79MeKjC6QSPKr7X7tLUvpGEXZ2Z5VNBTxExRzFJ659H', // @Stori
  'BC1YLfxCBXWevK913E8CU5ZqsYNzcJqvsh4ud9CoJUYg7dGQfPJn1gX', // @NFTzToken
]);

export const OPEN_FUND_MEGASWAP_AFFILIATE_ADDRESS = 'BC1YLj3zNA7hRAqBVkvsTeqw7oi4H6ogKiAFL1VXhZy6pYeZcZ6TDRY';

export const INVESTMENT_NETWORK_FEE_NANOS_BUFFER = 3000;
export const MIN_FEE_RATE_NANOS_PER_KB = 1000;

// When approving a transaction, we allow a buffer on the max deso limit
// to cover fees, among other things.
export const GLOBAL_DESO_LIMIT_BUFFER = 100000000;

export const IS_TESTNET = false;
export const NODE_URL = 'https://blockproducer.deso.org/api/v0';
export const OPENFUND_URL = 'https://openfund.com/api/v0';
export const DESO_IDENTITY_URL = 'https://identity.deso.org';
export const MEGA_SWAPPER_URL = 'https://heroswap.com/api/v1';
export const DESO_DOLLAR_PROFILE_NAME = 'dUSDC_';

export const EXPLORER_URLS = {
  blockcypher: 'https://live.blockcypher.com/btc',
  deso: 'https://node.deso.org',
  etherscan: 'https://etherscan.io',
  heroswap: 'https://heroswap.com',
  solana: 'https://explorer.solana.com',
  desoWallet: 'https://wallet.deso.com',
};

export const OPENFUND_TOKEN_PUBLIC_KEY = 'BC1YLj3zNA7hRAqBVkvsTeqw7oi4H6ogKiAFL1VXhZy6pYeZcZ6TDRY';
export const DESO_USDC_PUBLIC_KEY = 'BC1YLiwTN3DbkU8VmD7F7wXcRR1tFX6jDEkLyruHD2WsH3URomimxLX';
export const FOCUS_TOKEN_PUBLIC_KEY = 'BC1YLjEayZDjAPitJJX4Boy7LsEfN3sWAkYb3hgE9kGBirztsc2re1N';
export const DESO_BTC_PUBLIC_KEY = 'BC1YLiE4N4UWZQBrGFxpzJU6vrX5PjMbJXs3VLJsjm6wihxGB5vt6aZ';
export const DESO_ETH_PUBLIC_KEY = 'BC1YLgtgQc2u6MLbvr8Gjwx9UhZ81sqQ1U7NSexVRxuocZJrDwujGXq';
export const DESO_SOL_PUBLIC_KEY = 'BC1YLivpU6sdRZafRWZYRACMYHpnpXqUS2hsdRT4pobi7DVbTPAEPkd';

export const DESO_ZERO_PUBLIC_KEY = 'BC1YLbnP7rndL92x7DbLp6bkUpCgKmgoHgz7xEbwhgHTps3ZrXA6LtQ';

export const FOCUS_URL = 'https://focus.xyz';
export const DESO_EXPLORER_URL = 'https://explorer.deso.com';
export const AMM_URL = 'https://amm.deso.com';
export const GRAPHQL_URL = 'https://graphql.focus.xyz/graphql';
export const WS_GRAPHQL_URL = 'wss://graphql-ws.focus.xyz/graphql';
