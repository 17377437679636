import { WealthBadge } from 'components/app-ui/WealthBadge';
import { Avatar } from 'components/core/Avatar';
import { RouteLink } from 'components/core/RouteLink';
import { BalanceEntryResponse, ProfileEntryResponse } from 'deso-protocol';
import { useEffect, useState } from 'react';
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5';
import { Routes } from 'RoutePaths';
import { classNames } from 'utils/classNames';
import {
  baseUnitsToTokens,
  calcOwnershipPercent,
  desoNanosToUSD,
  formatDecimalValue,
  getHoldingPublicKey,
  getWealthFromBalanceEntry,
} from 'utils/currency';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { getWrappedAsset, getWrappedAssetIcon, shortenLongWord } from '../../utils/deso';
import { abbreviateNumber } from './USDValueWithDESO';

interface ProjectHodlersLeaderBoardProps {
  hodlers: BalanceEntryResponse[];
  project: ProfileEntryResponse;
  exchangeRates: GetExchangeRateUpdatedResponse;
  holdingsView?: boolean;
  limit?: number;
  viewMoreAction?: () => void;
}

export function ProjectHodlersLeaderBoard({
  hodlers,
  exchangeRates,
  project,
  holdingsView = false,
  limit,
  viewMoreAction,
}: ProjectHodlersLeaderBoardProps) {
  const columns = ['#', 'User', 'Ownership'];
  const [sortColumn, setSortColumn] = useState('Ownership');
  const [isDescendingSort, setIsDescendingSort] = useState(true);
  const [holdersSorted, setHoldersSorted] = useState<BalanceEntryResponse[]>([]);

  useEffect(() => {
    // NOTE: we should make a copy here instead of mutating the passed in array.
    const copy = hodlers.slice();
    copy.sort((a, b) => {
      let difference = getWealthFromBalanceEntry(a, holdingsView) - getWealthFromBalanceEntry(b, holdingsView);
      if (sortColumn === 'Ownership') {
        difference =
          baseUnitsToTokens(a.BalanceNanosUint256.toString()) - baseUnitsToTokens(b.BalanceNanosUint256.toString());
      }
      return isDescendingSort ? -difference : difference;
    });
    setHoldersSorted(typeof limit !== 'undefined' ? copy.slice(0, limit) : copy);
  }, [hodlers, limit, sortColumn, holdingsView, isDescendingSort]);

  return (
    <div
      className={classNames(
        'overflow-hidden border border-border rounded-2xl max-h-[400px] overflow-y-scroll openfund-scrollbar',
      )}
    >
      <div className="w-full overflow-x-scroll md:overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="items-center border-b border-border-light">
              {columns.map((c, i) => {
                const align = i === 0 ? ' text-left' : ' text-right';
                return (
                  <th
                    key={i}
                    className={
                      'py-2 text-left text-sm cursor-pointer select-none last:flex last:justify-end last:text-right' +
                      align
                    }
                  >
                    <button
                      className="font-bold flex"
                      onClick={() => {
                        if (sortColumn === columns[i]) {
                          setIsDescendingSort(!isDescendingSort);
                          return;
                        }
                        setSortColumn(columns[i]);
                        setIsDescendingSort(true);
                      }}
                    >
                      {sortColumn !== c ? (
                        <>&nbsp;&nbsp;</>
                      ) : isDescendingSort ? (
                        <span className="inline-block top-0.5 relative">
                          <IoArrowDownOutline />
                        </span>
                      ) : (
                        <span className="inline-block top-0.5 relative">
                          <IoArrowUpOutline />
                        </span>
                      )}
                      &nbsp;{c}
                    </button>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {holdersSorted.map((h, i) => {
              const holdingPublicKey = getHoldingPublicKey(h, holdingsView);

              const wrappedAsset = getWrappedAsset(h.ProfileEntryResponse?.Username || '');
              const isWrapped = !!wrappedAsset;
              const displayName = isWrapped ? wrappedAsset.displayName : h.ProfileEntryResponse?.Username || '';

              return (
                <tr className="items-center border-b border-border-light" key={holdingPublicKey}>
                  <td className="py-2 items-center justify-center text-center m-auto pl-2 max-w-[10px] text-xs text-muted font-mono">
                    {i + 1}
                  </td>
                  <td className="flex items-center py-2 px-4">
                    <Avatar
                      border="none"
                      className="flex-shrink-0 bg-gray-faint"
                      src={isWrapped ? getWrappedAssetIcon(wrappedAsset) : holdingPublicKey}
                    />
                    <div className="ml-2">
                      <div className="text-muted-foreground text-xs font-semibold">
                        {h.ProfileEntryResponse?.Username ? (
                          <RouteLink to={Routes.profile(displayName)}>
                            <div className="hover:underline  max-w-[150px] truncate text-ellipsis">
                              {holdingsView ? '$' : '@'}
                              {displayName}
                            </div>
                          </RouteLink>
                        ) : (
                          shortenLongWord(holdingPublicKey, 6, 6)
                        )}
                      </div>
                      <div className="flex lg:flex-row flex-col">
                        <div className="flex flex-row items-center text-xs text-muted">
                          {shortenLongWord(holdingPublicKey, 6, 6)}
                        </div>
                        <div className="text-muted text-xs mr-1 whitespace-nowrap hidden">
                          <WealthBadge
                            wealthUSD={desoNanosToUSD(
                              getWealthFromBalanceEntry(h, holdingsView),
                              exchangeRates.USDCentsPerDeSoCoinbase,
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="ml-auto text-right text-sm py-2 ">
                    <div className="whitespace-nowrap text-xs font-mono text-muted-foreground lowercase">
                      {abbreviateNumber(baseUnitsToTokens(h.BalanceNanosUint256.toString()))}
                    </div>
                    <div className="text-xs text-muted font-mono whitespace-nowrap">
                      ≈{' '}
                      {formatDecimalValue(
                        calcOwnershipPercent(
                          h.BalanceNanosUint256.toString(),
                          holdingsView
                            ? h.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos.toString()
                            : project.DAOCoinEntry?.CoinsInCirculationNanos.toString(),
                        ),
                        2,
                      )}
                      %
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {typeof viewMoreAction === 'function' && (
        <div className="text-center text-xs text-muted hover:text-primary py-2 cursor-pointer" onClick={viewMoreAction}>
          View All Holders
        </div>
      )}
    </div>
  );
}
