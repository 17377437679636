import { useLazyQuery } from '@apollo/client';
import { LockedBalanceEntriesOrderBy, TokenMarketVolumeSummariesExtendedDocument } from '../graphql/codegen/graphql';
import { useContext } from 'react';
import { DESO_PROJECT_NAME } from 'constants/TradeConstants';
import { DESO_ZERO_PUBLIC_KEY } from 'constants/AppConstants';
import { OpenfundContext } from '../contexts/OpenfundContext';

type TokenMarketDataProps = {
  publicKey: string;
  quoteCurrencyPublicKey: string;
};

export const useTokenMarketData = ({ publicKey, quoteCurrencyPublicKey }: TokenMarketDataProps) => {
  // Fallback to DESO_ZERO_PUBLIC_KEY if publicKey or quoteCurrencyPublicKey is DESO_PROJECT_NAME
  const effectivePublicKey = publicKey === DESO_PROJECT_NAME ? DESO_ZERO_PUBLIC_KEY : publicKey;
  const effectiveQuoteCurrencyPublicKey =
    quoteCurrencyPublicKey === DESO_PROJECT_NAME ? DESO_ZERO_PUBLIC_KEY : quoteCurrencyPublicKey;
  const { currentUser } = useContext(OpenfundContext);

  const [getTokenMarketData, { data, loading, refetch }] = useLazyQuery(TokenMarketVolumeSummariesExtendedDocument, {
    fetchPolicy: 'network-only',
  });

  const fetchTokenMarketData = async (ammPublicKey: string) => {
    if (!currentUser) {
      return;
    }

    await getTokenMarketData({
      variables: {
        marketVolumeFilter: {
          coin1Account: {
            publicKey: {
              // Coin public key
              equalTo: effectivePublicKey,
            },
          },
          coin2Account: {
            publicKey: {
              // Focus public key
              equalTo: effectiveQuoteCurrencyPublicKey,
            },
          },
        },
        founderBalanceFilter: {
          hodler: {
            publicKey: {
              // Coin public key
              equalTo: effectivePublicKey,
            },
          },
        },
        ammBalanceFilter: {
          hodler: {
            publicKey: {
              // AMM public key (retrievable from the AMM API)
              equalTo: ammPublicKey || 'UNKNOWN',
            },
          },
        },
        userBalanceFilter: {
          hodler: {
            publicKey: {
              // Public key of user to get balance of
              equalTo: currentUser.PublicKeyBase58Check,
            },
          },
        },
        lockedBalancesFirst: 1,
        lockedBalancesOrderBy: LockedBalanceEntriesOrderBy.UnlockTimestampNanoSecsAsc,
        founderUnlockFilter: {
          hodlerAccount: {
            publicKey: {
              // Coin public key
              equalTo: effectivePublicKey,
            },
          },
        },
      },
    });
  };

  return {
    data,
    loading,
    refetch,
    fetchTokenMarketData,
  };
};
