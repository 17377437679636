import React, { useContext, useMemo, useRef, useCallback, useEffect } from 'react';
import { ProfileEntryResponse } from 'deso-protocol';
import { useVirtualizer } from '@tanstack/react-virtual';
import { desoToUSD } from '../../utils/currency';
import { getWrappedAsset } from '../../utils/deso';
import {
  buildPriceLevelBookBySide,
  OPERATION_TYPES,
  priceBigIntToDecimalString,
  PriceLevel,
} from '../../utils/orderbook';
import { DESO_USDC_PUBLIC_KEY } from '../../constants/AppConstants';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import LowNumFormatter from './LowNumFormatter';
import { TokenLimitOrderEntryResponse } from '../../services/Deso';
import { PriceLevelSection } from './PriceLevelSection';

export const SCROLL_HEIGHT = 288;
export const ROW_HEIGHT = 45;

export function PriceLevelBook(props: {
  tokenOrderbook: TokenLimitOrderEntryResponse[];
  loggedInPublicKey: string;
  project: ProfileEntryResponse;
  onClickPriceRow: ({ price, usdPrice }: { price: number; usdPrice: number }) => void;
}) {
  const {
    quoteCurrencyPublicKey,
    denominatingCoinByQuoteCurrencyPublicKey,
    exchangeRates,
    usdTickerByQuoteCurrencyPublicKey,
  } = useContext(QuoteCurrencyContext);

  const { tokenOrderbook: selectedTokenOrderBook } = props;

  const denominatingToken = denominatingCoinByQuoteCurrencyPublicKey[quoteCurrencyPublicKey];
  const rate = exchangeRates[quoteCurrencyPublicKey];

  const bidPriceLevels = useMemo(
    () =>
      buildPriceLevelBookBySide(
        selectedTokenOrderBook,
        denominatingToken,
        OPERATION_TYPES.BID,
        props.loggedInPublicKey,
      ),
    [selectedTokenOrderBook, denominatingToken, props.loggedInPublicKey],
  );

  const askPriceLevels = useMemo(
    () =>
      buildPriceLevelBookBySide(
        selectedTokenOrderBook,
        denominatingToken,
        OPERATION_TYPES.ASK,
        props.loggedInPublicKey,
      ),
    [selectedTokenOrderBook, denominatingToken, props.loggedInPublicKey],
  );

  const minAskPrice = getMinPrice(askPriceLevels);
  const maxBidPrice = getMaxPrice(bidPriceLevels);

  const midPrice = minAskPrice === 0 || maxBidPrice === 0 ? 0 : (minAskPrice + maxBidPrice) / 2;

  const wrappedAsset = getWrappedAsset(props.project.Username);
  const isWrapped = !!wrappedAsset;
  const displayName = isWrapped ? wrappedAsset.displayName : props.project.Username;

  const handleClickPriceRow = useCallback(
    ({ price, usdPrice }: { price: number; usdPrice: number }) => {
      props.onClickPriceRow({ price, usdPrice });
    },
    [props.onClickPriceRow],
  );

  const askParentRef = useRef<HTMLDivElement>(null);
  const bidParentRef = useRef<HTMLDivElement>(null);
  const hasInitialScrollRef = useRef<boolean>(false);

  const askVirtualizer = useVirtualizer({
    count: askPriceLevels.length,
    getScrollElement: () => askParentRef.current,
    estimateSize: () => ROW_HEIGHT,
  });

  const bidVirtualizer = useVirtualizer({
    count: bidPriceLevels.length,
    getScrollElement: () => bidParentRef.current,
    estimateSize: () => ROW_HEIGHT,
  });

  useEffect(() => {
    if (askParentRef.current && askPriceLevels.length > 0 && !hasInitialScrollRef.current) {
      askParentRef.current.scrollTop = askParentRef.current.scrollHeight;
      hasInitialScrollRef.current = true;
    }
  }, [askPriceLevels]);

  return (
    <div className="w-full">
      <div className="w-full border border-border">
        <div className="flex flex-col text-sm text-muted">
          <PriceLevelTableHeaderRow side="Ask" tokenDisplayName={displayName} />
        </div>
        <PriceLevelSection
          priceLevels={askPriceLevels}
          side={OPERATION_TYPES.ASK}
          ref={askParentRef}
          virtualizer={askVirtualizer}
          displayName={displayName}
          handleClickPriceRow={handleClickPriceRow}
          className="border-t border-b border-border-light openfund-scrollbar overflow-y-scroll overflow-x-auto"
          style={{ height: SCROLL_HEIGHT, flexDirection: 'column-reverse' }}
          emptyMessage={`There are no orders on\nthe market to sell $${displayName} tokens`}
        />
        <div className="text-center text-xs text-muted py-2">
          Mid Price:{' '}
          <span className="text-muted-foreground font-normal font-mono">
            <LowNumFormatter
              price={desoToUSD(midPrice, rate)}
              className="text-xs"
              abbreviatePriceThreshold={Number.MAX_SAFE_INTEGER}
            />{' '}
            {usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
          </span>
        </div>
        <div className="border-t border-border-light">
          <PriceLevelSection
            priceLevels={bidPriceLevels}
            side={OPERATION_TYPES.BID}
            ref={bidParentRef}
            virtualizer={bidVirtualizer}
            displayName={displayName}
            handleClickPriceRow={handleClickPriceRow}
            className="overflow-y-scroll overflow-x-hidden openfund-scrollbar"
            style={{ height: SCROLL_HEIGHT }}
            emptyMessage={`There are no orders on\nthe market to buy $${displayName} tokens`}
          />
        </div>
      </div>
    </div>
  );
}

function PriceLevelTableHeaderRow(props: { side: string; tokenDisplayName: string }) {
  const { quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey, usdTickerByQuoteCurrencyPublicKey } =
    useContext(QuoteCurrencyContext);

  const priceAndTotalColumnTickerTitle = useMemo(() => {
    switch (quoteCurrencyPublicKey) {
      case DESO_USDC_PUBLIC_KEY:
        return usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey];
      default:
        return `${usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}/${tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}`;
    }
  }, [quoteCurrencyPublicKey]);

  return (
    <div className="text-right bg-accent flex justify-between font-bold">
      <div className="text-left px-1 xl:px-3 py-1 text-xs text-muted-foreground w-[38%]">
        Price
        <br /> <span className="text-xs text-muted font-normal">{priceAndTotalColumnTickerTitle}</span>
      </div>
      <div className="text-left px-1 xl:px-3 py-1 text-xs text-muted-foreground w-[32%]">
        Size
        <br /> <span className="text-xs text-muted font-normal block truncate">${props.tokenDisplayName}</span>
      </div>
      <div className="text-right px-1 xl:px-3 py-1 text-xs text-muted-foreground w-[30%]">
        Total
        <br /> <span className="text-xs text-muted font-normal">{priceAndTotalColumnTickerTitle}</span>
      </div>
    </div>
  );
}

function getMaxPrice(priceLevels: PriceLevel[]): number {
  if (priceLevels.length === 0) {
    return 0;
  }
  return Math.max(...priceLevels.map((priceLevel) => parseFloat(priceBigIntToDecimalString(priceLevel.Price))));
}

function getMinPrice(priceLevels: PriceLevel[]): number {
  if (priceLevels.length === 0) {
    return 0;
  }
  return Math.min(...priceLevels.map((priceLevel) => parseFloat(priceBigIntToDecimalString(priceLevel.Price))));
}
