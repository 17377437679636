import { Tab } from '@headlessui/react';
import { useState } from 'react';
import { ExternalLink } from 'components/core/ExternalLink';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Button } from 'components/shadcn/ui/button';
import { Textarea } from 'components/shadcn/ui/textarea';

interface MarkdownEditorProps {
  initialValue?: string;
  title?: string;
  placeholder?: string;
  onEdit: (markdown: string) => void;
}
export function MarkdownEditor({
  placeholder = 'Give a detailed description of your project...',
  initialValue = '',
  onEdit,
  title,
}: MarkdownEditorProps) {
  const [markdown, setMarkdown] = useState(initialValue);

  return (
    <Tab.Group>
      {({ selectedIndex }) => (
        <>
          <Tab.List className="flex justify-between mb-2 items-center">
            <p className="font-medium text-sm">{title}</p>
            <div className="flex gap-2">
              <Tab>
                <Button variant={selectedIndex === 0 ? 'default' : 'ghost'} size="sm">
                  Write
                </Button>
              </Tab>
              <Tab className="text-muted-foreground">
                <Button variant={selectedIndex === 0 ? 'ghost' : 'default'} size="sm">
                  Preview
                </Button>
              </Tab>
            </div>
          </Tab.List>
          <Tab.Panels className="mt-2 text-black">
            <Tab.Panel className="p-0.5 -m-0.5 rounded-lg">
              <div>
                <Textarea
                  id="markdown-text-area"
                  autoResize={true}
                  minHeight="100px"
                  maxHeight="800px"
                  rows={8}
                  name="longDesc"
                  className="w-full resize-none"
                  placeholder={placeholder}
                  onInput={(ev: React.FormEvent<HTMLTextAreaElement>) => {
                    const value = ev.currentTarget.value;
                    setMarkdown(value);
                    onEdit(value);
                  }}
                  value={markdown}
                />
                <p className="mt-3 text-xs text-muted">
                  Supports Markdown.&nbsp;
                  <ExternalLink
                    kind="text-only-light"
                    href="https://www.markdownguide.org/cheat-sheet/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dark:text-black underline"
                  >
                    Learn More
                  </ExternalLink>
                </p>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="border rounded-2xl border-border p-6">
                <div className="mx-px mt-px px-3 pt-2 pb-12 text-sm leading-5 text-foreground">
                  <div className="prose max-w-none text-foreground prose-headings:text-muted-foreground">
                    <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  );
}
