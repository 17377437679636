import { NavLink, NavLinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { getInteractiveElementClassName, InteractiveElementProps } from 'utils/getInteractiveElementClassName';
import { ReactNode } from 'react';

export function RouteLink({
  kind = 'text-only',
  size = 'md',
  shape = 'square',
  ...rest
}: InteractiveElementProps & NavLinkProps & { children: ReactNode }) {
  const { className: extraClasses = '', children, to } = rest;
  const isExternal = typeof to === 'string' && /^https?:\/\//.test(to);
  const classes = getInteractiveElementClassName({ kind, size, shape, extraClasses, disabled: false } as any);

  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  if (isExternal) {
    return (
      <a href={to} className={classes} target="_blank" rel="noopener hover:text-primary noreferrer">
        {children}
      </a>
    );
  }

  const props = { ...rest, className: classes };

  return (
    <NavLink {...props} aria-current={match ? 'page' : undefined}>
      {children}
    </NavLink>
  );
}
