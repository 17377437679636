import { CountdownTimer } from 'components/app-ui/CountdownTimer';
import { FeaturedImage } from 'components/app-ui/FeaturedImage';
import { FollowButton } from 'components/app-ui/FollowButton';
import { Heading } from 'components/core/Heading';
import { Text } from 'components/core/Text';
import { FEATURED_PROJECTS } from 'constants/AppConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { ProfileEntryResponse, User } from 'deso-protocol';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { deso, openfund } from 'services';
import { FundingRound } from 'services/Openfund';
import { formatUSD, usdCentsToUSD } from 'utils/currency';
import { hasEndTime } from 'utils/fundingRound';

// TODO: high res images
type FeaturedProjectCardProps = { profile: ProfileEntryResponse };
export function FeaturedProjectCard({ profile }: FeaturedProjectCardProps) {
  const featuredImageUrl = profile.ExtraData?.FeaturedImageURL;
  const [fundingRound, setFundingRound] = useState<FundingRound>();
  const [hasFutureStartTime, setHasFutureStartTime] = useState(false);
  const { currentUser } = useContext(OpenfundContext);
  const isFollowing = !!currentUser?.PublicKeysBase58CheckFollowedByUser?.find(
    (k) => k === profile.PublicKeyBase58Check,
  );
  const isMounted = useIsMounted();

  useEffect(() => {
    openfund.getFundingRoundsByUsername(profile.Username).then((res) => {
      if (res.length > 0 && isMounted.current) {
        const latestRound = res[0];
        setFundingRound(latestRound);
        setHasFutureStartTime(new Date(latestRound.StartTime).getTime() > Date.now());
      }
    });
  }, [profile]);

  return (
    <section className="flex-1 pb-4 lg:pb-12 mr-0 md:mr-6 last:mr-0 items-center justify-center text-center relative mb-4 lg:mb-4 w-full md:w-1/4">
      {(fundingRound?.RoundStatus === 'OPEN' || fundingRound?.RoundStatus === 'PAUSED') && (
        <div className="mt-4">
          {hasFutureStartTime && (
            <>
              <Text className="text-sm" color="secondary">
                Funding round starts in
              </Text>
              <CountdownTimer shortUnit={true} endTime={fundingRound.StartTime} />
            </>
          )}
          {!hasFutureStartTime && hasEndTime(fundingRound) && (
            <>
              <Text size="sm" color="secondary">
                Funding round ends in
              </Text>
              <CountdownTimer shortUnit={true} endTime={fundingRound.EndTime} />
            </>
          )}
        </div>
      )}
      {fundingRound?.RoundStatus === 'FINALIZED' && (
        <div className="mt-4">
          <Text size="sm" color="secondary">
            Funding round finalized{' '}
          </Text>
          <Text color="secondary" className="my-2">
            Amount raised:{' '}
            <span className="text-green">{formatUSD(usdCentsToUSD(fundingRound.AmountRaisedUSDCents), false)}</span>
          </Text>
        </div>
      )}
      <div className="mb-4 mt-4 lg:mb-8">
        <Link aria-label={`Contribute`} to={Routes.fund(profile.Username)}>
          <FeaturedImage src={featuredImageUrl} alt={`${profile.Username}`} />
        </Link>
      </div>
      <p className="text-xs opacity-70 uppercase mb-2">${profile.Username}</p>
      <Link aria-label={`Contribute`} to={Routes.fund(profile.Username)}>
        <Heading level={3} className="font-sans text32xl mb-4 lg:text-2xl ">
          {profile.ExtraData?.DisplayName || profile.Username}
        </Heading>
      </Link>
      <FollowButton
        followeePublicKey={profile.PublicKeyBase58Check}
        kind={isFollowing ? 'btn-secondary' : 'btn-secondary'}
        shape="rounded"
      />{' '}
    </section>
  );
}

type FeaturedProjectsProps = { projects: User[] };
export function FeaturedProjects({ projects }: FeaturedProjectsProps) {
  return (
    <div className="flex flex-col max-w-7xl m-auto justify-between md:flex-row">
      {projects.map((project, index) => (
        <FeaturedProjectCard profile={project.ProfileEntryResponse!} key={index} />
      ))}
    </div>
  );
}

export function FeaturedProjectsContainer() {
  const [projects, setProjects] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffectOnce(() => {
    setIsLoading(true);
    let isUnmounted = false;

    deso
      .getUsers(FEATURED_PROJECTS)
      .then(({ UserList }) => {
        if (!isUnmounted && !!UserList) {
          setProjects(UserList.filter((user) => !!user.ProfileEntryResponse));
        }
      })
      .catch((e) => {
        if (!isUnmounted) {
          setHasError(true);
        }
        throw e;
      })
      .finally(() => {
        if (!isUnmounted) {
          setIsLoading(false);
        }
      });
    return () => {
      isUnmounted = true;
    };
  });

  if (isLoading) {
    // TODO: need something nicer here?
    return <div>Loading...</div>;
  }

  if (hasError) {
    return <Fragment />;
  }

  return <FeaturedProjects projects={projects} />;
}
