import { Button as ButtonNew } from '../shadcn/ui/button';
import { Routes } from 'RoutePaths';
import { RouteLink } from 'components/core/RouteLink';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/shadcn/ui/dialog';
import { ExternalLink } from 'components/core/ExternalLink';
import { Avatar } from 'components/core/Avatar';
import { useTheme } from 'contexts/ThemeContext';
import { LuActivity, LuBadgeDollarSign, LuChartLine, LuSun, LuSwords, LuWallet } from 'react-icons/lu';
import { DaoCoinStatsDocument, DaoCoinStatsOrderBy } from 'graphql/codegen/graphql';
import { useQuery } from '@apollo/client';
import {
  DESO_BTC_PUBLIC_KEY,
  DESO_ETH_PUBLIC_KEY,
  DESO_EXPLORER_URL,
  DESO_SOL_PUBLIC_KEY,
  DESO_ZERO_PUBLIC_KEY,
  EXPLORER_URLS,
  FOCUS_TOKEN_PUBLIC_KEY,
  FOCUS_URL,
  OPENFUND_TOKEN_PUBLIC_KEY,
} from '../../constants/AppConstants';
import { getWrappedAsset, getWrappedAssetIcon, shortenLongWord } from '../../utils/deso';
import LowNumFormatter from '../app-ui/LowNumFormatter';
import { formatDecimalValue, MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING } from '../../utils/currency';
import { cn } from 'utils/shadcn';

export function Home() {
  return (
    <>
      <div className="relative">
        <div>
          <HomeContent />
        </div>
      </div>
    </>
  );
}

function HomeContent() {
  const { data } = useQuery(DaoCoinStatsDocument, {
    variables: {
      filter: {
        coinPublicKey: {
          in: [
            DESO_ZERO_PUBLIC_KEY,
            DESO_SOL_PUBLIC_KEY,
            DESO_BTC_PUBLIC_KEY,
            DESO_ETH_PUBLIC_KEY,
            OPENFUND_TOKEN_PUBLIC_KEY,
            FOCUS_TOKEN_PUBLIC_KEY,
          ],
        },
      },
      orderBy: DaoCoinStatsOrderBy.VolumeUsdPast_24HDesc,
    },
  });

  const { darkTheme } = useTheme();

  return (
    <div className="text-center pt-[130px] md:pt-[140px] max-w-7xl relative mx-auto flex flex-col justify-center">
      <div className="relative">
        <img
          className={`img--stars-1 ${!darkTheme ? 'invert' : ''}`}
          src="/images/image-stars-1.svg"
          alt="stars-1"
          loading="lazy"
        />
        <img className="img--planet-1" src="/images/image-planet-1.svg" alt="planet-1" loading="lazy" />
        <img className="img--planet-2" src="/images/image-planet-2.svg" alt="planet-2" loading="lazy" />
        <img className="img--planet-3" src="/images/image-planet-3.svg" alt="planet-3" loading="lazy" />
        <img className="img--planet-4" src="/images/image-planet-4.svg" alt="planet-4" loading="lazy" />
        <img className="img--planet-6" src="/images/image-planet-6.svg" alt="planet-6" loading="lazy" />
        <img className="img--planet-7" src="/images/image-planet-7.svg" alt="planet-7" loading="lazy" />
        <img className="img--planet-9" src="/images/image-planet-9.svg" alt="planet-9" loading="lazy" />
        <img className="img--rock-2" src="/images/image-rock-2.svg" alt="rock-2" loading="lazy" />
      </div>
      <section className="font-semibold relative font-sans text-3xl md:text-5xl text-muted-foreground mb-4 md:mb-6">
        Trade without limits.
      </section>
      <p className="w-full text-foreground mx-auto mb-6 relative font-light text-sm text-balance md:text-base max-w-2xl">
        Openfund is the first fully on-chain order-book spot-trading exchange. As efficient and easy-to-use as your
        favorite centralized exchange, with all the benefits of DeFi.{' '}
        <a
          href="https://docs.deso.org/openfund/what-is-openfund"
          className="underline underline-offset-4"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </p>
      <div className="flex flex-col relative sm:flex-row sm:justify-center gap-4">
        <RouteLink kind="text-only" to={Routes.trade()}>
          <ButtonNew>Start Trading</ButtonNew>
        </RouteLink>
        <ComingSoonModal triggerText="Launch Your Token" />
        {/* <StartProjectButton buttontype="createProject" kind="btn-primary" size="md" shape="rounded" /> */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mx-auto mt-12 gap-4 max-w-[1000px] md:scale-90 relative">
        {data &&
          (data?.daoCoinStats?.nodes || []).map((coin) => {
            const wrappedAsset = getWrappedAsset(coin?.coinUsername || '');

            if (!coin) {
              return null;
            }

            const username = coin.coinUsername ?? shortenLongWord(coin.coinPublicKey);
            const displayName = coin.coinPublicKey === FOCUS_TOKEN_PUBLIC_KEY ? 'FOCUS' : username;

            return (
              <TickerWidget
                key={wrappedAsset?.publicKey ?? coin.coinPublicKey}
                publicKey={wrappedAsset ? getWrappedAssetIcon(wrappedAsset) : coin.coinPublicKey}
                username={wrappedAsset?.tradingPair ?? username}
                displayName={wrappedAsset?.displayName ?? displayName}
                price={coin.priceUsd || 0}
                volume={coin.volumeUsdPast24H || 0}
                pricePercentageChange={coin.priceUsdPctChange24H || 0}
              />
            );
          })}
      </div>
      <div className="mt-24 w-full flex justify-center items-center gap-2 pb-12">
        <NewFooter />
      </div>
    </div>
  );
}

export default Home;

const ComingSoonModal = ({ triggerText }: { triggerText: string }) => {
  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          <ButtonNew variant="outline">{triggerText}</ButtonNew>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-left text-2xl">Coming Soon!</DialogTitle>
            <DialogDescription className="text-left">
              <div className="flex flex-col gap-4">
                <p>
                  Soon you will be able to launch new tokens using Focus, our next-generation SocialFi app, launching in
                  just a few weeks.
                </p>
                <p>
                  All new tokens will be powered by our breakthrough order-book automated market-makers (AMMs), which
                  are currently powering the{' '}
                  <RouteLink className="underline underline-offset-4" to="/trade/deso">
                    $DESO
                  </RouteLink>{' '}
                  and{' '}
                  <RouteLink className="underline underline-offset-4" to="/trade/openfund">
                    $openfund
                  </RouteLink>{' '}
                  markets.
                </p>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex justify-start gap-4">
            <DialogClose asChild>
              <ButtonNew variant="outline">Back</ButtonNew>
            </DialogClose>
            <ExternalLink href="https://focus.xyz" target="_blank" rel="noopener noreferrer">
              <ButtonNew>Learn More About Focus</ButtonNew>
            </ExternalLink>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
interface TickerWidgetProps {
  publicKey: string;
  username: string;
  displayName: string;
  price?: number;
  volume?: number;
  pricePercentageChange?: number;
  comingSoon?: boolean;
  className?: string;
}
const TickerWidget = ({
  publicKey,
  username,
  displayName,
  price,
  volume,
  pricePercentageChange,
  comingSoon,
  className = '',
}: TickerWidgetProps) => {
  const pricePctChange24h =
    Math.abs(pricePercentageChange || 0) < MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING
      ? 0
      : (pricePercentageChange ?? 0);

  return (
    <RouteLink to={comingSoon ? FOCUS_URL : `/trade/${displayName}`}>
      <div className={cn('bg-background rounded-lg w-full border border-border min-w-[300px]', className)}>
        <div className="flex flex-row justify-between p-4 w-full">
          <div className="flex items-center gap-3">
            <Avatar src={publicKey} alt={username} size="md" className="!border-0 unforce-radius rounded-full" />
            <div className="flex flex-col items-start">
              <p className="font-medium text-muted-foreground">${displayName}</p>
              <p className="text-sm text-muted">{username}</p>
            </div>
          </div>

          <div className="text-right">
            {!comingSoon && (
              <div className="flex flex-col items-end gap-1">
                <div className="text-base text-muted-foreground font-semibold font-mono">
                  <LowNumFormatter price={price || 0} highPriceThreshold={1} abbreviatePriceThreshold={1000000} />
                </div>
                <p className="text-xs text-muted opacity-60">Price</p>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between w-full items-center gap-2 text-sm border-t border-border-light p-4">
          {comingSoon ? (
            <span className="text-muted-foreground w-full text-center">Coming Soon</span>
          ) : (
            <>
              <span className="text-muted-foreground">
                <span className="font-mono text-xs text-muted">VOL</span>{' '}
                <LowNumFormatter
                  price={volume || 0}
                  highPriceThreshold={1}
                  abbreviatePriceThreshold={1000000}
                  className="font-mono"
                />
              </span>
              <span
                className={cn(
                  'font-mono',
                  pricePctChange24h !== 0 &&
                    (pricePercentageChange && pricePercentageChange >= 0 ? 'text-green-500' : 'text-red-500'),
                )}
              >
                {pricePercentageChange && (pricePercentageChange >= 0 ? '+' : '')}
                {formatDecimalValue(pricePctChange24h, 2, 2)}% (24h)
              </span>
            </>
          )}
        </div>
      </div>
    </RouteLink>
  );
};

const NewFooter = () => {
  return (
    <div className="relative flex w-full flex-col gap-4 px-4 text-sm text-muted md:inset-x-0 md:w-full md:p-0">
      <div className="leading-6 text-foreground text-balance">
        Openfund is built on <br className="block sm:hidden" />
        the DeSo Blockchain.
      </div>
      <div className="m-auto my-6 mb-16 grid grid-cols-2 items-center justify-center gap-6 text-sm md:my-0 md:flex md:text-xs">
        <div className="flex items-center gap-2">
          <LuSun className="text-lg" />
          <a
            className="cursor-pointer underline-offset-4 hover:underline"
            target="_blank"
            href="https://deso.com"
            rel="noreferrer"
          >
            Vision
          </a>
        </div>
        <div className="flex items-center gap-2">
          <LuActivity className="text-lg" />
          <a
            className="cursor-pointer underline-offset-4 hover:underline"
            target="_blank"
            href={DESO_EXPLORER_URL}
            rel="noreferrer"
          >
            Explorer
          </a>
        </div>
        <div className="flex items-center gap-2">
          <LuWallet className="text-lg" />
          <a
            className="cursor-pointer underline-offset-4 hover:underline"
            target="_blank"
            href={EXPLORER_URLS.desoWallet}
            rel="noreferrer"
          >
            Wallet
          </a>
        </div>
        <div className="flex items-center gap-2">
          <LuBadgeDollarSign className="text-lg" />
          <a
            className="cursor-pointer underline-offset-4 hover:underline"
            target="_blank"
            href={`${EXPLORER_URLS.desoWallet}/buy`}
            rel="noreferrer"
          >
            Buy $DESO
          </a>
        </div>
        <div className="flex items-center gap-2">
          <LuChartLine className="text-lg" />
          <a
            className="cursor-pointer underline-offset-4 hover:underline"
            target="_blank"
            href="https://www.coingecko.com/en/coins/deso"
            rel="noreferrer"
          >
            Listing
          </a>
        </div>
        <div className="flex items-center gap-2">
          <LuSwords className="text-lg" />
          <a
            className="cursor-pointer underline-offset-4 hover:underline"
            target="_blank"
            href="https://revolution.deso.com"
            rel="noreferrer"
          >
            Revolution
          </a>
        </div>
      </div>
    </div>
  );
};
