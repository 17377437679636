import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useToast } from 'components/hooks/use-toast';
import { useContext, useEffect, useState } from 'react';
import { deso, openfund } from 'services';
import { getErrorMsg } from 'utils/getErrorMsg';
import { InteractiveElementProps } from 'utils/getInteractiveElementClassName';

// TODO: we need to update the app user state after updating the following status.
interface FollowButtonProps {
  followeePublicKey: string;
  btnStateText?: {
    following: string;
    follow: string;
  };
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
}
export function FollowButton({
  followeePublicKey,
  btnStateText = { following: 'Following', follow: 'Follow' },
  size = 'sm',
  className,
  ...rest
}: FollowButtonProps & InteractiveElementProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isPendingToggleFollow, setIsPendingToggleFollow] = useState(false);
  const toast = useToast();
  const text = isFollowing ? btnStateText.following : btnStateText.follow;

  useEffect(() => {
    setIsFollowing(!!currentUser?.PublicKeysBase58CheckFollowedByUser?.find((k) => k === followeePublicKey));
  }, [currentUser]);

  return (
    <ButtonNew
      {...rest}
      size={size as any}
      className={className}
      onClick={async () => {
        if (isPendingToggleFollow) {
          return;
        }

        setIsFollowing(!isFollowing);
        setIsPendingToggleFollow(true);

        if (isFollowing) {
          if (currentUser?.PublicKeysBase58CheckFollowedByUser) {
            currentUser.PublicKeysBase58CheckFollowedByUser = currentUser.PublicKeysBase58CheckFollowedByUser.filter(
              (k) => k !== followeePublicKey,
            );
          }
        } else {
          currentUser?.PublicKeysBase58CheckFollowedByUser?.push(followeePublicKey);
        }

        setCurrentUser(currentUser && { ...currentUser });

        try {
          if (!currentUser) {
            await setCurrentUser(await openfund.login());
          }
          if (isFollowing) {
            await deso.unfollow(followeePublicKey);
            toast.toast({ description: 'Successfully unfollowed user', variant: 'success' });
          } else {
            await deso.follow(followeePublicKey);
            toast.toast({ description: 'Successfully followed user', variant: 'success' });
          }
        } catch (e) {
          setIsFollowing(isFollowing);
          if (isFollowing) {
            currentUser?.PublicKeysBase58CheckFollowedByUser?.push(followeePublicKey);
          } else {
            if (currentUser?.PublicKeysBase58CheckFollowedByUser) {
              currentUser.PublicKeysBase58CheckFollowedByUser = currentUser.PublicKeysBase58CheckFollowedByUser.filter(
                (k) => k !== followeePublicKey,
              );
            }
          }
          setCurrentUser(currentUser && { ...currentUser });
          toast.toast({ description: getErrorMsg(e), variant: 'destructive' });
        }
        setIsPendingToggleFollow(false);
      }}
    >
      {text}
    </ButtonNew>
  );
}
