import { createContext, ReactNode, useEffect, useState } from 'react';
import { TokenLimitOrderEntryResponse } from 'services/Deso';

interface TradeContextValue {
  selectedTokenOrderBook: TokenLimitOrderEntryResponse[];
  transactorOrders: TokenLimitOrderEntryResponse[];
}

const initialTradeContextValue: TradeContextValue = {
  selectedTokenOrderBook: [],
  transactorOrders: [],
};

export const TradeContext = createContext<TradeContextValue>(initialTradeContextValue);

interface TradeContextProviderProps {
  selectedTokenOrderBook: TokenLimitOrderEntryResponse[];
  transactorOrders: TokenLimitOrderEntryResponse[];
  children: ReactNode;
}

export const TradeContextProvider = ({
  selectedTokenOrderBook,
  transactorOrders,
  children,
}: TradeContextProviderProps) => {
  const [tradeContextValue, setTradeContextValue] = useState<TradeContextValue>({
    selectedTokenOrderBook,
    transactorOrders,
  });

  useEffect(() => {
    setTradeContextValue({
      selectedTokenOrderBook,
      transactorOrders,
    });
  }, [selectedTokenOrderBook, transactorOrders]);

  return <TradeContext.Provider value={tradeContextValue}>{children}</TradeContext.Provider>;
};
