import { RouteLink } from 'components/core/RouteLink';
import { Badge } from 'components/shadcn/ui/badge';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { ProfileEntryResponse, User } from 'deso-protocol';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { deso } from 'services';
import { Proposal } from 'services/Openfund';
import { linkifyText } from 'utils/text';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import ProposalSlideOver from './ProposalSlideOver';

function getDefaultProposalUser(): User {
  return {
    PublicKeyBase58Check: '',
    ProfileEntryResponse: {
      ExtraData: {},
      PublicKeyBase58Check: '',
      Username: '',
      Description: '',
      IsHidden: false,
      IsReserved: false,
      IsVerified: false,
      Comments: null,
      Posts: null,
      CoinEntry: null,
      DAOCoinEntry: null,
      CoinPriceDeSoNanos: 0,
      CoinPriceBitCloutNanos: 0,
      UsersThatHODL: null,
      IsFeaturedTutorialWellKnownCreator: false,
      IsFeaturedTutorialUpAndComingCreator: false,
    } as ProfileEntryResponse,
    Utxos: null,
    BalanceNanos: 0,
    UnminedBalanceNanos: 0,
    PublicKeysBase58CheckFollowedByUser: [],
    UsersYouHODL: [],
    UsersWhoHODLYouCount: 0,
    HasPhoneNumber: false,
    CanCreateProfile: false,
    BlockedPubKeys: {},
    HasEmail: false,
    EmailVerified: false,
    JumioStartTime: 0,
    JumioFinishedTime: 0,
    JumioVerified: false,
    JumioReturned: false,
    IsAdmin: false,
    IsSuperAdmin: false,
    IsBlacklisted: false,
    IsGraylisted: false,
    TutorialStatus: '',
    CreatorPurchasedInTutorialUsername: '',
    CreatorCoinsPurchasedInTutorial: 0,
    MustCompleteTutorial: false,
  };
}

interface ActivityFeedProposalProps {
  proposal: Proposal;
  className: string;
  diamondLevelMap: {
    [key: number]: number;
  };
  exchangeRates: GetExchangeRateUpdatedResponse;
}

export function ActivityFeedProposal({
  proposal,
  className,
  diamondLevelMap,
  exchangeRates,
}: ActivityFeedProposalProps) {
  const [proposalUserLoading, setProposalUserLoading] = useState(true);
  const [showSlideOver, setShowSlideOver] = useState(false);
  const [queryParamShow, setQueryParamShow] = useState(false);
  const [searchParams] = useSearchParams();
  const [proposalUser, setProposalUser] = useState<User>(getDefaultProposalUser());

  let proposalHashHex = searchParams.get('proposalId');
  if (proposalHashHex === proposal.PostHashHex && window.location.search !== '') {
    if (!showSlideOver && !queryParamShow) {
      setShowSlideOver(true);
      setQueryParamShow(true);
    }
  }

  function openProposal() {
    setShowSlideOver(true);
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?proposalId=${proposal.PostHashHex}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  function closeProposal() {
    setShowSlideOver(false);
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    proposalHashHex = null;
    searchParams.set('proposalId', '');
  }

  useEffectOnce(() => {
    setProposalUserLoading(true);
    deso
      .getUsers([proposal.UserPkidBase58Check], { SkipForLeaderboard: true })
      .then((res) => {
        if (res.UserList?.length === 1) {
          setProposalUser(res.UserList[0]);
        }
      })
      .finally(() => {
        setProposalUserLoading(false);
      });
  });

  return (
    <div className={'p-12 ' + className}>
      <div>
        <div className="flex pb-4 justify-between items-start">
          <div>
            <div
              className="text-lg text-muted-foreground font-bold mb-1 cursor-pointer hover:underline"
              onClick={() => openProposal()}
            >
              {proposal.Title}
            </div>
            {proposalUser?.ProfileEntryResponse && (
              <div className="text-sm text-muted">
                Proposed by{' '}
                <RouteLink
                  className="text-gray hover:text-black hover:underline"
                  to={Routes.profile(proposalUser.ProfileEntryResponse.Username)}
                >
                  @{proposalUser.ProfileEntryResponse.Username}
                </RouteLink>
              </div>
            )}
          </div>
          <div>
            {proposal.Status === 'CREATED' ? (
              <Badge variant="success">Voting is open</Badge>
            ) : (
              <Badge variant="destructive">Voting is closed</Badge>
            )}
          </div>
        </div>
        <div
          className="mb-6 text-sm text-foreground"
          dangerouslySetInnerHTML={{
            __html: linkifyText(proposal.Description),
          }}
        ></div>
        {!proposalUserLoading && (
          <ProposalSlideOver
            proposalUser={proposalUser}
            proposal={proposal}
            show={showSlideOver}
            closeSlideOver={() => closeProposal()}
            exchangeRates={exchangeRates}
            diamondLevelMap={diamondLevelMap}
            trigger={<ButtonNew onClick={() => openProposal()}>View Proposal</ButtonNew>}
          ></ProposalSlideOver>
        )}
      </div>
    </div>
  );
}
