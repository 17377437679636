import { cn } from '../../utils/shadcn';

interface ContainerProps {
  theme?: 'light' | 'dark';
  table?: 'responsive';
}
export function Container({
  className,
  children,
  table,
  theme = 'light',
  ...rest
}: ContainerProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      className={cn(
        'rounded-2xl',
        table === 'responsive' && 'w-full overflow-x-auto',
        theme === 'light' && 'bg-white text-black',
        theme === 'dark' && 'border border-[#555]',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
