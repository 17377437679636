import { FullPageError } from 'components/app-ui/FullPageError';
import { SwapCurrency } from 'components/app-ui/SwapCurrency';
import { Tabs } from 'components/core/Tabs';
import { ReactNode } from 'react';
import { getWrappedAsset } from '../../utils/deso';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/shadcn/ui/dialog';
import { Skeleton } from '../shadcn/ui/skeleton';
import { useDepositAddresses } from 'hooks/useDepositAddresses';

export function BuyModal(props: {
  isOpen: boolean;
  onClose: () => void;
  currency: 'DESO' | 'DUSD' | 'DBTC' | 'DETH' | 'DSOL';
  visibleTab?: string;
}) {
  const { addresses, loading, loadingError } = useDepositAddresses();

  let modalBody: ReactNode;

  const wrapperClasses = props.visibleTab ? '' : 'mt-4 sm:mt-0';

  if (loading) {
    modalBody = <Skeleton className="h-6 w-full" />;
  } else if (loadingError || !addresses || !addresses[props.currency]) {
    modalBody = <FullPageError error={new Error(loadingError)} />;
  } else {
    const tabs = [
      {
        tab: '$USDC-ETH',
        panel: (
          <div className={wrapperClasses}>
            <SwapCurrency
              depositAddress={addresses[props.currency].DepositAddresses.USDC}
              depositTicker="USDC"
              destinationTicker={props.currency}
            />
          </div>
        ),
      },
      {
        tab: '$USDC-SOL',
        panel: (
          <div className={wrapperClasses}>
            <SwapCurrency
              depositAddress={addresses[props.currency].DepositAddresses['USDC-SOL']}
              depositTicker="USDC-SOL"
              destinationTicker={props.currency}
            />
          </div>
        ),
      },
      {
        tab: '$ETH',
        panel: (
          <div className={wrapperClasses}>
            <SwapCurrency
              depositAddress={addresses[props.currency].DepositAddresses.ETH}
              depositTicker="ETH"
              destinationTicker={props.currency}
            />
          </div>
        ),
      },
      {
        tab: '$BTC',
        panel: (
          <div className={wrapperClasses}>
            <SwapCurrency
              depositAddress={addresses[props.currency].DepositAddresses.BTC}
              depositTicker="BTC"
              destinationTicker={props.currency}
            />
          </div>
        ),
      },
      {
        tab: '$SOL',
        panel: (
          <div className={wrapperClasses}>
            <SwapCurrency
              depositAddress={addresses[props.currency].DepositAddresses.SOL}
              depositTicker="SOL"
              destinationTicker={props.currency}
            />
          </div>
        ),
      },
      {
        tab: '$USDT',
        panel: (
          <div className={wrapperClasses}>
            <SwapCurrency
              depositAddress={addresses[props.currency].DepositAddresses.USDT}
              depositTicker="USDT"
              destinationTicker={props.currency}
            />
          </div>
        ),
      },
    ];

    // if (props.currency === 'DESO') {
    //   tabs.push({
    //     tab: '$DUSDC',
    //     panel: (
    //       <div className={wrapperClasses}>
    //         <SwapCurrency
    //           depositAddress={addresses[props.currency].DepositAddresses.DUSD}
    //           depositTicker="DUSD"
    //           destinationTicker="DESO"
    //         />
    //       </div>
    //     ),
    //   });
    // }

    if (props.currency === 'DUSD') {
      tabs.push({
        tab: '$DESO',
        panel: (
          <div className={wrapperClasses}>
            <SwapCurrency
              depositAddress={addresses[props.currency].DepositAddresses.DESO}
              depositTicker="DESO"
              destinationTicker={props.currency}
            />
          </div>
        ),
      });
    }

    const visibleTabs = props.visibleTab ? tabs.filter((e) => e.tab === props.visibleTab) : tabs;
    modalBody = <Tabs defaultIndex={0} tabs={visibleTabs} overflow={false} />;
  }

  let title: ReactNode;

  const wrappedAsset = getWrappedAsset(props.currency, 'heroswapDisplayName');

  if (wrappedAsset) {
    title = `Buy $${wrappedAsset.displayName}`;
  } else {
    title = 'Add Funds';
  }

  return (
    <Dialog open={props.isOpen} onOpenChange={props.onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="p-4 sm:p-6">{modalBody}</div>
      </DialogContent>
    </Dialog>
  );
}
