import { LuArrowDown, LuArrowUp } from 'react-icons/lu';
import { cn } from '../../utils/shadcn';
import { abbreviateNumber } from './USDValueWithDESO';
import { MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING } from '../../utils/currency';
import round from 'lodash/round';

interface CoinPriceFormatterProps {
  price: number;
  className?: string;
  numberClassName?: string;
  highPriceThreshold?: number;
  maximumFractionDigits?: number;
  arrowSize?: number;
  showArrow?: boolean;
}

const CoinPriceChangePercentageFormatter = ({
  price,
  className = '',
  numberClassName = '',
  highPriceThreshold = 100,
  maximumFractionDigits = 0,
  showArrow = true,
  arrowSize = 16,
}: CoinPriceFormatterProps) => {
  const pricePct = Number(price);
  const isUptrend = pricePct >= MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING;
  const isDowntrend = pricePct < 0;

  return (
    <div
      className={cn(
        'flex items-center text-sm font-mono gap-1',
        `${showArrow && isUptrend && 'text-green-600 font-shadow-green'}`,
        `${showArrow && isDowntrend && 'text-red-600'}`,
        `${showArrow && !isUptrend && !isDowntrend && 'text-muted'}`,
        className,
      )}
    >
      {showArrow && (
        <>
          {isUptrend && <LuArrowUp size={arrowSize} className="text-base" />}
          {isDowntrend && <LuArrowDown size={arrowSize} className="text-base" />}
        </>
      )}
      <div className={cn('inline-flex font-mono items-center', numberClassName)}>
        {abbreviateNumber(round(price, 2))}%
      </div>
    </div>
  );
};

export default CoinPriceChangePercentageFormatter;
