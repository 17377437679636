'use client';

import { Popover } from '@radix-ui/react-popover';
import { useMemo, useState } from 'react';
import { LuCheck, LuChevronsUpDown, LuSearch } from 'react-icons/lu';

import { SEARCH_ENTITY } from '../../hooks/useUserSearch';
import { Account, DaoCoinStat, DaoCoinStatsDocument, DaoCoinStatsOrderBy } from '../../graphql/codegen/graphql';
import { Avatar } from './Avatar';
import { Button } from '../shadcn/ui/button';
import { cn } from '../../utils/shadcn';
import { getWrappedAsset, getWrappedAssetIcon, shortenLongWord } from '../../utils/deso';
import { PopoverContent, PopoverTrigger } from '../shadcn/ui/popover';
import { Command, CommandInput, CommandItem, CommandList } from '../shadcn/ui/command';
import { Spinner } from './Spinner';
import { getUserName } from '../../utils/user';
import { RouteLink } from './RouteLink';
import { useLazyQuery } from '@apollo/client';
import { DESO_TOKEN_PUBLIC_KEY, DESO_WRAPPED_TOKENS } from '../../constants/TradeConstants';
import { useDaoCoinStatSearch } from '../../hooks/useDaoCoinStatSearch';
import LowNumFormatter from '../app-ui/LowNumFormatter';
import { formatDecimalValue, MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING } from '../../utils/currency';
import { FOCUS_TOKEN_PUBLIC_KEY, OPENFUND_TOKEN_PUBLIC_KEY } from 'constants/AppConstants';
import { FOCUS_TOKEN_SUPPLY } from '../../utils/constants';

interface UserSelectProps {
  selectedAccount: DaoCoinStat | null;
  onSelect?: (e: DaoCoinStat | null) => void;
  placeholder?: string;
  className?: string;
  customLink?: ((e: string) => string) | undefined;
  pinnedAccounts: Array<DaoCoinStat>;
}

const BaseCurrencySelect = ({
  onSelect,
  selectedAccount,
  placeholder = 'Search tokens...',
  className = '',
  customLink,
  pinnedAccounts,
}: UserSelectProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState(false);

  const [fetchSearchResultsLazy, { loading: loadingTokens }] = useLazyQuery(DaoCoinStatsDocument);

  const fetchSearchResultsRequest = async (
    inputValue: string,
    searchTypes: Array<SEARCH_ENTITY>,
    abortController: AbortController,
    excludedAccountUsernames?: Array<string>,
  ) => {
    const { data } = await fetchSearchResultsLazy({
      variables: {
        filter: {
          and: [
            {
              coinUsername: {
                notIn: excludedAccountUsernames || [],
              },
            },
            ...(searchTypes.includes(SEARCH_ENTITY.USERNAME)
              ? [
                  {
                    coinUsername: {
                      likeInsensitive: `${inputValue}%`,
                    },
                  },
                ]
              : [
                  {
                    coinPublicKey: {
                      equalTo: inputValue,
                    },
                  },
                ]),
          ],
        },
        orderBy: DaoCoinStatsOrderBy.VolumeUsdPast_24HDesc,
        first: 15,
      },
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    });

    return (data?.daoCoinStats?.nodes || []).filter((e) => !!e) as Array<DaoCoinStat>;
  };

  const scamTokenUsernames = [
    'shi_',
    'stx_',
    'dot_',
    'avax_',
    'tia_',
    'bnb_',
    'ada_',
    'icp_',
    'not_',
    'arb_',
    'shib_',
    'txr_',
    'ftm_',
    'xrp_',
  ];
  const usernamesToExclude = new Set([
    ...DESO_WRAPPED_TOKENS.map((e) => e.displayName.toLowerCase()),
    ...DESO_WRAPPED_TOKENS.map((e) => e.name.toLowerCase()),
    ...scamTokenUsernames.map((e) => e.toLowerCase()),
  ]);

  const { loading: loadingSearchResults, searchResults } = useDaoCoinStatSearch(
    searchValue,
    fetchSearchResultsRequest,
    loadingTokens,
    pinnedAccounts,
    usernamesToExclude,
  );

  const userElement = (e: DaoCoinStat, showAssets: boolean = false) => {
    const displayName = getUserName({
      account: {
        username: e.coinUsername,
        publicKey: e.coinPublicKey,
        extraData: {
          DisplayName: e.coinUsername,
        },
      } as Account,
      customPrefix: '$',
    });

    const wrappedAsset = getWrappedAsset(e.coinUsername || '');
    const pricePctChange24h =
      Math.abs(e.priceUsdPctChange24H || 0) < MIN_DESO_TOKEN_PCT_CHANGE_TO_HIGHLIGHT_TRENDING
        ? 0
        : (e.priceUsdPctChange24H ?? 0);
    const highlightColor =
      pricePctChange24h !== 0 ? (pricePctChange24h && pricePctChange24h >= 0 ? 'text-green-500' : 'text-red-500') : '';

    const marketCapUsd =
      e.coinPublicKey === FOCUS_TOKEN_PUBLIC_KEY ? (e.priceUsd ?? 0) * FOCUS_TOKEN_SUPPLY : (e.marketCapUsd ?? 0);

    return (
      <div
        style={{
          gridTemplateColumns: `var(--token-info-width) var(--price-width) var(--volume-width) var(--mcap-width) var(--change-width)`,
        }}
        className="w-full items-center grid gap-[16px]"
      >
        <div className="flex items-center gap-2 text-xs">
          <Avatar
            size="xs"
            className="w-6"
            src={!!wrappedAsset ? getWrappedAssetIcon(wrappedAsset) : e.coinPublicKey}
          />
          <span className="truncate">{displayName}</span>
          {(!!wrappedAsset ||
            e.coinPublicKey === OPENFUND_TOKEN_PUBLIC_KEY ||
            e.coinPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
            <span className="text-xs text-muted">
              <img src="/images/icon-verified.gif" alt="wrapped" className="w-4 h-4" />
            </span>
          )}
        </div>
        {showAssets && (
          <>
            <span className={`text-left font-mono ${highlightColor}`}>
              <LowNumFormatter price={e.priceUsd || 0} className="text-xs" />
            </span>
            <span className={`text-left font-mono ${highlightColor}`}>
              <LowNumFormatter price={e.volumeUsdPast24H || 0} className="text-xs" />
            </span>
            <span className="text-left font-mono">
              {wrappedAsset && wrappedAsset.publicKey !== DESO_TOKEN_PUBLIC_KEY ? (
                <span className="text-xs text-muted">—</span>
              ) : (
                <LowNumFormatter abbreviatePriceThreshold={1000000} price={marketCapUsd} className="text-xs" />
              )}
            </span>
            <span className={`${highlightColor} text-xs font-mono text-left`}>
              {formatDecimalValue(pricePctChange24h, 2, 2)}%
            </span>
          </>
        )}
      </div>
    );
  };

  const triggerButton = useMemo(
    () => (
      <Button
        aria-expanded={menuOpen}
        variant="outline"
        role="combobox"
        className={cn(
          'w-full justify-between bg-accent pl-2 hover:bg-accent hover:border-border',
          !selectedAccount && 'pl-4 text-muted',
          className,
        )}
      >
        {selectedAccount ? (
          <div className="flex items-center gap-2">
            {userElement(selectedAccount, false)}
            <span className="hidden md:flexmt-0.5 text-xs text-muted">
              {shortenLongWord(selectedAccount.coinPublicKey || '')}
            </span>
          </div>
        ) : (
          placeholder
        )}

        <LuChevronsUpDown className="ml-2 size-3 shrink-0 opacity-50" />
      </Button>
    ),
    [selectedAccount],
  );

  const results = searchValue
    ? searchResults.coinStats || []
    : [...pinnedAccounts, ...searchResults.coinStats].filter((project) =>
        (project.coinUsername || '').toLowerCase().includes(searchValue.toLowerCase()),
      );

  return (
    <Popover open={menuOpen} onOpenChange={setMenuOpen}>
      <PopoverTrigger asChild>{triggerButton}</PopoverTrigger>

      <PopoverContent
        className="w-[640px] p-0 bg-background max-w-[95vw] max-h-[80vh] overflow-auto"
        style={
          {
            '--token-info-width': '180px',
            '--price-width': '80px',
            '--volume-width': '80px',
            '--mcap-width': '100px',
            '--change-width': '90px',
          } as React.CSSProperties
        }
      >
        <Command shouldFilter={false} className="min-w-[640px]">
          <div className="relative">
            <CommandInput
              placeholder={placeholder}
              className="h-9 sticky top-0 z-10 bg-background !pl-[32px] rounded-none border-none"
              onInput={(event) => {
                setSearchValue(event.currentTarget.value);
              }}
              value={searchValue}
            />
            <div className="absolute top-[10px] left-2 z-10">
              <LuSearch className="size-4 text-muted" />
            </div>
          </div>

          <div
            style={{
              gridTemplateColumns: `var(--token-info-width) var(--price-width) var(--volume-width) var(--mcap-width) var(--change-width)`,
            }}
            className="grid gap-[16px] px-2 py-2 font-sans text-muted-foreground border-t text-xs sticky top-9 z-10 bg-background"
          >
            <div className="text-left">Token</div>
            <div className="text-left">Price</div>
            <div className="text-left">Volume</div>
            <div className="text-left">Market Cap</div>
            <div className="text-left">24h Vol</div>
          </div>

          <CommandList className="border-t openfund-scrollbar">
            {loadingSearchResults && (
              <Command className="text-muted-foreground">
                <Spinner size={32} className="mx-auto my-2" />
              </Command>
            )}

            {results.length > 0 &&
              results.map((e) => {
                return (
                  <CommandItem
                    onSelect={() => {
                      onSelect?.(e);
                      setMenuOpen(false);
                      setSearchValue('');
                    }}
                    key={e.coinPublicKey}
                    className="p-0 border-b border-border-light rounded-none"
                  >
                    {customLink && e?.coinUsername ? (
                      <RouteLink
                        to={customLink(e.coinUsername)}
                        className="flex cursor-pointer justify-between w-full text-foreground hover:text-foreground p-2"
                      >
                        <span className="text-foreground hover:text-foreground">{userElement(e, true)}</span>
                        <LuCheck
                          className={cn(
                            'mr-2 mt-1 h-4 w-4 flex',
                            selectedAccount?.coinPublicKey === e?.coinPublicKey ? 'opacity-100' : 'opacity-0',
                          )}
                        />
                      </RouteLink>
                    ) : (
                      <div className="flex cursor-pointer justify-between">
                        {userElement(e, true)}
                        <LuCheck
                          className={cn(
                            'mr-2 h-4 w-4 flex',
                            selectedAccount?.coinPublicKey === e?.coinPublicKey ? 'opacity-100' : 'opacity-0',
                          )}
                        />
                      </div>
                    )}
                  </CommandItem>
                );
              })}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default BaseCurrencySelect;
