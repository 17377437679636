import {
  DESO_BTC_PUBLIC_KEY,
  DESO_ETH_PUBLIC_KEY,
  DESO_SOL_PUBLIC_KEY,
  DESO_ZERO_PUBLIC_KEY,
  EXPLORER_URLS,
} from 'constants/AppConstants';
import { DESO_DOLLAR_PROFILE_NAME } from 'constants/AppConstants.dev';
import React, { ReactNode } from 'react';
import { DESO_PROJECT_NAME } from 'constants/TradeConstants';
import { RouteLink } from '../components/core/RouteLink';
import { Routes } from '../RoutePaths';
import { getWrappedAsset } from './deso';
import { InfoTooltip } from 'components/core/InfoTooltip';

const TICKER_TO_CURRENCY_NAME: Record<Ticker, string> = Object.freeze({
  ETH: 'ETH',
  BTC: 'BTC',
  DESO: 'DESO',
  SOL: 'SOL',
  USDC: 'USDC-ETH',
  'USDC-SOL': 'USDC-SOL',
  USDT: 'USDT',
  DUSD: 'DUSDC',
  DBTC: 'DBTC',
  DETH: 'DETH',
  DSOL: 'DSOL',
});

const TICKER_TO_NETWORK_NAME: Record<Ticker, string> = Object.freeze({
  ETH: 'Ethereum',
  BTC: 'Bitcoin',
  DESO: 'DeSo',
  SOL: 'Solana',
  USDC: 'Ethereum',
  'USDC-SOL': 'Solana',
  USDT: 'Ethereum',
  DUSD: 'DeSo',
  DBTC: 'DeSo',
  DETH: 'DeSo',
  DSOL: 'DeSo',
});

export type Ticker = 'ETH' | 'BTC' | 'DESO' | 'USDC' | 'SOL' | 'DUSD' | 'DBTC' | 'DETH' | 'DSOL' | 'USDT' | 'USDC-SOL';

const desoExplorerTypeConversion = {
  tx: 'transaction-id',
  address: 'public-key',
};

export function getExplorerLink(ticker: Ticker, value: string, type: 'tx' | 'address'): string {
  switch (ticker) {
    case 'ETH':
      return `${EXPLORER_URLS.etherscan}/${type}/${value}`;
    case 'BTC':
      return `${EXPLORER_URLS.blockcypher}/${type}/${value}`;
    case 'SOL':
    case 'USDC-SOL':
      const { origin, search } = new URL(EXPLORER_URLS.solana);
      return `${origin}/${type}/${value}${search}`;
    case 'DESO':
    case 'DUSD':
    case 'DBTC':
    case 'DETH':
    case 'DSOL':
      if (desoExplorerTypeConversion[type] === 'transaction-id') {
        return `${EXPLORER_URLS.desoWallet}/activity/transaction/${value}`;
      } else {
        return `${EXPLORER_URLS.desoWallet}/?tab=activity&user=${value}`;
      }
    case 'USDT':
    case 'USDC':
      return `${EXPLORER_URLS.etherscan}/${type}/${value}`;
    default:
      throw new Error('Unsupported ticker');
  }
}

export function wrappedAssetTickerToPublicKey(ticker: Ticker): string {
  switch (ticker) {
    case 'DETH':
      return DESO_ETH_PUBLIC_KEY;
    case 'DBTC':
      return DESO_BTC_PUBLIC_KEY;
    case 'DSOL':
      return DESO_SOL_PUBLIC_KEY;
    case 'DESO':
      return DESO_ZERO_PUBLIC_KEY;
    case 'DUSD':
      return DESO_DOLLAR_PROFILE_NAME;
    default:
      throw new Error('Unsupported ticker');
  }
}

export function getTickerCurrencyName(ticker: Ticker): string {
  const wrappedAsset = getWrappedAsset(ticker, 'heroswapName');
  return wrappedAsset?.heroswapDisplayNameExtended ?? TICKER_TO_CURRENCY_NAME[ticker] ?? '';
}

export function getTickerNetworkName(ticker: Ticker): string {
  return TICKER_TO_NETWORK_NAME[ticker] ?? '';
}

export function formatTicker(ticker: Ticker): string {
  const wrappedAsset = getWrappedAsset(ticker, 'heroswapName');
  if (wrappedAsset) {
    return wrappedAsset.heroswapDisplayNameExtended;
  }

  return ticker === 'DUSD' ? 'DUSDC' : ticker;
}

export function formatTickerWithTooltip(
  ticker: Ticker,
  iconSize: number = 16,
  iconClass: string = 'ml-1',
  reverse: boolean = false,
): string | ReactNode {
  const wrappedAsset = getWrappedAsset(ticker, 'heroswapName');
  const isWrapped = !!wrappedAsset;

  if (!isWrapped) {
    return ticker;
  }

  const tooltipContent = (
    <div className="font-base text-sm font-normal">
      <span>
        USDC, BTC, ETH, and SOL are represented in your wallet as wrapped assets, namely USDC-DESO, BTC-DESO, ETH-DESO,
        and SOL-DESO respectively. This allows you to trade them on the DeSo DEX, which is the first cross-chain
        order-book DEX, and to transact with virtually no gas!
        <br />
        You can convert real assets to wrapped assets, and vice versa, at any time simply by adding funds and cashing
        out in your DeSo wallet.
        <br />
        Give it a try!
      </span>
    </div>
  );

  return (
    <div className={`inline-flex gap-2 items-center ${reverse ? 'flex-row-reverse' : 'flex-row'}`}>
      <span>{wrappedAsset?.heroswapDisplayNameExtended}</span>
      <InfoTooltip
        text={tooltipContent}
        className="max-w-[330px] text-center"
        iconClassName={iconClass}
        iconSize={iconSize}
      />
    </div>
  );
}

export function formatProjectWithTooltip(name: string, iconSize = 32, iconClass = 'ml-2') {
  const wrappedAsset = getWrappedAsset(name);

  if (name === DESO_PROJECT_NAME || !wrappedAsset) {
    return '';
  }

  const projectName = wrappedAsset.displayName;
  const tooltipContent = (
    <div className="font-base text-sm font-normal">
      <span>
        {projectName} on Openfund is actually D{projectName}, which is wrapped {projectName} held in full self-custody
        via your DeSo wallet. For more information on D{projectName}, including the backing assets, see the profile{' '}
      </span>
      <RouteLink to={Routes.profile(wrappedAsset.displayName)} kind="text-only-underline" target="_blank">
        here
      </RouteLink>
    </div>
  );

  return (
    <InfoTooltip
      text={tooltipContent}
      iconSize={iconSize}
      iconClassName={iconClass}
      className="text-center max-w-[250px]"
      side="bottom"
    />
  );
}

export const PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER: Record<string, 'DUSD'> = {
  [DESO_DOLLAR_PROFILE_NAME]: 'DUSD',
};
