import { DESO_DOLLAR_PROFILE_NAME } from 'constants/AppConstants';
import { SupportedDestinationTickers } from 'services/HeroSwapper';
import { FundingRound, FundingRoundPostData } from 'services/Openfund';
import { desoNanosToUSD, desoToUSD, formatUSD, toHex, tokenToBaseUnits } from 'utils/currency';
import { ONE_DAY_MILLIS } from 'utils/date';
import { getTickerCurrencyName, PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER } from 'utils/tickers';
import { GetExchangeRateUpdatedResponse } from '../services/Deso';

export function getDefaultFundingRoundSettings(
  DaoOwnerPkidBase58check: string,
  now: number = Date.now(),
): FundingRoundPostData {
  const thirtyDaysFromNow = now + ONE_DAY_MILLIS * 30;
  return {
    RoundName: 'Round 1',
    RoundStatus: 'OPEN',
    DaoOwnerPkidBase58check,
    AmountToRaiseUsdCents: 0,
    AllowOverflow: true,
    OffChainDesoBalanceNanos: 0,
    ReserveRateBasisPoints: 0,
    GlobalReferralRateBasisPoints: 250,
    StartTime: new Date(now).toISOString(),
    // 30 days from now by default
    EndTime: new Date(thirtyDaysFromNow).toISOString(),
    // 100 tokens per 1 USD by default
    StartDaoCoinBaseUnitsPerTreasuryUnitHex: toHex(tokenToBaseUnits(100)),
    // No bonding curve by default
    PriceIncreaseIncrementBasisPoints: 0,
    PriceIncreaseUnit: 'DAO_COINS',
    PriceIncreaseAmount: 1000,
    IsInvestmentImmediatelyRefundable: true,
    TermsAndConditions: 'https://docs.google.com/document/d/1Almbb-xSm-lVumCs4WKKrWwYpgwmkDM6LJ-WyGBbl8Y',
    TreasuryCurrencyUnit: 'DAO_COIN',
    TreasuryDaoCoin: DESO_DOLLAR_PROFILE_NAME,
  };
}

export function getFormDataFromRound(
  round: FundingRound,
  exchangeRates: GetExchangeRateUpdatedResponse,
): FundingRoundPostData {
  const {
    RoundName,
    RoundStatus,
    DaoOwnerPkidBase58check,
    AmountToRaiseUsdCents,
    AllowOverflow,
    OffChainDesoBalanceNanos,
    ReserveRateBasisPoints,
    GlobalReferralRateBasisPoints,
    StartTime,
    EndTime,
    StartDaoCoinBaseUnitsPerTreasuryUnitHex,
    TreasuryCurrencyUnit,
    TreasuryDaoCoin,
    PriceIncreaseIncrementBasisPoints,
    PriceIncreaseUnit,
    PriceIncreaseAmount,
    IsInvestmentImmediatelyRefundable,
    TermsAndConditions,
  } = round;
  return {
    RoundName: RoundName,
    RoundStatus,
    DaoOwnerPkidBase58check,
    AmountToRaiseUsdCents,
    AllowOverflow,
    OffChainDesoBalanceNanos,
    ReserveRateBasisPoints,
    GlobalReferralRateBasisPoints,
    StartTime,
    EndTime: EndTime && new Date(EndTime).getFullYear() !== 0 ? EndTime : null,
    StartDaoCoinBaseUnitsPerTreasuryUnitHex,
    TreasuryCurrencyUnit,
    TreasuryDaoCoin,
    PriceIncreaseIncrementBasisPoints,
    // NOTE: if the bonding curve is based on amount invested, we always store the unit as deso nanos
    // in the db. On the frontend we use a "pseudo" USD unit to make it easier to understand when
    // inputting the value. We always convert it back to deso nanos when it is persisted.
    PriceIncreaseUnit:
      PriceIncreaseUnit === BONDING_CURVE_UNITS.DESO_NANOS || PriceIncreaseUnit === BONDING_CURVE_UNITS.DESO
        ? 'USD'
        : PriceIncreaseUnit,
    PriceIncreaseAmount:
      PriceIncreaseUnit === BONDING_CURVE_UNITS.DESO_NANOS
        ? desoNanosToUSD(PriceIncreaseAmount, exchangeRates.USDCentsPerDeSoCoinbase)
        : PriceIncreaseUnit === BONDING_CURVE_UNITS.DESO
          ? desoToUSD(PriceIncreaseAmount, exchangeRates.USDCentsPerDeSoCoinbase)
          : PriceIncreaseAmount,
    IsInvestmentImmediatelyRefundable,
    TermsAndConditions,
  };
}

export const BONDING_CURVE_UNITS = Object.freeze({
  DESO: 'DESO',
  DESO_NANOS: 'DESO_NANOS',
  DAYS: 'DAYS',
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
  SECONDS: 'SECONDS',
  DAO_COINS: 'DAO_COINS',
});

export function getBondingCurveUnitText(fundingRound: FundingRound, exchangeRateUSDCentsPerDeso: number) {
  switch (fundingRound.PriceIncreaseUnit) {
    case BONDING_CURVE_UNITS.DAO_COINS:
      return (
        <>
          for every <span className="font-mono text-muted-foreground">${fundingRound.PriceIncreaseAmount}</span>{' '}
          {getTickerCurrencyName(PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER[fundingRound.TreasuryDaoCoin])}{' '}
          contributed
        </>
      );
    case BONDING_CURVE_UNITS.DESO:
      return (
        <>
          for every{' '}
          <span className="font-mono text-muted-foreground">
            {formatUSD(desoToUSD(fundingRound.PriceIncreaseAmount, exchangeRateUSDCentsPerDeso))}
          </span>{' '}
          USD contributed
        </>
      );
    case BONDING_CURVE_UNITS.DESO_NANOS:
      return (
        <>
          for every{' '}
          <span className="font-mono text-muted-foreground">
            {formatUSD(desoNanosToUSD(fundingRound.PriceIncreaseAmount, exchangeRateUSDCentsPerDeso))}
          </span>{' '}
          USD contributed
        </>
      );
    case BONDING_CURVE_UNITS.DAYS:
      return (
        <>
          every <span className="font-mono text-muted-foreground">{fundingRound.PriceIncreaseAmount}</span>{' '}
          {fundingRound.PriceIncreaseAmount === 1 ? 'Day' : 'Days'}
        </>
      );
    case BONDING_CURVE_UNITS.HOURS:
      return (
        <>
          every <span className="font-mono text-muted-foreground">{fundingRound.PriceIncreaseAmount}</span>{' '}
          {fundingRound.PriceIncreaseAmount === 1 ? 'Hour' : 'Hours'}
        </>
      );
    case BONDING_CURVE_UNITS.MINUTES:
      return (
        <>
          every <span className="font-mono text-muted-foreground">{fundingRound.PriceIncreaseAmount}</span>{' '}
          {fundingRound.PriceIncreaseAmount === 1 ? 'Minute' : 'Minutes'}
        </>
      );
    case BONDING_CURVE_UNITS.SECONDS:
      return (
        <>
          every <span className="font-mono text-muted-foreground">{fundingRound.PriceIncreaseAmount}</span>{' '}
          {fundingRound.PriceIncreaseAmount === 1 ? 'Second' : 'Seconds'}
        </>
      );
  }
}

export function hasEndTime(fundingRound: FundingRound): boolean {
  return !!fundingRound.EndTime && fundingRound.EndTime !== '0001-01-01T00:00:00Z';
}

export function getTreasuryCurrency(fundingRound: FundingRound): SupportedDestinationTickers {
  return fundingRound.TreasuryCurrencyUnit === 'DAO_COIN'
    ? PROJECT_TREASURY_PROFILE_NAME_TO_MEGASWAP_TICKER[fundingRound.TreasuryDaoCoin]
    : 'DESO';
}
