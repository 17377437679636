import { createContext, useEffect, useState } from 'react';
import { configure, DeSoNetwork, getUsersStateless, identity, NOTIFICATION_EVENTS } from 'deso-protocol';
import { IS_TESTNET, NODE_URL } from '../constants/AppConstants';
import { openfund } from '../services';
import { OpenfundUser } from '../services/Openfund';
import { initializeTrackingOnce, trackingIdentifyUser } from '../utils/tracking';

type IOpenfundContext = {
  currentUser: OpenfundUser | null;
  setCurrentUser: (currentUser: OpenfundUser | null) => void;
  loadingUser: boolean;
  diamondLevelMap: Record<string, number>;
};

export const OpenfundContext = createContext<IOpenfundContext>({
  currentUser: null,
  setCurrentUser: () => {},
  loadingUser: false,
  diamondLevelMap: {},
});

const fetchSingleProfile = async (key?: string | null) => {
  if (!key) {
    return null;
  }

  return await getUsersStateless({
    PublicKeysBase58Check: [key],
    SkipForLeaderboard: true,
    IncludeBalance: true,
    GetUnminedBalance: true,
  }).then((r) => {
    return r.UserList?.[0] || null;
  });
};

export const OpenfundContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<OpenfundUser | null>(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    configure({
      nodeURI: NODE_URL.replace('/api/v0', ''),
      network: IS_TESTNET ? DeSoNetwork.testnet : DeSoNetwork.mainnet,
      spendingLimitOptions: {
        GlobalDESOLimit: 5 * 1e9,
        TransactionCountLimitMap: {
          UPDATE_PROFILE: 'UNLIMITED',
          FOLLOW: 'UNLIMITED',
          BASIC_TRANSFER: 'UNLIMITED',
          AUTHORIZE_DERIVED_KEY: 'UNLIMITED',
        },
      },
    });

    initializeTrackingOnce();

    identity.subscribe(({ event, currentUser, alternateUsers }) => {
      if (!currentUser && event === NOTIFICATION_EVENTS.SUBSCRIBE) {
        setLoadingUser(false);
        return;
      }

      if (!currentUser && !alternateUsers) {
        openfund.updateCurrentUser(currentUser).then((openfundUser) => {
          setUser(openfundUser);
          setLoadingUser(false);
          return;
        });
      }

      if (event === NOTIFICATION_EVENTS.AUTHORIZE_DERIVED_KEY_START && currentUser) {
        setLoadingUser(true);
        return;
      }

      if (
        currentUser &&
        currentUser?.publicKey !== user?.PublicKeyBase58Check &&
        [
          NOTIFICATION_EVENTS.SUBSCRIBE,
          NOTIFICATION_EVENTS.LOGIN_END,
          NOTIFICATION_EVENTS.CHANGE_ACTIVE_USER,
          NOTIFICATION_EVENTS.AUTHORIZE_DERIVED_KEY_FAIL,
          NOTIFICATION_EVENTS.AUTHORIZE_DERIVED_KEY_END,
        ].includes(event)
      ) {
        Promise.all([fetchSingleProfile(currentUser.publicKey)])
          .then(([profile]) => {
            openfund.updateCurrentUser(profile).then((openfundUser) => {
              setUser(openfundUser);
              setLoadingUser(false);

              trackingIdentifyUser(currentUser.publicKey, {
                isLoggedIn: true,
                hasProfile: true,
              });
            });
          })
          .catch(() => {
            trackingIdentifyUser(currentUser.publicKey, {
              isLoggedIn: true,
              hasProfile: false,
            });
          });
      }
    });
  }, []);

  return (
    <OpenfundContext.Provider
      value={{
        currentUser: user,
        setCurrentUser: setUser,
        loadingUser,
        diamondLevelMap: {
          '1': 50000,
          '2': 500000,
          '3': 5000000,
          '4': 50000000,
          '5': 500000000,
          '6': 5000000000,
          '7': 50000000000,
          '8': 500000000000,
        },
      }}
    >
      {children}
    </OpenfundContext.Provider>
  );
};
