import { BalanceEntryResponse, ProfileEntryResponse } from 'deso-protocol';
import { ProjectPublicKeysPurchasedKey } from '../services/Deso';
import { GetTokensValue } from '../components/pages/ProfileDetail';

export function hasPurchasedProject(balanceEntry: BalanceEntryResponse, profile: ProfileEntryResponse | null): boolean {
  if (
    balanceEntry.CreatorPublicKeyBase58Check === balanceEntry.HODLerPublicKeyBase58Check ||
    balanceEntry.HasPurchased
  ) {
    return true;
  }

  if (!profile || !profile.ExtraData) {
    return false;
  }

  const projectsPurchased = new Set((profile.ExtraData[ProjectPublicKeysPurchasedKey] || '').split(','));
  return projectsPurchased.has(balanceEntry.CreatorPublicKeyBase58Check);
}

export function sortHoldersByTokenValue(holders: BalanceEntryResponse[] | null = [], sortType: string = 'wealth') {
  const copy = holders ? holders.slice() : [];

  return copy.sort((ii, jj) => {
    let jjVal = sortType === 'coin_balance' ? Number(jj.BalanceNanosUint256.toString()) : GetTokensValue(jj);
    let iiVal = sortType === 'coin_balance' ? Number(ii.BalanceNanosUint256.toString()) : GetTokensValue(ii);
    if (iiVal === jjVal) {
      return (ii.ProfileEntryResponse?.Username.toLowerCase() || '') >
        (jj.ProfileEntryResponse?.Username.toLowerCase() || '')
        ? 1
        : -1;
    }
    return jjVal > iiVal ? 1 : -1;
  });
}
