import type { ComponentProps, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { useEnterKeyTrigger } from '../hooks/use-handle-keydown';

type StopPropagationWrapperProps = {
  children: ReactNode;
  onClick?: (e: MouseEvent) => void;
  className?: string;
} & ComponentProps<'div'>;

export const StopPropagationWrapper = ({
  onClick = () => {},
  className = '',
  ...rest
}: StopPropagationWrapperProps) => {
  const onKeyDown = useEnterKeyTrigger(onClick);

  return (
    <div
      className={className}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onKeyDown={(e: KeyboardEvent) => {
        e.stopPropagation();
        onKeyDown(e);
      }}
      {...rest}
    />
  );
};

StopPropagationWrapper.displayName = 'StopPropagationWrapper';
