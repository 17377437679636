import { Button } from 'components/shadcn/ui/button';
import { Input } from 'components/core/Input';
import { Spinner } from 'components/core/Spinner';
import { useSettingsContext } from 'components/pages/Settings';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useToast } from 'hooks/useToast';
import { useEffect, useRef, useState } from 'react';
import { openfund } from 'services';
import { getErrorMsg } from 'utils/getErrorMsg';

interface FormState {
  preferences: Array<string>;
}

export function Notifications() {
  const { setHeadingSuffix } = useSettingsContext();
  useDocumentTitle('Edit my notification preferences');
  useEffectOnce(() => setHeadingSuffix('Notification Preferences'));
  const { currentUser } = useSettingsContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading] = useState(false);
  const toast = useToast();
  const [formState, setFormState] = useState<FormState>({ preferences: [] });

  useEffect(() => {
    openfund.getEmailNotificationPreferences(currentUser?.PublicKeyBase58Check).then((data) => {
      setFormState((prevState) => ({ ...prevState, preferences: data.Preferences }));
    });
  }, [currentUser]);

  return (
    <div className="w-full">
      {isLoading ? (
        <div className="text-center py-8">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2 text-xl font-semibold text-muted-foreground mb-6">
            Notification preferences
          </div>

          <form
            ref={formRef}
            onSubmit={async (ev) => {
              ev.preventDefault();
              if (formState.preferences.length > 0) {
                try {
                  await openfund.saveEmailNotificationPreferences(
                    currentUser?.PublicKeyBase58Check,
                    formState.preferences,
                  );
                  toast.show({
                    message: 'Notification preferences were saved',
                    type: 'success',
                    sticky: false,
                  });
                } catch (e) {
                  toast.show({
                    message: getErrorMsg(e),
                    type: 'error',
                  });
                }
              }
            }}
          >
            <div className="flex text-left border border-border rounded-2xl p-4 md:p-6 w-full mb-6">
              <Input
                type="checkbox"
                className="cursor-pointer w-auto"
                checked={formState.preferences.includes('UNSUBSCRIBE_ALL')}
                labelText="Unsubscribe from all email notifications"
                labelSrOnly={true}
                textSize="sm"
                onChange={(ev) => {
                  if (ev.currentTarget.checked) {
                    setFormState({ ...formState, preferences: ['UNSUBSCRIBE_ALL'] });
                  } else {
                    setFormState({ ...formState, preferences: ['SUBSCRIBE_ALL'] });
                  }
                }}
              />
              <span className="ml-3">Unsubscribe from all email notifications</span>
            </div>

            <Button disabled={formState.preferences.length === 0} type="submit">
              Save Changes
            </Button>
          </form>
        </>
      )}
    </div>
  );
}

export default Notifications;
