import { useEffectOnce } from 'hooks/useEffectOnce';
import { ReactNode, useRef, useState } from 'react';
import { IconType } from 'react-icons';
import { classNames } from 'utils/classNames';
import { v4 as uuid } from 'uuid';

type CommonProps = {
  id?: string;
  labelText: ReactNode;
  labelSrOnly?: boolean;
  state?: 'default' | 'success' | 'error' | 'warning';
  hint?: ReactNode;
  theme?: 'dark' | 'light';
  containerClasses?: string;
};
const OUTLINE_CLASSES = Object.freeze({
  success: 'border border-green focus:border-green focus:ring-green focus:outline-none',
  error: 'border border-red focus:border-red focus:ring-red focus:outline-none',
  warning: 'border border-yellow focus:border-yellow focus:ring-yellow focus:outline-none',
  default: 'focus:ring-black placeholder:text-gray',
});
const INPUT_PADDING = Object.freeze({
  sm: 'p-2',
  md: 'p-4',
});
const TEXT_SIZES = Object.freeze({
  sm: 'text-sm',
  md: 'text-md',
});
export type InputProps = {
  type?: string;
  textSize?: 'sm' | 'md';
  leftIcon?: IconType;
  rightContent?: ReactNode;
  onEnterPressed?: (text: string) => void;
} & CommonProps &
  React.InputHTMLAttributes<HTMLInputElement>;
export function Input({
  id,
  type = 'text',
  placeholder = '',
  className = '',
  labelText,
  labelSrOnly = false,
  autoFocus = false,
  state = 'default',
  textSize = 'md',
  hint,
  required = false,
  containerClasses,
  theme = 'light',
  leftIcon,
  rightContent,
  onEnterPressed = (text: string) => {},
  ...rest
}: InputProps) {
  const baseClasses = classNames(
    OUTLINE_CLASSES[state],
    INPUT_PADDING[textSize],
    TEXT_SIZES[textSize],
    'w-full text-foreground bg-input border-0 rounded-xl',
  );
  const classes = classNames(className, baseClasses);
  const labelClasses = classNames(
    'pb-2',
    'block',
    'text-sm',
    'text-foreground',
    'font-medium',
    required ? 'required' : '',
    TEXT_SIZES[textSize],
    labelSrOnly ? 'sr-only' : '',
  );
  const hintId = hint && uuid();
  const inputId = id ?? uuid();
  const LeftIcon: IconType | undefined = leftIcon;

  return (
    <div className={classNames(theme === 'dark' && 'dark', theme === 'light' && 'text-black', containerClasses)}>
      <label className={labelClasses} htmlFor={inputId}>
        {labelText}
      </label>
      <span className="relative">
        {LeftIcon && <LeftIcon className={classNames('absolute text-gray top-1 left-3 -mt-1 h-5 w-5')} />}
        <input
          id={inputId.toString()}
          type={type}
          placeholder={placeholder}
          className={classNames(
            LeftIcon && 'pl-10',
            classes,
            rest.disabled && 'bg-gray-faint placeholder:text-gray text-gray',
          )}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onEnterPressed((e.target as any).value || '');
            }
          }}
          autoFocus={autoFocus}
          required={required}
          aria-required={required}
          {...rest}
          aria-describedby={hintId?.toString()}
          aria-invalid={state === 'error'}
        />
        {rightContent}
      </span>
      <div id={hintId?.toString()} className="mx-1 mt-1">
        {hint && (
          <div role="alert" className="text-muted text-xs leading-5 my-2">
            {hint}
          </div>
        )}
      </div>
    </div>
  );
}

export type TextAreaProps = { autoResize?: boolean } & CommonProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>;
export function TextArea({
  id,
  placeholder = '',
  className = '',
  labelText,
  labelSrOnly = false,
  autoFocus = false,
  state = 'default',
  autoResize = false,
  onInput,
  theme = 'light',
  ...rest
}: TextAreaProps) {
  const baseClasses = classNames(OUTLINE_CLASSES[state], autoResize ? 'resize-none' : '');
  const classes = className && theme === 'dark' ? `${className} ${baseClasses}` : `${className} ${baseClasses}`;
  const baseLabelClasses = `pb-2 font-medium`;
  const labelClasses = labelSrOnly ? `sr-only ${baseLabelClasses}` : baseLabelClasses;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const maxLength = rest.maxLength ?? 0;
  const [charCount, setCharCount] = useState(getCharCount(rest.value?.toString()));
  const [charCountColor, setCharCountColor] = useState(getCharCountColor(maxLength, charCount));

  const setHeight = (el: HTMLTextAreaElement, offset: number) => {
    el.style.height = 'auto';
    el.style.height = `${el.scrollHeight + offset}px`;
  };

  useEffectOnce(() => {
    if (autoResize) {
      const el = textAreaRef.current;
      if (!el) return;
      const offset = el.offsetHeight - el.clientHeight;
      setHeight(el, offset);
      el.addEventListener('input', () => setHeight(el, offset), false);
    }
  });

  return (
    <div className="flex flex-col w-full">
      <label className={labelClasses} htmlFor={id}>
        {labelText}
      </label>
      <textarea
        ref={textAreaRef}
        id={id}
        placeholder={placeholder}
        className={classes}
        autoFocus={autoFocus}
        onInput={(...args) => {
          onInput?.(...args);
          if (maxLength !== undefined) {
            const currentCharCount = getCharCount(args[0].currentTarget.value);
            setCharCount(currentCharCount);
            setCharCountColor(getCharCountColor(maxLength, currentCharCount));
          }
        }}
        {...rest}
      />
      {maxLength > 0 && (
        <div className={`ml-auto mt-2 text-xs ${charCountColor}`}>
          {charCount} / {maxLength}
        </div>
      )}
    </div>
  );
}

function getCharCount(text: string | undefined) {
  if (!text) return 0;
  return Array.from(text).length;
}

function getCharCountColor(maxLength: number, currentCharCount: number) {
  const percentOfMaxUsed = (currentCharCount / maxLength) * 100;
  if (percentOfMaxUsed > 90) {
    return 'text-red';
  } else if (percentOfMaxUsed > 60) {
    return 'text-yellow';
  }
  return 'text-black';
}
