'use client';

import { useEffect, useRef, useState } from 'react';
import { startViewTransition } from 'use-start-view-transition';
import { classNames } from 'utils/classNames';
import { Button } from '../../components/shadcn/ui/button';

type TruncatedContentProps = {
  children: React.ReactNode;
  maxHeight?: number;
  className?: string;
  height?: number;
};

// For contents barely exceeding the max height, we allow a little more to be shown,
// so we don't crop tiny amount of contents.
const VIGGLE_ROOM = 100;

const getShadowPercentages = (maxHeight: number) => {
  const start = Math.floor(80 - (700 - maxHeight) / 20);
  const stop = Math.floor(90 - (700 - maxHeight) / 20);

  return [start, stop];
};

export const TruncatedContent = ({ children, maxHeight = 165, className = '', height }: TruncatedContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [showReveal, setShowReveal] = useState(false);
  const [hideTruncate, setHideTruncate] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [shadowPercentageStart, shadowPercentageStop] = getShadowPercentages(maxHeight ?? 0);

  useEffect(() => {
    if (!maxHeight || !contentRef.current) return;

    const finalHeight = height || contentRef.current.offsetHeight;
    setShowReveal(finalHeight >= maxHeight);
    setHideTruncate(finalHeight - maxHeight <= VIGGLE_ROOM);
  }, [height, maxHeight]);

  const handleRevealClick = () => {
    startViewTransition(() => {
      if (expanded) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    });
  };

  const allowToReveal = showReveal && (!hideTruncate || expanded);

  return (
    <div className="relative">
      <div
        className="h-full overflow-hidden"
        style={{
          maxHeight: !hideTruncate && !expanded ? maxHeight : undefined,
          maskImage:
            allowToReveal && !expanded
              ? `linear-gradient(180deg, #000000FF ${shadowPercentageStart}%, #00000000 ${shadowPercentageStop}%)`
              : undefined,
        }}
      >
        <div ref={contentRef} className={className}>
          {children}
        </div>
      </div>
      {allowToReveal && (
        <Button
          onClick={handleRevealClick}
          variant="link"
          className={classNames('bottom-0 left-0 pl-0', expanded ? 'static' : 'absolute')}
        >
          {expanded ? 'Show Less...' : 'Show More...'}
        </Button>
      )}
    </div>
  );
};
