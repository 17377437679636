import { type GetCoinPropertiesResponse } from 'deso-protocol';
import type { ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';
import { LuCheck, LuX } from 'react-icons/lu';

import {
  basisPointsToPercent,
  dateNanosToInteger,
  formatDecimalValue,
  formatUSD,
  formatUSDCents,
  getVisiblePrice,
  tokenNanosToInteger,
} from '../../utils/currency';
import LowNumFormatter from './LowNumFormatter';
import { InfoTooltip } from '../core/InfoTooltip';
import { CopyToClipboard } from './CopyToClipboard';
import { Skeleton } from '../shadcn/ui/skeleton';
import { cn } from '../../utils/shadcn';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import {
  DESO_EXPLORER_URL,
  DESO_ZERO_PUBLIC_KEY,
  EXPLORER_URLS,
  FOCUS_TOKEN_PUBLIC_KEY,
} from '../../constants/AppConstants';
import { OpenfundContext } from '../../contexts/OpenfundContext';
import { useCoinProperties } from '../../hooks/useCoinProperties';
import { useTokenMarketData } from '../../hooks/useTokenMarketData';
import { Account } from '../../graphql/codegen/graphql';
import { TimestampTooltip } from './TimestampTooltip';
import { getErrorMsg } from '../../utils/getErrorMsg';
import { useToast } from 'hooks/useToast';
import PricePerToken from './PricePerToken';
import { getFormattedDate } from '../../utils/date';
import { APP_NAME_GENERIC, FOCUS_TOKEN_SUPPLY } from '../../utils/constants';
import { getUserName } from '../../utils/user';
import { pluralize } from '../../utils/text';
import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/shadcn/ui/button';
import { FollowButton } from './FollowButton';
import { useIsMounted } from 'hooks/useIsMounted';
import { deso } from 'services';
import { ExternalLink } from 'components/core/ExternalLink';
import { Link } from 'react-router-dom';
import { getWrappedAsset, getWrappedAssetIcon } from '../../utils/deso';
import { USD_TICKER } from '../../constants/TradeConstants';
import { getMarkdownBody } from '../../utils/markdown';
import { TruncatedContent } from './TruncatedContent';

type CoinInfoWidgetProps = {
  publicKey: string;
  fallback: ReactNode;
};

const NOT_FOUND_LABEL = 'N/A';

const getFormattedValue = (value: number | string | null | undefined, formatter: (val: number) => string): string =>
  Number.isFinite(+value!) ? formatter(+value!) : NOT_FOUND_LABEL;

export const CoinInfoWidget = ({ publicKey, fallback }: CoinInfoWidgetProps) => {
  const { loadingExchangeRates, exchangeRates, quoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);
  const { currentUser } = useContext(OpenfundContext);

  const toast = useToast();
  const [showFounderCoins, setShowFounderCoins] = useState(false);
  const [showTotalCoins, setShowTotalCoins] = useState(false);
  const [showAmmConfig, setShowAmmConfig] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  const {
    data: tokenMarketData,
    loading: tokenMarketDataLoading,
    fetchTokenMarketData,
    refetch: refetchTokenMarketData,
  } = useTokenMarketData({
    publicKey,
    quoteCurrencyPublicKey,
  });

  const { data, loading } = useCoinProperties({ publicKey });

  const focusExchangeRate = exchangeRates[FOCUS_TOKEN_PUBLIC_KEY];

  const isMounted = useIsMounted();

  const getTokenData = async () => {
    if (!data || !publicKey) {
      return;
    }

    try {
      await fetchTokenMarketData(data.coinProperties?.AmmConfigs?.[0]?.AmmPublicKey);
    } catch (e) {
      toast.show({ message: getErrorMsg(e), type: 'error' });
    }
  };

  useEffect(() => {
    getTokenData();
  }, [data, publicKey, currentUser?.PublicKeyBase58Check]);

  useEffect(() => {
    if (!data?.hasCoin || !data.coinProperties.RunAt[0]) return;

    const checkMarketOpen = async (intervalId?: number) => {
      const target = new Date(data.coinProperties.RunAt[0]);
      const now = new Date();
      const diffInSeconds = Math.floor((target.getTime() - now.getTime()) / 1000);

      if (diffInSeconds <= 0) {
        if (!refetching) {
          setRefetching(true);
          if (intervalId) {
            clearInterval(intervalId);
          }
          await refetchTokenMarketData();
        }
      }
    };

    checkMarketOpen();

    const intervalId = window.setInterval(() => {
      checkMarketOpen(intervalId);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [data, refetchTokenMarketData]);

  useEffect(() => {
    if (publicKey && data?.coinProperties?.Profile?.Username) {
      Promise.all([
        deso.getFollowerCountByUsername(data.coinProperties.Profile.Username),
        deso.getFollowingCountByUsername(data.coinProperties.Profile.Username),
      ])
        .then(([followerCountResponse, followingCountResponse]) => {
          if (isMounted.current) {
            setFollowerCount(followerCountResponse);
            setFollowingCount(followingCountResponse);
          }
        })
        .catch((e) => {
          if (isMounted.current) {
            toast.show({ message: getErrorMsg(e), type: 'error' });
          }
        });
    }
  }, [publicKey, data?.coinProperties?.Profile?.Username]);

  if (loading || !data || tokenMarketDataLoading || loadingExchangeRates || !focusExchangeRate)
    return (
      <div className="flex flex-col gap-4">
        <Skeleton className="h-[285px]" />
        <Skeleton className="h-[140px]" />
        <Skeleton className="h-[380px]" />
      </div>
    );

  const coinProperties = data.coinProperties;
  const price = data.coinPrice;
  const hasCoin = data.hasCoin;

  if (!loading && !hasCoin) return <>Loading...</>;

  // Price and Token Summary
  const visiblePrice = price ? getVisiblePrice(price) : 0;
  const tokenSummary = tokenMarketData?.tokenMarketVolumeSummaries?.nodes[0];
  const tokenFounderSummary = tokenSummary?.coin1Account?.founderBalance.nodes[0];
  const currentUserBalance = Number(tokenSummary?.coin1Account?.userBalance.nodes[0]?.totalBalance ?? '0');

  // AMM Configuration
  const ammConfig = coinProperties.AmmConfigs?.length ? coinProperties.AmmConfigs[0] : null;

  // Market Cap and Supply Fields
  const marketCapField = Number(tokenSummary?.marketCapUsdCents ?? '0');
  const totalSupplyField = Number(tokenSummary?.totalSupplyNanos ?? '0');
  const balanceField = tokenNanosToInteger(currentUserBalance);
  const ownershipField = (tokenNanosToInteger(currentUserBalance) / tokenNanosToInteger(totalSupplyField)) * 100;
  const totalHoldersField = tokenSummary?.hodlerCount || 0;

  // Founder Tokens
  const unlockedFounderTokenNanos = Number(tokenFounderSummary?.unlockedBalanceNanos ?? '0');
  const unlockedFounderTokenField = tokenNanosToInteger(unlockedFounderTokenNanos);
  const unlockFounderTokenSupplyField = (unlockedFounderTokenNanos * 100) / totalSupplyField;

  const lockedFounderTokenNanos = Number(tokenFounderSummary?.lockedBalanceNanos ?? '0');
  const lockedFounderTokenField = tokenNanosToInteger(lockedFounderTokenNanos);
  const lockFounderTokenSupplyField = (lockedFounderTokenNanos * 100) / totalSupplyField;

  const nextFounderUnlockDateField = dateNanosToInteger(
    Number(tokenSummary?.coin1Account?.nextFounderUnlock?.nodes[0]?.unlockTimestampNanoSecs ?? '0'),
  );

  const totalBalanceField = Number(tokenFounderSummary?.totalBalance ?? '0');
  const totalFounderTokensField = tokenNanosToInteger(totalBalanceField);
  const totalFounderTokensSupplyField = (totalBalanceField * 100) / totalSupplyField;

  // Unlocked and Locked Tokens
  const unlockedTokensNanos =
    Number(tokenSummary?.totalSupplyUnlockedNanos ?? '0') -
    Number(tokenSummary?.coin1Account?.ammBalance?.nodes[0]?.unlockedBalanceNanos ?? '0');
  const unlockedTokensField = tokenNanosToInteger(unlockedTokensNanos);
  const unlockedTokensSupplyField = (unlockedTokensNanos * 100) / totalSupplyField;

  const lockedTokensNanos =
    Number(tokenSummary?.totalSupplyLockedNanos ?? '0') -
    Number(tokenSummary?.coin1Account?.ammBalance?.nodes[0]?.lockedBalanceNanos ?? '0');
  const lockedTokensField = tokenNanosToInteger(lockedTokensNanos);
  const lockedTokensSupplyField = (lockedTokensNanos * 100) / totalSupplyField;

  const nextUnlockDateField = dateNanosToInteger(
    Number(tokenSummary?.coin1Account?.nextUnlock?.nodes[0]?.unlockTimestampNanoSecs ?? '0'),
  );

  // Tokens in AMM
  const tokensInAmmNanos = Number(tokenSummary?.coin1Account?.ammBalance?.nodes[0]?.totalBalance ?? '0');
  const tokensInAmmField = tokenNanosToInteger(tokensInAmmNanos);
  const tokensInAmmSupplyField = (tokensInAmmNanos * 100) / totalSupplyField;

  const username = getUserName({
    account: {
      publicKey: coinProperties.Profile.PublicKeyBase58Check,
      username: coinProperties.Profile.Username,
      extraData: coinProperties.Profile.ExtraData,
      description: coinProperties.Profile.Description,
    } as Account,
    customPrefix: '$',
  });

  const wrappedAsset = getWrappedAsset(coinProperties.Profile.Username);
  const isWrapped = !!wrappedAsset;

  return (
    <div className="mb-12 flex flex-col gap-4">
      <div className="flex flex-col border border-border rounded-xl">
        <div className="w-full text-left flex p-4 border-b border-border">
          <div className="text-left flex lg:flex-col 2xl:flex-row items-center gap-4 w-full">
            <Avatar
              src={isWrapped ? getWrappedAssetIcon(wrappedAsset) : coinProperties.Profile.PublicKeyBase58Check}
              className="bg-background w-[70px] h-[70px]"
            />
            <div className="flex flex-col w-full">
              <div className="flex flex-row lg:flex-col 2xl:flex-row items-center w-full relative">
                <div className="flex items-start flex-col m-0 lg:m-auto 2xl:m-0">
                  <h2 className="text-lg font-bold text-muted-foreground font-sans">
                    {isWrapped
                      ? wrappedAsset.displayName
                      : coinProperties.Profile.ExtraData?.DisplayName || coinProperties.Profile.Username}
                  </h2>
                  <span className="font-medium text-sm text-muted">${coinProperties.Profile.Username}</span>
                </div>
                <div className="absolute lg:relative 2xl:absolute w-auto lg:w-full 2xl:w-auto right-0 top-0 m-0 lg:my-4 2xl:my-0">
                  <FollowButton
                    className="w-auto lg:block lg:w-full 2xl:w-auto"
                    followeePublicKey={coinProperties.Profile.PublicKeyBase58Check}
                  />
                </div>
              </div>
              <div className="flex flex-row lg:flex-col 2xl:flex-row text-left mt-1 items-center gap-4 lg:gap-2 2xl:gap-4 text-sm">
                <div className="flex items-center">
                  <span className="text-muted-foreground font-bold inline-block mr-1">
                    {followerCount.toLocaleString('en-US')}
                  </span>{' '}
                  <span className="text-muted">Follower{followerCount !== 1 && 's'}</span>
                </div>
                <div className="flex items-center">
                  <span className="text-muted-foreground font-bold inline-block mr-1">
                    {followingCount.toLocaleString('en-US')}
                  </span>{' '}
                  <span className="text-muted">Following</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {coinProperties.Profile.Description && (
          <div className="text-sm text-muted p-4 pb-0">
            <TruncatedContent>{getMarkdownBody(coinProperties.Profile.Description ?? '')}</TruncatedContent>
          </div>
        )}
        <div className="text-sm text-muted-foreground font-bold p-4">
          <div className="flex items-center gap-2 flex-wrap">
            <Link
              to={`/profile/${isWrapped ? wrappedAsset?.displayName : coinProperties.Profile.Username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="xs" variant="outline">
                Profile
              </Button>
            </Link>
            <ExternalLink
              href={`${EXPLORER_URLS.desoWallet}/?user=${coinProperties.Profile.PublicKeyBase58Check}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="xs" variant="outline">
                Wallet
              </Button>
            </ExternalLink>
            <ExternalLink
              href={`${DESO_EXPLORER_URL}/u/${coinProperties.Profile.PublicKeyBase58Check}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="xs" variant="outline">
                Activity
              </Button>
            </ExternalLink>
            <Link
              to={`/profile/${isWrapped ? wrappedAsset?.displayName : coinProperties.Profile.Username}?tab=Holders`}
              target="_blank"
            >
              <Button size="xs" variant="outline">
                Holders ({totalHoldersField})
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="grid w-full items-start rounded-xl border bg-accent">
        <div className="flex flex-col items-start text-left text-xs">
          <PreviewRowItem label="Name" value={username} />
          {!isWrapped && (
            <PreviewRowItem
              label="Display Name"
              value={coinProperties.Profile.ExtraData?.DisplayName ?? NOT_FOUND_LABEL}
            />
          )}
          <PreviewRowItem
            label="Address"
            value={
              <CopyToClipboard label="" text={coinProperties.Profile.PublicKeyBase58Check} width={6} compact={true} />
            }
          />
          {!isWrapped && (
            <PreviewRowItem
              label="Category"
              value={coinProperties.Profile.ExtraData?.CoinCategoryExtraDataKey ?? NOT_FOUND_LABEL}
            />
          )}
          {data.ammRunning && (
            <>
              <PreviewRowItem
                label="Price"
                value={
                  visiblePrice ? (
                    <LowNumFormatter
                      price={visiblePrice}
                      abbreviatePriceThreshold={Number.MAX_SAFE_INTEGER}
                      className="text-xs"
                    />
                  ) : (
                    NOT_FOUND_LABEL
                  )
                }
              />
              {!isWrapped &&
                (publicKey === FOCUS_TOKEN_PUBLIC_KEY ? (
                  <>
                    {/* Special MC treatment for FOCUS token */}
                    <PreviewRowItem
                      label="Circulating Market Cap"
                      value={visiblePrice ? formatUSD(visiblePrice * FOCUS_TOKEN_SUPPLY) : NOT_FOUND_LABEL}
                      tooltipText={`This is the total number of UNLOCKED coins, times the market price.`}
                    />
                    <PreviewRowItem
                      label="Fully Diluted Market Cap"
                      value={getFormattedValue(marketCapField, formatUSDCents)}
                      tooltipText={`This is the total number of coins, including locked coins, times the market price.`}
                    />
                  </>
                ) : (
                  <PreviewRowItem
                    label="Market Cap"
                    value={getFormattedValue(marketCapField, formatUSDCents)}
                    tooltipText={`Market Caps shown in ${APP_NAME_GENERIC} are computed by multiplying the total number of tokens by the price.`}
                  />
                ))}
              <PreviewRowItem
                label="Your Balance"
                value={
                  <div className="text-right">
                    <div className="flex items-center justify-end gap-x-1">
                      <LowNumFormatter price={balanceField} highPriceThreshold={10} isUsd={false} className="text-xs" />{' '}
                      {username}
                    </div>
                    <div className="inline-block text-muted hover:text-foreground">
                      <LowNumFormatter
                        price={balanceField * visiblePrice}
                        className="text-xs"
                        highPriceThreshold={10}
                      />{' '}
                      {USD_TICKER}
                    </div>
                  </div>
                }
              />
              {(!isWrapped || wrappedAsset?.publicKey === DESO_ZERO_PUBLIC_KEY) && (
                <PreviewRowItem
                  label="Your Ownership"
                  value={
                    ownershipField ? (
                      <>
                        <LowNumFormatter price={ownershipField} isUsd={false} className="text-xs" />%
                      </>
                    ) : (
                      '0%'
                    )
                  }
                />
              )}
              <PreviewRowItem
                label="Total Holders"
                value={
                  <>
                    {formatDecimalValue(totalHoldersField)} <br />
                    <Link
                      to={`/profile/${isWrapped ? wrappedAsset?.displayName : coinProperties.Profile.Username}?tab=Holders`}
                      className="text-muted hover:text-foreground"
                    >
                      View Holders
                    </Link>
                  </>
                }
              />
            </>
          )}
        </div>
      </div>

      {(totalFounderTokensField || tokenSummary?.totalSupplyNanos) && !isWrapped && (
        <>
          <h2 className="flex items-center gap-2 text-xs font-semibold text-muted">
            Supply Summary{' '}
            <InfoTooltip
              iconSize={14}
              text={
                <>
                  <p className="mb-3">
                    Tokens can either be unlocked, locked, or held by the AMM. Since the AMM holds coins solely to
                    provide liquidity, AMM coins are considered separately from other tokens.
                  </p>
                </>
              }
            />
          </h2>
          <div className="grid w-full items-start rounded-xl border bg-accent">
            <div className="flex flex-col items-start text-left text-xs">
              {Number.isFinite(totalFounderTokensField) && (
                <>
                  <div className="flex w-full items-center justify-between p-3 pb-0">
                    <h2 className="flex items-center gap-2 text-xs font-semibold">
                      Founder Supply{' '}
                      <InfoTooltip
                        iconSize={14}
                        text={<p>This is the number of tokens held by the founder who created this token.</p>}
                      />
                    </h2>
                    <div>
                      <button
                        onClick={() => setShowFounderCoins(!showFounderCoins)}
                        className="text-xs text-muted underline underline-offset-4 hover:text-muted-foreground"
                      >
                        {showFounderCoins ? 'Hide' : 'Details'}
                      </button>
                    </div>
                  </div>
                  {showFounderCoins && (
                    <>
                      <PreviewRowItem
                        label="Unlocked Founder Tokens"
                        value={getFormattedValue(unlockedFounderTokenField, formatDecimalValue)}
                        valueCaption={getFormattedValue(
                          unlockFounderTokenSupplyField,
                          (val) => `${formatDecimalValue(val)}%`,
                        )}
                        tooltipText={`Unlocked founder coins is the number of ${username} tokens that have been unlocked from the founder supply.`}
                      />
                      <PreviewRowItem
                        label="Locked Founder Tokens"
                        value={getFormattedValue(lockedFounderTokenField, formatDecimalValue)}
                        valueCaption={getFormattedValue(
                          lockFounderTokenSupplyField,
                          (val) => `${formatDecimalValue(val)}%`,
                        )}
                      />

                      {lockedFounderTokenField !== 0 && (
                        <PreviewRowItem
                          label="Next Unlock Date"
                          value={
                            nextFounderUnlockDateField ? (
                              <TimestampTooltip timestamp={new Date(nextFounderUnlockDateField).toISOString()}>
                                <span>
                                  {getFormattedDate(nextFounderUnlockDateField, {
                                    hour: undefined,
                                    minute: undefined,
                                  })}
                                </span>
                              </TimestampTooltip>
                            ) : (
                              NOT_FOUND_LABEL
                            )
                          }
                        />
                      )}
                    </>
                  )}
                  <PreviewRowItem
                    label="Total Founder Tokens"
                    value={getFormattedValue(totalFounderTokensField, formatDecimalValue)}
                    valueCaption={getFormattedValue(
                      totalFounderTokensSupplyField,
                      (val) => `${formatDecimalValue(val)}%`,
                    )}
                  />
                </>
              )}
              {tokenSummary?.totalSupplyNanos && (
                <>
                  <div className="flex w-full items-center justify-between p-3 pb-0">
                    <h2 className="flex items-center gap-2 text-xs font-semibold">
                      Total Supply{' '}
                      <InfoTooltip
                        iconSize={14}
                        text={
                          <p>
                            This is the total number of tokens that currently exist, including tokens that are held by
                            the AMM to provide liquidity.
                          </p>
                        }
                      />
                    </h2>
                    <div>
                      <button
                        onClick={() => setShowTotalCoins(!showTotalCoins)}
                        className="text-xs text-muted underline underline-offset-4"
                      >
                        {showTotalCoins ? 'Hide' : 'Details'}
                      </button>
                    </div>
                  </div>
                  {showTotalCoins && (
                    <>
                      <PreviewRowItem
                        label="Unlocked Tokens"
                        value={getFormattedValue(unlockedTokensField, formatDecimalValue)}
                        valueCaption={getFormattedValue(
                          unlockedTokensSupplyField,
                          (val) => `${formatDecimalValue(val)}%`,
                        )}
                      />
                      <PreviewRowItem
                        label="Locked Tokens"
                        value={getFormattedValue(lockedTokensField, formatDecimalValue)}
                        valueCaption={getFormattedValue(
                          lockedTokensSupplyField,
                          (val) => `${formatDecimalValue(val)}%`,
                        )}
                      />
                      {lockedTokensField !== 0 && (
                        <PreviewRowItem
                          label="Next Unlock Date"
                          value={
                            nextUnlockDateField ? (
                              <TimestampTooltip timestamp={new Date(nextUnlockDateField).toISOString()}>
                                <span>
                                  {getFormattedDate(nextUnlockDateField, {
                                    hour: undefined,
                                    minute: undefined,
                                  })}
                                </span>
                              </TimestampTooltip>
                            ) : (
                              NOT_FOUND_LABEL
                            )
                          }
                        />
                      )}
                      <PreviewRowItem
                        label="Tokens in AMM"
                        value={getFormattedValue(tokensInAmmField, formatDecimalValue)}
                        valueCaption={getFormattedValue(tokensInAmmSupplyField, (val) => `${formatDecimalValue(val)}%`)}
                      />
                    </>
                  )}
                  <PreviewRowItem
                    label="Total Tokens"
                    value={getFormattedValue(tokenSummary?.totalSupplyNanos, (val) =>
                      formatDecimalValue(tokenNanosToInteger(val)),
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}

      {!isWrapped && (
        <>
          <h2 className="flex items-center gap-2 text-xs font-semibold text-muted">
            Token Properties{' '}
            <InfoTooltip
              iconSize={14}
              text={
                <>
                  The creator of a token can adjust various properties, and even mint more coins. The safest coins have
                  updates fully disabled on all properties, making them virtually &#34;un-ruggable.&#34;
                </>
              }
            />
          </h2>
          <div className="grid w-full items-start rounded-xl border bg-accent">
            <div className="flex flex-col items-start text-left text-xs">
              <div className="flex w-full items-center justify-between p-3 pb-0">
                <h2 className="text-xs font-semibold">Rewards</h2>
              </div>
              <PreviewRowItem
                label="Revenue Share"
                value={
                  formatDecimalValue(basisPointsToPercent(coinProperties.CreatorRevsharePercentageBasisPoints)) + '%'
                }
                tooltipText={`This is the percentage of a creator's earnings that go to holders of their token. Every time a creator earns money on Openfund, owners of their token can get a distribution so they share in the creator’s growth on the platform.`}
              />
              <TradingFeeRowItem
                publicKey={coinProperties.Profile.PublicKeyBase58Check}
                username={username}
                coinProperties={coinProperties}
              />
              <div className="flex w-full items-center justify-between p-3 pb-0">
                <h2 className="text-xs font-semibold">Yield Curve</h2>
              </div>
              <PreviewRowItem
                label="APY"
                value={formatDecimalValue(basisPointsToPercent(coinProperties.CoinApyBasisPoints)) + '%'}
                tooltipText="Holders of a token can earn a yield for locking up their token for a certain amount of time. This can create more demand for a token. The APY is the “Annual Percentage Yield” for this particular token."
              />
              {coinProperties.CoinApyBasisPoints > 0 && (
                // TODO: redo it better
                <PreviewRowItem
                  label="Minimum Lockup Duration for Yield"
                  value={pluralize(
                    Math.floor(coinProperties.MinLockupDurationNanos / 1e9 / 60 / 60 / 24 / 365),
                    'year',
                  )}
                />
              )}
              <>
                <PreviewRowItem
                  label="Updating Trading Fees"
                  tooltipText="When trading fee updates are disabled, this gives traders the certainty that the fee will never change suddenly in the future."
                  value={coinProperties.DisableTradingFeeUpdate ? 'Disabled' : 'Enabled'}
                />
                <PreviewRowItem
                  label="Updating Revenue Share"
                  tooltipText="When revenue share updates are disabled, this gives traders the certainty that the revenue share percentage will never decrease in the future."
                  value={coinProperties.DisableCreatorRevshareUpdate ? 'Disabled' : 'Enabled'}
                />
              </>
              <PreviewRowItem
                label="Minting New Tokens"
                tooltipText="When token minting is disabled, it gives traders certainty that nobody will ever be able to mint more tokens in the future, thus guaranteeing a permanently fixed supply of the coin. Beware of coins that haven&#39;t disabled this property."
                value={coinProperties.Profile.DAOCoinEntry?.MintingDisabled ? 'Disabled' : 'Enabled'}
              />
              <PreviewRowItem
                label="Updating Transfer Status"
                tooltipText={`DeSo Tokens come with the option to restrict transfers in various ways. Disabling transfer restriction updates gives traders certainty around their ability to send their coin to others.`}
                value={
                  coinProperties.Profile.DAOCoinEntry?.TransferRestrictionStatus === 'unrestricted'
                    ? 'Enabled'
                    : 'Disabled'
                }
              />
            </div>
          </div>
        </>
      )}

      {data.hasAmmConfigs && ammConfig && !isWrapped && (
        <>
          <h2 className="text-xs font-semibold text-muted">AMM Summary</h2>
          <div className="grid w-full items-start rounded-xl border bg-accent">
            <div className="flex flex-col items-start text-left text-xs">
              <div className="flex w-full items-center justify-between p-3 pb-0">
                <h2 className="text-xs font-semibold">AMM Stats</h2>
              </div>
              <PreviewRowItem
                label="USD in AMM Bids"
                value={formatUSD(coinProperties.UsdInAmmBidsTotal)}
                tooltipText="This is the amount of USD that the AMM is offering on the buy side of this token's market. A higher number here generally implies that the AMM can absorb more selling pressure without the price going down as much."
              />
              <PreviewRowItem
                label="Tokens in AMM Asks"
                value={formatDecimalValue(coinProperties.CoinsInAmmAsksTotal)}
                tooltipText="This is the USD it would take to buy all of the tokens currently being used to provide liquidity by the AMM. A high value here could imply that the AMM is putting a lot of sell pressure on the market, but it could also just be that the AMM has a lot of orders placed at very high prices. You would need to look at the spacing of the AMM's orders to know for sure."
              />
              <div className="flex w-full items-center justify-between p-3">
                <h2 className="text-xs font-semibold">AMM Config</h2>
                <div>
                  <button
                    onClick={() => setShowAmmConfig(!showAmmConfig)}
                    className="text-xs text-muted underline underline-offset-4 hover:text-muted-foreground"
                  >
                    {showAmmConfig ? 'Hide' : 'Details'}
                  </button>
                </div>
              </div>

              {showAmmConfig && (
                <>
                  <PreviewRowItem
                    label="Start Price USD"
                    value={<LowNumFormatter price={ammConfig.StartPriceUsd} className="text-xs" />}
                  />
                  <PreviewRowItem
                    label="Order Spacing"
                    value={formatDecimalValue(basisPointsToPercent(ammConfig.OrderSpacingBasisPoints)) + '%'}
                  />
                  <PreviewRowItem
                    label="Amount Per Level USD"
                    value={<LowNumFormatter price={ammConfig.BaseAmountPerLevelUsd} className="text-xs" />}
                  />

                  {ammConfig.IncreaseBaseAmountByUsdEachLevel > 0 ? (
                    <PreviewRowItem
                      label="Amount Increase by USD"
                      value={
                        <LowNumFormatter
                          price={ammConfig.IncreaseBaseAmountByUsdEachLevel}
                          maxDecimalsDigitsAfterZero={4}
                          className="text-xs"
                        />
                      }
                    />
                  ) : (
                    <PreviewRowItem
                      label="Amount Increase Per Level"
                      value={
                        formatDecimalValue(basisPointsToPercent(ammConfig.IncreaseBaseAmountByBasisPointsEachLevel)) +
                        '%'
                      }
                    />
                  )}

                  <PreviewRowItem
                    label="Terminal Price USD"
                    value={<LowNumFormatter price={ammConfig.TerminalPriceUsd} className="text-xs" />}
                  />
                  <PreviewRowItem
                    label="Terminal Amount USD"
                    value={<LowNumFormatter price={ammConfig.TerminalAmountUsd} className="text-xs" />}
                  />
                  <PreviewRowItem
                    label="Final Price USD"
                    value={<LowNumFormatter price={ammConfig.FinalPriceUsd} className="text-xs" />}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

type PreviewRowItemProps = {
  label: ReactNode;
  value: ReactNode;
  className?: string;
  labelClassName?: string;
  valueCaption?: string;
  tooltipText?: string | ReactNode;
  footerText?: string | ReactNode;
};

const TradingFeeRowItem = ({
  publicKey,
  username,
  coinProperties,
}: {
  publicKey: string;
  username: string;
  coinProperties: GetCoinPropertiesResponse;
}) => {
  if (!publicKey) {
    return null;
  }

  return (
    <PreviewRowItem
      label="Trading Fees"
      value={formatDecimalValue(basisPointsToPercent(coinProperties.TotalTradingFeeBasisPoints || 0)) + '%'}
      tooltipText={
        <PricePerToken
          username={username}
          publicKey={publicKey}
          leadingText={
            "Openfund allows a token's creator to set fees for their market (thanks to the DeSo DEX). The fees for this market are listed below. All fees are taker-only right now (maker fees are zero). Fees paid to Focus and Openfund are used to buy & burn $FOCUS and $OPENFUND respectively."
          }
        />
      }
    />
  );
};

export const PreviewRowItem = ({
  label,
  valueCaption,
  className = '',
  labelClassName = '',
  value,
  tooltipText = '',
  footerText = '',
}: PreviewRowItemProps) => {
  return (
    <div className="border-b border-border-light px-3 py-2 last:border-none w-full">
      <div className={cn('flex w-full items-start justify-between', className)}>
        <div className="flex w-full max-w-[65%] flex-row items-end gap-2">
          <div className="flex items-start gap-1 text-xs text-muted">
            <span className={cn(labelClassName)}>{label}</span>
            {tooltipText && <InfoTooltip iconSize={14} iconClassName="ml-1 mt-0.5" text={tooltipText} />}
          </div>
        </div>
        <div className="flex flex-col items-end text-right text-xs text-foreground">
          <div className="inline-block text-muted hover:text-foreground">
            {value === 'Disabled' && <LuCheck className="mr-2 inline-flex text-lg text-green-500" />}
            {value === 'Enabled' && <LuX className="mr-2 inline-flex text-lg text-red-500" />}
            <div className="inline-block font-mono text-muted-foreground">{value}</div>{' '}
          </div>
          {valueCaption && (
            <div>
              <span className="inline-block text-muted hover:text-foreground">{valueCaption}</span>
            </div>
          )}
        </div>
      </div>

      {footerText}
    </div>
  );
};
