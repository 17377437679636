import { useLazyQuery } from '@apollo/client';
import { AccountExtendedByPublicKeyDocument } from 'graphql/codegen/graphql';
import { getTransactionInfo, TransactionResponse } from 'deso-protocol';
import { useEffect, useRef, useState } from 'react';
import { useToast } from './useToast';
import { getErrorMsg } from 'utils/getErrorMsg';
import minBy from 'lodash/minBy';

export interface UseAccountDetailsResult {
  loading: boolean;
  error: Error | null;
  account: any;
  accountFirstTransaction: any;
}

export const useAccountDetails = (
  publicKey: string | undefined,
  initialLoading: boolean = false,
): UseAccountDetailsResult => {
  const toast = useToast();
  const [accountFirstTransaction, setAccountFirstTransaction] = useState<any>(null);
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState<Error | null>(null);
  const previousKeyRef = useRef<string | undefined>(undefined);

  const [fetchAccount, { loading: loadingExtendedAccount, data: extendedAccount }] = useLazyQuery(
    AccountExtendedByPublicKeyDocument,
  );

  useEffect(() => {
    if (!publicKey || previousKeyRef.current === publicKey) {
      return;
    }

    previousKeyRef.current = publicKey;

    const fetchData = async () => {
      setLoading(true);

      try {
        const [_, transactionInfo] = await Promise.all([
          fetchAccount({
            variables: {
              publicKey,
              filter: {
                creator: {
                  userAssociationsAsTarget: {
                    some: {
                      transactor: {
                        publicKey: {
                          equalTo: publicKey,
                        },
                      },
                    },
                  },
                },
              },
            },
          }),
          getTransactionInfo({
            LastPublicKeyTransactionIndex: 1,
            LastTransactionIDBase58Check: '',
            Limit: 100,
            PublicKeyBase58Check: publicKey,
          }),
        ]);

        setAccountFirstTransaction(
          minBy(transactionInfo?.Transactions, (tx: TransactionResponse) => tx.BlockInfo?.Height),
        );
      } catch (e) {
        setError(e as Error);
        toast.show({ message: getErrorMsg(e), type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [publicKey, fetchAccount, toast]);

  return {
    loading: loading || loadingExtendedAccount,
    error,
    account: extendedAccount?.accountByPublicKey,
    accountFirstTransaction,
  };
};
