import { Tab } from '@headlessui/react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/ui/select';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { classNames } from 'utils/classNames';

export interface TabGroup {
  tab: ReactNode;
  panel: ReactNode;
}
interface TabsProps {
  tabs: TabGroup[];
  defaultIndex?: number;
  selectedIndex?: number;
  onTabSelected?: (tabGroup: TabGroup) => void;
  overflow?: boolean;
  tabListClassName?: string;
  showSelect?: boolean;
}
export function Tabs({
  tabs,
  defaultIndex,
  selectedIndex,
  onTabSelected,
  overflow = true,
  tabListClassName,
  showSelect = true,
}: TabsProps) {
  const [internalSelectedIndex, setInternalSelectedIndex] = useState(defaultIndex ?? selectedIndex ?? 0);

  useEffect(() => {
    if (typeof selectedIndex === 'number' && selectedIndex >= 0) {
      setInternalSelectedIndex(() => selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (onTabSelected) {
      onTabSelected(tabs[internalSelectedIndex]);
    }
  }, [internalSelectedIndex]);

  return (
    <Tab.Group
      defaultIndex={defaultIndex ?? 0}
      selectedIndex={internalSelectedIndex}
      onChange={setInternalSelectedIndex}
    >
      {showSelect && (
        <div className="sm:hidden">
          <Select
            value={String(internalSelectedIndex)}
            onValueChange={(value) => setInternalSelectedIndex(Number(value))}
          >
            <SelectTrigger>
              <SelectValue>{tabs[internalSelectedIndex].tab}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              {tabs.map(({ tab }, i) => (
                <SelectItem key={i} value={String(i)}>
                  {tab}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      {tabs.length > 1 && (
        <Tab.List
          className={classNames(
            'border border-border border-b flex items-center gap-4 rounded-xl mb-4 p-2 px-4',
            tabListClassName,
            showSelect ? 'hidden sm:flex' : '',
          )}
        >
          {tabs.map(({ tab }, i) => (
            <Tab key={i} as={Fragment}>
              {({ selected }) => (
                <button
                  data-tab={`tab-${i}`}
                  className={classNames(
                    selected ? 'text-muted-foreground font-bold' : 'text-muted hover:text-foreground',
                    'px-0 text-sm',
                  )}
                >
                  {tab}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
      )}
      <Tab.Panels>
        {tabs.map(({ panel }, i) => (
          <Tab.Panel key={i} className={overflow ? 'overflow-hidden' : ''}>
            {panel}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
