import { FollowButton } from 'components/app-ui/FollowButton';
import { Avatar } from 'components/core/Avatar';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { OnboardingLayout } from 'components/pages/Onboarding';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useIsMounted } from 'hooks/useIsMounted';
import { useState } from 'react';
import { Routes } from 'RoutePaths';
import { deso, openfund } from 'services';
import { LeaderBoardData } from 'services/Openfund';
import { classNames } from 'utils/classNames';

export function FollowTopProjects() {
  const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useIsMounted();

  useEffectOnce(() => {
    setIsLoading(true);
    openfund
      .getTopProjects({ limit: 15, offset: 0 })
      .then((res) => {
        if (isMounted.current && res.length > 0) {
          setLeaderBoardData(res);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });
  });

  return (
    <OnboardingLayout
      title="Follow Projects"
      content={
        isLoading ? (
          <div className="text-center py-8">
            <Spinner />
          </div>
        ) : (
          <ul className="bg-gray-800 px-2 lg:px-8 pb-8 pt-2 rounded-xl lg:w-3/4 mx-auto">
            {leaderBoardData.map((d, i) => (
              <li
                key={d.PkidBase58check}
                className={classNames('flex items-center py-4', i < leaderBoardData.length - 1 ? 'border-b' : '')}
              >
                <Avatar src={deso.profilePicUrl(d.PkidBase58check)} className="mr-2 bg-gray-faint" />
                <span className="font-bold text-sm lg:text-md">${d.Username}</span>
                <FollowButton
                  kind="btn-tertiary-light"
                  size="sm"
                  shape="rounded"
                  className="ml-auto"
                  followeePublicKey={d.PkidBase58check}
                />
              </li>
            ))}
            <RouteLink kind="btn-primary" shape="rounded" to={Routes.activity()} className="mt-8">
              Continue
            </RouteLink>
          </ul>
        )
      }
    />
  );
}

export default FollowTopProjects;
