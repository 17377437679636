import { IoCloseCircleOutline } from 'react-icons/io5';
import { Button } from '../core/Button';

type PostImageProps = {
  src: string;
  imageUploading?: boolean;
  createPost?: boolean;
  onDelete?: () => void;
};

export function PostImage({ src, imageUploading, createPost, onDelete }: PostImageProps) {
  return (
    <div className={`${!src && !imageUploading ? 'hidden' : ''}`}>
      {imageUploading && <div>Uploading Photo ...</div>}
      {src && (
        <div className="post-image-container bg-background rounded-2xl border-border border">
          {createPost ? (
            <Button kind="text-only" onClick={onDelete} className="text-4xl post-delete-media hover:text-black">
              <IoCloseCircleOutline style={{ zIndex: 1000 }} />
            </Button>
          ) : (
            <></>
          )}
          <img className="post-image" src={src} loading="lazy" alt="" />
        </div>
      )}
    </div>
  );
}
