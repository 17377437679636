import { RouteLink } from 'components/core/RouteLink';
import { Tabs } from 'components/core/Tabs';
import { useContext } from 'react';
import { Routes } from 'RoutePaths';
import { getWrappedAsset } from '../../utils/deso';
import { TradeActivityTableWrapper } from './ActivityTableWrapper';
import { APP_NAME_GENERIC } from 'utils/constants';
import MyOrders from '../pages/MyOrders';
import { OpenfundContext } from '../../contexts/OpenfundContext';

const TRADE_ACTIVITY_TAB_LOCAL_STORAGE_KEY = 'tradeActivityPreferredTab';

export function OpenOrdersTable(props: {
  loggedInPublicKey: string | undefined;
  project: { Username: string; PublicKeyBase58Check: string };
  refreshOrderBookCallback: (skipOrderbookUpdate?: boolean) => void;
}) {
  const { currentUser } = useContext(OpenfundContext);

  const wrappedAsset = getWrappedAsset(props.project.Username);
  const isWrapped = !!wrappedAsset;
  const displayName = isWrapped ? wrappedAsset.displayName : props.project.Username;

  const onSuccessfulCancel = () => {
    props.refreshOrderBookCallback();
  };

  const tabs = [
    {
      tab: `$${displayName}`,
      panel: (
        <TradeActivityTableWrapper
          tokenPublicKey={props.project.PublicKeyBase58Check}
          defaultFilter={{
            isMatchedOrder: {
              equalTo: false,
            },
          }}
        />
      ),
    },
    ...(currentUser
      ? [
          {
            tab: 'Open Orders',
            panel: (
              <MyOrders
                baseCurrencyPublicKey={props.project.PublicKeyBase58Check}
                heading={
                  <div className="font-sans text-xl text-muted-foreground font-semibold">
                    <div className="flex justify-between items-center lg:items-end">My Orders</div>
                  </div>
                }
                onCancel={onSuccessfulCancel}
              />
            ),
          },
          {
            tab: 'Trades',
            panel: (
              <TradeActivityTableWrapper
                tokenPublicKey={props.project.PublicKeyBase58Check}
                traderPublicKey={currentUser.PublicKeyBase58Check}
                title="My Trades"
                subtitle={`View your recent trades on the ${APP_NAME_GENERIC}`}
              />
            ),
          },
        ]
      : []),
  ];
  const storedTab = window.localStorage.getItem(TRADE_ACTIVITY_TAB_LOCAL_STORAGE_KEY);

  return (
    <div className="w-full p-4 md:p-6">
      <header className="w-full flex items-center mb-4">
        <h2 className="text-lg font-semibold text-muted font-sans">Activity</h2>
        {props.loggedInPublicKey && (
          <div className="text-right ml-auto">
            <RouteLink kind="text-only" to={Routes.orders()}>
              <span className="hidden md:block text-muted hover:underline text-sm">View My Orders</span>
            </RouteLink>
          </div>
        )}
      </header>
      <Tabs
        showSelect={false}
        onTabSelected={(tabGroup) => {
          if (tabGroup?.tab) {
            window.localStorage.setItem(TRADE_ACTIVITY_TAB_LOCAL_STORAGE_KEY, tabGroup.tab.toString());
          }
        }}
        defaultIndex={(storedTab && currentUser && tabs.findIndex((t) => t.tab.toString() === storedTab)) || 0}
        tabs={tabs}
      />
    </div>
  );
}
